import React, { createContext, useState } from "react";

const DesignLibraryContext = createContext();

function DesignLibraryProvider({ children }) {
  const [designLibraryData, setDesignLibraryData] = useState(null);

  const updateDesignLibraryData = (newData) => {
    setDesignLibraryData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearDesignLibraryData = () => {
    setDesignLibraryData(null);
  };

  return (
    <DesignLibraryContext.Provider
      value={{
        designLibraryData,
        updateDesignLibraryData,
        clearDesignLibraryData,
      }}
    >
      {children}
    </DesignLibraryContext.Provider>
  );
}

export { DesignLibraryContext, DesignLibraryProvider };
