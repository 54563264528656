/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import "./News.scss";
import loader from "../../assets/images/workspace/upscale-gif.gif";
import filter from "../../assets/images/icons/filter.png";
import filtera from "../../assets/images/icons/filter-a.png";
import {
  GetAllNewsWithPagination,
  GetAllTeamNotifications,
} from "../../utils/apiCalls";
import { UserSocketContext } from "../../store/UserSocketData";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import { Popover } from "antd";
import { UserContext } from "../../store/UserDetailsStore";
import { useTranslation } from "react-i18next";

const News = () => {
  const { t } = useTranslation();
  const options = [
    {
      id: 0.1,
      value: t("all_news"),
    },
    {
      id: 1,
      value: t("platform_news"),
    },
    {
      id: 2,
      value: t("subscription_news"),
    },
    {
      id: 3,
      value: t("team_news"),
    },
    {
      id: 4,
      value: t("updates_news"),
    },
    {
      id: 5,
      value: t("other_news"),
    },
  ];

  const { updateSocketData } = useContext(UserSocketContext);
  const { handleTeamMemberNofications } = useContext(UserSocketContext);
  const { handleNews } = useContext(UserContext);
  const newsApiRef = useRef(false);

  const [newsDataList, setNewsDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectDropdown, setSelectDropdown] = useState(options[0]);

  let lang = localStorage?.getItem("language");

  useEffect(() => {
    if (selectDropdown) {
      GetAllNewsData(selectDropdown?.id);
    }
  }, [selectDropdown]);

  const GetAllNewsData = (id) => {
    setIsLoading(true);
    const language = lang === "ch" ? true : false;
    if (!newsApiRef.current) {
      newsApiRef.current = true;
      if (id === 3) {
        GetAllTeamNotifications((res) => {
          if (res.status === 200) {
            const data = res?.data?.filter(
              (notification) => notification?.id || notification?.teamId
            );
            handleTeamMemberNofications();
            setIsLoading(false);
            setNewsDataList(data);
          } else {
            setIsLoading(false);
          }
          if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          }
        }, language);
      } else {
        GetAllNewsWithPagination(
          (res) => {
            if (res.status === 200) {
              setIsLoading(false);
              const data = res?.data?.filter(
                (notification) => notification?.id || notification?.teamId
              );
              setNewsDataList(data);
            } else {
              setIsLoading(false);
            }
            if (res?.message === "Invalid access token") {
              updateSocketData({
                isExitPopup: true,
              });
            }
          },
          language,
          id
        );
      }
    }
  };

  const handleOpen = (isOpen) => {
    setOpen(isOpen);
  };

  const dropdown = () => {
    return (
      <div className="dropdown">
        {options?.map((opt) => {
          const active = selectDropdown?.id === opt?.id;
          return (
            <div
              key={opt?.id}
              onClick={() => handleSelectDropdown(opt)}
              className={`dropdown__option ${active ? "active" : ""}`}
            >
              <h5>{opt?.value}</h5>
            </div>
          );
        })}
      </div>
    );
  };

  const handleSelectDropdown = (opt) => {
    newsApiRef.current = false;
    setNewsDataList([]);
    setSelectDropdown(opt);
    setOpen(false);
  };

  return (
    <UserPortalLayout>
      <div className="news" onClick={() => handleNews()}>
        <div className="news__container">
          <div className="news__sections" onClick={(e) => e.stopPropagation()}>
            <div className="news__main" data-scrollable="true">
              <div className="news__main__heading">
                <div className="news__main__heading__left">
                  <h4>{selectDropdown?.value}</h4>
                </div>
                <Popover
                  overlayClassName="news-dropdown-popover"
                  content={dropdown()}
                  arrow={false}
                  trigger="click"
                  placement="bottomRight"
                  open={open}
                  onOpenChange={handleOpen}
                >
                  <div
                    className={`news__main__heading__right ${
                      open ? "active" : ""
                    }`}
                  >
                    <img className="default" src={filter} alt="filter-icon" />
                    <img className="active" src={filtera} alt="filter-icon" />
                    <h5>{t("filter")}</h5>
                  </div>
                </Popover>
              </div>
              <div className="news__main__section">
                {newsDataList?.length !== 0 ? (
                  newsDataList?.map((news, index) => {
                    const formattedMessage = news?.description
                      ? news?.description?.trim()?.replace(/\\n/g, "<br>")
                      : "";
                    const isTeam = news?.teamId;
                    const fullStop = news?.description?.trim().endsWith(".");
                    return (
                      <div className="news__main__card" key={index}>
                        {!isTeam ? (
                          <div className="news__main__card__container">
                            <div className="news__main__card__heading">
                              <div className="news__main__card__heading__left">
                                <h3>{news.title}</h3>
                                <span>{news.subTitle}</span>
                              </div>
                              <div className="news__main__card__heading__right">
                                <h6>
                                  {new Date(news.createdDate)
                                    .toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })
                                    .replace(/\//g, ".")}
                                </h6>
                              </div>
                            </div>
                            <div className="news__main__card__content">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: formattedMessage,
                                }}
                              ></p>
                            </div>
                          </div>
                        ) : (
                          <div className="news__main__card__container">
                            <div className="news__main__card__heading">
                              <div className="news__main__card__heading__left">
                                <div className="news__main__card__heading__left__image">
                                  <img
                                    src={news?.teamAvatarUrl}
                                    alt={news?.teamName}
                                  />
                                  <h3>
                                    {t("team")} : {news.teamName}
                                  </h3>
                                </div>
                                <span>
                                  {news?.description}
                                  {lang !== "ch" && !fullStop && "."}
                                </span>
                              </div>
                              <div className="news__main__card__heading__right">
                                <h6>
                                  {new Date(news?.createdDate)
                                    .toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })
                                    .replace(/\//g, ".")}
                                </h6>
                              </div>
                            </div>
                            <div className="news__main__card__content">
                              <p>{news?.message}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="gif">
                    {isLoading ? (
                      <img src={loader} alt="gif-loader" />
                    ) : (
                      <p style={{ width: "100%", textAlign: "center" }}>
                        {t("no_data_found")}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserPortalLayout>
  );
};

export default News;
