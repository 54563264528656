import React from "react";

const AccountIcon = ({fill, size = "22"}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0003 11.0006C11.9893 11.0006 12.956 10.7073 13.7783 10.1579C14.6006 9.60845 15.2415 8.82751 15.62 7.91383C15.9984 7.00014 16.0975 5.99475 15.9045 5.02479C15.7116 4.05483 15.2353 3.16386 14.536 2.46456C13.8367 1.76525 12.9458 1.28902 11.9758 1.09608C11.0058 0.903144 10.0005 1.00217 9.08677 1.38063C8.17308 1.75909 7.39214 2.39999 6.8427 3.22228C6.29326 4.04458 6 5.01133 6 6.0003C6.00132 7.32605 6.52856 8.59713 7.46601 9.53458C8.40347 10.472 9.67454 10.9993 11.0003 11.0006ZM11.0003 2.66677C11.6596 2.66677 12.3041 2.86228 12.8523 3.22857C13.4005 3.59486 13.8278 4.11549 14.0801 4.72461C14.3324 5.33373 14.3984 6.004 14.2698 6.65064C14.1411 7.29728 13.8237 7.89126 13.3575 8.35746C12.8913 8.82366 12.2973 9.14115 11.6506 9.26978C11.004 9.3984 10.3337 9.33239 9.72461 9.08008C9.11549 8.82777 8.59486 8.4005 8.22857 7.85231C7.86227 7.30411 7.66677 6.65961 7.66677 6.0003C7.66677 5.11619 8.01798 4.26829 8.64313 3.64314C9.26829 3.01798 10.1162 2.66677 11.0003 2.66677Z"
        fill={fill}
      />
      <path
        d="M11.0004 12.668C9.01188 12.6702 7.10539 13.4611 5.69927 14.8672C4.29314 16.2734 3.50221 18.1798 3.5 20.1684C3.5 20.3894 3.5878 20.6014 3.74409 20.7577C3.90038 20.914 4.11236 21.0018 4.33338 21.0018C4.55441 21.0018 4.76638 20.914 4.92267 20.7577C5.07896 20.6014 5.16677 20.3894 5.16677 20.1684C5.16677 18.6212 5.78138 17.1374 6.87541 16.0434C7.96944 14.9494 9.45326 14.3347 11.0004 14.3347C12.5476 14.3347 14.0314 14.9494 15.1255 16.0434C16.2195 17.1374 16.8341 18.6212 16.8341 20.1684C16.8341 20.3894 16.9219 20.6014 17.0782 20.7577C17.2345 20.914 17.4465 21.0018 17.6675 21.0018C17.8885 21.0018 18.1005 20.914 18.2568 20.7577C18.4131 20.6014 18.5009 20.3894 18.5009 20.1684C18.4987 18.1798 17.7078 16.2734 16.3016 14.8672C14.8955 13.4611 12.989 12.6702 11.0004 12.668Z"
        fill={fill}
      />
    </svg>
  );
};

export default AccountIcon;
