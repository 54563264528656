import React from "react";

const TutorialIcon = ({fill, size = "22"}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7981_102894)">
        <path
          opacity="0.972"
          d="M9.24886 4.6481L9.23622 4.64924L9.22426 4.6535C8.69452 4.84222 8.33568 5.20911 8.15335 5.74684L8.14835 5.76161L8.14807 5.7772C8.11939 7.41118 8.11939 9.04516 8.14807 10.6791L8.14836 10.6955L8.15386 10.711C8.30187 11.1261 8.52688 11.432 8.83496 11.6153C9.14292 11.7985 9.52091 11.8515 9.96007 11.7861L9.97133 11.7844L9.98192 11.7802C10.2559 11.6725 10.519 11.5409 10.7712 11.3856L10.7719 11.3852L12.3832 10.3754L13.9945 9.36564L14.0041 9.35961L14.0122 9.35158C14.1764 9.18718 14.3052 8.99767 14.3983 8.78369L14.399 8.78396L14.4024 8.77277C14.5114 8.41268 14.5212 8.07772 14.4244 7.77181C14.3276 7.46587 14.127 7.19815 13.831 6.96763L13.8314 6.96708L13.8213 6.96094C12.6545 6.25589 11.4949 5.54007 10.3424 4.8135L10.3347 4.80865L10.3263 4.80527C9.98122 4.66692 9.62164 4.61456 9.24886 4.6481ZM9.70073 6.34336C10.7134 6.96221 11.721 7.59043 12.7237 8.22802C11.7256 8.86068 10.7245 9.48886 9.72037 10.1126C9.70185 8.85623 9.6953 7.59983 9.70073 6.34336Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
        <path
          opacity="0.994"
          d="M2.98424 1.25425L2.97062 1.25427L2.95749 1.25793C2.07878 1.5032 1.51542 2.07442 1.27649 2.96104L1.27316 2.97342L1.27305 2.98624C1.2444 6.48157 1.2444 9.97693 1.27305 13.4723L1.27315 13.4847L1.27628 13.4966C1.39104 13.9372 1.58865 14.3011 1.87167 14.5841C2.15469 14.8671 2.51862 15.0648 2.95917 15.1795L2.9713 15.1827L2.98384 15.1828C8.31232 15.2114 13.6408 15.2114 18.9693 15.1828L18.9818 15.1827L18.994 15.1795C19.4345 15.0648 19.7984 14.8671 20.0814 14.5841C20.3645 14.3011 20.5621 13.9372 20.6768 13.4966L20.68 13.4847L20.6801 13.4723C20.7087 9.97693 20.7087 6.48157 20.6801 2.98624L20.68 2.97385L20.6768 2.96185C20.5621 2.52131 20.3645 2.15738 20.0814 1.87436C19.7984 1.59134 19.4345 1.39373 18.994 1.27896L18.9818 1.27578L18.9692 1.27573C13.6409 1.25425 8.31255 1.24709 2.98424 1.25425ZM3.02975 3.01869C3.12135 2.92413 3.24967 2.85916 3.42254 2.82924C8.45918 2.8221 13.4958 2.82925 18.5324 2.85071C18.7102 2.8743 18.8397 2.93497 18.9303 3.02554C19.0209 3.11616 19.0816 3.24574 19.1051 3.42367C19.1337 6.6274 19.1337 9.8311 19.1051 13.0348C19.0816 13.2128 19.0209 13.3423 18.9303 13.433C18.8397 13.5235 18.7102 13.5842 18.5323 13.6078C13.4952 13.6364 8.45797 13.6364 3.42081 13.6078C3.24297 13.5842 3.11345 13.5235 3.02286 13.433C2.93224 13.3423 2.87156 13.2128 2.84799 13.0348C2.81942 9.83142 2.81942 6.62805 2.84798 3.42463C2.87517 3.24632 2.93776 3.11365 3.02975 3.01869Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
        <path
          opacity="0.985"
          d="M4.35078 16.3797L4.34301 16.3805L4.33546 16.3825C3.59325 16.5797 3.06202 17.0329 2.74756 17.7353L2.29448 17.7542L1.77886 17.7757L1.76145 17.7764L1.7453 17.783C1.527 17.8719 1.37114 18.0278 1.28221 18.2461L1.28149 18.2458L1.27824 18.2579C1.21722 18.485 1.2203 18.6925 1.30004 18.8729C1.37988 19.0536 1.53057 19.1936 1.73845 19.2971L1.75755 19.3067L1.77886 19.3075L2.29448 19.329L2.74681 19.3479C2.96526 19.8482 3.31613 20.2325 3.79717 20.4983L3.80428 20.5022L3.81194 20.5049C4.48729 20.7457 5.08713 20.7717 5.60335 20.5668C6.10473 20.3677 6.51154 19.957 6.82857 19.3504L13.4708 19.3291L20.174 19.3076L20.1934 19.3076L20.2114 19.3002C20.4297 19.2113 20.5855 19.0555 20.6745 18.8372L20.6752 18.8374L20.6784 18.8254C20.7395 18.5982 20.7364 18.3908 20.6566 18.2103C20.5768 18.0296 20.4261 17.8896 20.2182 17.7861L20.1973 17.7757L20.174 17.7756L13.4708 17.7541L6.83044 17.7328C6.58749 17.2376 6.26374 16.8714 5.85637 16.6423C5.43348 16.4044 4.92965 16.3193 4.35078 16.3797ZM4.33794 18.2099C4.41359 18.1181 4.5008 18.0475 4.59977 17.9969C4.948 17.9954 5.14597 18.0776 5.23956 18.206C5.33298 18.3341 5.35004 18.5462 5.24056 18.8747C5.12244 18.9928 5.00203 19.0571 4.87947 19.077C4.755 19.0973 4.61719 19.0737 4.46298 18.9965C4.34151 18.8767 4.27564 18.7546 4.2554 18.6302C4.23497 18.5047 4.25919 18.3656 4.33794 18.2099Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_7981_102894">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TutorialIcon;
