import React, { useState } from "react";
import "./HomeFooter.scss";
import logo from "../../../assets/images/home/airi_lab_logo.png";
import wechat from "../../../assets/images/home/footer_wechat.png";
import bilibili from "../../../assets/images/home/footer_bilibili.png";
import redbook from "../../../assets/images/home/footer_redbook.png";
import instagram from "../../../assets/images/home/footer_instagram.png";
import youtube from "../../../assets/images/home/footer_youtube.png";
import pintrest from "../../../assets/images/home/footer_pintrest.png";
import wechatTooltip from "../../../assets/images/home/airilab_wechat_qrcode.jpg";
import redbookTooltip from "../../../assets/images/home/airilab_redbook_qrcode.jpg";
import { useTranslation } from "react-i18next";
import SacnnerTooltip from "../ScannerTooltip/SacnnerTooltip";
import police from "../../../assets/images/icons/police-logo.png";

const HomeFooter = () => {
  const { t } = useTranslation();
  const [activeTooltip, setActiveTooltip] = useState({ id: 1, bool: false });

  const handleTooltip = (id) => {
    if (activeTooltip?.id !== id) {
      setActiveTooltip({ id: id, bool: true });
    } else if (activeTooltip?.id === id) {
      setActiveTooltip({ id: id, bool: !activeTooltip?.bool });
    }
  };

  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__container__top">
          <div className="footer__container__left">
            <div className="footer__container__left__top">
              <div className="footer__container__left__logo">
                <img src={logo} alt="airi-lab-beta_tag" />
              </div>
              <div className="footer__container__left__contact">
                <h5>{t("footer_contact")}</h5>
                <h5>{t("footer_time")}</h5>
              </div>
            </div>
            <div className="footer__container__left__social">
              <div className="footer__container__left__social__sec">
                <img
                  src={wechat}
                  alt="wechat-icon"
                  onClick={() => handleTooltip(1)}
                />
                <div
                  className={`sec-tooltip ${
                    activeTooltip?.id === 1 && activeTooltip?.bool
                      ? "active"
                      : ""
                  }`}
                >
                  <SacnnerTooltip
                    tooltipContent={t("wechat_tooltip")}
                    image={wechatTooltip}
                    left="-60px"
                    bottom="30px"
                  />
                </div>
              </div>
              <div className="footer__container__left__social__sec">
                <a
                  href="https://www.bilibili.com/video/BV1s14y1C7NP/?spm_id_from=333.999.0.0"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={bilibili} alt="bilibili-icon" />
                </a>
              </div>
              <div
                className="footer__container__left__social__sec"
                onClick={() => handleTooltip(2)}
                key={activeTooltip}
              >
                <img src={redbook} alt="redbook-icon" />
                <div
                  className={`sec-tooltip ${
                    activeTooltip?.id === 2 && activeTooltip?.bool
                      ? "active"
                      : ""
                  }`}
                >
                  <SacnnerTooltip
                    tooltipContent={t("redbook_tooltip")}
                    image={redbookTooltip}
                    left="-60px"
                    bottom="30px"
                  />
                </div>
              </div>
              <div className="footer__container__left__social__sec">
                <a
                  href=" https://www.pinterest.fr/AIRIlab2023/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={pintrest} alt="instagram-icon" />
                </a>
              </div>
              <div className="footer__container__left__social__sec">
                <a
                  href="https://www.instagram.com/airi.lab/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} alt="instagram-icon" />
                </a>
              </div>
              <div className="footer__container__left__social__sec">
                <a
                  href="https://www.youtube.com/channel/UCfn6bdOQkrVUY2-bpD-Dgkg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={youtube} alt="youtube-icon" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer__container__right">
            <div className="footer__container__right__sec">
              <div className="footer__container__right__sec__header">
                <h5>{t("footer_option1_heading")}</h5>
              </div>
              <div className="footer__container__right__sec__options">
                <h4>{t("footer_option1_sec1")}</h4>
                <h4>{t("footer_option1_sec2")}</h4>
                <h4>{t("footer_option1_sec3")}</h4>
              </div>
            </div>
            <div className="footer__container__right__sec">
              <div className="footer__container__right__sec__header">
                <h5>{t("footer_option2_heading")}</h5>
              </div>
              <div className="footer__container__right__sec__options">
                <h4>{t("footer_option2_sec1")}</h4>
                <h4>{t("footer_option2_sec2")}</h4>
                <h4>{t("footer_option2_sec3")}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__container__bottom">
          <h4>{t("footer_bottom_sec0")}</h4>
        </div>
        <div className="footer__container__bottom2">
          <h4>{t("footer_bottom_sec1")}</h4>
          <h5>{t("footer_bottom_sec2")}</h5>
          <h5>
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              rel="noreferrer"
              style={{color:'white'}}
            >
              {t("footer_bottom_sec3")}
            </a>
          </h5>
          <h5>             
            <a
              href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002003609"
              target="_blank"
              rel="noreferrer"
              style={{ display: 'flex', alignItems: 'center',color:'white'}}
            >
              <img src={police} style={{ width: '15px', height: '15px', marginRight: '2px' }} />
              {t("footer_bottom_sec4")}
            </a>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default HomeFooter;
