import React from "react";

const Logout = ({ fill = "#263238", size = "22" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.81818 3.22727C4.38726 3.22727 3.22727 4.38726 3.22727 5.81818V8.60101C3.22727 9.07798 2.84061 9.46465 2.36364 9.46465C1.88666 9.46465 1.5 9.07798 1.5 8.60101V5.81818C1.5 3.43331 3.43332 1.5 5.81818 1.5H16.1818C18.5667 1.5 20.5 3.43332 20.5 5.81818V16.1818C20.5 18.5667 18.5667 20.5 16.1818 20.5H5.81818C3.43332 20.5 1.5 18.5667 1.5 16.1818V14.1091C1.5 13.6321 1.88666 13.2455 2.36364 13.2455C2.84061 13.2455 3.22727 13.6321 3.22727 14.1091V16.1818C3.22727 17.6127 4.38726 18.7727 5.81818 18.7727H16.1818C17.6127 18.7727 18.7727 17.6127 18.7727 16.1818V5.81818C18.7727 4.38726 17.6127 3.22727 16.1818 3.22727H5.81818Z"
        fill={fill}
      />
      <path
        d="M7.33749 10.9975L7.33751 10.9962C7.34064 10.6573 7.47705 10.3333 7.71721 10.0944C7.71724 10.0944 7.71726 10.0944 7.71729 10.0943L10.2642 7.53968L10.2645 7.53939C10.3945 7.41009 10.5703 7.3375 10.7536 7.3375C10.9369 7.3375 11.1127 7.41009 11.2427 7.53939C11.3077 7.60391 11.3593 7.68066 11.3945 7.76521C11.4297 7.84979 11.4478 7.94052 11.4478 8.03215C11.4478 8.12378 11.4297 8.2145 11.3945 8.29909C11.3593 8.38365 11.3077 8.46041 11.2427 8.52493C11.2427 8.52495 11.2427 8.52497 11.2426 8.52499L9.46057 10.3029L15.1562 10.3029C15.3403 10.3029 15.5167 10.3761 15.6468 10.5063C15.7769 10.6365 15.85 10.813 15.85 10.9971C15.85 11.1812 15.7769 11.3577 15.6468 11.4879C15.5167 11.6181 15.3403 11.6913 15.1562 11.6913L9.46005 11.6913L11.2427 13.4752C11.2427 13.4752 11.2427 13.4752 11.2428 13.4753C11.3733 13.6051 11.447 13.7814 11.4477 13.9655C11.4483 14.1497 11.3758 14.3266 11.2461 14.4573C11.1164 14.588 10.9402 14.6618 10.756 14.6625C10.5719 14.6631 10.3951 14.5906 10.2645 14.4608L10.2642 14.4605L7.71729 11.9058C7.71723 11.9057 7.71718 11.9057 7.71712 11.9056C7.47545 11.6651 7.33894 11.3386 7.33749 10.9975Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default Logout;
