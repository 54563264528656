import React, { useState } from "react";
import "./AIProjectManagement.scss";
import gif from "../../assets/images/workspace/upscale-gif.gif";
import SideNavComponent from "../../components/SideNav/SideNavComponent";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";

const AIProjectMangement = () => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };
  return (
    <UserPortalLayout>
      <div className="ai-project">
        <SideNavComponent />
        <div className="ai-project__container">
          <div className="ai-project__sections">
            {/* {loading && <img src={gif} alt="gif" />} */}
            <iframe
              src="https://www.profly.net/website/#/"
              title="Embedded Page"
              width="100%"
              height="100%"
              onLoad={handleLoad}
            ></iframe>
          </div>
        </div>
      </div>
    </UserPortalLayout>
  );
};

export default AIProjectMangement;
