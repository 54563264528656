/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import cross from "../../../assets/images/workspace/icons/cross-icon.png";
import wipeOut from "../../../assets/images/icons/wipeOut.png";
import tick from "../../../assets/images/icons/tick-icon.png";
import lasso_erase from "../../../assets/images/icons/lasso_minus.png";
import lasso_plus from "../../../assets/images/icons/lasso_plus.png";
import lassoP_plus from "../../../assets/images/icons/lassoP_plus.png";
import manipulateCN from "../../../assets/images/icons/manipulateCN.png";
import brush from "../../../assets/images/icons/brush_default.png";
import rect from "../../../assets/images/icons/rectangle_default.png";
import invert from "../../../assets/images/icons/invert.png";
import showPreview from "../../../assets/images/icons/showPreview.png";
import undo_icon from "../../../assets/images/icons/undo_default.png";
import redo_icon from "../../../assets/images/icons/redo_default.png";
import zooming_icon from "../../../assets/images/icons/zooming_default.png";
import { RenderToolsetContext } from "../../../store/RenderToolsetData";
import { EditToolsetContext } from "../../../store/EditToolsetData";
import { uploadFile, isCanvasBlank } from "../../../utils/workspaceUtils";
import { useTranslation } from "react-i18next";
import TooltipTopNew from "../../Tooltips/TooltipTopNew";
import Draggable from "react-draggable";
import { Slider } from "antd";
import { ResizableBox } from "react-resizable";
import "../../../../../node_modules/react-resizable/css/styles.css";
import "./ImageMaskPopUp.scss";
import { ResponsiveDataContext } from "../../../store/ResponsiveData";

const brushList = [
  { text: "first", size: 10 },
  { text: "second", size: 40 },
  { text: "third", size: 70 },
  { text: "fourth", size: 100 },
  { text: "lasso", size: 100 },
  { text: "lasso_eraise", size: 100 },
  { text: "rect", size: 100 },
  { text: "rect_eraise", size: 100 },
  { text: "lassoP", size: 50 },
  { text: "lasso_eraiseP", size: 100 },
  { text: "drag", size: 100 },
  { text: "dragging", size: 100 },
];

const defaultBrushIndex = 2;

const mode = [
  "brush",
  "erase",
  "lasso",
  "lasso_erase",
  "rect",
  "rect_erase",
  "lassoP",
  "lasso_eraseP",
  "drag",
];

// this is for storing previous values in case there is a need to rework the mask
export let previousData = {
  previousBase64Mask: null,
  previousCNImageSize: { width: 200, height: 200 },
  previousCNImagePos: { x: 0, y: 0 },
  previousCNImageUrl: null,
};

export const maskFillColor = "rgba(255, 77, 79, 0.7)";
export const maskFillFullOpacity = "rgba(255, 77, 79, 1)";
export const maskFillColorL = [255, 77, 79, 200];
export const CNOpacity = 0.5;

export const resetMask = (mode) => {
  switch (mode) {
    case 0: //full reset
      previousData.previousBase64Mask = null;
      previousData.previousCNImageSize = { width: 200, height: 200 };
      previousData.previousCNImagePos = { x: 0, y: 0 };
      previousData.previousCNImageUrl = null;
      break;
    case 1: //just reset position and size
      // previousData.previousCNImageSize={width:200,height:200};
      // previousData.previousCNImagePos={x:0,y:0};
      break;
    default:
      break;
  }
};

// here is the canvas for background
let BGCanvas;
let BGctx;
// here is the main canvas where the user is performing actions
let canvas;
let ctx;
let BGtoCanvasRatio;
let CNImgRatio;
// Create an off-screen temp canvas and its context, this is to store temporary lasso
let offscreenCanvas;
let offscreenCtx;
// Create a preview canvas for viewing and overwriting too
let previewCanvas;
let previewCtx;

//drawing elements
let isDrawing = false;
let previousX = 0;
let previousY = 0;
let lassoPoints = [];
let rectStartPoints = [];
let lassoPPoints = [];
let currentMousePos = { x: 0, y: 0 };
let CNInitSize = { width: 200, height: 200 };
let CNImageInitScale = 1;

let debounceTimeout;
const MAX_STATES = 50;

const ImageMaskPopUp = ({
  setIsButtonDisabled,
  setIsLimitPopup,
  handleClosePopupGlobal,
  setGlobalErrorMessage,
}) => {
  const { t } = useTranslation();
  const [deviceType, setDeviceType] = useState("PC");
  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);
  const canvasRef = useRef(null);
  const parentCanvasRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const offscreenCanvasRef = useRef(null);
  const BGCanvasRef = useRef(null);
  const draggableRef = useRef(null);
  const sectionRef = useRef(null);
  const fullPageRef = useRef(null);
  const imageRef = useRef(null);
  
  const { updateRenderToolsetData } = useContext(RenderToolsetContext);
  const { editToolsetData, updateEditToolsetData } =
    useContext(EditToolsetContext);
  const { responsiveData } = useContext(ResponsiveDataContext);

  const [selectedBrush, setSelectedBrush] = useState(brushList[4]);
  const [selectedMode, setSelectedMode] = useState(mode[2]);
  const [imageDIm, setImageDIm] = useState([
    Math.floor((1 - 0.04 -0.04) * window.innerWidth),
    Math.floor((1 - 0.04 - 0.035 - 0.04) * window.innerHeight) - 32,
  ]);
  const [isDimensionsSet, setDimensionsSet] = useState(false);
  const [isSetCanvasDim, setCanvasDim] = useState(false);
  const [isManipulatingCNImage, setIsManipulatingCNImage] = useState(false);
  const [isShowingPreview, setIsShowingPreview] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [isLassoSelected, setIsLassoSelected] = useState(false);
  const [isEraserSelected, setIsEraserSelected] = useState(false);
  const [isRectSelectSelected, setIsRectSelectSelected] = useState(false);
  const [isRectEraserSelected, setIsRectEraserSelected] = useState(false);
  const [isLassoPSelected, setIsLassoPSelected] = useState(false);
  const [isEraserPSelected, setIsEraserPSelected] = useState(false);
  const [isBrushSelected, setIsBrushSelected] = useState(false);
  const [isBrushEraseSelected, setIsBrushEraseSelected] = useState(false);
  const [isChangingBrushSize, setIsChangingBrushSize] = useState(false);
  const [CNImagePosition, setCNImagePosition] = useState({ x: 0, y: 0 });
  const [CNImageSize, setCNImageSize] = useState(CNInitSize);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  });
  const [isZooming, setIsZooming] = useState(true);
  const [scale, setScale] = useState(1);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [startTranslate, setStartTranslate] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
 
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType("iPad/iPhone");
    } else if (/Android/.test(userAgent)) {
      setDeviceType("Android Device");
      // } else if (/Windows/.test(userAgent)) {
      //   setDeviceType('PC');
      // } else if (/Mac OS X/.test(userAgent)) {
      //   setDeviceType('PC');
    } else {
      setDeviceType("PC");
    }
  }, []);

  useEffect(() => {
    if (document.querySelector(".image-mask__main__section")) {
      setDimensionsSet(true);
    } //TODO: check if redundant, delete?
    fullPageRef.current.focus();
  }, []);

  useEffect(() => {
    if (isDimensionsSet) {
      canvas = canvasRef.current;
      BGCanvas = BGCanvasRef.current;
      offscreenCanvas = offscreenCanvasRef.current;
      previewCanvas = previewCanvasRef.current;

      ctx = canvas.getContext("2d", { willReadFrequently: true });
      BGctx = BGCanvas.getContext("2d", { willReadFrequently: true });
      offscreenCtx = offscreenCanvas.getContext("2d", {willReadFrequently: true});
      previewCtx = previewCanvas.getContext("2d", { willReadFrequently: true });

      if (previousData.previousBase64Mask) {
        // if there was a previous mask
        renderInitialImage().then(() => {
          loadMaskedImage();
          // No need for setTimeout here since we're already in an async flow
          if (
            previousData.previousCNImageUrl === editToolsetData?.originalCnImage
          ) {
            setCNImagePosition(previousData.previousCNImagePos);
            setCNImageSize(previousData.previousCNImageSize);
            draggableRef.current?.setState(previousData.previousCNImagePos);
            resetTools();
            setInitialTools();
          } else {
            resetTools();
            setInitialTools();
            setInitialCNImageDimensionsFromCanvas();
          }
        });
      } else {
        renderInitialImageAndResetCNImage();
      }
    }
  }, [isDimensionsSet]);

  const loadMaskedImage = () => {
    const mask = new Image();
    mask.onload = function () {
      ctx.drawImage(mask, 0, 0, canvas.width, canvas.height);
      offscreenCtx.drawImage(mask, 0, 0, canvas.width, canvas.height);
      previewCtx.drawImage(mask, 0, 0, canvas.width, canvas.height);
      const imageData = ctx.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );
      saveState(imageData);
    };
    mask.src = previousData?.previousBase64Mask;
  };

  const setInitialTools = () => {
      activateLasso();
  };

  const renderInitialImage = () => {
    return new Promise((resolve) => {
      const bg = new Image();
      bg.onload = function () {
        setCanvasDimensionsFromBackgroundImg(bg).then(resolve);
      };
      bg.src = setBGimage();
    });
  };

  const resetSize = () =>{
    setTranslateX(0);
    setTranslateY(0);
    setScale(1);
  }

  const renderInitialImageAndResetCNImage = () => {
    resetTools();
    setInitialTools();
    resetSize();
    return new Promise((resolve) => {
      const bg = new Image();
      bg.onload = function () {
        setCanvasDimensionsFromBackgroundImg(bg).then(() => {
          setInitialCNImageDimensionsFromCanvas();
          const imageData = ctx.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          );
          saveState(imageData);
          resolve();
        });
      };
      bg.src = setBGimage();
    });
  };

  const setCanvasDimensionsFromBackgroundImg = (img) => {
    return new Promise((resolve) => {
      setCanvasDim(false);

      let widthScale = imageDIm[0] / img.width;
      let heightScale = imageDIm[1] / img.height;
      BGtoCanvasRatio = Math.min(widthScale, heightScale);

      canvas.width =
        BGCanvas.width =
        offscreenCanvas.width =
        previewCanvas.width =
          img.width * BGtoCanvasRatio;
      canvas.height =
        BGCanvas.height =
        offscreenCanvas.height =
        previewCanvas.height =
          img.height * BGtoCanvasRatio;

      BGctx.drawImage(img, 0, 0, BGCanvas.width, BGCanvas.height);

      setCanvasDim(true);
      resolve(); // Resolve after all canvas operations are complete
    });
  };

  const setInitialCNImageDimensionsFromCanvas = () => {
    const CNImage = imageRef.current;
    if (CNImage) {
      //fit CN image again inside the BG image
      let CNwidthScale = canvas.width / CNImage.naturalWidth;
      let CNheightScale = canvas.height / CNImage.naturalHeight;
      CNImgRatio = Math.min(CNwidthScale, CNheightScale);
      CNInitSize = {
        width: CNImage.naturalWidth * CNImgRatio * CNImageInitScale,
        height: CNImage.naturalHeight * CNImgRatio * CNImageInitScale,
      };
      //reset both size and position
      setCNImageSize(CNInitSize);
      setCNImagePosition({ x: 0, y: 0 });
      previousData.previousCNImageSize = CNInitSize;
      draggableRef.current?.setState({
        x: 0,
        y: 0,
      });
    }
  };  

  const setBGimage = () => {
    const imageUrl = editToolsetData?.feedImageBaseImage
      ? editToolsetData?.feedImageBaseImage
      : editToolsetData?.uploadedBaseImage; //we are mostly using this actually
    return imageUrl;
  };

  // Function to save the current canvas state
  const saveState = (imageData) => {
    setUndoStack((prevStack) => {
      const newStack = [...prevStack];
      if (newStack.length >= MAX_STATES) {
        newStack.shift(); // Remove the oldest state to maintain the limit
      }
      newStack.push(imageData);
      return newStack;
    });
    setRedoStack([]); // Clear the redo stack
  };


  // Function to handle undo
  const undo = () => {
    resetCurrentDrawing();
    if (undoStack.length > 0) {
      setRedoStack((prevRedoStack) => [
        ...prevRedoStack,
        undoStack[undoStack.length - 1],
      ]);
      setUndoStack((prevUndoStack) => {
        const newUndoStack = prevUndoStack.slice(0, -1);
        const imageData = newUndoStack.length > 0 ? newUndoStack[newUndoStack.length - 1] : null;
        if (imageData) {
          offscreenCtx.putImageData(imageData, 0, 0);
          ctx.putImageData(imageData, 0, 0);
        } else {
          offscreenCtx.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        return newUndoStack;
      });
    }
  };

  // Function to handle redo
  const redo = () => {
    resetCurrentDrawing();
    if (redoStack.length > 0) {
      setRedoStack((prevRedoStack) => {
        const newRedoStack = [...prevRedoStack];
        const imageData = newRedoStack.pop();
        setUndoStack((prevUndoStack) => [...prevUndoStack, imageData]);
        offscreenCtx.putImageData(imageData, 0, 0);
        ctx.putImageData(imageData, 0, 0);
        return newRedoStack;
      });
    }
  };

  const retrievePostZoomCoordinates = (e) => {
    // Get the raw mouse coordinates
    const [mouseX, mouseY] = getCoordinates(e);

    // Transform the coordinates based on the current zoom and pan
    const transformedX = (mouseX) / scale;
    const transformedY = (mouseY) / scale;

    return [transformedX,transformedY]
  }

  const globalMouseDownHandler = (e) => {
    if(e.button === 1 || e.button === 2) return;
    if(isZooming) return;
    if (e.target === canvasRef.current) {
      if (selectedMode === mode[0] || selectedMode === mode[1]) {
        [previousX,previousY]=retrievePostZoomCoordinates(e);
        drawDot(previousX, previousY);
        isDrawing = true;
      } if (selectedMode === mode[2] || selectedMode === mode[3]) {
        [previousX,previousY]=retrievePostZoomCoordinates(e);
        isDrawing = true;
        ctx.beginPath();
        ctx.moveTo(previousX, previousY);
      } else if (selectedMode === mode[4] || selectedMode === mode[5]) {
        [previousX,previousY]=retrievePostZoomCoordinates(e);
        isDrawing = true;
        rectStartPoints = [previousX, previousY];
      } else if (selectedMode === mode[6] || selectedMode === mode[7]) {
        // do nothing for now still drawing, always is drawing, until double click
      }
    }
  };

  const globalMouseMoveHandler = (e) => {
    if (!isDrawing) return;
    const [currentX,currentY]=retrievePostZoomCoordinates(e);
    currentMousePos.x = currentX;
    currentMousePos.y = currentY;
    if (selectedMode === mode[0] || selectedMode === mode[1]) {
      drawLine(previousX, previousY, currentX, currentY);
      previousX = currentX; //move this and add this to lasso only and drawing only
      previousY = currentY;
    }else if (selectedMode === mode[2] || selectedMode === mode[3]) {
      lassoPoints.push({ x: currentX, y: currentY });
      drawLasso(currentX, currentY);
      previousX = currentX; //move this and add this to lasso only and drawing only
      previousY = currentY;
    } else if (selectedMode === mode[4] || selectedMode === mode[5]) {
      // drawing rect
      drawRectangle(currentX, currentY);
    } else if (selectedMode === mode[6] || selectedMode === mode[7]) {
      // drawing lassoP
      drawLassoP(currentX, currentY);
    }
  };

  const globalMouseUpHandler = (e) => {
    if(e.button === 1 || e.button === 2) return;
    if (selectedMode === mode[6] || selectedMode === mode[7]) {
      // drawing lassoP, starting to draw the first point
      if (isLassoPSelected) {
        isDrawing = true;
        [previousX, previousY] = retrievePostZoomCoordinates(e);
        lassoPPoints.push({ x: previousX, y: previousY });
      }
      return;
    }

    // else the following conditions need to be drawing otherwise ignore clicks
    if (!isDrawing) return;

    if(selectedMode === mode[0] || selectedMode === mode[1]){
      performBrushSelectAction();
      isDrawing = false;
    } else if (selectedMode === mode[2] || selectedMode === mode[3]) {
      isDrawing = false;
      // Close the lasso by connecting the last point to the first point
      if (lassoPoints.length > 0) {
        const firstPoint = lassoPoints[0];
        drawLasso(firstPoint.x, firstPoint.y);
      }
      // Perform lasso action
      performLassoAction();
      ctx.setLineDash([]); // reset pptern to black solid
      lassoPoints = [];
    } else if (selectedMode === mode[4] || selectedMode === mode[5]) {
      // drawing rect
      isDrawing = false;
      const [currentX, currentY] = retrievePostZoomCoordinates(e);
      performRectAction(currentX, currentY);
      ctx.setLineDash([]); // reset pattern to black solid
      rectStartPoints = [];
    }
  };

  const doubleClickHandler = () => {
    if (selectedMode === mode[6] || selectedMode === mode[7]) {
      // drawing rect
      // if (isDrawing && e.target === canvasRef.current) {
      if (isDrawing) {
        isDrawing = false;
        if (lassoPPoints.length > 0) {
          const firstPoint = lassoPPoints[0];
          drawLassoP(firstPoint.x, firstPoint.y);
        }
        performLassoPAction();
        lassoPPoints = [];
      }
    }
  };

  const mouseOutHandler = () => {
    if (selectedMode === mode[1]) {
      ctx.globalCompositeOperation = "source-over";
    }
  };

  const touchToMouse = (e) => {
    if (e.touches && e.touches[0]) { //When the touch event ends, the touches array is empty, but the changedTouches array still contains the relevant information. You need to make sure you are accessing the correct array in the touch event.
      return {
        clientX: e.touches[0].clientX,
        clientY: e.touches[0].clientY,
        target: e.target,
      };
    } else if (e.changedTouches && e.changedTouches[0]) {
      return {
        clientX: e.changedTouches[0].clientX,
        clientY: e.changedTouches[0].clientY,
        target: e.target,
      };
    }
    return { clientX: 0, clientY: 0, target: e.target }; // Or another value indicating an error or non-existent touch
  };

  const touchStartHandler = (e) => {
    // if (e.target.closest('button')) {
    //   return;
    // }
    const touchMouseEvent = touchToMouse(e);
    globalMouseDownHandler(touchMouseEvent);
  };

  const touchMoveHandler = (e) => {
    // if (e.target.closest('button')) {
    //   return;
    // }
    const touchMouseEvent = touchToMouse(e);
    globalMouseMoveHandler(touchMouseEvent);
  };

  const touchEndHandler = (e) => {
    // if (e.target.closest('button')) {
    //   return;
    // }
    const touchMouseEvent = touchToMouse(e);
    globalMouseUpHandler(touchMouseEvent);
  };

  const drawRectangle = (x, y) => {
    const existingImageData = offscreenCtx.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    );

    // Clear the previous drawing
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.putImageData(existingImageData, 0, 0);

    // Draw the stored points
    ctx.beginPath();
    ctx.rect(
      rectStartPoints[0],
      rectStartPoints[1],
      x - rectStartPoints[0],
      y - rectStartPoints[1]
    );

    // Set the border style for the guidance rectangle if in prescreen, else do nothing
    ctx.setLineDash([10, 5]);
    ctx.lineDashOffset = 0;
    ctx.strokeStyle = "rgba(0, 0, 0, 0.5)";
    ctx.lineWidth = 2;
    ctx.stroke(); // Draw the rectangle border without filling it
  };

  const drawLasso = (x, y) => {
    console.log('ttttt',scale)
    const existingImageData = offscreenCtx.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    );

    // Add the new point to the lassoPoints array
    lassoPoints.push({ x: x, y: y });

    // Clear the previous drawing
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.putImageData(existingImageData, 0, 0);

    // Draw the stored points
    ctx.setLineDash([10/scale, 5/scale]);
    ctx.lineDashOffset = 0;
    ctx.strokeStyle = "rgba(0, 0, 0, 1)";
    ctx.lineWidth = 2/scale;

    ctx.beginPath();
    ctx.moveTo(lassoPoints[0].x, lassoPoints[0].y);
    lassoPoints.forEach((point) => {
      ctx.lineTo(point.x, point.y);
    });
    ctx.stroke();
  };

  const drawLassoP = (x, y, reset = false) => {
    const existingImageData = offscreenCtx.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    );

    // Clear the previous drawing
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.putImageData(existingImageData, 0, 0);

    if (!reset) {
      // Begin a new path for the drawing
      ctx.beginPath();
      ctx.strokeStyle = "rgba(0, 0, 0, 1)";
      ctx.lineWidth = 2/scale;

      // Move to the first point
      ctx.moveTo(lassoPPoints[0].x, lassoPPoints[0].y);

      // Loop through all stored points and draw lines between them
      for (let i = 1; i < lassoPPoints.length; i++) {
        ctx.lineTo(lassoPPoints[i].x, lassoPPoints[i].y);
      }

      // Draw the final line from the last stored point to the current position
      ctx.lineTo(x, y);

      // Stroke the path
      ctx.stroke();
    }
  };

  const performLassoAction = () => {
    //previous mask
    const existingImageData = offscreenCtx.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    );

    if (lassoPoints.length > 0) {
      // Draw the new shape on the off-screen canvas, without stroke
      offscreenCtx.beginPath();
      offscreenCtx.moveTo(lassoPoints[0].x, lassoPoints[0].y);
      lassoPoints.forEach((point) => {
        offscreenCtx.lineTo(point.x, point.y);
      });
      offscreenCtx.closePath();

      if (selectedMode === mode[3]) {
        // Erase mode
        offscreenCtx.globalCompositeOperation = "destination-out";
        offscreenCtx.fillStyle = "rgba(0, 0, 0, 1)"; // 100% transparency
      } else {
        offscreenCtx.globalCompositeOperation = "source-over";
        offscreenCtx.fillStyle = maskFillColor;
      }

      offscreenCtx.fill();

      // new lasso
      const newImageData = offscreenCtx.getImageData(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      if (selectedMode !== mode[3]) {
        // Combine the existing image and the new shape
        for (let i = 0; i < newImageData.data.length; i += 4) {
          if (
            newImageData.data[i + 3] !== 0 ||
            existingImageData.data[i + 3] !== 0
          ) {
            existingImageData.data[i] = maskFillColorL[0];
            existingImageData.data[i + 1] = maskFillColorL[1];
            existingImageData.data[i + 2] = maskFillColorL[2];
            existingImageData.data[i + 3] = maskFillColorL[3];
          }
        }
        offscreenCtx.putImageData(existingImageData, 0, 0);
        ctx.putImageData(existingImageData, 0, 0);
        saveState(existingImageData);
      } else {
        ctx.putImageData(newImageData, 0, 0);
        saveState(newImageData);
        // In erase mode, we don't need to combine, because we already used 'destination-out'
        // So just use newImageData
      }
      // Put the modified image data back onto the main canvas, overwriting the existing lasso
      offscreenCtx.globalCompositeOperation = "source-over"; // Reset to default
    }
  };

  const performLassoPAction = () => {
    //previous mask
    const existingImageData = offscreenCtx.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    );

    if (lassoPPoints.length > 0) {
      // Draw the new shape on the off-screen canvas, without stroke
      offscreenCtx.beginPath();
      offscreenCtx.moveTo(lassoPPoints[0].x, lassoPPoints[0].y);
      lassoPPoints.forEach((point) => {
        offscreenCtx.lineTo(point.x, point.y);
      });
      offscreenCtx.closePath();

      if (selectedMode === mode[7]) {
        // Erase mode
        offscreenCtx.globalCompositeOperation = "destination-out";
        offscreenCtx.fillStyle = "rgba(0, 0, 0, 1)"; // 100% transparency
      } else {
        offscreenCtx.globalCompositeOperation = "source-over";
        offscreenCtx.fillStyle = maskFillColor;
      }

      offscreenCtx.fill();

      // new lasso
      const newImageData = offscreenCtx.getImageData(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      if (selectedMode !== mode[7]) {
        // Combine the existing image and the new shape
        for (let i = 0; i < newImageData.data.length; i += 4) {
          if (
            newImageData.data[i + 3] !== 0 ||
            existingImageData.data[i + 3] !== 0
          ) {
            existingImageData.data[i] = maskFillColorL[0];
            existingImageData.data[i + 1] = maskFillColorL[1];
            existingImageData.data[i + 2] = maskFillColorL[2];
            existingImageData.data[i + 3] = maskFillColorL[3];
          }
        }
        offscreenCtx.putImageData(existingImageData, 0, 0);
        ctx.putImageData(existingImageData, 0, 0);
        saveState(existingImageData);
      } else {
        ctx.putImageData(newImageData, 0, 0);
        saveState(newImageData);
        // In erase mode, we don't need to combine, because we already used 'destination-out'
        // So just use newImageData
      }

      // Put the modified image data back onto the main canvas, overwriting the existing lasso
      offscreenCtx.globalCompositeOperation = "source-over"; // Reset to default
    }
  };

  const performRectAction = (currentX, currentY) => {
    //previous mask
    const existingImageData = offscreenCtx.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    );

    if (rectStartPoints) {
      offscreenCtx.beginPath();
      offscreenCtx.rect(
        rectStartPoints[0],
        rectStartPoints[1],
        currentX - rectStartPoints[0],
        currentY - rectStartPoints[1]
      );

      if (selectedMode === mode[5]) {
        // Erase mode
        offscreenCtx.globalCompositeOperation = "destination-out";
        offscreenCtx.fillStyle = "rgba(0, 0, 0, 1)"; // 100% transparency
      } else {
        offscreenCtx.globalCompositeOperation = "source-over";
        offscreenCtx.fillStyle = maskFillColor;
      }

      offscreenCtx.fill();

      // new lasso
      const newImageData = offscreenCtx.getImageData(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      if (selectedMode !== mode[5]) {
        // Combine the existing image and the new shape
        for (let i = 0; i < newImageData.data.length; i += 4) {
          if (
            newImageData.data[i + 3] !== 0 ||
            existingImageData.data[i + 3] !== 0
          ) {
            existingImageData.data[i] = maskFillColorL[0];
            existingImageData.data[i + 1] = maskFillColorL[1];
            existingImageData.data[i + 2] = maskFillColorL[2];
            existingImageData.data[i + 3] = maskFillColorL[3];
          }
        }
        offscreenCtx.putImageData(existingImageData, 0, 0);
        ctx.putImageData(existingImageData, 0, 0);
        saveState(existingImageData);
      } else {
        ctx.putImageData(newImageData, 0, 0);
        saveState(newImageData);
        // In erase mode, we don't need to combine, because we already used 'destination-out'
        // So just use newImageData
      }

      // Put the modified image data back onto the main canvas, overwriting the existing lasso
      offscreenCtx.globalCompositeOperation = "source-over"; // Reset to default
    }
  };

  const performBrushSelectAction = () =>{
    const newImageData = ctx.getImageData(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );

    for (let i = 0; i < newImageData.data.length; i += 4) {
      if (
        newImageData.data[i + 3] !== 0 
        //wherever pixel is not transparent, change the color to semi transparent
      ) {
        newImageData.data[i] = maskFillColorL[0];
        newImageData.data[i + 1] = maskFillColorL[1];
        newImageData.data[i + 2] = maskFillColorL[2];
        newImageData.data[i + 3] = maskFillColorL[3];
      }
    }
    offscreenCtx.putImageData(newImageData, 0, 0); // store for previous state
    ctx.putImageData(newImageData, 0, 0); // show immediately on canvas
    saveState(newImageData);

    ctx.globalCompositeOperation = "source-over";
  };

  const performInvertAction = () => {
    resetTools();
    setInitialTools();
    const currentImageData = ctx.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    );

    for (let i = 0; i < currentImageData.data.length; i += 4) {
      if (
        currentImageData.data[i + 3] !== 0 //not empty
      ) {
        currentImageData.data[i] = 0;
        currentImageData.data[i + 1] = 0;
        currentImageData.data[i + 2] = 0;
        currentImageData.data[i + 3] = 0;
      } else {
        currentImageData.data[i] = maskFillColorL[0];
        currentImageData.data[i + 1] = maskFillColorL[1];
        currentImageData.data[i + 2] = maskFillColorL[2];
        currentImageData.data[i + 3] = maskFillColorL[3];
      }
    }

    ctx.putImageData(currentImageData, 0, 0);
    offscreenCtx.putImageData(currentImageData, 0, 0);
    saveState(currentImageData);

    ctx.globalCompositeOperation = "source-over";
  };

  const drawDot = (x, y) => {
    if (selectedMode === mode[0]) {
      ctx.globalCompositeOperation = "source-over"; // Default composite operation
      ctx.beginPath();
      ctx.arc(x, y, selectedBrush.size / (2*scale), 0, 2 * Math.PI);
      ctx.fillStyle = maskFillFullOpacity;
      ctx.fill();
    } else {
      ctx.globalCompositeOperation = "destination-out";
      ctx.beginPath();
      ctx.arc(x, y, selectedBrush.size / (2*scale), 0, 2 * Math.PI);
      ctx.fill();
      ctx.globalCompositeOperation = "source-over"; // Reset to default
    }
  };

  const drawLine = (x1, y1, x2, y2) => {
    const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    const step = 2;

    for (let t = 0; t <= 1; t += step / distance) {
      const x = x1 + t * (x2 - x1);
      const y = y1 + t * (y2 - y1);

      if (selectedMode === mode[0]) {
        ctx.beginPath();
        ctx.arc(x, y, selectedBrush.size / (2*scale), 0, 2 * Math.PI);
        ctx.fillStyle = maskFillFullOpacity;
        ctx.fill();
      } else {
        ctx.globalCompositeOperation = "destination-out";
        ctx.beginPath();
        ctx.arc(x, y, selectedBrush.size / (2*scale), 0, 2 * Math.PI);
        ctx.fill();
      }
    }
  };

  // Updated getCoordinates function to account for different origins when the mouse is outside the canvas
  const getCoordinates = (e) => {
    const { top, left } = canvas.getBoundingClientRect();
    return [
      // e.clientX - left + window.scrollX,
      // e.clientY - top + window.scrollY,
      e.clientX - left,
      e.clientY - top,
    ];
  };

  const handleBrushSizeChange = (value) => {
    value = value - 1;
    if (value >= 0 && value <= 3) {
      const newSize = brushList[value].size;
      setSelectedBrush(brushList[value]);
      document.documentElement.style.setProperty(
        "--cursor-size",
        `${newSize}px`
      );
    }
  };

  const handleBrushSizeChangeFromScrolling = (value) => {
    let selectedIndex = brushList
      ?.map((item) => item.text)
      ?.indexOf(selectedBrush.text);
    if(selectedIndex > 3) return; //means it could be lasso, and it should not change
    value === "+" ? selectedIndex++ : selectedIndex--;
    if (selectedIndex >= 0 && selectedIndex <= 3) {
      const newSize = brushList[selectedIndex].size;
      setSelectedBrush(brushList[selectedIndex]);
      document.documentElement.style.setProperty(
        "--cursor-size",
        `${newSize}px`
      );
    }
  };


  function makePreviewCanvasNoCN(isNoMask) {
    //replace current mask for user friendly presentation
    previewCtx.clearRect(0, 0, previewCanvas.width, previewCanvas.height); // Clear the previewCanvas, TODO: we no longer need this as we have added a canvas
    if (isNoMask) {
      //draw the original background
      previewCtx.globalCompositeOperation = "source-over";
      previewCtx.drawImage(
        BGCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height
      );
      previewCtx.fillStyle = maskFillColor; // Set the fill color to white
      previewCtx.fillRect(0, 0, previewCanvas.width, previewCanvas.height);
    } else {
      //draw the original background
      previewCtx.globalCompositeOperation = "source-over";
      previewCtx.drawImage(BGCanvas, 0, 0, canvas.width, canvas.height);
      //draw the mask on top
      previewCtx.globalCompositeOperation = "source-atop";
      previewCtx.drawImage(
        //TODO: remove the excess code, kept here just in case for rollback
        canvas,
        0,
        0,
        canvas.width,
        canvas.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
    }
  }

  function invertMask4API(
    mask,
    width,
    height,
    originalWidth,
    originalHeight,
    isNoMask
  ) {
    previewCtx.clearRect(0, 0, previewCanvas.width, previewCanvas.height); // Clear the canvas
    //reset the size to original bg
    previewCanvas.width = originalWidth;
    previewCanvas.height = originalHeight;
    // drawing the output mask
    if (isNoMask) {
      //if no mask then draw full white
      previewCtx.fillStyle = "#ffffff";
      previewCtx.fillRect(0, 0, originalWidth, originalHeight);
    } else {
      previewCtx.drawImage(
        mask,
        0,
        0,
        width,
        height,
        0,
        0,
        originalWidth,
        originalHeight
      );
      const previewImageData = previewCtx.getImageData(
        0,
        0,
        originalWidth,
        originalHeight
      );
      const data = previewImageData.data;

      for (let i = 0; i < data.length; i += 4) {
        const alpha = data[i + 3];

        if (alpha === 0) {
          //if transparent, make it full opaque black
          data[i] = 0;
          data[i + 1] = 0;
          data[i + 2] = 0;
          data[i + 3] = 255;
        } else {
          //make it full opaque white
          data[i] = 255;
          data[i + 1] = 255;
          data[i + 2] = 255;
          data[i + 3] = 255;
        }
      }
      previewCtx.putImageData(previewImageData, 0, 0);
    }
    return previewCanvas.toDataURL("image/png");
  }

  const handleFetchError = (error) => {
    console.error("Error during fetch:", error);
    throw error;
  };

  const handleUploadError = (error) => {
    console.error("Error during upload:", error);
    throw error;
  };

  const confirmHandler = () => {
    const imageType = "base-image";
    localStorage.setItem(
      `imageType_${lastPart}`,
      JSON.stringify(imageType)
    );
    resetTools();
    const base64String = canvas.toDataURL("image/png"); //create the base64string for the current mask

    // check if reupload is necessary
    if (
      base64String === previousData.previousBase64Mask &&
      CNImagePosition === previousData.previousCNImagePos &&
      CNImageSize === previousData.previousCNImageSize &&
      editToolsetData?.originalCnImage === previousData.previousCNImageUrl
    ) {
      updateRenderToolsetData({
        showPopUpForBaseImage: false,
      });
      return; //handling case for both, everything is exactly the same,image, pos and scale, and CN image Url is the same, do nothing
    }
    const isNoMask = isCanvasBlank(ctx, canvas.width, canvas.height); //no mask was drawn == it is all white == true, meaning user drew no mask
    if (
      !editToolsetData?.isFromDesignActivated &&
      isNoMask &&
      !previousData.previousBase64Mask
    ) {
      updateRenderToolsetData({
        showPopUpForBaseImage: false,
      });
      return; //handling no CN image default mask, do nothing, because no mask is drawn and there is no cn image, no thing to update;
    }
    if (
      editToolsetData?.isFromDesignActivated &&
      isNoMask &&
      CNImagePosition.x === previousData.previousCNImagePos.x &&
      CNImagePosition.y === previousData.previousCNImagePos.y &&
      CNImageSize === previousData.previousCNImageSize &&
      !previousData.previousBase64Mask
    ) {
      updateRenderToolsetData({
        showPopUpForBaseImage: false,
      });
      return; //handling cn image default mask, do nothing, because no mask is drawn, there is no cn image, and the cn image did not resize or tranpose;
    }

    setIsButtonDisabled(true); // disable generation button
    updatePreview(isNoMask, true);

    updateEditToolsetData({
      //upload mask viewport but wait for link
      maskImageData: base64String,
      maskImageBase64: null, //reset the mask in the FE
    });

    updateRenderToolsetData({
      showPopUpForBaseImage: false,
    });

    previousData = {
      // update pre-processed mask to be that of the current canvas.
      previousBase64Mask: base64String,
      previousCNImageSize: CNImageSize,
      previousCNImagePos: CNImagePosition,
      previousCNImageUrl: editToolsetData?.originalCnImage,
    };
    const previewImageBase64 = previewCanvas.toDataURL("image/png");

    updateEditToolsetData({
      //upload mask viewport with the link
      maskImageData: previewImageBase64,
      maskImageBase64: null,
    });

    let bg = new Image(); //renamed to background
    bg.src = setBGimage();
    bg.onload = () => {
      //invert the mask from ui change to ML needs: white = redo, black = hold
      const maskBase64 = invertMask4API(
        canvas,
        canvas.width, //TODO: get rid of these
        canvas.height,
        bg.width,
        bg.height,
        isNoMask
      );

      if (editToolsetData?.isFromDesignActivated === true) {
        //need to replace old base image to new, only applicable if there is a mask
        let bool = false;
        let CNImage = new Image();
        CNImage.src = editToolsetData?.originalCnImage; //TODO: change to originalCNImage
        CNImage.onload = () => {
          const newCNImageWithCN = updateCNImageWithCN(bg, CNImage);
          updateEditToolsetData({
            //upload viewport but wait for link
            cnImage: previewCanvas.toDataURL("image/png"),
            cnImageBase64: null, //reset the originalimage in the FE
          });

          const fetchMaskBase64 = fetch(maskBase64)
            .then((res) => res.blob())
            .then(
              (blob) =>
                new File([blob], "resized_image.jpeg", { type: "image/jpeg" })
            )
            .catch(handleFetchError);

          const fetchBaseImageBase64 = fetch(newCNImageWithCN)
            .then((res) => res.blob())
            .then(
              (blob) =>
                new File([blob], "resized_image.jpeg", { type: "image/jpeg" })
            )
            .catch(handleFetchError);

          Promise.all([fetchMaskBase64, fetchBaseImageBase64])
            .then(([maskedImageFile, newCNImageWithCN]) => {
              const uploadMaskedImage = () => {
                return new Promise((resolve, reject) => {
                  uploadFile(
                    "base-image",
                    bool,
                    maskedImageFile,
                    (data) => {
                      if (data) {
                        resolve({ maskImageBase64: data });
                      } else {
                        reject("Error: Uploaded mask image data is undefined.");
                      }
                    },
                    (error) => {
                      reject(`Error during mask image upload: ${error}`);
                    }
                  );
                });
              };

              const uploadNewCNImageWithCN = () => {
                return new Promise((resolve, reject) => {
                  uploadFile(
                    "cn-image",
                    bool,
                    newCNImageWithCN,
                    (data) => {
                      if (data && data.path) {
                        resolve({ cnImageBase64: data });
                      } else {
                        reject(
                          "Error: Uploaded new base image data is undefined or missing path."
                        );
                      }
                    },
                    (error) => {
                      reject(
                        `Error during new base image with CN upload: ${error}`
                      );
                    }
                  );
                });
              };

              Promise.all([uploadMaskedImage(), uploadNewCNImageWithCN()])
                .then(([maskImageResult, newCNImageResult]) => {
                  updateEditToolsetData({
                    //update link
                    ...maskImageResult,
                    ...newCNImageResult,
                  });
                  setIsButtonDisabled(false);
                })
                .catch(handleUploadError);
            })
            .catch(handleFetchError);
        };
      } else {
        let bool = false;
        updateEditToolsetData({
          //upload viewport but wait for link
          maskImageBase64: null, //reset the mask in the FE
        });

        fetch(maskBase64)
          .then((res) => res.blob())
          .then((blob) => {
            const maskedImageFile = new File([blob], "resized_image.jpeg", {
              type: "image/jpeg",
            });
            uploadFile(
              "base-image",
              bool,
              maskedImageFile,
              (data) => {
                updateEditToolsetData({
                  //update link
                  maskImageBase64: data,
                });
              },
              () => setIsButtonDisabled(false),
              (error) => {handleRateLimitPopup(error);
                updateEditToolsetData({
                  maskImageBase64: null,
                });
              }
            );
          })
          .catch(handleFetchError);
      }
    };
  };

  const handleRateLimitPopup = (error) => {
    updateEditToolsetData({
      maskImageBase64: null,
    });
    setIsLimitPopup(true);
    setGlobalErrorMessage(error.message);
  };

  useEffect(() => {
    //set boundaries for dragging
    if (canvasRef.current && imageRef) {
      const parentBounds = canvasRef.current.getBoundingClientRect();
      setBounds({
        left: -parentBounds.width / 2,
        top: -parentBounds.height / 2,
        right: parentBounds.width / 2, // 200 is the initial width of the image
        bottom: parentBounds.height / 2, // 200 is the initial height of the image
      });
      //   const imageSize = imageRef.current.getBoundingClientRect();
      //   setBounds({
      //     left: -parentBounds.width/2 + imageSize.width/2,
      //     top: -parentBounds.height/2 + imageSize.height/2,
      //     right: parentBounds.width/2 - imageSize.width/2, // 200 is the initial width of the image
      //     bottom: parentBounds.height/2 - imageSize.height/2 // 200 is the initial height of the image
      //   });
    }
  }, [isSetCanvasDim]);

  const handlePostResizeImageUpdate = (e, data) => {
    setCNImageSize(data.size);
    //add something the part above to reupdate the size
  };

  const HandleOnDraggableEnd = (e, data) => {
    setCNImagePosition({
      x: data.x,
      y: data.y,
    });
    //update the position of the image
  };

  const resetTools = () => {
    resetCurrentDrawing();
    setIsLassoSelected(false);
    setIsEraserSelected(false);
    setIsManipulatingCNImage(false);
    setIsRectSelectSelected(false);
    setIsRectEraserSelected(false);
    setIsLassoPSelected(false);
    setIsEraserPSelected(false);
    setIsBrushSelected(false);
    setIsBrushEraseSelected(false);
    setIsZooming(false);
    setIsChangingBrushSize(false);
  };

  useEffect(() => {
    console.log("isChangingBrushSize state updated:", isChangingBrushSize);
  }, [isChangingBrushSize]);

  const resetCurrentDrawing = () =>{
    lassoPPoints = [];
    drawLassoP(0, 0, true);
    lassoPoints = [];
    rectStartPoints = [];
    isDrawing = false;
    previousX = 0;
    previousY = 0;
  }

  const activateManipulateCNImage = () => {
    resetTools();
    setIsManipulatingCNImage(true);
  };

  const activateBrushSelect = () => {
    resetTools();
    setSelectedMode(mode[0]);
    setSelectedBrush(brushList[defaultBrushIndex]);
    setIsBrushSelected(true);
    setIsChangingBrushSize(true);
  };

  const activateBrushEraseSelect = () => {
    resetTools();
    setSelectedMode(mode[1]);
    setSelectedBrush(brushList[defaultBrushIndex]);
    setIsBrushEraseSelected(true);
  };

  const activateLasso = () => {
    resetTools();
    setSelectedMode(mode[2]);
    setSelectedBrush(brushList[4]);
    setIsLassoSelected(true);
  };

  const activateRectSelect = () => {
    resetTools();
    setSelectedMode(mode[4]);
    setSelectedBrush(brushList[6]);
    setIsRectSelectSelected(true);
  };

  const activateLassoP = () => {
    resetTools();
    setSelectedMode(mode[6]);
    setSelectedBrush(brushList[8]);
    setIsLassoPSelected(true);
  };

  const activateEraser = () => {
    resetTools();
    setSelectedMode(mode[3]);
    setSelectedBrush(brushList[5]);
    setIsEraserSelected(true);
  };

  const activateRectErase = () => {
    resetTools();
    setSelectedMode(mode[5]);
    setSelectedBrush(brushList[7]);
    setIsRectEraserSelected(true);
  };

  const activateEraserP = () => {
    resetTools();
    setSelectedMode(mode[7]);
    setSelectedBrush(brushList[9]);
    setIsEraserPSelected(true);
  };

  const activateZoomPan = () =>{
    resetTools();
    setSelectedMode(mode[8]);
    setSelectedBrush(brushList[10]);
    setIsZooming(true);
  }

  const handleKeyDown = (e) => {
    e.preventDefault();
    if (e.key === "v") {
      if (editToolsetData?.isFromDesignActivated) {
        setIsShowingPreview(true);
      }
    }
    if (e.key === "m") {
      if (editToolsetData?.isFromDesignActivated) {
        activateManipulateCNImage();
      }
    }
    if (e.key === "l") {
      activateLasso();
    }
    /*if (e.key === "r") {
      activateRectSelect();
    }*/
    if (e.key === "b") {
      activateBrushSelect();
      setIsChangingBrushSize(true);
    }
    if (e.key === "p") {
      activateLassoP();
    }
    if (e.key === "e") {
      activateEraser();
    }
    if (e.key === "g") {
      activateZoomPan();
    }
    if (e.key === "Enter") {
      doubleClickHandler();
    }
    // if (e.key === "Backspace" || (e.ctrlKey && e.key === "z")) {
    if (e.key === "Backspace") {
      if (selectedMode === mode[6] || selectedMode === mode[7]) {
        if (lassoPPoints.length >= 2) {
          lassoPPoints.pop();
          drawLassoP(currentMousePos.x, currentMousePos.y);
        }
      }
    }
    if (e.key === "Escape") {
      if (selectedMode === mode[6]) {
        activateLassoP();
        drawLassoP(0, 0, true);
      } else if (selectedMode === mode[7]) {
        activateEraserP();
        drawLassoP(0, 0, true);
      }
    }
    if (e.ctrlKey && e.key === 'z') {
      undo();
    } else if (e.ctrlKey && e.key === 'y') {
      redo();
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === "v") {
      setIsShowingPreview(false);
    }
    if (e.key === "b") {
      setIsChangingBrushSize(true);
    }
  };

  const handleScroll = (e) =>{
    if(isChangingBrushSize && e.altKey){
      if (e.deltaY < 0) {
        handleBrushSizeChangeFromScrolling('+');
      }else{
        handleBrushSizeChangeFromScrolling('-');
      }
    }
  }

  const handleWheel = (e) => {
    if(e.altKey && (isBrushSelected || isBrushEraseSelected) ) return; //when brush is on and alt is pressed
    // Determine the zoom direction and scale delta and Update the scale
    const delta = e.deltaY < 0 ? 0.1 : -0.1;
    const newScale = Math.min(Math.max(scale + delta, 0.5), 3); // Clamp scale between 0.5 and 3
    setScale(newScale);
    if (newScale <= 1) {
      setTranslateX(0);
      setTranslateY(0);
      return
    }; 

    // Get the bounding rectangle of the canvas and that of the container
    const canvasRect = parentCanvasRef.current.getBoundingClientRect();
    const sectionRect = sectionRef.current.getBoundingClientRect();

    // Calculate the center of the image/container
    const containerCenterX = canvasRect.left + canvasRect.width / 2;
    const containerCenterY = canvasRect.top + canvasRect.height / 2;

    // Calculate the mouse position relative to the center of the image/container
    const mouseX = e.clientX - containerCenterX ;
    const mouseY = e.clientY - containerCenterY;

    // Calculate the zoom factor
    const zoomFactor = newScale / scale;
    const newTranslateX = translateX/zoomFactor - (mouseX / scale) * (zoomFactor - 1); //this is not working perfectly and it does not make sense but... i works zooming in, but not zooming out
    const newTranslateY = translateY/zoomFactor - (mouseY / scale) * (zoomFactor - 1);
  
    //  and translation state
    const marginX = sectionRect.width * 0.05;
    const marginY = sectionRect.height * 0.05;
    const absMaxTranslateX = (sectionRect.width - (sectionRect.width - canvasRect.width)/2 - marginX)/scale;
    const absMaxTranslateY = (sectionRect.height - (sectionRect.height - canvasRect.height)/2 - marginY)/scale;

    if (mouseX < 0) {
      if(newTranslateX < absMaxTranslateX){
        setTranslateX(newTranslateX);
      }else{
        setTranslateX(absMaxTranslateX);
      }
    } else if (mouseX > 0) {
      if(newTranslateX > -absMaxTranslateX){
        setTranslateX(newTranslateX);
      }else{
        setTranslateX(-absMaxTranslateX);
      }
    }

    if (mouseY < 0) {
      if(newTranslateY < absMaxTranslateY){
        setTranslateY(newTranslateY);
      }else{
        setTranslateY(absMaxTranslateY);
      }
    } else if (mouseY > 0) {
      if(newTranslateY > -absMaxTranslateY){
        setTranslateY(newTranslateY);
      }else{
        setTranslateY(-absMaxTranslateY);
      }
    }
  };

  // Handle mouse down for dragging
  const handleMouseDown = (e) => {
    if (isZooming) { // click wheel, if not zooming or left button allowed if zooming
      // Handle mouse wheel press
      if (scale <= 1) {
        setTranslateX(0);
        setTranslateY(0);
      }else{
        e.preventDefault(); // Prevent unwanted selections
        setIsDragging(true);
        // Store the current translate values and mouse position at the start of drag
        setStartTranslate({ x: translateX, y: translateY });
        setMousePosition({ x: e.clientX, y: e.clientY });
        setSelectedBrush(brushList[11]);
      }
  }
  };

  // Handle mouse move for dragging
  const handleMouseMove = (e) => {
    if (!isZooming) return;
    // Handle mouse wheel press
    if (!isDragging) return;

    let deltaX = e.clientX - mousePosition.x;
    let deltaY = e.clientY - mousePosition.y;

    const canvasRect = parentCanvasRef.current.getBoundingClientRect();
    const sectionRect = sectionRef.current.getBoundingClientRect();
  
    const marginX = sectionRect.width * 0.05;
    const marginY = sectionRect.height * 0.05;

    const potentialTranslateX = (startTranslate.x + deltaX/scale);
    const potentialTranslateY = (startTranslate.y + deltaY/scale);
    const absMaxTranslateX = (sectionRect.width - (sectionRect.width - canvasRect.width)/2 - marginX)/scale;
    const absMaxTranslateY = (sectionRect.height - (sectionRect.height - canvasRect.height)/2 - marginY)/scale;

    if (deltaX > 0) {
      if(potentialTranslateX < absMaxTranslateX){
        setTranslateX(potentialTranslateX);
      }else{
        setTranslateX(absMaxTranslateX);
      }
    } else if (deltaX < 0) {
      if(potentialTranslateX > -absMaxTranslateX){
        setTranslateX(potentialTranslateX);
      }else{
        setTranslateX(-absMaxTranslateX);
      }
    }

    if (deltaY > 0) {
      if(potentialTranslateY < absMaxTranslateY){
        setTranslateY(potentialTranslateY);
      }else{
        setTranslateY(absMaxTranslateY);
      }
    } else if (deltaY < 0) {
      if(potentialTranslateY > -absMaxTranslateY){
        setTranslateY(potentialTranslateY);
      }else{
        setTranslateY(-absMaxTranslateY);
      }
    }
  };

  // Handle global mouse up
  const handleMouseUp = (e) => {
    if (isZooming) { // 1 is the middle button
      setIsDragging(false); // Stop dragging
      setSelectedBrush(brushList[10]);
  }
  };

  // Set up and clean up global listeners for dragging
  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const makePreviewCanvasWithCN = (isNoMask, isFinished) => {
    const CNImage = imageRef.current;
    const bgImage = BGCanvasRef.current; //TODO: check to remove this line
    if (CNImage && CNImage.complete) {
      // Check if the CNImage is loaded
      previewCtx.clearRect(0, 0, previewCanvas.width, previewCanvas.height);

      previewCtx.drawImage(
        bgImage,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height
      );

      previewCtx.drawImage(
        CNImage,
        CNImagePosition.x + canvas.width / 2 - CNImageSize.width / 2,
        CNImagePosition.y + canvas.height / 2 - CNImageSize.height / 2,
        CNImageSize.width,
        CNImageSize.height
      );

      previewCtx.globalCompositeOperation = "destination-in";

      if (isNoMask) {
        previewCtx.fillStyle = maskFillFullOpacity; // Set the fill color to white
        previewCtx.fillRect(0, 0, previewCanvas.width, previewCanvas.height);
      } else {
        const tempCanvas2 = document.createElement("canvas");
        tempCanvas2.width = previewCanvas.width;
        tempCanvas2.height = previewCanvas.height;
        const tempCtx2 = tempCanvas2.getContext("2d");

        let imageData = ctx.getImageData(
          0,
          0,
          previewCanvas.width,
          previewCanvas.height
        ); // this is canvas data, or mask data (Lasso)
        let data = imageData.data;

        for (let i = 3; i < data.length; i += 4) {
          // make transparent opaque
          if (data[i] === maskFillColorL[3]) {
            data[i] = 255; // Set alpha to 255 (fully opaque)
          }
        }

        // Put the modified image data onto the destination canvas
        tempCtx2.putImageData(imageData, 0, 0);

        previewCtx.drawImage(tempCanvas2, 0, 0);
      }

      previewCtx.globalCompositeOperation = "source-over";

      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = previewCanvas.width;
      tempCanvas.height = previewCanvas.height;
      const tempCtx = tempCanvas.getContext("2d");

      tempCtx.drawImage(BGCanvas, 0, 0);
      tempCtx.globalAlpha = CNOpacity;
      tempCtx.drawImage(previewCanvas, 0, 0);
      tempCtx.globalAlpha = 1;

      if (isFinished) {
        if (isNoMask) {
          tempCtx.fillStyle = maskFillColor;
          tempCtx.fillRect(0, 0, previewCanvas.width, previewCanvas.height);
        } else {
          tempCtx.drawImage(canvas, 0, 0);
        }
      }

      // Now copy everything back to previewCtx
      previewCtx.clearRect(0, 0, previewCanvas.width, previewCanvas.height);
      previewCtx.drawImage(tempCanvas, 0, 0);
    }
  };

  const updateCNImageWithCN = (bgImage, CNImage) => {
    previewCtx.clearRect(0, 0, previewCanvas.width, previewCanvas.height);
    previewCanvas.width = bgImage.width;
    previewCanvas.height = bgImage.height; //reset the CNImage back to original Size
    previewCtx.fillStyle = "rgba(255, 255, 255, 0)"; // maybe this should be full transparent black
    previewCtx.fillRect(0, 0, bgImage.width, bgImage.height);
    previewCtx.drawImage(
      CNImage,
      CNImagePosition.x / BGtoCanvasRatio +
        bgImage.width / 2 -
        CNImageSize.width / 2 / BGtoCanvasRatio,
      CNImagePosition.y / BGtoCanvasRatio +
        bgImage.height / 2 -
        CNImageSize.height / 2 / BGtoCanvasRatio,
      CNImageSize.width / BGtoCanvasRatio,
      CNImageSize.height / BGtoCanvasRatio
    );
    return previewCanvas.toDataURL("image/png");
  };

  const updatePreview = (isNoMask, isFinished) => {
    setIsPreviewLoading(true); // Start loading
    if (editToolsetData?.isFromDesignActivated === true) {
      makePreviewCanvasWithCN(isNoMask, isFinished);
    } else {
      makePreviewCanvasNoCN(isNoMask); //replace masked image by mask + bg image, for user friendly presentation
    }
    setIsPreviewLoading(false);
  };

  useEffect(() => {
    if (!isShowingPreview) {
      return;
    }
    // if (canvas.toDataURL("image/png") === previousData.previousBase64Mask
    //   && CNImagePosition === previousData.previousCNImagePos
    //   && CNImageSize === previousData.previousCNImageSize
    //   ) return;
    const isNoMask = isCanvasBlank(ctx, canvas.width, canvas.height); //no mask was drawn == it is all white == true, meaning user drew no mask
    updatePreview(isNoMask, false);
  }, [isShowingPreview]);

  const handleMaskExit = () =>{
    setUndoStack([]);
    setRedoStack([]);
    updateRenderToolsetData({
      showPopUpForBaseImage: false,
    })
  }

  return (
    <div
      className="backdrop"
      tabIndex="0"
      ref={fullPageRef}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onMouseDown={globalMouseDownHandler}
      onMouseUp={globalMouseUpHandler}
      onMouseLeave={mouseOutHandler}
      onMouseMove={globalMouseMoveHandler}
      onDoubleClick={doubleClickHandler}
      onTouchStart={touchStartHandler}
      onTouchEnd={touchEndHandler}
      onTouchCancel={touchEndHandler}
      onTouchMove={touchMoveHandler}
      onWheel={handleScroll}
    >
      <div className="image-mask" onClick={(e) => e.stopPropagation()}>
        <div className="image-mask__container">
          <div className="image-mask__main">
            <div className="image-mask__main__heading">
              <div className="center">
              </div>
              <div className="left">
                <div className="left__sec">
                  <div className="tooltip-top">
                    <button
                      tabIndex="-1"
                      style={{
                        background: "transparent",
                        cursor:!undoStack.length && "not-allowed",
                        opacity:undoStack.length? 1 : 0.6
                      }}
                      disabled={!undoStack.length}
                      onClick={undo}
                      // onTouchEnd={undo}
                    >
                      <img 
                        className="icon" 
                        src={undo_icon} 
                        alt="cross-icon" 
                        style={{
                          cursor:!undoStack.length && "not-allowed"
                        }}
                        />
                    </button>
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipTopNew TooltipContent={t("undo_tooltip")} />
                      )}
                    </div>
                  </div>
                  <div className="tooltip-top">
                    <button
                      tabIndex="-1"
                      style={{
                        background: "transparent",
                        cursor:!redoStack.length && "not-allowed",
                        opacity:redoStack.length? 1 : 0.6
                      }}
                      onClick={redo}
                      disabled={!redoStack.length}
                      // onTouchEnd={redo}
                    >
                      <img 
                        className="icon" 
                        src={redo_icon} 
                        alt="cross-icon" 
                        style={{
                          cursor:!redoStack.length && "not-allowed"
                        }}
                        />
                    </button>
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipTopNew TooltipContent={t("redo_tooltip")} />
                      )}
                    </div>
                  </div>
                  <div className="tooltip-top">
                    <button
                      tabIndex="-1"
                      style={{
                        background: "transparent",
                      }}
                      onClick={() => renderInitialImageAndResetCNImage()}
                    >
                      <img className="icon" src={wipeOut} alt="cross-icon" />
                    </button>
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipTopNew TooltipContent={t("reset_tooltip")} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="left__sec">
                  <div className="tooltip-top">
                    <button
                      tabIndex="-1"
                      style={{
                        background: isBrushSelected
                          ? "#d4d6d7"
                          : "transparent",
                      }}
                      onClick={activateBrushSelect}
                      // onTouchEnd={activateBrushSelect}
                    >
                      <img
                        className="icon"
                        src={brush}
                        alt="cross-icon"
                      />
                    </button>
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipTopNew TooltipContent={t("brushSelect_tooltip")} />
                      )}
                    </div>
                  </div>
                  {isBrushSelected && (
                    <div className="tooltip-top__sec">
                      <span>
                        {brushList.indexOf(selectedBrush)}
                      </span>
                      <Slider
                        // key={selectedBrush}
                        // defaultValue={brushList.indexOf(selectedBrush) || defaultBrushIndex}
                        value={brushList.indexOf(selectedBrush)+1 || defaultBrushIndex+1} // for some reason using value does not allow min 0 max 3 so a lot of places had to work with numbers
                        step={1}
                        min={1}
                        max={4}
                        onChange={handleBrushSizeChange}
                      />
                    </div>
                  )} 
                  {/* <div className="tooltip-top">
                    <button
                      tabIndex="-1"
                      style={{
                        background: isBrushEraseSelected
                          ? "#d4d6d7"
                          : "transparent",
                      }}
                      onClick={activateBrushEraseSelect}
                    >
                      <img
                        className="icon"
                        src={invert}
                        alt="cross-icon"
                      />
                    </button>
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipTopNew TooltipContent={t("brushErase_tooltip")} />
                      )}
                    </div>
                  </div>   */}
                  {/* <div className="tooltip-top">
                    <button
                      tabIndex="-1"
                      style={{
                        background: isRectSelectSelected ? "#d4d6d7" : "transparent",
                      }}
                      onClick={activateRectSelect}
                      // onTouchEnd={activateRectSelect}
                    >
                      <img className="icon" src={rect} alt="cross-icon" />
                    </button>
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipTopNew TooltipContent={t("rectangleSelect_tooltip")} />
                      )}
                    </div>
                  </div> */}
                  {/* <div className="tooltip-top">
                    <button
                      tabIndex="-1"
                      style={{
                        background: isRectEraserSelected
                          ? "#d4d6d7"
                          : "transparent",
                      }}
                      onClick={activateRectErase}
                    >
                      <img
                        className="icon"
                        src={invert}
                        alt="cross-icon"
                      />
                    </button>
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipTopNew TooltipContent={t("inpainting_rectangle_eraise")} />
                      )}
                    </div>
                  </div>                   */}
                  {deviceType == "PC" && (
                    <div className="tooltip-top">
                      <button
                        tabIndex="-1"
                        style={{
                          background: isLassoPSelected
                            ? "#d4d6d7"
                            : "transparent",
                        }}
                        onClick={activateLassoP}
                      >
                        <img
                          className="icon"
                          src={lassoP_plus}
                          alt="cross-icon"
                        />
                      </button>
                      <div className="tooltip-show">
                        {!responsiveData && (
                          <TooltipTopNew TooltipContent={t("lassoP_tooltip")} />
                        )}
                      </div>
                    </div>
                  )}
                  <div className="tooltip-top">
                    <button
                      tabIndex="-1"
                      style={{
                        background: isLassoSelected ? "#d4d6d7" : "transparent",
                      }}
                      onClick={activateLasso}
                      // onTouchEnd={activateLasso}
                    >
                      <img className="icon" src={lasso_plus} alt="cross-icon" />
                    </button>
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipTopNew TooltipContent={t("lasso_tooltip")} />
                      )}
                    </div>
                  </div>
                  <div className="tooltip-top">
                    <button
                      tabIndex="-1"
                      style={{
                        background: isEraserSelected
                          ? "#d4d6d7"
                          : "transparent",
                      }}
                      onClick={activateEraser}
                      // onTouchEnd={activateEraser}
                    >
                      <img
                        className="icon"
                        src={lasso_erase}
                        alt="cross-icon"
                      />
                    </button>
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipTopNew TooltipContent={t("eraser_tooltip")} />
                      )}
                    </div>
                  </div>
                  {/* <div className="tooltip-top">
                    <button
                      tabIndex="-1"
                      style={{
                        background: isEraserPSelected
                          ? "#d4d6d7"
                          : "transparent",
                      }}
                      onClick={activateEraserP}
                    >
                      <img
                        className="icon"
                        src={lasso_erase}
                        alt="cross-icon"
                      />
                    </button>
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipTopNew TooltipContent={t("eraser_tooltip")} />
                      )}
                    </div>
                  </div>                   */}
                  <div className="tooltip-top">
                    <button
                      tabIndex="-1"
                      style={{
                        background: "transparent",
                      }}
                      onClick={() => performInvertAction()}
                      // onTouchEnd={() => performInvertAction()}
                    >
                      <img className="icon" src={invert} alt="cross-icon" />
                    </button>
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipTopNew TooltipContent={t("invert_tooltip")} />
                      )}
                    </div>
                  </div>
                  {/* <div className="tooltip-top">
                    <button
                      tabIndex="-1"
                      style={{
                        background: isZooming
                          ? "#d4d6d7"
                          : "transparent",
                      }}
                      onClick={activateZoomPan}
                      // onTouchEnd={activateZoomPan}
                    >
                      <img
                        className="icon"
                        src={lasso_erase}
                        alt="cross-icon"
                      />
                    </button>
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipTopNew TooltipContent={t("activate zoom and pan")} />
                      )}
                    </div>
                  </div> */}
                  {/* <div className="tooltip-top">
                    <button
                      tabIndex="-1"
                      style={{
                        background: isZooming
                          ? "#d4d6d7"
                          : "transparent",
                      }}
                      onClick={activateZoomPan}
                      // onTouchEnd={activateZoomPan}
                    >
                      <img
                        className="icon"
                        src={zooming_icon}
                        alt="cross-icon"
                      />
                    </button>
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipTopNew TooltipContent={t("zooming_tooltip")} />
                      )}
                    </div>
                  </div> */}
                </div>
                <div className="left__sec">
                  {editToolsetData?.isFromDesignActivated ? (
                    <div className="tooltip-top">
                      <button
                        tabIndex="-1"
                        style={{
                          background: isManipulatingCNImage
                            ? "lightgray"
                            : "transparent",
                        }}
                        onClick={activateManipulateCNImage}
                        // onTouchEnd={activateManipulateCNImage}
                      >
                        <img
                          className="icon m-0"
                          src={manipulateCN}
                          alt="cross-icon"
                        />
                      </button>
                      <div className="tooltip-show">
                        {!responsiveData && (
                          <TooltipTopNew
                            TooltipContent={t("manipulate_tooltip")}
                          />
                        )}
                      </div>
                    </div>
                  ) : null}
                  {deviceType == "PC" && (
                    <div className="tooltip-top">
                      <button
                        tabIndex="-1"
                        style={{
                          background: isZooming
                            ? "#d4d6d7"
                            : "transparent",
                        }}
                        onClick={activateZoomPan}
                        // onTouchEnd={activateZoomPan}
                      >
                        <img
                          className="icon"
                          src={zooming_icon}
                          alt="cross-icon"
                        />
                      </button>
                      <div className="tooltip-show">
                        {!responsiveData && (
                          <TooltipTopNew TooltipContent={t("zooming_tooltip")} />
                        )}
                      </div>
                    </div>
                  )}
                  {editToolsetData?.isFromDesignActivated ? (
                    <div className="tooltip-top">
                      <button
                        tabIndex="-1"
                        style={{
                          background: isShowingPreview
                            ? "#d4d6d7"
                            : "transparent",
                        }}
                        onMouseDown={() => setIsShowingPreview(true)}
                        onMouseUp={() => setIsShowingPreview(false)}
                        onTouchStart={() => setIsShowingPreview(true)} // Handle touch events for mobile devices
                        onTouchEnd={() => setIsShowingPreview(false)}
                      >
                        <img
                          className="icon m-0"
                          src={showPreview}
                          alt="cross-icon"
                        />
                      </button>
                      <div className="tooltip-show">
                        {!responsiveData && (
                          <TooltipTopNew
                            TooltipContent={t("preview_tooltip")}
                          />
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className="tooltip-top">
                    <button tabIndex="-1" onClick={confirmHandler}>
                      <img className="icon m-0" src={tick} alt="cross-icon" />
                    </button>
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipTopNew
                          TooltipContent={t("confirm_maskpopup_tooltip")}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <img
                className="icon right"
                onClick={handleMaskExit}
                src={cross}
                alt="cross-icon"
              />
            </div>
            <div className="image-mask__main__section" 
              ref={sectionRef}                  
              onWheel={handleWheel}>
              <div
                className="image-mask__main__section__images"
                style={{ padding: 0 }}
              >
                <div
                  className="image-mask__main__section__images__background"
                  style={{
                    width: canvas?.width,
                    height: canvas?.height,
                    overflow: "hidden",
                    position: "relative",
                    transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
                    transformOrigin: "center",
                    transition: isDragging ? 'none' : 'transform 0.2s ease-out',
                  }}
                  onMouseDown={handleMouseDown}
                  ref={parentCanvasRef}
                >
                  <canvas
                    ref={BGCanvasRef}
                    style={{
                      zIndex: "0",
                      position: "absolute",
                    }}
                    id="base-image-section"
                  ></canvas>
                  {editToolsetData?.cnImage &&
                  editToolsetData?.isFromDesignActivated ? (
                    <Draggable
                      ref={draggableRef}
                      handle=".handle"
                      defaultPosition={CNImagePosition}
                      position={null}
                      scale={1}
                      bounds={bounds}
                      onStop={HandleOnDraggableEnd}
                    >
                      <div
                        className="image-mask__main__section__images__background"
                        style={{
                          position: "absolute",
                        }}
                      >
                        <ResizableBox
                          width={CNImageSize.width}
                          height={CNImageSize.height}
                          lockAspectRatio={true}
                          minConstraints={[
                            CNInitSize.width * 0.06,
                            CNInitSize.height * 0.06,
                          ]}
                          maxConstraints={[
                            CNInitSize.width * 1.8,
                            CNInitSize.height * 1.8,
                          ]}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          resizeHandles={
                            isManipulatingCNImage
                              ? ["sw", "nw", "se", "ne", "s", "w", "e", "n"]
                              : []
                          }
                          onResizeStop={handlePostResizeImageUpdate}
                        >
                          <img
                            ref={imageRef}
                            className="handle"
                            onDragStart={(e) => {
                              e.preventDefault();
                            }}
                            style={{
                              cursor: "move",
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              opacity: CNOpacity,
                            }}
                            src={editToolsetData?.originalCnImage}
                            alt="resize-img"
                          ></img>
                        </ResizableBox>
                      </div>
                    </Draggable>
                  ) : null}
                  <canvas
                    ref={canvasRef}
                    style={{
                      zIndex: editToolsetData?.originalCnImage
                        ? isManipulatingCNImage
                          ? "-1"
                          : "0"
                        : "0",
                      position: "absolute",
                    }}
                    id="base-image-section"
                    className={`${selectedBrush?.text}`}
                  ></canvas>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      zIndex: isShowingPreview ? "0" : "-1",
                      position: "absolute",
                      // opacity: isPreviewLoading ? 0 : 1, // Fade-in effect
                      // transition: 'opacity 1s ease', // Smooth transition for the fade-in
                      display: isShowingPreview ? "block" : "none", // Control visibility with display
                    }}
                    id="base-image-section"
                  ></canvas>
                  <canvas
                    ref={offscreenCanvasRef}
                    style={{
                      zIndex: "-99",
                      position: "absolute",
                      // opacity: isPreviewLoading ? 0 : 1, // Fade-in effect
                      // transition: 'opacity 1s ease', // Smooth transition for the fade-in
                      display: "block", // Control visibility with display
                    }}
                    id="base-image-section"
                  ></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageMaskPopUp;
