import React, { createContext, useState, useEffect, useRef } from "react";
import {
  GetAllGalleryTeamProjectsList,
  GetAllGalleryTeamsList,
  GetAllUserTeams,
  GetUserDetailsOfTeam,
  GetUserTeamsOrder,
} from "../utils/apiCalls";

const TeamDesignContext = createContext();

function TeamDesignProvider({ children }) {
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const loginUser = JSON.parse(localStorage.getItem("isLoginUser"));
  const lastProject = JSON.parse(localStorage.getItem("lastProjectGenerate"));
  const token = localStorage.getItem("token");
  const apiCalledRef = useRef(false);
  const apiCalledRef1 = useRef(true);
  const [isTeamList, setIsTeamList] = useState(false);
  const [isCreateTeam, setIsCreateTeam] = useState(false);
  const [isTeamDetails, setIsTeamDetails] = useState(false);
  const [isTeamData, setIsTeamData] = useState(false);
  const [selectedTeamData, setSelectedTeamData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [isAddMember, setIsAddMember] = useState(false);
  const [teamsList, setTeamsList] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [isTeamChanges, setIsTeamChanges] = useState(false);
  const [isInviteSuccess, setIsInviteSuccess] = useState(false);
  const [activeTeamUserData, setActiveTeamUserData] = useState();
  const [teamsOrder, setTeamsOrder] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [isGenCount, setIsGenCount] = useState(0);
  const [apiSuccess, setApiSuccess] = useState(false);

  const handleOpenTeamList = () => {
    setIsTeamList(true);
  };
  const handleCloseTeamList = () => {
    setIsTeamList(false);
  };
  const handleOpenCreateTeam = () => {
    setIsCreateTeam(true);
  };
  const handleCloseCreateTeam = () => {
    setIsCreateTeam(false);
  };
  const handleOpenTeamDetails = () => {
    setIsTeamDetails(true);
  };
  const handleCloseTeamDetails = () => {
    setIsTeamDetails(false);
  };
  const handleOpenTeamData = () => {
    setIsTeamData(true);
  };
  const handleCloseTeamData = () => {
    setIsTeamData(false);
  };
  const handleOpenAddMember = () => {
    setIsAddMember(true);
  };
  const handleCloseAddMember = () => {
    setIsAddMember(false);
  };
  const handleUpdateTeamDetails = (data) => {
    setSelectedTeamData(data);
  };
  const handleTeamChanges = () => {
    setIsTeamChanges(!isTeamChanges);
  };
  const handleInviteSuccess = () => {
    setIsInviteSuccess(!isInviteSuccess);
  };

  useEffect(() => {
    if (!apiCalledRef.current && token !== null && token !== undefined) {
      apiCalledRef.current = true;
      // if (teamsList?.length === 0) {
      //   getAllUserTeams();
      //   getTeamsOrder();
      // }
      getUserDetailsOfteam();
    }
  }, [token]);

  useEffect(() => {
    if ((lastProject === null || lastProject === undefined) && token) {
      getLastProjectGenerate();
    }
  }, [token]);

  const getLastProjectGenerate = () => {
    const bool = true;
    const id = null;
    GetAllGalleryTeamProjectsList(
      async (res) => {
        if (res?.status === 200) {
          const team = await getAllGalleryteams();
          const last = res?.data[0];
          const active = {
            teamId: team?.teamId,
            teamName: team?.teamName,
          };
          const lastActive = { ...last, ...active };
          localStorage.setItem(
            "lastProjectGenerate",
            JSON.stringify(lastActive)
          );
        }
      },
      bool,
      id
    );
  };

  const getAllGalleryteams = () => {
    const bool = true;
    return new Promise((resolve, reject) => {
      GetAllGalleryTeamsList((res) => {
        if (res?.status === 200) {
          resolve(res?.data[0]);
        } else {
          reject("Failed to get gallery teams");
        }
      }, bool);
    });
  };

  const getTeamsOrder = () => {
    // console.log("what is the issue??? checking");
    setTeamsOrder([]);
    GetUserTeamsOrder((res) => {
      if (res?.status === 200) {
        setTeamsOrder(res?.data);
      }
    });
  };

  const getAllUserTeams = () => {
    setTeamsData([]);
    setIsLoader(true);
    setApiSuccess(false);
    GetAllUserTeams((res) => {
      if (res?.status === 200) {
        setApiSuccess(true);
        if (res?.data?.length !== 0) {
          const data = res?.data;
          setTeamsData(data);
        } else {
          setTeamsData([]);
        }
      } else {
        setTeamsData([]);
      }
      setIsLoader(false);
    });
  };

  useEffect(() => {
    //apiCalledRef1.current is a boolean, it is false on signin/accepting team in team notification/team deletion kickout in routercomponent, and set as true otherwise.
    //note team creation is updated automatically without this boolean
    //when it is false, order will get updated, otherwise it won't
    // console.log("apiCalledRef1.current", apiCalledRef1.current);
    if (teamsOrder?.length !== 0 && teamsData?.length !== 0 && !isActiveTeam) {
      // this will trigger when we have the api data and no active team
      setIsLoader(true);
      const matchedTeams = teamsOrder
        ?.map((priorityItem) => {
          const matchedTeam = teamsData?.find(
            (team) => team.teamId === priorityItem.teamId
          );
          return matchedTeam
            ? { ...matchedTeam, priority: priorityItem.priority }
            : null;
        })
        .filter((item) => item !== null);

      const matchedTeamIds = new Set(matchedTeams.map((team) => team.teamId));
      const unmatchedTeams = teamsData?.filter(
        (team) => !matchedTeamIds.has(team.teamId)
      );

      const resultArray = [...matchedTeams, ...unmatchedTeams];
      setTeamsList(resultArray);
      setIsTeamChanges(!isTeamChanges);
      localStorage.setItem("activeTeam", JSON.stringify(resultArray[0]));
      setIsLoader(false);
    } else if (teamsOrder?.length !== 0 && teamsData?.length !== 0) {
      // this will trigger when we have the api data and active team and want to recall the api
      setIsLoader(true);
      const matchedTeams = teamsOrder
        ?.map((priorityItem) => {
          const matchedTeam = teamsData?.find(
            (team) => team.teamId === priorityItem.teamId
          );
          return matchedTeam
            ? { ...matchedTeam, priority: priorityItem.priority }
            : null;
        })
        .filter((item) => item !== null);

      const matchedTeamIds = new Set(matchedTeams.map((team) => team.teamId));
      const unmatchedTeams = teamsData?.filter(
        (team) => !matchedTeamIds.has(team.teamId)
      );

      const resultArray = [...matchedTeams, ...unmatchedTeams];
      setTeamsList(resultArray);
      setIsTeamChanges(!isTeamChanges);
      if (!apiCalledRef1.current) {
        localStorage.setItem("activeTeam", JSON.stringify(resultArray[0]));
      }
      apiCalledRef1.current = true;
      setIsLoader(false);
    }
  }, [teamsOrder, teamsData]);

  const getUserDetailsOfteam = () => {
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    if (token !== null && token !== undefined) {
      GetUserDetailsOfTeam((res) => {
        if (res?.status === 200) {
          setActiveTeamUserData(res?.data);
        }
      }, teamId);
    }
  };

  return (
    <TeamDesignContext.Provider
      value={{
        isTeamList,
        handleOpenTeamList,
        handleCloseTeamList,
        isCreateTeam,
        handleOpenCreateTeam,
        handleCloseCreateTeam,
        isTeamDetails,
        handleOpenTeamDetails,
        handleCloseTeamDetails,
        handleUpdateTeamDetails,
        selectedTeamData,
        edit,
        setEdit,
        isAddMember,
        handleOpenAddMember,
        handleCloseAddMember,
        getAllUserTeams,
        teamsList,
        setTeamsList,
        isLoader,
        handleTeamChanges,
        isTeamChanges,
        isInviteSuccess,
        handleInviteSuccess,
        activeTeamUserData,
        getUserDetailsOfteam,
        isTeamData,
        handleOpenTeamData,
        handleCloseTeamData,
        getTeamsOrder,
        setIsLoader,
        apiCalledRef1,
        isGenCount,
        setIsGenCount,
      }}
    >
      {children}
    </TeamDesignContext.Provider>
  );
}

export { TeamDesignContext, TeamDesignProvider };
