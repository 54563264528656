import React from "react";
import "./AddonSuccessPopup.scss";
import info from "../../../assets/images/icons/sub-tick.png";
import cross from "../../../assets/images/workspace/icons/cross-icon.png";
import { useTranslation } from "react-i18next";

const AddonSucessfull = ({ handleAddonSuccess }) => {
  const { t } = useTranslation();

  return (
    <div className="sucess" onClick={handleAddonSuccess}>
      <div className="sucess__popup">
        <div
          className="sucess__popup__container"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="sucess__popup__section">
            <div className="sucess__popup__section__header">
              <img src={info} alt="info-icon" />
              <h3>{t("membership_upgrade_heading")}</h3>
              <img
                className="cross"
                src={cross}
                alt="cross-icon"
                onClick={handleAddonSuccess}
              />
            </div>
            <div className="sucess__popup__section__content">
              <p>{t("membership_upgrade_content1")} {t("membership_upgrade_content2")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddonSucessfull;
