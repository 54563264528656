import React, { useEffect } from "react";
import ExitPopup from "../components/ExitPopup/ExitPopup";
import { useNavigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const adminToken = localStorage.getItem("admintoken");

  useEffect(() => {
    if (!token) {
      if (adminToken) {
        navigate("/admin/dashboard");
      }
    }
  }, [token, adminToken, navigate]);

  if (!token) {
    if (!adminToken) {
      return <ExitPopup />;
    }
    return null;
  }

  return children;
};

export const SignInRoute = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const adminToken = localStorage.getItem("admintoken");

  useEffect(() => {
    if (token) {
      if (adminToken) {
        navigate("/admin/dashboard");
      } else {
        navigate("/projects");
      }
    }
  }, [token, adminToken, navigate]);

  if (token) {
    return null;
  }

  return children;
};

export const AdminRoute = ({ children }) => {
  const navigate = useNavigate();
  const adminToken = localStorage.getItem("admintoken");

  if (!adminToken) {
    return navigate("/admin/sign-in");
  }

  return children;
};

export const AdminSignInRoute = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("admintoken");

  useEffect(() => {
    if (token) {
      navigate("/admin/dashboard");
    }
  }, [token, navigate]);

  if (token) {
    return null;
  }

  return children;
};
