import React, { createContext, useState } from "react";

const EditToolsetContext = createContext();

function EditToolsetProvider({ children }) {
  const [editToolsetData, setEditToolsetData] = useState(null);

  const updateEditToolsetData = (newData) => {
    setEditToolsetData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearEditToolsetData = () => {
    setEditToolsetData(null);
  };

  return (
    <EditToolsetContext.Provider
      value={{
        editToolsetData,
        updateEditToolsetData,
        clearEditToolsetData,
      }}
    >
      {children}
    </EditToolsetContext.Provider>
  );
}

export { EditToolsetContext, EditToolsetProvider };
