import React from "react";
import "./CancelMembershipSuccess.scss";
import info from "../../../assets/images/icons/sub-tick.png";
import cross from "../../../assets/images/workspace/icons/cross-icon.png";
import { useTranslation } from "react-i18next";

const CancelMembershipSuccess = ({
  handleOpenCloseCancel,
  isConfirmMessage,
}) => {
  const { t } = useTranslation();

  return (
    <div className="cancel-sucess" onClick={handleOpenCloseCancel}>
      <div className="cancel-sucess__popup">
        <div
          className="cancel-sucess__popup__container"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="cancel-sucess__popup__section">
            <div className="cancel-sucess__popup__section__header">
              <img src={info} alt="info-icon" />
              <h3>{t("cancel_heading")}</h3>
              <img
                className="cross"
                src={cross}
                alt="cross-icon"
                onClick={handleOpenCloseCancel}
              />
            </div>
            <div className="cancel-sucess__popup__section__content">
              <p>
                {t("cancel_content")}{" "}
                {isConfirmMessage?.membership}{"."}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelMembershipSuccess;
