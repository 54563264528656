/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import "./GraphicStyleDropdown.scss";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ResponsiveDataContext } from "../../../store/ResponsiveData";
import realistic from "../../../assets/images/hover-tips-images/realistic.png";
import markpen from "../../../assets/images/hover-tips-images/marx-pen.png";
import lineart from "../../../assets/images/hover-tips-images/line-art.png";
import watercolor from "../../../assets/images/hover-tips-images/watercolor.png";
import inkpainting from "../../../assets/images/hover-tips-images/ink-painting.png";
import anime from "../../../assets/images/hover-tips-images/anime.png";
import plasticmodel from "../../../assets/images/hover-tips-images/plastic-model.png";
import photography from "../../../assets/images/hover-tips-images/photography.png";
import Portal from "../../TipsPopup/Portal1";
import { GetGraphicStyleData } from "../../../utils/apiCalls";

let newDataList = [];
const GraphicStyleDropDown = ({ data, left }) => {
  const { t } = useTranslation();
  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);
  const selectRef = useRef(null);
  const hoveredContentRef = useRef(null);

  const { responsiveData } = useContext(ResponsiveDataContext);

  const tiresData = JSON.parse(
    localStorage.getItem(`designLibraryData_${lastPart}`)
  );
  const graphicData = JSON.parse(
    localStorage.getItem(`graphicStyleData_${lastPart}`)
  );

  const [selectedOption, setSelectedOption] = useState(
    graphicData?.graphicSelectedOption || data[0]
  );

  useEffect(() => {
    if (!graphicData && data) {
      setSelectedOption(data[0]);
      const graphicTypeData = {
        graphicSelectedOption: data[0],
        graphicName: data[0]?.name,
        graphicId: data[0]?.id,
      };
      localStorage.setItem(
        `graphicStyleData_${lastPart}`,
        JSON.stringify(graphicTypeData)
      );
    }
  }, [data]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#FFFFFF" : "#F8F8F9",
      color: state.isFocused ? "#677074" : "#A8ADAF",
      borderRadius: "4px",
      padding: "5px",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000000",
    }),
  };

  const OnHoverMenu = [
    {
      id: 1,
      name: t("Rendering Style"),
      image: realistic,
    },
    {
      id: 10,
      name: t("Mark Pen"),
      image: markpen,
    },
    {
      id: 11,
      name: t("Line Art"),
      image: lineart,
    },
    {
      id: 12,
      name: t("Watercolor"),
      image: watercolor,
    },
    {
      id: 13,
      name: t("Ink Painting"),
      image: inkpainting,
    },
    {
      id: 14,
      name: t("Anime"),
      image: anime,
    },
    {
      id: 15,
      name: t("Plastic Model"),
      image: plasticmodel,
    },
    {
      id: 16,
      name: t("Photography"),
      image: photography,
    }
  ];

  const [hoveredOption, setHoveredOption] = useState(null);
  const [hoveredOption1, setHoveredOption1] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hoveredElement, setHoveredElement] = useState(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [dataNew, setDataNew] = useState([]);
  const [dataNew1, setDataNew1] = useState([]);

  useEffect(() => {
    setDataNew1(newDataList);
  }, [newDataList, dataNew]);

  useEffect(() => {
    let graphic = JSON.parse(
      localStorage.getItem(`graphicStyleData_${lastPart}`)
    );
    let tiresData = JSON.parse(
      localStorage.getItem(`designLibraryData_${lastPart}`)
    );
    if (
      graphic?.graphicSelectedOption !== null &&
      graphic?.graphicSelectedOption !== undefined
    ) {
      if (
        !tiresData?.isSecondTierId ||
        tiresData?.isSecondTierId !== undefined
      ) {
        const payload = {
          secondTierId: tiresData?.isSecondTierId,
        };
        if (payload?.secondTierId) {
          GetGraphicStyleData(function (res) {
            if (res?.status === 200 && res?.data?.length > 0) {
              setDataNew(res?.data);
              newDataList = res?.data;
              if (res?.data?.length !== 0) {
                const index = res?.data.findIndex(
                  (item) => item.name === graphic?.graphicName
                );
                if (index !== -1) {
                  graphic.graphicSelectedOption = res?.data[index];
                  graphic.graphicId = res?.data[index]?.id;
                  graphic.graphicName = res?.data[index]?.name;
                  setSelectedOption(res?.data[index]);
                } else {
                  graphic.graphicSelectedOption = res?.data[0];
                  graphic.graphicId = res?.data[0]?.id;
                  graphic.graphicName = res?.data[0]?.name;
                  setSelectedOption(res?.data[0]);
                }
              }
              localStorage.setItem(
                `graphicStyleData_${lastPart}`,
                JSON.stringify(graphic)
              );
            }
          }, payload);
        }
      }
    } else {
      if (
        !tiresData?.isSecondTierId ||
        tiresData?.isSecondTierId !== undefined
      ) {
        const payload = {
          secondTierId: tiresData?.isSecondTierId,
        };
        if (payload?.secondTierId) {
          GetGraphicStyleData(function (res) {
            if (res?.status === 200 && res?.data?.length > 0) {
              if (res?.data?.length !== 0) {
                setDataNew(res?.data);
                newDataList = res?.data;
                setSelectedOption(res?.data[0]);
                const graphicData = {
                  graphicSelectedOption: res?.data[0],
                  graphicId: res?.data[0]?.id,
                  graphicName: res?.data[0]?.name,
                };
                localStorage.setItem(
                  `graphicStyleData_${lastPart}`,
                  JSON.stringify(graphicData)
                );
              }
            }
          }, payload);
        }
      }
    }
  }, [tiresData?.isSecondTierId]);

  const handleMouseOver = (event, option) => {
    if (isDropdownOpen) {
      setHoveredElement(event.currentTarget);
      setHoveredOption(option);
    }
  };

  useEffect(() => {
    if (hoveredElement) {
      const rect = selectRef.current.getBoundingClientRect();
      setPosition({
        left: rect.left + window.scrollX + 124,
        top: rect.top + window.scrollY + 46,
      });
    }
  }, [hoveredElement]);

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
    setHoveredOption(null);
  };

  const handleMouseLeaveDropdown = () => {
    setHoveredOption(null);
  };

  const handleChange = (e) => {
    setSelectedOption(e);
    let imageType = JSON.parse(
      localStorage.getItem(`graphicStyleData_${lastPart}`)
    );
    if (
      (imageType?.graphicSelectedOption !== undefined ||
        imageType?.graphicSelectedOption !== null) &&
      imageType?.graphicSelectedOption !== e
    ) {
      imageType.graphicSelectedOption = e;
      imageType.graphicName = e.name;
      imageType.graphicId = e.id;
    }
    localStorage.setItem(
      `graphicStyleData_${lastPart}`,
      JSON.stringify(imageType)
    );
  };

  return (
    <div className="graphicStyle" ref={selectRef}>
      <Select
        key={graphicData}
        menuPlacement="bottom"
        placeholder={t("select_option")}
        value={
          JSON.parse(localStorage.getItem(`graphicStyleData_${lastPart}`))
            ?.graphicSelectedOption ??
          graphicData?.graphicSelectedOption ??
          selectedOption
        }
        options={dataNew1 ?? data}
        onChange={handleChange}
        isSearchable={false}
        onMouseOver={handleMouseLeaveDropdown}
        onMouseLeave={handleMouseLeaveDropdown}
        onMenuOpen={handleDropdownOpen}
        onMenuClose={handleDropdownClose}
        styles={{
          ...customStyles,
          menu: (provided) => ({
            ...provided,
            width: responsiveData ? 130 : 200,
            left: responsiveData ? 0 : -100,
          }),
          placeholder: (provided) => ({
            ...provided,
            fontSize: "12px",
          }),
        }}
        getOptionLabel={(e) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
            className="select-option"
            onMouseOver={(event) => handleMouseOver(event, e)}
            onMouseLeave={handleMouseLeaveDropdown}
          >
            <span
              style={{
                marginLeft: 5,
                fontSize: responsiveData ? "12px" : "12px",
              }}
            >
              {t(e.name)}
            </span>
          </div>
        )}
        getOptionValue={(option) => option.value}
      />
      {!responsiveData && (
        <Portal style={{ display: "block" }}>
          {isDropdownOpen && hoveredOption && (
            <div
              className="hover__options"
              style={{
                top: `${position.top}px`,
                left: `${position.left}px`,
              }}
            >
              {!responsiveData && (
                <>
                  {OnHoverMenu?.map((menuOption) => {
                    const match = menuOption.id === hoveredOption.id;
                    return (
                      match && (
                        <div className="hover__section" key={menuOption?.id}>
                          <img
                            className="hover__image"
                            key={menuOption?.id}
                            src={menuOption?.image}
                            alt={menuOption?.name}
                          />
                          <p>{menuOption?.name}</p>
                        </div>
                      )
                    );
                  })}
                </>
              )}
            </div>
          )}
        </Portal>
      )}
    </div>
  );
};

export default GraphicStyleDropDown;
