import React, { createContext, useState } from "react";

const CurrentProjectContext = createContext();

function CurrentProjectProvider({ children }) {
  const [currentProjectData, setCurrentProjectData] = useState(null);

  const updateCurrentProjectData = (newData) => {
    setCurrentProjectData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearCurrentProjectData = () => {
    setCurrentProjectData(null);
  };

  return (
    <CurrentProjectContext.Provider
      value={{
        currentProjectData,
        updateCurrentProjectData,
        clearCurrentProjectData,
      }}
    >
      {children}
    </CurrentProjectContext.Provider>
  );
}

export { CurrentProjectContext, CurrentProjectProvider };
