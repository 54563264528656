import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";

const Button = (props) => {
  const { t } = useTranslation();
  const { buttonClass, buttonText, buttonClick, isBtnDisabled } = props;

  return (
    <button
      disabled={isBtnDisabled}
      onClick={buttonClick}
      className={`${buttonClass} ${isBtnDisabled && "button__disabled"}`}
    >
      {buttonText ?? t("Ok")}
    </button>
  );
};

export default React.memo(Button);
