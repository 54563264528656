import React from "react";
import "./ErrorPopup.scss";
import error from "../../assets/images/global-error.png";

const ErrorPopup = () => {
  return (
    <div className="error-global">
      <div className="error-global__container">
        <img src={error} alt="error" />
      </div>
    </div>
  );
};

export default ErrorPopup;
