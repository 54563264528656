import React, { useContext } from "react";
import "./Notification.scss";
import info from "../../assets/images/icons/alert-icon.png";
import { t } from "i18next";
import { UserSocketContext } from "../../store/UserSocketData";

const Notification = ({ handleShowNotification, isNotification }) => {
  const { handleShowSubscription } = useContext(UserSocketContext);
  const handleClose = () => {
    handleShowNotification();
  };

  const lang = localStorage.getItem("language");

  return (
    <div className="notification" onClick={handleClose}>
      <div className="notification__container">
        <div
          className="notification__sections"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="notification__sections__header">
            <img src={info} alt="info-icon" />
            {lang === "ch" ? (
              <h3>{isNotification?.chHeading}</h3>
            ) : (
              <h3>{isNotification?.engHeading}</h3>
            )}
          </div>
          <div className="notification__sections__content">
            {lang === "ch" ? (
              <p>{isNotification?.chContent}</p>
            ) : (
              <p>{isNotification?.engContent}</p>
            )}
          </div>
          <div className="notification__sections__buttons">
            <button onClick={handleClose}>{t("ok")}</button>
            <button onClick={handleShowSubscription}>{t("view_plans")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
