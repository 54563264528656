import React from "react";
import "./AdminLayout.scss";
import AdminHeader from "../AdminHeader/AdminHeader";

const AdminLayout = ({ children }) => {
  return (
    <div className="admin-layout">
      <div className="admin-layout__container">
        <div className="admin-layout__sections">
          <div className="admin-layout__sections__header">
            <AdminHeader />
          </div>
          <div className="admin-layout__sections__main">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
