import React from "react";
import "./TooltipRight.scss";

const TooltipRight = ({ TooltipContent, display }) => {
  const contentLines = TooltipContent?.split("/n");
  return (
    <div className="tooltipr" style={display}>
      <div className="tooltipr__arrow"></div>
      <div className="tooltipr__container">
        <ul>
          {contentLines?.map((item, index) => (
            <li key={index}> {item?.trim()}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TooltipRight;
