import React, { createContext, useEffect, useState } from "react";

const ResponsiveDataContext = createContext();

function ResponsiveDataProvider({ children }) {
  const [responsiveData, setResponsiveData] = useState(false);
  const [screenWidth, setScreenWidth] = useState(false);
  const [isRedirect, setIsRedirect] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setScreenWidth(screenWidth);
      setResponsiveData(screenWidth <= 1090);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ResponsiveDataContext.Provider value={{ responsiveData, isRedirect, setIsRedirect,screenWidth }}>
      {children}
    </ResponsiveDataContext.Provider>
  );
}

export { ResponsiveDataContext, ResponsiveDataProvider };
