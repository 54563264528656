/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import "./SubscriptionLimit.scss";
import info from "../../../assets/images/icons/alert-icon.png";
import { useTranslation } from "react-i18next";
import { UserSocketContext } from "../../../store/UserSocketData";

const SubscriptionLimit = () => {
  const { t } = useTranslation();
  const { setSubscriptionLimit } = useContext(UserSocketContext);

  const handlePopup = () => {
    setSubscriptionLimit(false);
  };

  return (
    <div className="sub-limit" onClick={handlePopup}>
      <div className="sub-limit__container">
        <div className="sub-limit__sections">
          <div className="sub-limit__sections__header">
            <img src={info} alt="info-icon" />
            <h3>{t("sub_limit_heading")}</h3>
          </div>
          <div className="sub-limit__sections__content">
            <p>{t("sub_limit_content")}</p>
          </div>
          <div className="sub-limit__sections__buttons">
            <button onClick={handlePopup}>{t("ok")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionLimit;
