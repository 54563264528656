export function processText(inputText, limit, count = 4) {
    let engChars = 0;
    let chnChars = 0;
    const chinesePattern = /[\u4E00-\u9FFF]/;
    const specialCharPattern = /[~!@#$%^&*()_+[\]{};':"\\|<>,.?/]/;
  
    function isSpecialCharacter(char) {
      return specialCharPattern.test(char);
    }
  
    for (let i = 0; i < inputText.length; i++) {
      const char = inputText[i];
      if (char.match(chinesePattern)) {
        chnChars += 1;
      } else if (isSpecialCharacter(char)) {
        engChars += 2;
      } else {
        engChars += 1;
      }
  
      if (engChars + chnChars * count >= limit) {
        inputText = inputText.slice(0, i);
        break;
      }
    }
  
    const remainingChars = engChars + chnChars * count;
    return { truncatedText: inputText, remainingChars };
  }

  export function sortAddonsByDate(addons) {
    return addons?.slice()?.sort((a, b) => {
      if (a?.endDate && b?.endDate) {
        return new Date(a?.endDate) - new Date(b?.endDate);
      } else if (a?.endDate) {
        return -1;
      } else if (b?.endDate) {
        return 1;
      } else {
        return 0;
      }
    });
  }