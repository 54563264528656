import React, { useState } from "react";
import Avatar from "react-avatar-edit";
import { useTranslation } from "react-i18next";
import "./CropModal.scss";

const ImageCropperModal = ({ onSave, onClose }) => {
  const { t } = useTranslation();
  const [preview, setPreview] = useState(null);

  const handleSave = () => {
    onSave(preview);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const handleCrop = (preview) => {
    setPreview(preview);
  };

  return (
    <div className="cropper-modal">
      <h3>{t("upload_avatar")}</h3>
      <div className="cropper-modal__main">
        <Avatar
          width={400}
          height={300}
          onCrop={handleCrop}
          onClose={handleCancel}
          src={null}
          labelStyle={{
            color: "#ffffff",
          }}
          style={{
            border: "2px dashed #ffffff",
            width:"100%"
          }}
        />
        <div className="cropper-modal__separator" />
        {preview && (
          <div className="cropper-modal__preview">
            <img src={preview} alt="Cropped" />
          </div>
        )}
      </div>
      <div className="cropper-modal__buttons">
        <button className="save" onClick={handleCancel}>
          {t("cancel")}
        </button>
        <button className="cancel" onClick={handleSave}>
          {t("save")}
        </button>
      </div>
      {/* {isConfirmModal && (
        <Confirm
          buttonText={"OK"}
          isCancelRequired={false}
          confirmTitle={isAlertText}
          onConfirm={() => {
            setIsConfirmModal(false);
          }}
          onCancel={() => {
            setIsConfirmModal(false);
          }}
        />
      )}
      {isLoaderImg ? <Loader /> : ""} */}
    </div>
  );
};

export default ImageCropperModal;
