import React from "react";
import "./index.scss";
import gif from "../../assets/images/workspace/upscale-gif.gif";

const GifLoader = (props) => {
  const { message } = props;

  return (
    <div className="loading">
      <div className="loading__container">
        <img src={gif} alt="loading-gif" />
        <p>{message}</p>
      </div>
    </div>
  );
};

export default GifLoader;
