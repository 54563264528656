import React from "react";
import "./TermsOfServices.scss";
import { useTranslation } from "react-i18next";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";

const TermsOfServices = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("language");

  return (
    <UserPortalLayout>
      <div className="terms">
        <div className="terms__container">
          <div className="terms__sections" data-scrollable="true">
            <div className="terms__heading">
              {t("")}
              <div className="terms__heading__content">
                <h2>{t("tos_heading")}</h2>
              </div>
              <div className="terms__heading__date">
                <h6>{t("tos_date_heading")} : </h6>
                <h6 style={{ padding: "0 0 0 4px" }}>
                  {t("tos_date_content")}
                </h6>
              </div>
            </div>
            <div className="terms__content__container">
              <div className="terms__content__points">
                <h5>{t("tos_content1")}</h5>
                <h5>{t("tos_content2")}</h5>
                <h5>{t("tos_content3")}</h5>
                <h5>{t("tos_content4")}</h5>
                <h5>{t("tos_content5")}</h5>
              </div>
              <div className="terms__content__points">
                <h4>{t("tos_point1_heading")}</h4>
                <h6>{t("tos_point1_content1")}</h6>
                <h6>{t("tos_point1_content2")}</h6>
                <h6>{t("tos_point1_content3")}</h6>
                <h6>{t("tos_point1_content4")}</h6>
              </div>
              <div className="terms__content__points">
                <h4>{t("tos_point2_heading")}</h4>
                <h5>{t("tos_point2_content1")}</h5>
                <h5>{t("tos_point2_content2")}</h5>
                <h5>{t("tos_point2_content3")}</h5>
                <h5>{t("tos_point2_content4")}</h5>
              </div>
              <div className="terms__content__points">
                <h4>{t("tos_point3_heading")}</h4>
                <h5>{t("tos_point3_content1")}</h5>
                <h5>{t("tos_point3_content2")}</h5>
                <h5>{t("tos_point3_content3")}</h5>
              </div>
              <div className="terms__content__points">
                <h4>{t("tos_point4_heading")}</h4>
                <h5>{t("tos_point4_content1")}</h5>
              </div>
              <div className="terms__content__points">
                <h4>{t("tos_point5_heading")}</h4>
                <h5>{t("tos_point5_content1")}</h5>
                <h5>{t("tos_point5_content2")}</h5>
                <h5>{t("tos_point5_content3")}</h5>
                <h5>{t("tos_point5_content4")}</h5>
                <h5>{t("tos_point5_content5")}</h5>
                <h5>{t("tos_point5_content6")}</h5>
                <h5>{t("tos_point5_content7")}</h5>
                <h5>{t("tos_point5_content8")}</h5>
                <h5>{t("tos_point5_content9")}</h5>
                <h5>{t("tos_point5_content10")}</h5>
                <h5>{t("tos_point5_content11")}</h5>
                <h5>{t("tos_point5_content12")}</h5>
                <h5>{t("tos_point5_content13")}</h5>
              </div>
              <div className="terms__content__points">
                <h4>{t("tos_point6_heading")}</h4>
                <h5>{t("tos_point6_content1")}</h5>
              </div>
              <div className="terms__content__points">
                <h4>{t("tos_point7_heading")}</h4>
                <h5>{t("tos_point7_content1")}</h5>
                <h5>{t("tos_point7_content2")}</h5>
              </div>
              <div className="terms__content__points">
                <h4>{t("tos_point8_heading")}</h4>
                {t("tos_point8_content") && <h5>{t("tos_point8_content")}</h5>}
                <h5>{t("tos_point8_content1")}</h5>
                <h5>{t("tos_point8_content2")}</h5>
                <ul>
                  <li>
                    <h5>{t("tos_point8_content2.1")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point8_content2.2")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point8_content2.3")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point8_content2.4")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point8_content2.5")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point8_content2.6")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point8_content2.7")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point8_content2.8")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point8_content2.9")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point8_content2.10")}</h5>
                  </li>
                </ul>
                <h5>{t("tos_point8_content3")}</h5>
                <h5>{t("tos_point8_content4")}</h5>
              </div>
              <div className="terms__content__points">
                <h4>{t("tos_point9_heading")}</h4>
                <h5>
                  {t("tos_point9_content1_n")}{" "}
                  {t("tos_point9_content1_b", { fontWeight: "bold" })}
                </h5>
                <h5>{t("tos_point9_content2")}</h5>
                <h5>{t("tos_point9_content3")}</h5>
                <h5>{t("tos_point9_content4")}</h5>
                <h5>{t("tos_point9_content4_point1")}</h5>
                <h5>{t("tos_point9_content4_point2")}</h5>
                <ul>
                  <li>
                    <h5>{t("tos_point9_content4_point2.1")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point9_content4_point2.2")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point9_content4_point2.3")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point9_content4_point2.4")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point9_content4_point2.5")}</h5>
                  </li>
                </ul>
                <h5>{t("tos_point9_content5")}</h5>
                <h5>{t("tos_point9_content5_point1")}</h5>
                <h5>{t("tos_point9_content5_point2")}</h5>
              </div>
              <div className="terms__content__points">
                <h4>{t("tos_point10_heading")}</h4>
                <h6>{t("tos_point10_content1")}</h6>
                <h6>{t("tos_point10_content2")}</h6>
                <h6>{t("tos_point10_content3")}</h6>
                <h6>{t("tos_point10_content4")}</h6>
                <ul>
                  <li>
                    <h5>{t("tos_point10_content4.1")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point10_content4.2")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point10_content4.3")}</h5>
                  </li>
                </ul>
                <h6>{t("tos_point10_content5")}</h6>
                <h6>{t("tos_point10_content6")}</h6>
                <h6>{t("tos_point10_content7")}</h6>
                <h6>{t("tos_point10_content8")}</h6>
                <ul>
                  <li>
                    <h6>{t("tos_point10_content8.1")}</h6>
                  </li>
                  <li>
                    <h6>{t("tos_point10_content8.2")}</h6>
                  </li>
                  <li>
                    <h6>{t("tos_point10_content8.3")}</h6>
                  </li>
                  <li>
                    <h6>{t("tos_point10_content8.4")}</h6>
                  </li>
                </ul>
              </div>
              <div className="terms__content__points">
                <h4>{t("tos_point11_heading")}</h4>
                <h5>{t("tos_point11_content1")}</h5>
                <h5>{t("tos_point11_content1.1")}</h5>
                <ul>
                  <li>
                    <h5>{t("tos_point11_content1.1.1")}</h5>
                  </li>
                  <li>
                    <h5>{t("tos_point11_content1.1.2")}</h5>
                  </li>
                </ul>
                {lang === "en" && <h5>{t("tos_point11_content1.2")}</h5>}
                {lang === "en" && <h5>{t("tos_point11_content1.3")}</h5>}
                <h5>{t("tos_point11_content2")}</h5>
                <h5>{t("tos_point11_content2.1")}</h5>
                <h5>{t("tos_point11_content2.2")}</h5>
                <h5>{t("tos_point11_content2.3")}</h5>
                <h5>{t("tos_point11_content3")}</h5>
                <h5>{t("tos_point11_content3.1")}</h5>
                <h5>{t("tos_point11_content3.2")}</h5>
                <h5>{t("tos_point11_content3.3")}</h5>
              </div>
              <div className="terms__content__points">
                <h4>{t("tos_point12_heading")}</h4>
                <h5>{t("tos_point12_content1")}</h5>
                <h5>{t("tos_point12_content2")}</h5>
                <h5>{t("tos_point12_content3")}</h5>
                <h5>{t("tos_point12_content4")}</h5>
                <h5>{t("tos_point12_content5")}</h5>
                <h5>{t("tos_point12_content6")}</h5>
              </div>
              <div className="terms__content__points">
                <h4>{t("tos_point13_heading")}</h4>
                <h5>{t("tos_point13_content1")}</h5>
                <h5>{t("tos_point13_content2")}</h5>
                <h5>{t("tos_point13_content3")}</h5>
                <h5>{t("tos_point13_content4")}</h5>
                <h5>{t("tos_point13_content5")}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserPortalLayout>
  );
};

export default TermsOfServices;
