import React from "react";
import "./UnCancelPlanPopup.scss";
import info from "../../../assets/images/icons/alert-icon.png";
import cross from "../../../assets/images/workspace/icons/cross-icon.png";
import { useTranslation } from "react-i18next";

const UnCancelPLan = ({ handleCloseUnCancelPopup, handleUnCancelPlan }) => {
  const { t } = useTranslation();

  const handleSetSubscription = () => {
    handleUnCancelPlan();
  };

  return (
    <div className="cancel-plan" onClick={handleCloseUnCancelPopup}>
      <div className="cancel-plan__popup">
        <div
          className="cancel-plan__popup__container"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="cancel-plan__popup__section">
            <div className="cancel-plan__popup__section__header">
              <img src={info} alt="info-icon" />
              <h3>{t("we’re_sorry_to_see_you_go!")}</h3>
              <img
                className="cross"
                src={cross}
                alt="cross-icon"
                onClick={handleCloseUnCancelPopup}
              />
            </div>
            <div className="cancel-plan__popup__section__content">
              <p>
                {t("uncancel_content1")}{" "}
                <span>{t("uncancel_content1_sub")}</span>
                {t("uncancel_content2")}
              </p>
            </div>
            <div className="cancel-plan__popup__section__buttons">
              <div className="cancel-plan__popup__section__buttons__section">
                <button onClick={handleCloseUnCancelPopup} className="cancel">
                  {t("back")}
                </button>
                <button onClick={handleSetSubscription}>
                  {t("confirm_uncancellation")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnCancelPLan;
