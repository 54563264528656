import React, { createContext, useState } from "react";

const GenerateImageContext = createContext();

function GenerateImageProvider({ children }) {
  const [generateImageData, setGenerateImageData] = useState(null);
  const [generateImageData1, setGenerateImageData1] = useState(null);

  const updateGenerateImageData = (newData) => {
    setGenerateImageData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearGenerateImageData = () => {
    setGenerateImageData(null);
  };

  const updateGenerateImageData1 = (newData) => {
    setGenerateImageData1((prevData) => ({ ...prevData, ...newData }));
  };

  const clearGenerateImageData1 = () => {
    setGenerateImageData1(null);
  };

  return (
    <GenerateImageContext.Provider
      value={{
        generateImageData,
        updateGenerateImageData,
        clearGenerateImageData,
        generateImageData1,
        updateGenerateImageData1,
        clearGenerateImageData1,
      }}
    >
      {children}
    </GenerateImageContext.Provider>
  );
}

export { GenerateImageContext, GenerateImageProvider };
