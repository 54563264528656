import React from "react";
import "./GlobalErrorPopup.scss";
import { t } from "i18next";
import contact from "../../assets/images/contact-qr.png";

const GlobalErrorPopup = ({ heading, handleClosePopupGlobal }) => {
  return (
    <div className="global-error-popup" onClick={handleClosePopupGlobal}>
      <div className="global-error-popup__container">
        <div
          className="global-error-popup__card"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="global-error-popup__card__heading">
            <h2>{heading}</h2>
          </div>
          <div className="global-error-popup__card__content">
            <p>{t("global_error_content")}</p>
          </div>
          <div className="global-error-popup__card__qr-text">
            <p>{t("global_error_qr_content")}</p>
          </div>
          <div className="global-error-popup__card__qr-code">
            <img src={contact} alt="contact" />
            <p>{t("global_error_qr_content1")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalErrorPopup;
