import React from "react";
import "./PLanEnd.scss";
import info from "../../../assets/images/icons/alert-icon.png";
import cross from "../../../assets/images/workspace/icons/cross-icon.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PLanEnd = ({ handleCloseAccessPopup, handleSubscription, content, stringA = "ok", stringB = "view_plans" }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSetSubscription = () => {
    handleSubscription();
  };

  const handleContact = () => {
    navigate('/contact');
  }

  return (
    <div className="planEnd" onClick={handleCloseAccessPopup}>
      <div className="planEnd__popup">
        <div
          className="planEnd__popup__container"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="planEnd__popup__section">
            <div className="planEnd__popup__section__header">
              <img src={info} alt="info-icon" />
              <h3>{t("privillage_heading")}</h3>
              <img
                className="cross"
                src={cross}
                alt="cross-icon"
                onClick={handleCloseAccessPopup}
              />
            </div>
            <div className="planEnd__popup__section__content">
              {/* <p>{t("privilages_content")}</p> */}
              <p>{content}</p>
            </div>
            <div className="planEnd__popup__section__buttons">
              <div className="planEnd__popup__section__buttons__section">
                <button onClick={handleCloseAccessPopup} className="cancel">
                  {t(stringA)}
                </button>
                <button onClick={handleContact}>
                  {t(stringB)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PLanEnd;
