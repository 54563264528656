/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect, useRef, useContext } from "react";
import "./Tutorials.scss";
import loader from "../../assets/images/workspace/upscale-gif.gif";
import tutorialimg1 from "../../assets/images/tutorial1.jpg";
import tutorialimg2 from "../../assets/images/tutorial2.jpg";
import tutorialimg3 from "../../assets/images/tutorial3.jpg";
import { GetAllTutorialsList } from "../../utils/apiCalls";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { UserSocketContext } from "../../store/UserSocketData";
import { t } from "i18next";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";

const Tutorials = () => {
  const { updateSocketData } = useContext(UserSocketContext);
  const tutorialsApiRef = useRef(false);
  const baseUrl = window.location.origin;
  const [tutorialsList, setTutorialsList] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }, []);

  useEffect(() => {
    if (!tutorialsApiRef.current) {
      tutorialsApiRef.current = true;
      getTutorialsList();
    }
    return () => {
      tutorialsApiRef.current = false;
    };
  }, []);

  const getTutorialsList = () => {
    setLoading(true);
    const lang = localStorage.getItem("language");
    const chinese = lang === "ch" ? true : false;
    GetAllTutorialsList((res) => {
      if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
      if (res?.status === 200) {
        setTutorialsList(res?.data[0]?.cards);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }, chinese);
  };

  const handleVideo = (video) => {
    window.open(`${baseUrl}/tutorial-data/${video?.id}`, "_blank");
  };

  return (
    <UserPortalLayout>
      <div className="tutorial">
        <div className="tutorial__container">
          <div className="tutorial__sections" data-scrollable="true">
            <div className="tutorial__main">
              <div className="tutorial__main__section">
                {tutorialsList?.length !== 0 ? (
                  tutorialsList?.map((tutorial, index) => {
                    const lastChild = tutorial?.id === 3;
                    return (
                      <div
                        className="tutorial__main__card"
                        key={index}
                        onClick={() => handleVideo(tutorial)}
                      >
                        <div className="tutorial__main__card__container">
                          <div className="tutorial__main__card__content__video">
                            {isLoading ? (
                              <Skeleton height={200} />
                            ) : (
                              <div
                                className="tutorial__main__card__content__video__section"
                                onClick={() => handleVideo(tutorial)}
                                // onMouseEnter={() => handleHover(index, true)}
                                // onMouseLeave={() => handleHover(index, false)}
                              >
                                <img
                                  src={
                                    index === 2
                                      ? tutorialimg3
                                      : index === 1
                                      ? tutorialimg2
                                      : tutorialimg1
                                  }
                                  alt="tutorial-img"
                                />
                                {/* <iframe
                                  onClick={() => handleVideo(tutorial)}
                                  ref={
                                    index === 1
                                      ? iframeRef1
                                      : index === 2
                                      ? iframeRef2
                                      : iframeRef
                                  }
                                  src={video}
                                  // allow="autoplay; encrypted-media"
                                  allowFullScreen
                                  className="hover-video"
                                  title={`tutorial-video-${index}`}
                                ></iframe> */}
                              </div>
                            )}
                          </div>
                          <div className="tutorial__main__card__heading">
                            <div className="tutorial__main__card__heading__left">
                              <div className="tutorial__main__card__heading__left__top">
                                <h3>
                                  {isLoading ? (
                                    <Skeleton width={100} />
                                  ) : (
                                    tutorial?.title
                                  )}
                                </h3>
                                {lastChild && (
                                  <h6>
                                    {isLoading ? (
                                      <Skeleton width={100} />
                                    ) : (
                                      t("new")
                                    )}
                                  </h6>
                                )}
                              </div>
                              <span style={{ background: tutorial?.color }}>
                                {isLoading ? (
                                  <Skeleton width={150} />
                                ) : (
                                  tutorial?.subTitle
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="tutorial__main__card__content">
                            <p>
                              {isLoading ? (
                                <Skeleton count={5} />
                              ) : (
                                tutorial?.description
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="gif">
                    {loading ? (
                      <img src={loader} alt="gif-loader" />
                    ) : (
                      <p>{t("no_data_found")}</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserPortalLayout>
  );
};

export default Tutorials;
