import React from "react";

const ProjectIcon = ({fill, size = "22"}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11242_325562)">
        <path d="M20.2675 8.55922L13.9467 2.23755C13.1644 1.45754 12.1047 1.01953 11 1.01953C9.89529 1.01953 8.83564 1.45754 8.05334 2.23755L1.73251 8.55922C1.49953 8.79071 1.31482 9.06614 1.18908 9.36954C1.06334 9.67295 0.999075 9.99829 1.00001 10.3267V18.5076C1.00001 19.1706 1.2634 19.8065 1.73224 20.2753C2.20108 20.7442 2.83697 21.0076 3.50001 21.0076H18.5C19.1631 21.0076 19.7989 20.7442 20.2678 20.2753C20.7366 19.8065 21 19.1706 21 18.5076V10.3267C21.0009 9.99829 20.9367 9.67295 20.8109 9.36954C20.6852 9.06614 20.5005 8.79071 20.2675 8.55922ZM13.5 19.3409H8.50001V16.0626C8.50001 15.3995 8.7634 14.7636 9.23224 14.2948C9.70108 13.8259 10.337 13.5626 11 13.5626C11.6631 13.5626 12.2989 13.8259 12.7678 14.2948C13.2366 14.7636 13.5 15.3995 13.5 16.0626V19.3409ZM19.3333 18.5076C19.3333 18.7286 19.2455 18.9405 19.0893 19.0968C18.933 19.2531 18.721 19.3409 18.5 19.3409H15.1667V16.0626C15.1667 14.9575 14.7277 13.8977 13.9463 13.1163C13.1649 12.3349 12.1051 11.8959 11 11.8959C9.89494 11.8959 8.83513 12.3349 8.05373 13.1163C7.27233 13.8977 6.83334 14.9575 6.83334 16.0626V19.3409H3.50001C3.279 19.3409 3.06703 19.2531 2.91075 19.0968C2.75447 18.9405 2.66668 18.7286 2.66668 18.5076V10.3267C2.66745 10.1059 2.75517 9.89421 2.91084 9.73755L9.23168 3.41839C9.70143 2.95083 10.3372 2.68834 11 2.68834C11.6628 2.68834 12.2986 2.95083 12.7683 3.41839L19.0892 9.74005C19.2442 9.89609 19.3319 10.1067 19.3333 10.3267V18.5076Z" fill={fill}/>
      </g>
      <defs>
        <clipPath id="clip0_11242_325562">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProjectIcon;
