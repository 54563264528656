import React, { createContext, useState, useEffect } from "react";

const AuthDataContext = createContext();

function AuthDataProvider({ children }) {
  const [authData, setAuthData] = useState(null);

  useEffect(() => {
    localStorage.setItem("authData", JSON.stringify(authData));
  }, [authData]);

  const updateAuthData = (newData) => {
    setAuthData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearAuthData = () => {
    setAuthData(null);
  };

  return (
    <AuthDataContext.Provider
      value={{ authData, updateAuthData, clearAuthData }}
    >
      {children}
    </AuthDataContext.Provider>
  );
}

export { AuthDataContext, AuthDataProvider };
