import React, { useContext, useEffect, useRef, useState } from "react";
import "./AddExistingMember.scss";
import loader from "../../../assets/images/workspace/upscale-gif.gif";
import { useTranslation } from "react-i18next";
import {
  GetCurrentTeamNotPresentUsers,
  InviteExistingUser,
} from "../../../utils/apiCalls";
import { UserSocketContext } from "../../../store/UserSocketData";
import Confirm from "../../confirmModal/confirm";
import Loader from "../../loader";
import Button from "../../button";
import { useNavigate } from "react-router-dom";

const AddExistingMember = ({
  team,
  onClose,
  handleCreateTeam,
  teamCreated,
  setTeamCreated,
  setIsEdit,
  setEditTeamData,
  handleNewMember,
  privilegeSummary,
  setIsInvited,
  isInvited,
  setIsInviteLimitPopup,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateSocketData } = useContext(UserSocketContext);
  const teamApiRef = useRef(false);
  const [usersListDetails, setUsersListDetails] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [isLoader1, setIsLoader1] = useState(false);
  const [inviteSucess, setInviteSucess] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!teamApiRef.current) {
      teamApiRef.current = true;
      getTeamDetails();
    }
  }, []);

  const getTeamDetails = () => {
    setIsLoader(true);
    const teamId = team?.teamId;
    setUsersListDetails([]);
    GetCurrentTeamNotPresentUsers((res) => {
      if (res?.status === 200) {
        setIsLoader(false);
        const filterData = res?.data?.filter(
          (member) => member?.status === "invite"
        );
        setUsersListDetails(filterData);
      } else if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      } else {
        setUsersListDetails([]);
        setIsLoader(false);
      }
    }, teamId);
  };

  const handleInvite = (id) => {
    setInviteList((prevInviteList) => {
      if (prevInviteList.includes(id)) {
        return prevInviteList.filter((inviteId) => inviteId !== id);
      } else {
        return [...prevInviteList, id];
      }
    });
  };

  const handleConfirmInvite = () => {
    setIsLoader1(true);
    const payload = {
      teamId: team?.teamId,
      userId: inviteList,
    };
    InviteExistingUser((res) => {
      if (res?.status === 200) {
        setIsInvited(!isInvited);
        if (!teamCreated) {
          setInviteSucess(true);
        } else {
          handleNewMember();
        }
        setIsLoader1(false);
        onClose();
      } else if (res?.status === 203) {
        setIsLoader1(false);
        setIsInviteLimitPopup(true);
      } else if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      } else {
        setError(res?.message);
        setIsLoader1(false);
      }
    }, payload);
  };

  const handleCloseInviteSuccess = () => {
    onClose();
    setInviteSucess(false);
  };

  const handleClose = () => {
    onClose();
    setTeamCreated(false);
  };

  const handleBack = () => {
    onClose();
    handleCreateTeam();
    setIsEdit(true);
    setEditTeamData(team);
  };

  const handleNext = () => {
    if (inviteList?.length !== 0) {
      handleConfirmInvite();
    } else {
      onClose();
      handleNewMember();
    }
  };

  const handleContact = () => {
    navigate("/contact");
  };

  return (
    <div className="existing-members">
      <div
        className="existing-members__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={`existing-members__section ${
            privilegeSummary?.userMemberLeftCount === 0 ? "no-data" : ""
          }`}
        >
          <div className="existing-members__heading">
            {/* {!teamCreated ? (
              <h2>
                {t("team_members_list")} {`${usersListDetails?.length}`} {t("members")}
              </h2> */}
            {/* // ) : ( */}
            <h2>{t("add_member")}</h2>
            {/* // )} */}
            {/* {teamCreated && ( */}
            {/* <p style={{ color: "#2BA471", fontSize: "14px" }}>
                {t("existing_member1")} {usersListDetails?.length}{" "}
                {t("existing_member2")}
              </p> */}
            {/* )} */}
            {privilegeSummary?.userMemberLeftCount === 0 ? (
              <p style={{ color: "red" }}>{t("member_limit_text")}</p>
            ) : (
              <p style={{ color: "#2BA471" }}>
                {t("existing_member1")} {usersListDetails?.length}{" "}
                {t("existing_member2")}
              </p>
            )}
            <h5>{t("definition_existing_members")}</h5>
          </div>
          {privilegeSummary?.userMemberLeftCount !== 0 ? (
            <>
              <div className="existing-members__list">
                {usersListDetails?.length !== 0 ? (
                  usersListDetails?.map((member, index) => {
                    const active = inviteList?.includes(member?.userId);
                    return (
                      <div
                        className="existing-members__list__member"
                        key={index}
                      >
                        <div className="existing-members__list__member__left">
                          <div className="existing-members__list__member__icon">
                            <img src={member?.avatarUrl} alt="member-avatar" />
                          </div>
                          <div className="existing-members__list__member__details">
                            <h3>{member?.username}</h3>
                            <h4>{member?.phoneNumber}</h4>
                          </div>
                        </div>
                        <div className="existing-members__list__member__right">
                          {!active ? (
                            <button
                              onClick={() => handleInvite(member?.userId)}
                              className={`${
                                member?.status === "invite" ? "invite" : ""
                              }`}
                            >
                              {t(member?.status)}
                            </button>
                          ) : (
                            <button
                              onClick={() => handleInvite(member?.userId)}
                              className="active"
                            >
                              {t("invited")}
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="team-members__list__loader">
                    {isLoader ? (
                      <img src={loader} alt="loader-gif" />
                    ) : (
                      <p>{t("empty_affiliated_account_size_text")}</p>
                    )}
                  </div>
                )}
              </div>
              <div className="existing-members__buttons">
                <div className="existing-members__buttons__main">
                  <Button
                    buttonClick={handleClose}
                    buttonClass="button_link"
                    buttonText={
                      teamCreated ? t("operate_next_time") : t("exit")
                    }
                  />
                  <Button
                    buttonClick={teamCreated ? handleNext : handleConfirmInvite}
                    buttonClass="button"
                    isBtnDisabled={
                      inviteList?.length === 0
                        ? teamCreated
                          ? false
                          : true
                        : false
                    }
                    buttonText={teamCreated ? t("next") : t("confirm")}
                  />
                </div>
              </div>
              {error && <p className="error-message">{error}</p>}
            </>
          ) : (
            <div className="new-members__section__buttons">
              <Button
                buttonClick={handleContact}
                buttonClass="button"
                isBtnDisabled={false}
                buttonText={t("contact_airi")}
              />
            </div>
          )}
        </div>
        {inviteSucess && (
          <Confirm
            buttonText={t("ok")}
            isCancelRequired={false}
            confirmTitle={t("team_invite_success")}
            content={t("team_invite_success_text")}
            onConfirm={() => handleCloseInviteSuccess()}
            onCancel={() => handleCloseInviteSuccess()}
          />
        )}
        {isLoader1 && <Loader />}
      </div>
    </div>
  );
};

export default AddExistingMember;
