/* eslint-disable no-unused-vars */
import React from "react";
import "./ApiErrorPopup.scss";
import info from "../../assets/images/icons/alert-icon.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { apiError } from "../../utils/apiCalls";

const ApiErrorPopup = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleRefresh = () => {
    apiError = false;
    window.location.reload();
  };

  return (
    <div className="api-error-popup" onClick={handleRefresh}>
      <div className="api-error-popup__container">
        <div className="api-error-popup__sections">
          <div className="api-error-popup__sections__header">
            <img src={info} alt="info-icon" />
            <h3>{t("you_have_been_logged_out")}</h3>
          </div>
          <div className="api-error-popup__sections__content">
            <p>{t("exit_popup_content1")}</p>
            <p>{t("exit_popup_content2")}</p>
          </div>
          <div className="api-error-popup__sections__buttons">
            <button onClick={handleRefresh}>{t("ok")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiErrorPopup;
