import React from "react";
import "./HoverTip.scss";

const HoverTip = ({ gifUrl, videoResetKey, heading, content, position, defaultPosition }) => {
  const tooltipStyle = {
    left: position?.left - defaultPosition?.left || 0,
    top: position?.top - defaultPosition?.top || 0,
  };

  return (
    <div className="instruct" style={tooltipStyle}>
      <div className="instruct__container">
        <div className="instruct__card">
          <img src={`${gifUrl}?reset=${videoResetKey}`} alt="gif" />
        </div>
        <div className="instruct__content">
          <h4>{heading}</h4>
          {content?.map((text, index) => {
            return <p key={index}>&#x2022; {text}</p>;
          })}
        </div>
      </div>
    </div>
  );
};

export default HoverTip;
