import React, { useContext, useEffect, useRef, useState } from "react";
import "./TeamMembers.scss";
import loader from "../../../assets/images/workspace/upscale-gif.gif";
import { useTranslation } from "react-i18next";
import { GetTeamDetailsById } from "../../../utils/apiCalls";
import { UserSocketContext } from "../../../store/UserSocketData";

const TeamMembers = ({ team, onClose }) => {
  const { t } = useTranslation();
  const teamApiRef = useRef(false);
  const { updateSocketData } = useContext(UserSocketContext);
  const [teamDetails, setTeamDetails] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    if (!teamApiRef.current) {
      teamApiRef.current = true;
      getTeamDetails();
    }
  }, []);

  const getTeamDetails = () => {
    setIsLoader(true);
    const teamId = team?.teamId;
    GetTeamDetailsById((res) => {
      if (res?.status === 200) {
        setIsLoader(false);
        const joinedMembers = res?.data?.membersData?.filter(
          (member) =>
            member?.memberRoleId === 2 ||
            member?.memberRoleId === 1 ||
            member?.memberRoleId === 4
        );
        setTeamDetails(joinedMembers);
      } else if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      } else {
        setTeamDetails([]);
        setIsLoader(false);
      }
    }, teamId);
  };

  return (
    <div className="team-members" onClick={onClose}>
      <div
        className="team-members__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="team-members__section">
          <div className="team-members__heading">
            <h2>
              {t("team_members_list")} {`${teamDetails?.length}`} {t("people")}
            </h2>
          </div>
          <div className="team-members__list">
            {teamDetails?.length !== 0 ? (
              teamDetails?.map((member, index) => {
                return (
                  <div className="team-members__list__member" key={index}>
                    <div className="team-members__list__member__icon">
                      <img src={member?.avatarUrl} alt="member-avatar" />
                    </div>
                    <div className="team-members__list__member__details">
                      <h3>{member?.username}</h3>
                      <h4>{member?.phoneNumber}</h4>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="team-members__list__loader">
                {isLoader ? (
                  <img src={loader} alt="loader-gif" />
                ) : (
                  <p>{t("empty_team_size_text")}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMembers;
