import React, { createContext, useState } from "react";

const UpscaleToolsetContext = createContext();

function UpscaleToolsetProvider({ children }) {
  const [upscaleToolsetData, setUpscaleToolsetData] = useState(null);

  const updateUpscaleToolsetData = (newData) => {
    setUpscaleToolsetData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearUpscaleToolsetData = () => {
    setUpscaleToolsetData(null);
  };

  return (
    <UpscaleToolsetContext.Provider
      value={{
        upscaleToolsetData,
        updateUpscaleToolsetData,
        clearUpscaleToolsetData,
      }}
    >
      {children}
    </UpscaleToolsetContext.Provider>
  );
}

export { UpscaleToolsetContext, UpscaleToolsetProvider };
