import React from "react";
import "./AccountTooltip.scss";

const AccountTooltip = ({ TooltipContent, display }) => {
  const contentLines = TooltipContent?.split("/n");
  return (
    <div className="acccount-tooltip" style={display} onClick={(e) => e.stopPropagation()}>
      <div className="acccount-tooltip__container">
        <ul>
          {contentLines?.map((item, index) => (
            <li key={index}>{item?.trim()}</li>
          ))}
        </ul>
      </div>
      <div className="acccount-tooltip__arrow"></div>
    </div>
  );
};

export default AccountTooltip;
