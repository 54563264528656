import React from "react";
import "./TipsPopup.scss";
import { t } from "i18next";

const TipsPopups = ({ heading, content, styles, image, position }) => {
  const baseUrl = window.location.origin;
  const contentLines = content?.split("/n");

  const popupStyle = {
    position: "fixed",
    right: `calc(100vw - ${position?.left}px + 20px)`,
    bottom: `calc(100vh - 40px - ${position?.top}px - ${styles})`,
  };

  return (
    <div
      className={`learn ${styles?.top === true ? "top" : "bottom"} ${
        styles?.left === true ? "left" : "right"
      }`}
      style={popupStyle}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="learn__popup">
        <div className="learn__popup__card">
          <img src={image} alt="learn-tip" />
        </div>
        <div className="learn__popup__content">
          <h4>{heading}</h4>
          <ul>
            {contentLines?.map((item, index) => (
              <li key={index}>
                <span>&#x2022;</span> {item?.trim()}
              </li>
            ))}
          </ul>
          <a href={`${baseUrl}/tutorials`} target="_blank" rel="noreferrer">
            {t("learn_how")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default TipsPopups;
