import React from "react";
import "./FavoriteTooltip.scss";

const FavoriteTooltip = ({ text }) => {
  const contentLines = text?.split("/n");
  return (
    <div className="fav-tooltip">
      <div className="fav-tooltip__container">
        <ul>
          {contentLines?.map((item, index) => (
            <li key={index}> {item?.trim()}</li>
          ))}
        </ul>
      </div>
      <div className="fav-tooltip__section">
        <div className="fav-tooltip__arrow"></div>
      </div>
    </div>
  );
};

export default FavoriteTooltip;
