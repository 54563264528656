/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Portal from "./Portal";
import "./BotTooltip.scss";
import "../../pages/AiriBot/AiriBotStyles.scss";

const BotTooltip = ({ triggerElement, isVisible, TooltipContent }) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (isVisible && triggerElement) {
      const rect = triggerElement.getBoundingClientRect();
      setPosition({
        top: rect.top + window.scrollY - 8,
        left: rect.right + window.scrollX + 60,
      });
    }
  }, [isVisible, triggerElement]);

  return (
    <Portal>
      <div
        className="bot-tooltip"
        style={{
          top: `${position.top}px`,
          left: `${position.left}px`,
          display: isVisible ? "flex" : "none",
        }}
      >
        <div className="bot-tooltip__arrow"></div>
        <div className="bot-tooltip__container">
          <ul>
            {TooltipContent.split("\n").map((item, index) => (
              <li key={index}>{item.trim()}</li>
            ))}
          </ul>
        </div>
      </div>
    </Portal>
  );
};

export default BotTooltip;
