import React, { useEffect } from "react";
import "./ComingSoon.scss";
import info from "../../assets/images/icons/alert-icon.png";
import { useTranslation } from "react-i18next";

const ComingSoon = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.add("body-no-scroll");

    return () => {
      document.body.classList.remove("body-no-scroll");
    };
  }, []);

  return (
    <div className="coming">
      <div className="coming__popup">
        <div
          className="coming__popup__container"
          //   onClick={(e) => e.stopPropagation()}
        >
          <div className="coming__popup__section">
            <div className="coming__popup__section__header">
              <img src={info} alt="info-icon" />
              <h3>{t("coming_soon")}</h3>
              {/* <img
                className="cross"
                src={cross}
                alt="cross-icon"
               
              /> */}
            </div>
            <div className="coming__popup__section__content">
              <p>{t("coming_soon_content")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
