import React from "react";
import "./PrivacyPolicy.scss";
import { useTranslation } from "react-i18next";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("language");

  return (
    <UserPortalLayout>
      <div className="privacy">
        <div className="privacy__container">
          <div className="privacy__sections" data-scrollable="true">
            <div className="privacy__heading">
              <div className="privacy__heading__content">
                <h2>{t("pp_heading")}</h2>
              </div>
              <div className="privacy__heading__date">
                <h6>{t("pp_date_heading")} : </h6>
                <h6 style={{ padding: "0 0 0 4px" }}>{t("pp_date_content")}</h6>
              </div>
              <h5>{t("pp_content")}</h5>
            </div>
            <div className="privacy__content__container">
              <div className="privacy__content__points">
                <h4>{t("pp_point1_heading")}</h4>
                <h5>{t("pp_point1_content1")}</h5>
                <h5>{t("pp_point1_content2")}</h5>
                <h5>{t("pp_point1_content3")}</h5>
                <h6>{t("pp_point1_content4")}</h6>
                <h5>{t("pp_point1_content5")}</h5>
                <h5>{t("pp_point1_content5.1")}</h5>
                <h5>{t("pp_point1_content5.2")}</h5>
                <h5>{t("pp_point1_content5.3")}</h5>
                <h5>{t("pp_point1_content5.4")}</h5>
                <h5>{t("pp_point1_content5.5")}</h5>
                <h5>{t("pp_point1_content5.6")}</h5>
                <h5>{t("pp_point1_content5.7")}</h5>
                <h5>{t("pp_point1_content5.8")}</h5>
                <h5>{t("pp_point1_content5.9")}</h5>
                <h5>{t("pp_point1_content5.10")}</h5>
              </div>
              <div className="privacy__content__points">
                <h4>{t("pp_point2_heading")}</h4>
                <h5>{t("pp_point2_content1")}</h5>
                <h5>{t("pp_point2_content2")}</h5>
                <h5>{t("pp_point2_content3")}</h5>
                <h5>{t("pp_point2_content4")}</h5>
                <h5>{t("pp_point2_content5")}</h5>
              </div>
              <div className="privacy__content__points">
                <h4>{t("pp_point3_heading")}</h4>
                <h5>{t("pp_point3_content1")}</h5>
                <h5>{t("pp_point3_content1.1")}</h5>
                <h5>{t("pp_point3_content1.2")}</h5>
                <h5>{t("pp_point3_content2")}</h5>
                <h5>{t("pp_point3_content2.1")}</h5>
                <h5>{t("pp_point3_content2.1.1")}</h5>
                <h5>{t("pp_point3_content2.1.2")}</h5>
                <h5>{t("pp_point3_content2.1.3")}</h5>
                <h5>{t("pp_point3_content2.2")}</h5>
                <h5>{t("pp_point3_content2.2_content")}</h5>
                <h5>{t("pp_point3_content2.2_content1")}</h5>
                <ul>
                  <li>
                    <h6>{t("pp_point3_content2.2_content1.1")}</h6>
                  </li>
                  <li>
                    <h6>{t("pp_point3_content2.2_content1.2")}</h6>
                  </li>
                  <li>
                    <h5>{t("pp_point3_content2.2_content1.3")}</h5>
                  </li>
                </ul>
                <h5>{t("pp_point3_content2.2_content1.4")}</h5>
                <h5>{t("pp_point3_content2.2_content2")}</h5>
                <h5>{t("pp_point3_content2.2_content3")}</h5>
                <h5>{t("pp_point3_content2.3")}</h5>
                <h5>{t("pp_point3_content2.3_content1")}</h5>
                <h5>{t("pp_point3_content2.3_content2")}</h5>
                <h5>{t("pp_point3_content2.4")}</h5>
                <h5>{t("pp_point3_content2.4_content1")}</h5>
                <h5>{t("pp_point3_content2.4_content1.1")}</h5>
                <h5>{t("pp_point3_content2.4_content1.2")}</h5>
                <h5>{t("pp_point3_content2.4_content1.3")}</h5>
                <h5>{t("pp_point3_content2.4_content1.4")}</h5>
                <h5>{t("pp_point3_content2.4_content1.5")}</h5>
                <h5>{t("pp_point3_content2.4_content1.6")}</h5>
                <h5>{t("pp_point3_content2.4_content1.7")}</h5>
                <h5>{t("pp_point3_content2.4_content1.8")}</h5>
                <h5>{t("pp_point3_content2.4_content1.9")}</h5>
                <h5>{t("pp_point3_content2.4_content1.10")}</h5>
                <h5>{t("pp_point3_content2.4_content1.11")}</h5>
                <h6>{t("pp_point3_content2.4_content2")}</h6>
                <h5>{t("pp_point3_content3")}</h5>
                <h5>{t("pp_point3_content3.1")}</h5>
                <h5>{t("pp_point3_content3.2")}</h5>
                <h5>{t("pp_point3_content3.3")}</h5>
                <h5>{t("pp_point3_content4")}</h5>
                <h5>{t("pp_point3_content4.1")}</h5>
                <h5>{t("pp_point3_content4.2")}</h5>
                <h5>{t("pp_point3_content5")}</h5>
                <h5>{t("pp_point3_content5.1")}</h5>
                <h5>{t("pp_point3_content5.2")}</h5>
                <h5>{t("pp_point3_content6")}</h5>
                <h5>{t("pp_point3_content6.1")}</h5>
              </div>
              <div className="privacy__content__points">
                <h4>{t("pp_point4_heading")}</h4>
                <h5>{t("pp_point4_content1")}</h5>
                <h5>{t("pp_point4_content2")}</h5>
                <h5>{t("pp_point4_content3")}</h5>
                <h5>{t("pp_point4_content4")}</h5>
                <h5>{t("pp_point4_content5")}</h5>
                <h5>{t("pp_point4_content6")}</h5>
                <h5>{t("pp_point4_content7")}</h5>
                <h5>{t("pp_point4_content8")}</h5>
                <h5>{t("pp_point4_content9")}</h5>
                <h5>{t("pp_point4_content10")}</h5>
                <h5>{t("pp_point4_content11")}</h5>
                <h5>{t("pp_point4_content12")}</h5>
                <h5>{t("pp_point4_content13")}</h5>
                <h5>{t("pp_point4_content14")}</h5>
                <h5>{t("pp_point4_content15")}</h5>
                <h5>{t("pp_point4_content16")}</h5>
                <h5>{t("pp_point4_content17")}</h5>
                <h5>{t("pp_point4_content18")}</h5>
                <h5>{t("pp_point4_content19")}</h5>
                <h5>{t("pp_point4_content20")}</h5>
                <h5>{t("pp_point4_content21")}</h5>
                <h5 style={{ textDecoration: "underline" }}>
                  {t("pp_point4_content22")}
                </h5>
                <h5>{t("pp_point4_content23")}</h5>
                <h5>{t("pp_point4_content24")}</h5>
                <h5>{t("pp_point4_content25")}</h5>
                <h5>{t("pp_point4_content26")}</h5>
                <h5>
                  {t("pp_point4_content27")}
                  <span
                    style={{ fontWeight: "bold", textDecoration: "underline" }}
                  >
                    {t("pp_point4_content27.1")}
                  </span>
                </h5>
                <h5>{t("pp_point4_content28")}</h5>
                <h5>{t("pp_point4_content29")}</h5>
                <h5>{t("pp_point4_content30")}</h5>
                <h5>{t("pp_point4_content31")}</h5>
                <h5>{t("pp_point4_content32")}</h5>
                <h5>{t("pp_point4_content33")}</h5>
                <h5>{t("pp_point4_content34")}</h5>
                <h5>{t("pp_point4_content35")}</h5>
                <h5>{t("pp_point4_content36")}</h5>
                <h5>{t("pp_point4_content37")}</h5>
                <h5>{t("pp_point4_content38")}</h5>
                <h6>{t("pp_point4_content39")}</h6>
                <h5>{t("pp_point4_content40")}</h5>
                <h5>{t("pp_point4_content41")}</h5>
                <h5>{t("pp_point4_content42")}</h5>
                <h5>{t("pp_point4_content43")}</h5>
                <h5>{t("pp_point4_content44")}</h5>
                <h5>{t("pp_point4_content45")}</h5>
                <h5>{t("pp_point4_content46")}</h5>
                <h5>{t("pp_point4_content47")}</h5>
                <h5>{t("pp_point4_content48")}</h5>
                <h5>{t("pp_point4_content49")}</h5>
                <h5>{t("pp_point4_content50")}</h5>
                <h5>{t("pp_point4_content51")}</h5>
                <h5>{t("pp_point4_content52")}</h5>
                <h5>{t("pp_point4_content53")}</h5>
                <h5>{t("pp_point4_content54")}</h5>
                <h5>{t("pp_point4_content55")}</h5>
                <h5>{t("pp_point4_content56")}</h5>
                <h5>{t("pp_point4_content57")}</h5>
                <h5>{t("pp_point4_content58")}</h5>
                <h5>{t("pp_point4_content59")}</h5>
              </div>
              <div className="privacy__content__points">
                <h4>{t("pp_point5_heading")}</h4>
                <h5>{t("pp_point5_content1")}</h5>
                <h5>{t("pp_point5_content2")}</h5>
                <h5>{t("pp_point5_content3")}</h5>
                <h5>{t("pp_point5_content4")}</h5>
                <h5>{t("pp_point5_content5")}</h5>
                <h5>{t("pp_point5_content6")}</h5>
                <h5>{t("pp_point5_content7")}</h5>
                <h5>{t("pp_point5_content8")}</h5>
                <h5>{t("pp_point5_content9")}</h5>
                <h5>{t("pp_point5_content10")}</h5>
                <h6>{t("pp_point5_content11")}</h6>
                <h5>{t("pp_point5_content12")}</h5>
                <h5>{t("pp_point5_content13")}</h5>
                <h5>{t("pp_point5_content14")}</h5>
                <h5>{t("pp_point5_content15")}</h5>
                <h5>{t("pp_point5_content16")}</h5>
                <h5>{t("pp_point5_content17")}</h5>
                <h5>{t("pp_point5_content18")}</h5>
                <h5>{t("pp_point5_content19")}</h5>
              </div>
              <div className="privacy__content__points">
                <h4>{t("pp_point6_heading")}</h4>
                <h5>{t("pp_point6_content1")}</h5>
                <h5>{t("pp_point6_content2")}</h5>
              </div>
              <div className="privacy__content__points">
                <h4>{t("pp_point7_heading")}</h4>
                <h5>{t("pp_point7_content1")}</h5>
                <h5>{t("pp_point7_content2")}</h5>
              </div>
              <div className="privacy__content__points">
                <h4>{t("pp_point8_heading")}</h4>
                <h5>{t("pp_point8_content1")}</h5>
                <h5>{t("pp_point8_content2")}</h5>
                <h5>{t("pp_point8_content2")}</h5>
              </div>
              <div className="privacy__content__points">
                <h4>{t("pp_point9_heading")}</h4>
                <h5>{t("pp_point9_content1")}</h5>
                <h5>{t("pp_point9_content2")}</h5>
                <h5>{t("pp_point9_content3")}</h5>
                <h5>{t("pp_point9_content4")}</h5>
              </div>
              <div className="privacy__content__points">
                <h4>{t("pp_point10_heading")}</h4>
                <h5>{t("pp_point10_content1")}</h5>
                <h5>{t("pp_point10_content2")}</h5>
                {lang === "en" && <h5>{t("pp_point10_content3")}</h5>}
                <h5>{t("pp_point10_content4")}</h5>
                <h5>{t("pp_point10_content5")}</h5>
                <h5>{t("pp_point10_content6")}</h5>
                <h5>{t("pp_point10_content7")}</h5>
                <h5>{t("pp_point10_content8")}</h5>
                <h5>{t("pp_point10_content9")}</h5>
                <h5>{t("pp_point10_content10")}</h5>
                <h5>{t("pp_point10_content11")}</h5>
                <h5>{t("pp_point10_content12")}</h5>
                <h5>{t("pp_point10_content13")}</h5>
                <h5>{t("pp_point10_content14")}</h5>
                <h5>{t("pp_point10_content15")}</h5>
                <h5>{t("pp_point10_content16")}</h5>
                <h5>{t("pp_point10_content17")}</h5>
                <h5>{t("pp_point10_content18")}</h5>
                <h5>{t("pp_point10_content19")}</h5>
                <h5>{t("pp_point10_content20")}</h5>
                <h5>{t("pp_point10_content21")}</h5>
                <h5>{t("pp_point10_content22")}</h5>
                <h5>{t("pp_point10_content23")}</h5>
                <h5>{t("pp_point10_content24")}</h5>
              </div>
              <div className="privacy__content__points">
                <h4>{t("pp_point11_heading")}</h4>
                <h5>{t("pp_point11_content")}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserPortalLayout>
  );
};

export default PrivacyPolicy;
