import React, { useState, useEffect, useContext } from "react";
import "./NewVersion.scss";
import info from "../../assets/images/icons/alert-icon.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserSocketContext } from "../../store/UserSocketData";

const NewVersion = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { socketData, latestVersion, updateSocketData } =
    useContext(UserSocketContext);
  const lang = localStorage.getItem("language");
  const [isDisable, setIsDisable] = useState(true);
  const [count, setCount] = useState(5);

  const handleExit = () => {
    updateSocketData({
      isNewVersionPopup: false,
      isSocketClose: true,
      isNewVersionExit: true,
    });
    if (latestVersion?.version !== "0") {
      const token = localStorage.getItem("token");
      const getOldVersion = localStorage.getItem("currentVersion");
      const lang = localStorage.getItem("language");
      localStorage.clear();
      localStorage.setItem("token", token);
      localStorage.setItem("currentVersion", getOldVersion);
      localStorage.setItem("language", lang);
      window.location.href = "/projects";
    } else {
      const getOldVersion = localStorage.getItem("currentVersion");
      const lang = localStorage.getItem("language");
      localStorage.clear();
      localStorage.setItem("currentVersion", getOldVersion);
      localStorage.setItem("language", lang);
      window.location.href = "/sign-in";
    }
  };

  const handleClose = () => {
    updateSocketData({
      isNewVersionPopup: false,
      isSocketClose: false,
      isNewVersionExit: true,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setIsDisable(false);
    }, 5000);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount > 0) {
          return prevCount - 1;
        } else {
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      className="new-version"
      onClick={
        socketData?.isNewVersionExit
          ? handleExit
          : isDisable
          ? null
          : handleClose
      }
    >
      <div className="new-version__container">
        <div
          className="new-version__sections"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="new-version__sections__header">
            <img src={info} alt="info-icon" />
            <h3>
              {lang === "ch"
                ? latestVersion?.chineseTitle
                : latestVersion?.title}
            </h3>
          </div>
          <div className="new-version__sections__content">
            {lang === "ch" ? (
              <p>
                {latestVersion?.chineseSubtitle
                  ?.trim()
                  ?.replace(/\\n/g, "<br>")}
              </p>
            ) : (
              <p>{latestVersion?.subtitle?.trim()?.replace(/\\n/g, "<br>")}</p>
            )}
            <p
              dangerouslySetInnerHTML={{
                __html:
                  lang === "ch"
                    ? latestVersion?.chineseDescription
                        ?.trim()
                        ?.replace(/\\n/g, "<br>")
                    : latestVersion?.description
                        ?.trim()
                        ?.replace(/\\n/g, "<br>"),
              }}
            ></p>
          </div>
          <div className="new-version__sections__buttons">
            {socketData?.isNewVersionExit ? (
              <button onClick={handleExit}>{latestVersion?.version !== "0" ? t("version_button_text1") : t("version_button_text")}</button>
            ) : (
              <button disabled={isDisable} onClick={handleClose}>
                {t("update_i_have_read")} {isDisable ? `(${count}s)` : ""}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewVersion;
