import React from "react";
import "./RenewalPolicy.scss";
import { useTranslation } from "react-i18next";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";

const RenewalPolicy = () => {
  const { t } = useTranslation();

  return (
    <UserPortalLayout>
      <div className="renewal">
        <div className="renewal__container">
          <div className="renewal__sections" data-scrollable="true">
            <div className="renewal__heading">
              <div className="renewal__heading__content">
                <h2>{t("auto_heading")}</h2>
              </div>
              <div className="renewal__heading__date">
                <h6>{t("auto_date_heading")} : </h6>
                <h6>{t("auto_date_content")}</h6>
              </div>
            </div>
            <div className="renewal__content__container">
              <div className="renewal__content__points">
                <h4>{t("auto_point1_heading")}</h4>
                <h5>{t("auto_point1_content")}</h5>
              </div>
              <div className="renewal__content__points">
                <h4>{t("auto_point2_heading")}</h4>
                <h5>{t("auto_point2_content1")}</h5>
                <h6>{t("auto_point2_content2")}</h6>
                <h6>{t("auto_point2_content3")}</h6>
                <h5>{t("auto_point2_content4")}</h5>
              </div>
              <div className="renewal__content__points">
                <h4>{t("auto_point3_heading")}</h4>
                <h6>{t("auto_point3_content1")}</h6>
                <h6>{t("auto_point3_content2")}</h6>
                <h6>{t("auto_point3_content3")}</h6>
                <h6>{t("auto_point3_content4")}</h6>
                <h6>{t("auto_point3_content5")}</h6>
              </div>
              <div className="renewal__content__points">
                <h4>{t("auto_point4_heading")}</h4>
                <h6>{t("auto_point4_content1")}</h6>
                <h6>{t("auto_point4_content2")}</h6>
              </div>
              <div className="renewal__content__points">
                <h4>{t("auto_point5_heading")}</h4>
                <h5>{t("auto_point5_content1")}</h5>
                <h5>{t("auto_point5_content2")}</h5>
                <h6>{t("auto_point5_content3")}</h6>
                <h6>{t("auto_point5_content4")}</h6>
              </div>
              <div className="renewal__content__points">
                <h4>{t("auto_point6_heading")}</h4>
                <h5>{t("auto_point6_content1")}</h5>
                <h5>{t("auto_point6_content2")}</h5>
                <h6>{t("auto_point6_content3")}</h6>
                <h6>{t("auto_point6_content4")}</h6>
              </div>
              <div className="renewal__content__points">
                <h4>{t("auto_point7_heading")}</h4>
                <h5>{t("auto_point7_content1")}</h5>
                <h5>{t("auto_point7_content2")}</h5>
                <h6>{t("auto_point7_content3")}</h6>
              </div>
              <div className="renewal__content__points">
                <h4>{t("auto_point8_heading")}</h4>
                <h5>{t("auto_point8_content1")}</h5>
                <h5>{t("auto_point8_content2")}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserPortalLayout>
  );
};

export default RenewalPolicy;
