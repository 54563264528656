import React from "react";
import "./PaymentPopup.scss";
import payment from "../../../assets/images/payment.png";

const PaymentPopup = ({ handleOpenClosePayment, handleOpenCloseSuccess }) => {
  const handleClick = () => {
    handleOpenClosePayment();
    handleOpenCloseSuccess();
  };

  return (
    <div className="payment" onClick={handleClick}>
      <div className="payment__container">
        <img src={payment} alt="error" onClick={(e) => e.stopPropagation()} />
      </div>
    </div>
  );
};

export default PaymentPopup;
