import React, { createContext, useState } from "react";

const AtmosphereRefContext = createContext();

function AtmosphereRefProvider({ children }) {
  const [atmosphereRefData, setAtmosphereRefData] = useState(null);

  const updateAtmosphereRefData = (newData) => {
    setAtmosphereRefData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearAtmosphereRefData = () => {
    setAtmosphereRefData(null);
  };

  return (
    <AtmosphereRefContext.Provider
      value={{
        atmosphereRefData,
        updateAtmosphereRefData,
        clearAtmosphereRefData,
      }}
    >
      {children}
    </AtmosphereRefContext.Provider>
  );
}

export { AtmosphereRefContext, AtmosphereRefProvider };
