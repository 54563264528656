import React, { createContext, useState } from "react";

const ToolsetDataContext = createContext();

function ToolsetDataProvider({ children }) {
  const [toolsetData, setToolsetData] = useState(null);

  const updateToolsetData = (newToolsetData) => {
    setToolsetData((prevData) => ({ ...prevData, ...newToolsetData }));
  };

  const clearToolsetData = () => {
    setToolsetData(null);
  };

  return (
    <ToolsetDataContext.Provider
      value={{
        toolsetData,
        updateToolsetData,
        clearToolsetData,
      }}
    >
      {children}
    </ToolsetDataContext.Provider>
  );
}

export { ToolsetDataContext, ToolsetDataProvider };
