import React, { useContext, useEffect, useState } from "react";
import "./Subscriptions.scss";
import userIcon from "../../../assets/images/icons/user-icon.png";
import {
  GetAllAddOnPlansFromAdmin,
  ProvideAddonOnExhibition,
  UserHaveSpecialAddon,
} from "../../../utils/apiCalls";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/loader";
import { UserSocketContext } from "../../../store/UserSocketData";

const Subscriptions = ({
  addonRecord = null,
  onClose,
  setIsSuccess,
  setAddedAddon,
  setAddedAddonUser,
}) => {
  const { t } = useTranslation();
  const { updateSocketData } = useContext(UserSocketContext);
  const token = localStorage.getItem("admintoken");
  const lang = localStorage.getItem("language");
  const [addons, setAddons] = useState([]);
  const [load, setIsLoad] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedAddon, setSelectedAddon] = useState(null);
  const [redeemCode, setRedeemCode] = useState("");
  const [redeemError, setRedeemError] = useState(false);
  const [adminCode, setAdminCode] = useState("");
  const [adminError, setAdminError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(true);
  const [userAddon, setUserAddon] = useState(null);

  useEffect(() => {
    if (token && addonRecord) {
      getUserHaveAddon();
      getAllAddonPlans();
    }
  }, [addonRecord]);

  useEffect(() => {
    if (!selectedAddon || redeemCode === "" || adminCode === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [selectedAddon, redeemCode, adminCode]);

  const getUserHaveAddon = () => {
    const userId = addonRecord?.id;
    const chinese = lang === "ch" ? true : false;
    setLoader(true);
    UserHaveSpecialAddon(
      (res) => {
        if (res?.status === 200) {
          setLoader(false);
          setUserAddon(res);
        } else if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        } else {
          setLoader(false);
        }
      },
      chinese,
      userId
    );
  };

  const getAllAddonPlans = () => {
    const bonusId = 5;
    const teamId = 0;
    const chinese = lang === "ch" ? true : false;
    setIsLoad(true);
    setAddons([]);
    GetAllAddOnPlansFromAdmin(
      (res) => {
        const { specialAddons } = res?.data?.reduce(
          (acc, item) => {
            if (item.specialAddon) {
              acc.specialAddons.push(item);
            } else {
              acc.regularAddons.push(item);
            }
            return acc;
          },
          { specialAddons: [], regularAddons: [] }
        );
        setAddons(specialAddons);
        setIsLoad(false);
      },
      chinese,
      bonusId,
      teamId
    );
  };

  const handleSelect = (add, name) => {
    if (selectedAddon !== null && selectedAddon?.id === add?.id) {
      setSelectedAddon(null);
    } else {
      setSelectedAddon(add);
      setAddedAddon(name);
    }
  };

  const handleRedeemChange = (val) => {
    setRedeemCode(val);
  };

  const handleAdminChange = (val) => {
    setAdminCode(val);
  };

  const handleSubmit = () => {
    if (redeemCode !== "" && adminCode !== "") {
      setRedeemError(false);
      setAdminError(false);
      const payload = {
        userId: addonRecord?.id,
        addOnId: selectedAddon?.id,
        couponCode: redeemCode,
        supervisorCode: adminCode,
        chinese: lang === "ch" ? true : false,
      };
      if (payload) {
        setErrorMessage("");
        setIsLoad(true);
        ProvideAddonOnExhibition((res) => {
          if (res?.status === 200) {
            setIsSuccess(true);
            setAddedAddonUser(addonRecord);
            onClose();
          } else {
            setErrorMessage(res?.message);
            setIsSuccess(false);
          }
          setIsLoad(false);
        }, payload);
      }
    } else if (redeemCode === "" && adminCode === "") {
      setRedeemError(true);
      setAdminError(true);
      if (redeemCode === "") {
        setRedeemError(true);
      } else if (adminCode === "") {
        setAdminError(true);
      }
    }
  };

  return (
    <div className="subscription">
      <div className="subscription_container">
        <div className="subscription_sections">
          {!loader && (
            <>
              <div className="subscription_heading">
                <img
                  src={addonRecord ? addonRecord?.avatarUrl : userIcon}
                  alt="user-icon"
                  className="usericon"
                />
                <h4>{addonRecord?.name ?? addonRecord?.phoneNumber}</h4>
              </div>
              {userAddon?.data === true ? (
                <p>{userAddon?.message}</p>
              ) : (
                <>
                  <div className="subscription_addons">
                    <div className="subscription_addons_list">
                      {addons &&
                        addons?.map((add) => {
                          const active = (selectedAddon?.id ?? 0) === add?.id;
                          return (
                            <div
                              className={`subscription_addons_sec ${
                                active ? "active" : ""
                              }`}
                              key={add?.id}
                            >
                              <div className="subscription_addons_sec_data">
                                <h4>{add?.name}</h4>
                                {active ? (
                                  <button
                                    className="active"
                                    onClick={() => handleSelect(add, add?.name)}
                                  >
                                    {t("un_select")}
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => handleSelect(add, add?.name)}
                                  >
                                    {t("select")}
                                  </button>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="subscription_redeem">
                    <div className="subscription_redeem_section">
                      <div className="subscription_redeem_text">
                        <h5>{t("redeem_code")}</h5>
                      </div>
                      <div className="subscription_redeem_input">
                        <input
                          value={redeemCode}
                          placeholder={t("redeem_placeholder")}
                          onChange={(e) => handleRedeemChange(e.target.value)}
                        />
                      </div>
                    </div>
                    {redeemError && <p>{t("redeem_error")}</p>}
                  </div>
                  <div className="subscription_admin">
                    <div className="subscription_admin_tip">
                      <h3>{t("admin_tip")}</h3>
                    </div>
                    <div className="subscription_admin_section">
                      <div className="subscription_admin_text">
                        <h5>{t("admin_code")}</h5>
                      </div>
                      <div className="subscription_admin_input">
                        <input
                          value={adminCode}
                          placeholder={t("admin_placeholder")}
                          onChange={(e) => handleAdminChange(e.target.value)}
                        />
                      </div>
                    </div>
                    {adminError && <p>{t("admin_error")}</p>}
                  </div>
                  <div className="subscription_submit">
                    {errorMessage !== "" && <p>{errorMessage}</p>}
                    <button
                      className={`${isDisabled ? "disable" : ""}`}
                      onClick={() => handleSubmit()}
                    >
                      {t("submit_redeem")}
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {loader && <Loader />}
        {load && <Loader />}
      </div>
    </div>
  );
};

export default Subscriptions;
