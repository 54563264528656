import React, { useContext, useRef, useState } from "react";
import "./GenrateImage.scss";
import baseicon from "../../assets/images/workspace/icons/base-icon.png";
import defaultimgbig from "../../assets/images/workspace/workspace-generating box.png";
import dots from "../../assets/images/workspace/dots.gif";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import { GenerateImageContext } from "../../store/GenerateImage";
import { useTranslation } from "react-i18next";
import {
  computePostProcessedDimension,
  handleBase64Data,
  processImage,
  uploadFile,
} from "../../utils/workspaceUtils";
import { CallAwsUrl, GenerateImage } from "../../utils/apiCalls";
import { processText } from "../../utils/commonUtils";

const GenrateImage = () => {
  const { t } = useTranslation();
  const textareaRef = useRef(null);
  const { updateGenerateImageData, updateGenerateImageData1 } =
    useContext(GenerateImageContext);
  const generateImageLocal = JSON.parse(
    localStorage.getItem(`generateImageBaseimg`)
  );
  const generateImageLocal1 = JSON.parse(
    localStorage.getItem(`generateImageBaseimg1`)
  );

  const [inputKey, setInputKey] = useState(1);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [defaultText, setDefaultText] = useState("");
  const [remaining, setRemaining] = useState(0);
  const [typing, setTyping] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileUpload = (file, toolset) => {
    uploadFile(
      "base-image",
      false,
      file,
      async (data) => {
        const renderUpdate = {
          feedDesignBase64: data,
          uploadedBaseImageUrl: data?.path,
        };
  
        switch (toolset) {
          case 21:
            localStorage.setItem(
              `generateImageBaseimg`,
              JSON.stringify(renderUpdate)
            );
            updateGenerateImageData(renderUpdate);
            break;
  
          case 22:
            localStorage.setItem(
              `generateImageBaseimg1`,
              JSON.stringify(renderUpdate)
            );
            updateGenerateImageData(renderUpdate);
            break;
  
          default:
            console.log("Unhandled toolset:", toolset);
        }
      },
      () => {
        console.log("Upload completed for toolset:", toolset);
      },
      (error) => {
        console.error("Upload error:", error);
      }
    );
  };

  const handleFileChangeWithToolsetInput = (toolset) => {
    return (event) => {
      setInputKey((prevKey) => prevKey + 1);

      const file = handleFileChange(toolset, event);
      if (file) {
        updatePreUploadState(toolset, file);
        setTimeout(() => {
            handleFileUpload(file, toolset);
        }, 0);
      }
    };
  };

  const updatePreUploadState = (toolset, uploadedUrl) => {
    switch (toolset) {
      case 21:
        updateGenerateImageData({
          uploadedBaseImageUrl: uploadedUrl,
          feedDesignBase64: null,
        });
        const renderUpdate = {
          feedDesignBase64: null,
          uploadedBaseImageUrl: uploadedUrl,
        };
        localStorage.setItem(
          `generateImageBaseimg`,
          JSON.stringify(renderUpdate)
        );
        break;
      case 22:
        updateGenerateImageData1({
          uploadedBaseImageUrl: uploadedUrl,
          feedDesignBase64: null,
        });
        const renderUpdate1 = {
          feedDesignBase64: null,
          uploadedBaseImageUrl: uploadedUrl,
        };
        localStorage.setItem(
          `generateImageBaseimg1`,
          JSON.stringify(renderUpdate1)
        );
        break;
      default:
        break;
    }
  };

  const handleFileChange = (toolset, event) => {
    if (!event) {
      console.log(event);
      return null;
    }
    const files = event.target.files || event.dataTransfer.files;
    if (!files || files.length === 0) {
      console.log("No files selected");
      return null;
    }
    // setIsButtonDisabled(true);
    const file = files[0];
    if (!file) {
      //   setIsButtonDisabled(false);
      return null;
    }

    const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp"];
    if (validTypes.includes(file.type)) {
      return file;
    } else {
      alert("Please upload an image in JPG or PNG format");
      return null;
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event, toolset = null) => {
    event.preventDefault();
    event.stopPropagation();

    const selectedToolset = toolset ?? 21;
    const file = event.dataTransfer.files[0];

    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp"];
      if (validTypes.includes(file.type)) {
        updatePreUploadState(selectedToolset, file);
        handleFileUpload(file, selectedToolset);
      } else {
        alert("Please upload an image in JPG or PNG format");
      }
    } else {
      alert("No valid file dropped");
    }
  };

  if (!localStorage.getItem('generateImageprompt')) {
    localStorage.setItem('generateImageprompt', JSON.stringify({ prompt: "" }));
  }

  const handleTextAreaChange = (e) => {
    let inputText = e;
    if (inputText === undefined) return;

    if (inputText === "") {
      setDefaultText(inputText);
      setRemaining(0);
      const text = {
        prompt: inputText,
      };
      localStorage.setItem(`generateImageprompt`, JSON.stringify(text));
      return;
    }
    const { truncatedText, remainingChars } = processText(inputText, 1500);
    setDefaultText(truncatedText);
    setRemaining(Math.max(0, remainingChars));
    const text1 = {
      prompt: truncatedText,
    };
    localStorage.setItem(`generateImageprompt`, JSON.stringify(text1));
  };

  const handleTypingFocus = () => {
    if (!typing) {
      setTyping(true);
    }
  };

  const handleTypingBlur = () => {
    if (typing) {
      setTyping(false);
    }
  };

  const handleGenerate = () => {
    const generateImageLocal = JSON.parse(
      localStorage.getItem(`generateImageBaseimg`)
    );
    const generateImageLocal1 = JSON.parse(
      localStorage.getItem(`generateImageBaseimg1`)
    );
    const generateImagePrompt = JSON.parse(
      localStorage.getItem(`generateImageprompt`)
    );
    const payload = {
      baseImage:
        generateImageLocal?.feedDesignBase64?.path ||
        generateImageLocal?.uploadedBaseImageUrl,
      addOnImage:
        generateImageLocal1?.feedDesignBase64?.path ||
        generateImageLocal1?.uploadedBaseImageUrl,
      prompt: generateImagePrompt?.prompt,
    };
    if (payload) {
      setLoading(true);
      GenerateImage((res) => {
        setApiResponse(res?.data);
        setLoading(false);
      }, payload);
    }
  };

  return (
    <UserPortalLayout>
      <div className="generate-image">
        <div className="generate-image__container">
          <div className="generate-image__sections">
            <div className="generate-image__heading">
              <h1>{t("Generate_Image")}</h1>
            </div>
            <div className="generate-image__options">
              <div className="generate-image__options__images">
                <div className="generate-image__options__base-img">
                  <div
                    className="generate-image__options__base-img__heading"
                    style={{ position: "relative" }}
                  >
                    <h4>{t("base_image")}</h4>
                  </div>
                  <div
                    className={`card
                          }`}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, 21)}
                  >
                    <input
                      key={inputKey}
                      type="file"
                      accept=".jpeg, .jpg, .png, .webp"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChangeWithToolsetInput(21)}
                    />
                    <label
                      htmlFor="fileInput"
                      style={{
                        cursor:
                          generateImageLocal?.uploadedBaseImageUrl &&
                          !generateImageLocal?.feedDesignBase64
                            ? "progress"
                            : "pointer",
                      }}
                    >
                      <div
                        className="card-content"
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, 21)}
                      >
                        {generateImageLocal?.uploadedBaseImageUrl ? (
                          <>
                            <img
                              className="uploaded"
                              src={generateImageLocal?.uploadedBaseImageUrl}
                              draggable="false"
                            />
                            {generateImageLocal?.feedDesignBase64 === null ||
                            generateImageLocal?.feedDesignBase64 ===
                              undefined ? (
                              <>
                                <div className="gradient-mask"></div>
                                <div className="text-display">
                                  {t("uploading...")}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <>
                            <img className={`icon`} src={baseicon} alt="" />
                            <p>{t("design_base_image_text1")}</p>
                          </>
                        )}
                      </div>
                    </label>
                  </div>
                </div>
                <div className="generate-image__options__addon-img">
                  <div
                    className="generate-image__options__addon-img__heading"
                    style={{ position: "relative" }}
                  >
                    <h4>{t("Addon_Image")}</h4>
                  </div>
                  <div
                    className={`card
                          }`}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, 22)}
                  >
                    <input
                      key={inputKey}
                      type="file"
                      accept=".jpeg, .jpg, .png, .webp"
                      id="fileInput1"
                      style={{ display: "none" }}
                      onChange={handleFileChangeWithToolsetInput(22)}
                    />
                    <label
                      htmlFor="fileInput1"
                      style={{
                        cursor:
                          generateImageLocal1?.uploadedBaseImageUrl &&
                          !generateImageLocal1?.feedDesignBase64
                            ? "progress"
                            : "pointer",
                      }}
                    >
                      <div
                        className="card-content"
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, 22)}
                      >
                        {generateImageLocal1?.uploadedBaseImageUrl ? (
                          <>
                            <img
                              className="uploaded"
                              src={generateImageLocal1?.uploadedBaseImageUrl}
                              draggable="false"
                            />
                            {generateImageLocal1?.feedDesignBase64 === null ||
                            generateImageLocal1?.feedDesignBase64 ===
                              undefined ? (
                              <>
                                <div className="gradient-mask"></div>
                                <div className="text-display">
                                  {t("uploading...")}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <>
                            <img className={`icon`} src={baseicon} alt="" />
                            <p>{t("design_base_image_text1")}</p>
                          </>
                        )}
                      </div>
                    </label>
                  </div>
                </div>
                <div className="generate-image__options__prompt">
                  <div className="generate-image__options__prompt__heading">
                    <h4>{t("Prompt")}</h4>
                  </div>
                  <div className="generate-image__options__prompt__input">
                    <textarea
                      ref={textareaRef}
                      id="myTextArea"
                      placeholder={t("archi_sec_main1_inp")}
                      value={defaultText}
                      draggable={false}
                      style={{ resize: "none" }}
                      onChange={(e) => {
                        handleTextAreaChange(e.target.value);
                        setTyping(true);
                      }}
                      className="auto-resize-textarea"
                      onFocus={handleTypingFocus}
                      onBlur={handleTypingBlur}
                    ></textarea>
                    <p
                      style={{
                        color: remaining === 1500 ? "red" : "inherit",
                      }}
                    >
                      <span>{remaining}</span> / {1500}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="generate-image__button">
              <button onClick={handleGenerate}>{t("generate")}</button>
            </div>
            <div className="generate-image__response">
              {!loading ? (
                <div className="generate-image__response__image">
                  <img
                    src={apiResponse?.image_urls[0]}
                    alt={apiResponse?.image_metadata[0]?.filename}
                  />
                </div>
              ) : (
                <div className="generate-image__response__image">
                  <img src={defaultimgbig} alt="" />
                  <img src={dots} className="dots" alt="dots-gif" />
                  <p>{t("text_01")}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </UserPortalLayout>
  );
};

export default GenrateImage;
