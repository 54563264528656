/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import "./AiriBotStyles.scss";
import addicon from "../../assets/images/chatgpt/add-icon.png";
import entericon from "../../assets/images/chatgpt/enter-icon.png";
import trashicon from "../../assets/images/chatgpt/trash.png";
import trashiconh from "../../assets/images/chatgpt/trash-h.png";
import trashiconc from "../../assets/images/chatgpt/trash-c.png";
import airiicon from "../../assets/images/chatgpt/airi-icon.png";
import info from "../../assets/images/icons/alert-icon.png";
import cross from "../../assets/images/workspace/icons/cross-icon.png";
import downarrow from "../../assets/images/chatgpt/down-arrow.png";
import anim from "../../assets/images/workspace/upscale-gif.gif";
import uploadicon from "../../assets/images/icons/upload-icon.png";
import xlsx from "../../assets/images/chatgpt/xlmx.png";
import pdf from "../../assets/images/chatgpt/pdf.png";
import word from "../../assets/images/chatgpt/word.png";
import png from "../../assets/images/chatgpt/png.png";
import jpg from "../../assets/images/chatgpt/jpg.png";
import text from "../../assets/images/chatgpt/text.png";
import gif from "../../assets/images/chatgpt/gif.png";
import pptx from "../../assets/images/chatgpt/pptx.png";
import close from "../../assets/images/chatgpt/close.png";
import upload from "../../assets/images/chatgpt/upload.png";
import download from "../../assets/images/icons/download.png";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader";
import {
  AddUserChatRoom,
  DeleteUserChatRoom,
  GetChatMessages,
  GetUserChatMessages,
  GetUserChatRooms,
  SendChatMessage,
  UpdateUserChatRoomName,
} from "../../utils/apiCalls";
import { UserContext } from "../../store/UserDetailsStore";
import PLanEnd from "../../components/SubScriptionPopups/plan-end/PLanEnd";
import BotTooltip from "../../components/Tooltips/BotTooltip";
import { ResponsiveDataContext } from "../../store/ResponsiveData";
import { UserSocketContext } from "../../store/UserSocketData";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import { API_CONSTANTS, BaseURL } from "../../utils/api-constant";
import { Popover, Modal } from "antd";

const AiriBotComponent = () => {
  const token = localStorage.getItem("token");
  const lastProject = JSON.parse(localStorage.getItem("lastProjectGenerate"));
  const { t } = useTranslation();
  const { isLoginUserDetails, isPrivilagesDetails } = useContext(UserContext);
  const { responsiveData } = useContext(ResponsiveDataContext);
  const { updateSocketData } = useContext(UserSocketContext);
  const targetRef = useRef();
  const spanRef = useRef(null);
  const spanRef1 = useRef(null);
  const [isEditingRoomId, setIsEditingRoomId] = useState(null);
  const [isMessage, setIsMessage] = useState("");
  const [isMessageClean, setIsMessageClean] = useState("");
  const [isGetAllChatRooms, setIsGetAllChatRooms] = useState([]);
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);
  const [isSelectedChatRoom, setIsSelectedChatRoom] = useState(null);
  const [isDeleteChatRoom, setIsDeleteChatRoom] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [editingStates, setEditingStates] = useState(
    Array(isGetAllChatRooms.length).fill(false)
  );
  const [editingState, setEditingState] = useState(-1);
  const [isCurrentChatRoomMessages, setIsCurrentChatRoomMessages] = useState(
    []
  );
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [isMessageShow, setIsMessageShow] = useState("");
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [messageResponse, setMessageResponse] = useState();
  const [newChatCreated, isNewChatCreated] = useState();
  const [lastChatRoomId, setLastChatRoomId] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [chatRoomNameInput, setChatRoomNameInput] = useState("");
  const [newMessageDisplay, setNewMessageDisplay] = useState("");
  const [isGptActive, setIsGptActive] = useState(false);
  const [isGptActivePopup, setIsGptActivePopup] = useState(false);
  const [remaining, setRemaining] = useState(0);
  const [hoverStatus, setHoverStatus] = useState({});
  const [hoveredElement, setHoveredElement] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  useEffect(() => {
    if (isPrivilagesDetails?.GPTUsageRights === false) {
      setIsGptActive(true);
    }
  }, [isPrivilagesDetails]);

  const handleCloseAccessPopup = () => {
    setIsGptActivePopup(false);
  };

  useEffect(() => {
    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth() + 1;
    const formattedDate = `${day < 10 ? `0${day}` : day}/${
      month < 10 ? `0${month}` : month
    }`;
    setCurrentDate(formattedDate);
  }, []);

  const scrollToBottom = () => {
    const targetElement = targetRef.current;
    if (targetElement) {
      const scrollOptions = {
        top: targetElement.scrollHeight,
        behavior: "smooth",
      };
      targetElement.scrollTo(scrollOptions);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  const handleClick = () => {
    const targetElement = targetRef.current;

    if (targetElement) {
      targetElement.scrollTo({
        top: targetElement.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    const targetElement = targetRef.current;

    if (targetElement) {
      const divHeight = targetElement.scrollHeight;
      const scrollTop = targetElement.scrollTop;
      const shouldShowArrow =
        scrollTop + targetElement.clientHeight < divHeight;
      setShowScrollArrow(shouldShowArrow);
    }
  };

  useEffect(() => {
    const targetElement = targetRef.current;

    if (targetElement) {
      targetElement.addEventListener("scroll", handleScroll);
      return () => {
        targetElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const handleDoubleClick = (roomIndex) => {
    if (roomIndex === editingState) {
      return;
    }

    if (editingState !== -1) {
    }

    setEditingState(roomIndex);
    setChatRoomNameInput(isGetAllChatRooms[roomIndex].chatRoomName);
    setIsEditingRoomId(isGetAllChatRooms[roomIndex]?.chatRoomId);
  };

  const handleInputBlur = (roomIndex) => {
    const newEditingStates = [...editingStates];
    newEditingStates[roomIndex] = false;
    setEditingStates(newEditingStates);
  };

  const handleKeyPress = (e, roomIndex) => {
    if (e.key === "Enter") {
      const newEditingStates = [...editingStates];
      newEditingStates[roomIndex] = false;
      setEditingStates(newEditingStates);

      e.preventDefault();
      handleUpdateChatRoomName(
        isGetAllChatRooms[roomIndex].chatRoomId,
        chatRoomNameInput
      );
    }
  };

  const handleMessageChange = (e) => {
    if (isPrivilagesDetails?.GPTUsageRights === true) {
      const textarea = document.getElementById("messageTextarea");
      textarea.style.height = "none !important";
      let inputText = e.target.value;

      let engChars = 0;
      let chnChars = 0;
      const chinesePattern = /[\u4E00-\u9FFF]/;
      const specialCharPattern = /[~!@#$%^&*()_+[\]{};':"\\|<>,.?/]/;

      for (let i = 0; i < inputText.length; i++) {
        const char = inputText[i];
        if (char.match(chinesePattern)) {
          chnChars += 1;
        } else if (specialCharPattern.test(char)) {
          engChars += 2;
        } else {
          engChars += 1;
        }

        if (engChars + chnChars * 4 >= 10000) {
          inputText = inputText.slice(0, i);
          break;
        }
      }

      const cleanedMessage = inputText.replace(/\s+/g, " ").trim();
      setIsMessageClean(cleanedMessage);
      adjustTextareaHeight(e.target);

      const remainingChars = 0 + (engChars + chnChars * 4);

      setIsMessage(inputText);
      setIsMessageShow(inputText);
      setNewMessage(inputText);
      setRemaining(Math.max(0, remainingChars));
    } else {
      setIsGptActivePopup(true);
    }
  };

  useEffect(() => {
    if (isShowMessage === false) {
      setNewMessageDisplay(newMessage);
    }
  }, [newMessage, isShowMessage]);

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
    document.documentElement.style.setProperty(
      "--textarea-height",
      textarea.style.height
    );
  };

  useEffect(() => {
    getAllUserChatRooms();
  }, []);

  useEffect(() => {
    if (lastChatRoomId) {
      getAllUserChatRooms();
    }
  }, [lastChatRoomId]);

  const getAllUserChatRooms = () => {
    const teamId = lastProject?.teamId;
    const projectId = lastProject?.projectId;
    GetUserChatRooms(
      (res) => {
        if (res?.status === 200) {
          setIsGetAllChatRooms(res?.data);
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      },
      teamId,
      projectId
    );
  };

  const handleCreateChatRoom = () => {
    if (isPrivilagesDetails?.GPTUsageRights === true) {
      const payload = {
        teamId: lastProject?.teamId,
        projectId: lastProject?.projectId,
      };
      AddUserChatRoom((res) => {
        if (res.status === 200) {
          setUploadedFiles([]);
          isNewChatCreated(res?.data);
          setSelectedChatRoom(res?.data);
          const textarea = document.getElementById("messageTextarea");
          textarea.style.height = "auto";
          textarea.focus();
          textarea.value = "";
          textarea.setSelectionRange(1, 1);
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    } else {
      setIsGptActivePopup(true);
    }
  };

  const handleChatRoomClick = (room) => {
    setSelectedChatRoom(room);
    setIsMessage("");
    setUploadedFiles([]);
    const textarea = document.getElementById("messageTextarea");
    textarea.style.height = "auto";
    textarea.value = " ";
    textarea.setSelectionRange(1, 1);
  };

  useEffect(() => {
    if (isEditingRoomId !== null) {
      if (isEditingRoomId !== selectedChatRoom?.chatRoomId) {
        setEditingState(-1);
      }
    }
  }, [isEditingRoomId, selectedChatRoom]);

  const handleCloseDeletePopup = () => {
    setDeletePopup(false);
  };

  const handleDeletePopup = (room) => {
    setDeletePopup(true);
    setIsSelectedChatRoom(room);
    setIsDeleteChatRoom(room);
  };

  const handleDeleteChatRoom = () => {
    setDeletePopup(false);
    setIsLoader(true);
    DeleteUserChatRoom((res) => {
      if (res?.status === 200) {
        setIsLoader(false);
        setShowConfirmModal(true);
        getAllUserChatRooms();
      }
      if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
    }, isDeleteChatRoom?.chatRoomId);
  };

  const handleUpdateChatRoomName = (chatRoomId, isChatName) => {
    if (isPrivilagesDetails?.GPTUsageRights === true) {
      setIsLoader(true);
      UpdateUserChatRoomName(
        (res) => {
          if (res?.status === 200) {
            setEditingState(-1);
            setIsLoader(false);
            getAllUserChatRooms();
          }
          if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          }
        },
        chatRoomId,
        isChatName
      );
    } else {
      setIsGptActivePopup(true);
    }
  };

  useEffect(() => {
    getChatRoomMessages();
  }, [selectedChatRoom?.chatRoomId]);

  const getChatRoomMessages = () => {
    if (selectedChatRoom?.chatRoomId) {
      GetUserChatMessages((res) => {
        if (res?.status === 200) {
          if (res?.data?.length !== 0) {
            res.data = res?.data?.map((chat) => {
              const date = new Date(chat?.createdDate);
              const day = date.getDate().toString().padStart(2, "0");
              const month = (date.getMonth() + 1).toString().padStart(2, "0");
              const formatDate = `${day}/${month}`;
              const formattedMessage = chat?.message
                ? chat?.message?.trim()?.replace(/\n/g, "<br>")
                : "";

              return {
                ...chat,
                createdDate: formatDate,
                message: formattedMessage,
              };
            });
            setIsCurrentChatRoomMessages(res.data);
            setTimeout(() => {
              scrollToBottom();
            }, 500);
          }
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, selectedChatRoom?.chatRoomId);
    }
  };

  const handleKeyPressText = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setIsMessage(isMessage + "\n");
      setIsMessageShow(isMessage + "\n");
      setIsMessageClean(isMessageClean + "\n");
      const textarea = e.target;
      textarea.scrollTop = textarea.scrollHeight;
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
      // setIsMessageShow(isMessage + "\n");
    } else if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
      // setIsMessageShow(isMessage + "\n");
    }
  };

  const GetChatMessages1 = async (callback, payload) => {
    try {
      const response = await fetch(
        "https://aigc.airilab.net:58013/api/Gpt/chatMessageV1",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok || !response.body) {
        console.error("Failed to fetch event stream.");
        return;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });

        const lines = chunk
          .split("\n")
          .filter((line) => line.startsWith("data:"));
        for (let line of lines) {
          try {
            const dataString = line.slice(5).trim();
            const res = dataString;
            callback(res);
          } catch (error) {
            console.error("Error parsing event stream data:", error);
          }
        }
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleSendMessage = () => {
    if (isPrivilagesDetails?.GPTUsageRights === true) {
      const payload = {
        teamId: lastProject?.teamId,
        projectId: lastProject?.projectId,
      };
      if (isShowMessage === false) {
        // const sending = {
        //   isResponse: false,
        //   createdDate: currentDate,
        //   message: newMessageDisplay,
        //   file: uploadedFiles[0]?.fileUrl ?? null,
        // };
        // const chats = [...isCurrentChatRoomMessages, sending];
        // setIsCurrentChatRoomMessages(chats);
        setIsMessage("");
        const textarea = document.getElementById("messageTextarea");
        textarea.style.height = "auto";
        textarea.focus();
        textarea.value = "";
        textarea.setSelectionRange(1, 1);
        setRemaining(0);
        if (newMessage !== "") {
          setIsShowMessage(true);
          setTimeout(() => {
            scrollToBottom();
          }, 300);
          if (
            selectedChatRoom?.chatRoomId === undefined ||
            selectedChatRoom?.chatRoomId === null
          ) {
            AddUserChatRoom((res) => {
              if (res?.status === 200) {
                isNewChatCreated(res?.data);
                setSelectedChatRoom(res?.data);
                if (res?.data !== null || res?.data !== undefined) {
                  const payload = {
                    chatRoomId: res?.data?.chatRoomId,
                    message: newMessage,
                    s3Path:
                      uploadedFiles[uploadedFiles?.length - 1]?.fileUrl ?? "",
                  };
                  if (payload) {
                    SendChatMessage(async (res) => {
                      if (res?.status === 200) {
                        const message = await res?.data;
                        setMessageResponse(message);
                        setIsShowMessage(false);
                        getChatRoomMessages();
                        setTimeout(() => {
                          scrollToBottom();
                        }, 500);
                      }
                    }, payload);
                    // setUploadedFiles([]);
                    // const eventSource = GetChatMessages1(async (res) => {
                    //   if (res === "STARTED") {
                    //     const newMessage = {
                    //       isResponse: true,
                    //       createdDate: currentDate,
                    //       message: "",
                    //       file: null,
                    //     };
                    //     setIsCurrentChatRoomMessages((prevChats) => [
                    //       ...prevChats,
                    //       newMessage,
                    //     ]);
                    //   } else if (res.startsWith("fileReceived,")) {
                    //     const fileLink = res.split(",")[1].trim();
                    //     setIsCurrentChatRoomMessages((prevChats) => {
                    //       const lastMessageIndex = prevChats.length - 1;
                    //       const updatedChats = [...prevChats];
                    //       updatedChats[lastMessageIndex].file = fileLink;
                    //       return updatedChats;
                    //     });
                    //   } else if (res === "ENDED") {
                    //     setIsShowMessage(false);
                    //     setTimeout(() => {
                    //       scrollToBottom();
                    //     }, 100);
                    //   } else {
                    //     setIsCurrentChatRoomMessages((prevChats) => {
                    //       const lastMessageIndex = prevChats.length - 1;
                    //       const updatedChats = [...prevChats];
                    //       updatedChats[lastMessageIndex].message +=
                    //         res === "" ? " " : res;
                    //       return updatedChats;
                    //     });
                    //   }
                    // }, payload);
                  }
                }
              }
              if (res?.message === "Invalid access token") {
                updateSocketData({
                  isExitPopup: true,
                });
              }
            }, payload);
          } else {
            const payload = {
              chatRoomId: selectedChatRoom?.chatRoomId,
              message: newMessage,
              s3Path: uploadedFiles[uploadedFiles?.length - 1]?.fileUrl ?? "",
            };
            if (payload) {
              setLastChatRoomId(selectedChatRoom?.chatRoomId);
              SendChatMessage((res) => {
                if (res?.status === 200) {
                  setMessageResponse(res?.data);
                  setIsShowMessage(false);
                  setTimeout(() => {
                    scrollToBottom();
                  }, 500);
                }
                if (res?.message === "Invalid access token") {
                  updateSocketData({
                    isExitPopup: true,
                  });
                }
              }, payload);
              // setUploadedFiles([]);
              // setLastChatRoomId(selectedChatRoom?.chatRoomId);
              // const eventSource = GetChatMessages1(async (res) => {
              //   if (res === "STARTED") {
              //     const newMessage = {
              //       isResponse: true,
              //       createdDate: currentDate,
              //       message: "",
              //       file: null,
              //     };
              //     setIsCurrentChatRoomMessages((prevChats) => [
              //       ...prevChats,
              //       newMessage,
              //     ]);
              //   } else if (res.startsWith("fileReceived,")) {
              //     const fileLink = res.split(",")[1].trim();
              //     setIsCurrentChatRoomMessages((prevChats) => {
              //       const lastMessageIndex = prevChats.length - 1;
              //       const updatedChats = [...prevChats];
              //       updatedChats[lastMessageIndex].file = fileLink;
              //       return updatedChats;
              //     });
              //   } else if (res === "ENDED") {
              //     setIsShowMessage(false);
              //     setTimeout(() => {
              //       scrollToBottom();
              //     }, 100);
              //   } else {
              //     setIsCurrentChatRoomMessages((prevChats) => {
              //       const lastMessageIndex = prevChats.length - 1;
              //       const updatedChats = [...prevChats];
              //       updatedChats[lastMessageIndex].message +=
              //         res === "" ? " " : res;
              //       return updatedChats;
              //     });
              //   }
              // }, payload);
            }
          }
        }
      }
    } else {
      setIsGptActivePopup(true);
    }
  };

  useEffect(() => {
    if (messageResponse?.length !== 0) {
      pushArrayList(messageResponse);
    }
  }, [messageResponse]);

  const pushArrayList = (messageResponse) => {
    for (let i = 0; i < messageResponse?.length; i++) {
      isCurrentChatRoomMessages?.push(messageResponse[i]);
    }
    setIsCurrentChatRoomMessages([...isCurrentChatRoomMessages]);
  };

  useEffect(() => {
    if (newChatCreated !== undefined && newChatCreated !== null) {
      pushChatList([newChatCreated]);
    }
  }, [newChatCreated]);

  const pushChatList = (newChatCreated) => {
    const updatedChatRooms = [...newChatCreated, ...isGetAllChatRooms];
    setIsGetAllChatRooms(updatedChatRooms);
  };

  const handleMouseEnter = (event, index) => {
    setHoveredElement(event.currentTarget);
    setHoverStatus({ ...hoverStatus, [index]: true });
  };

  const handleMouseLeave = (index) => {
    setHoverStatus({ ...hoverStatus, [index]: false });
  };

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const acceptedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "image/*",
    "text/plain",
    "text/csv",
  ];

  const handleFileUpload = (files) => {
    const acceptedFile = Array.from(files).find(
      (file) =>
        acceptedFileTypes.includes(file.type) || file.type.startsWith("image/")
    );
    if (acceptedFile) {
      const fileObject = { file: acceptedFile };
      setUploadedFiles([fileObject]);
      uploadAnyMedia([fileObject]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFileUpload(e.dataTransfer.files);
  };

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case "application/pdf":
      case "pdf":
        return pdf;
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return word;
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "xlsx":
        return xlsx;
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return pptx;
      case "text/plain":
      case "txt":
        return text;
      case "image/png":
      case "png":
        return png;
      case "image/jpeg":
      case "jpeg":
      case "jpg":
        return jpg;
      default:
        return fileType.startsWith("image/") ? png : jpg;
    }
  };

  const handleFileInputChange = (e) => {
    handleFileUpload(e.target.files);
  };

  const uploadAnyMedia = (acceptedFiles) => {
    const files = acceptedFiles ?? uploadedFiles;
    const file = files[files?.length - 1].file;
    const teamId = lastProject?.teamId;
    const formData = new FormData();
    formData.append("myFile", file, file.name);
    formData.append("imagePart", null);
    formData.append("teamId", teamId);
    setUploading(true);
    fetch(`${API_CONSTANTS.UPLOAD_ANY_FILE}`, {
      method: "POST",
      body: formData,
      headers: {
        authorization: "Bearer " + [token],
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((res) => {
        if (res?.status === 200) {
          const data = {
            file: file,
            fileUrl: res.data.path,
          };
          const updatedFiles = [data];
          setUploadedFiles(updatedFiles);
        } else if (res.status === 203) {
          console.log("error");
        }
        setUploading(false);
      })
      .catch((error) => {
        const url = window.location.pathname;
        const lastPart = url.substring(url.lastIndexOf("/") + 1);
        console.error("Error:", error);
        const serializedError = JSON.stringify({
          message: error.message,
          name: error.name,
          stack: error.stack,
        });

        const data = {
          val: true,
          error: serializedError,
        };
        localStorage.setItem(`upload_error_${lastPart}`, JSON.stringify(data));
        setUploading(false);
      })
      .finally(() => {
        console.log("complete");
        setUploading(false);
      });
  };

  const handleOpen = (isOpen) => {
    setOpen(isOpen);
  };

  const handleOpen1 = (isOpen) => {
    setOpen1(isOpen);
  };

  const handleOpen2 = (isOpen) => {
    setOpen2(isOpen);
  };

  const handleDeleteFile = () => {
    setUploadedFiles([]);
  };

  const [openPopovers, setOpenPopovers] = useState({});

  const handlePopoverOpen = (index, isOpen) => {
    setOpenPopovers((prev) => ({
      ...prev,
      [index]: isOpen,
    }));
  };

  const handleDownloadFile = (url, name) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalFileUrl, setModalFileUrl] = useState("");
  const [modalFileType, setModalFileType] = useState("");
  const [modalFileName, setModalFileName] = useState("");

  const handleOpenFilePopup = (fileUrl, ext, name) => {
    setModalFileUrl(fileUrl);
    setModalFileType(ext);
    setIsModalOpen(true);
    setModalFileName(name);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalFileUrl("");
    setModalFileType("");
    setModalFileName("");
  };

  const renderModalContent = () => {
    switch (modalFileType) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return (
          <img
            src={modalFileUrl}
            alt="file-preview"
            style={{ width: "100%" }}
          />
        );

      case "pdf":
        return (
          <iframe
            src={modalFileUrl}
            style={{ width: "100%", height: "500px" }}
            title="PDF preview"
          ></iframe>
        );

      case "txt":
      case "csv":
        return (
          <iframe
            src={modalFileUrl}
            style={{ width: "100%", height: "500px" }}
            title="Text preview"
          ></iframe>
        );

      case "docx":
      case "xlsx":
      case "pptx":
        const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
          modalFileUrl
        )}`;
        return (
          <iframe
            src={viewerUrl}
            style={{ width: "100%", height: "500px" }}
            title={`${modalFileType} preview`}
          ></iframe>
        );

      default:
        return (
          <div>
            <p>
              File format not supported for preview.{" "}
              <a href={modalFileUrl} download>
                Download file
              </a>
            </p>
          </div>
        );
    }
  };

  return (
    <UserPortalLayout>
      <div className="airi-bot">
        <div className="airi-bot__container">
          <div className="airi-bot__sections">
            <div className="airi-bot__sidenav">
              <div className="airi-bot__sidenav__container">
                <div
                  className="airi-bot__sidenav__sections"
                  data-scrollable="true"
                >
                  <div className="airi-bot__sidenav__header">
                    <h4>{t("gpt_heading")}</h4>
                  </div>
                  <div className="airi-bot__sidenav__button">
                    <button onClick={handleCreateChatRoom}>
                      <img src={addicon} alt="add-icon" />{" "}
                      <span>{t("new_chat")}</span>{" "}
                    </button>
                  </div>
                  <div className="airi-bot__sidenav__chats">
                    <div className="airi-bot__sidenav__chats__list">
                      {isGetAllChatRooms?.length ? (
                        isGetAllChatRooms?.map((room, index) => {
                          const isSelected =
                            selectedChatRoom?.chatRoomId === room.chatRoomId;
                          // const isCurrentlyEditing = editingStates[index];
                          const isCurrentlyEditing = index === editingState;
                          return (
                            <div
                              key={index}
                              className={`airi-bot__sidenav__chats__list__sec ${
                                isSelected ? "active" : ""
                              } ${isCurrentlyEditing === true ? "edit" : ""}`}
                              onClick={() => handleChatRoomClick(room)}
                            >
                              <div className="airi-bot__sidenav__chats__list__sec__input">
                                <div
                                  className={`airi-bot__sidenav__chats__list__sec__inputs ${
                                    isCurrentlyEditing === true ? "edit" : ""
                                  }`}
                                  onDoubleClick={() => handleDoubleClick(index)}
                                >
                                  {isCurrentlyEditing ? (
                                    <input
                                      ref={(input) => {
                                        if (input && editingState === index) {
                                          input.focus();
                                        }
                                      }}
                                      value={chatRoomNameInput}
                                      onChange={(e) =>
                                        setChatRoomNameInput(e.target.value)
                                      }
                                      onBlur={() => handleInputBlur(index)}
                                      onKeyPress={(e) =>
                                        handleKeyPress(e, index)
                                      }
                                    />
                                  ) : (
                                    <>
                                      {room.chatRoomName.length > 20 ? (
                                        <div
                                          className="airi-bot__sidenav__chats__list__sec__tooltip"
                                          onMouseEnter={(e) =>
                                            handleMouseEnter(e, index)
                                          }
                                          onMouseLeave={() =>
                                            handleMouseLeave(index)
                                          }
                                          key={index}
                                        >
                                          <h5>
                                            {isCurrentlyEditing
                                              ? chatRoomNameInput
                                              : room?.chatRoomName}
                                          </h5>
                                          {!responsiveData && (
                                            <BotTooltip
                                              triggerElement={hoveredElement}
                                              isVisible={hoverStatus[index]}
                                              TooltipContent={
                                                room?.chatRoomName
                                              }
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <h5>
                                          {isCurrentlyEditing
                                            ? chatRoomNameInput
                                            : room?.chatRoomName}
                                        </h5>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div
                                className="trash-icons"
                                onClick={() => handleDeletePopup(room)}
                              >
                                <img
                                  className="trash"
                                  src={trashicon}
                                  alt="trash-icon"
                                />
                                <img
                                  className="hover"
                                  src={trashiconh}
                                  alt="trash-icon"
                                />
                                <img
                                  className="click"
                                  src={trashiconc}
                                  alt="trash-icon"
                                />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="airi-bot__main"
              // onDragOver={handleDragOver}
              // onDrop={handleDrop}
            >
              <div className="airi-bot__main__section" data-scrollable="true">
                <div className="airi-bot__main__section__chat" ref={targetRef}>
                  {isCurrentChatRoomMessages?.length ? (
                    isCurrentChatRoomMessages?.map((chat, index) => {
                      const date = new Date(chat?.createdDate);
                      const day = date.getDate().toString().padStart(2, "0");
                      const month = (date.getMonth() + 1)
                        .toString()
                        .padStart(2, "0");
                      const formatDate = `${day}/${month}`;
                      
                      // const formattedMessage = chat?.message
                      //   ? chat?.message?.includes("<br>")
                      //   : false;
                      const formattedMessage = chat?.message
                        ? chat?.message?.trim()?.replace(/\n/g, "<br>")
                        : "";
                      const isFile = chat?.file !== null && chat?.file !== "";
                      const splits = isFile ? chat?.file?.split(".") : null;

                      const getFileName = (url) => {
                        const fullPath = new URL(url).pathname;
                        return fullPath.substring(
                          fullPath.lastIndexOf("_") + 1
                        );
                      };
                      const getFileName1 = (url) => {
                        return url.substring(url.lastIndexOf("/") + 1);
                      };

                      const fileName = isFile
                        ? chat?.isResponse
                          ? getFileName1(chat?.file)
                          : getFileName(chat?.file)
                        : null;

                      const ext = splits && splits[splits?.length - 1];

                      return (
                        <div
                          className={`airi-bot__main__section__chat__container ${
                            chat?.isResponse === true ? "answer" : ""
                          }`}
                          key={index}
                        >
                          <div
                            className={`airi-bot__main__section__chat__container__data ${
                              chat?.isResponse === true ? "answer__data" : ""
                            }`}
                          >
                            <div
                              className={`airi-bot__main__section__chat__container__data__user ${
                                chat?.isResponse === true
                                  ? "answer__data__user"
                                  : ""
                              }`}
                            >
                              <div
                                className={`airi-bot__main__section__chat__container__data__user__top ${
                                  chat?.isResponse === true
                                    ? "answer__data__user__top"
                                    : ""
                                }`}
                              >
                                {chat?.isResponse === true ? (
                                  <img src={airiicon} alt="user-icon" />
                                ) : (
                                  <img
                                    src={isLoginUserDetails?.avatarUrl}
                                    alt="airi-icon"
                                  />
                                )}
                                <p>{formatDate}</p>
                              </div>
                            </div>
                            <div
                              className={`airi-bot__main__section__chat__container__data__content ${
                                chat?.isResponse === true
                                  ? "answer__data__content"
                                  : ""
                              }`}
                            >
                              {isFile && (
                                <div
                                  className={`airi-bot__main__section__chat__container__data__content__file ${
                                    chat?.isResponse === true
                                      ? "answer__data__content__file"
                                      : ""
                                  }`}
                                >
                                  <Popover
                                    overlayClassName="file-popover"
                                    content={fileName}
                                    arrow={true}
                                    trigger="hover"
                                    placement="right"
                                    open={openPopovers[index]}
                                    onOpenChange={(isOpen) =>
                                      handlePopoverOpen(index, isOpen)
                                    }
                                  >
                                  <div
                                    key={index}
                                    className={`airi-bot__main__section__chat__container__data__content__file__section ${
                                      chat?.isResponse === true
                                        ? "answer__data__content__file__section"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleOpenFilePopup(
                                        chat?.file,
                                        ext,
                                        fileName
                                      )
                                    }
                                  >
                                    <img
                                      src={getFileIcon(ext)}
                                      alt={`${ext}-icon`}
                                      className="file-icon"
                                    />
                                    <div
                                      className={`airi-bot__main__section__chat__container__data__content__file__section__name ${
                                        chat?.isResponse === true
                                          ? "answer__data__content__file__section__name"
                                          : ""
                                      }`}
                                    >
                                      <span className="name">{fileName}</span>
                                      <span>{ext}</span>
                                    </div>
                                    <div
                                      className={`airi-bot__main__section__chat__container__data__content__file__section__download ${
                                        chat?.isResponse === true
                                          ? "answer__data__content__file__section__download"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleDownloadFile(chat?.file, fileName)
                                      }
                                    >
                                      <img
                                        className="download"
                                        src={download}
                                        alt="download-icon"
                                      />
                                    </div>
                                  </div>
                                  </Popover>
                                </div>
                              )}
                              <p
                                  dangerouslySetInnerHTML={{
                                    __html: formattedMessage,
                                  }}
                                ></p>
                              {/* {formattedMessage ? (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: chat?.message,
                                  }}
                                ></p>
                              ) : (
                                <p>{chat?.message}</p>
                              )} */}
                            </div>
                            <span></span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  {isShowMessage && (
                    <div className="container">
                      <img src={anim} alt="user-icon" />
                    </div>
                  )}
                  {showScrollArrow && (
                    <div className="airi-bot__main__section__scroll">
                      <img
                        src={downarrow}
                        alt="down-arrow"
                        onClick={handleClick}
                      />
                    </div>
                  )}
                   <div
                    className="airi-bot__main__section__empty">
                    <div className="airi-bot__main__section__empty__container"></div>
                  </div>
                  {/* <div
                    className={`airi-bot__main__section__empty ${
                      uploadedFiles?.length !== 0 ? "file" : ""
                    }`}
                  >
                    <div className="airi-bot__main__section__empty__container"></div>
                  </div> */}
                </div>
                <div
                  className="airi-bot__main__section__bottom"
                >
                {/* <div
                  className={`airi-bot__main__section__bottom ${
                    uploadedFiles?.length !== 0 ? "file" : ""
                  }`}
                > */}
                  {/* <div className="airi-bot__main__section__bottom__uploads">
                    {uploadedFiles?.length !== 0 &&
                      uploadedFiles?.map((file, index) => {
                        const splits = file.file.name.split(".");
                        const ext = splits[splits?.length - 1];
                        const isOverflowing = (element) => {
                          return element?.scrollWidth > element?.clientWidth;
                        };
                        return (
                          <Popover
                            overlayClassName="file-popover"
                            content={file.file.name}
                            arrow={true}
                            trigger="hover"
                            placement="right"
                            open={isOverflowing(spanRef.current) ? open : false}
                            onOpenChange={handleOpen}
                          >
                            <div
                              key={index}
                              className="airi-bot__main__section__bottom__uploads__file"
                            >
                              <img
                                src={getFileIcon(file.file.type)}
                                alt={`${file.file.type}-icon`}
                                className="file-icon"
                              />
                              <div className="airi-bot__main__section__bottom__uploads__file__name">
                                <span ref={spanRef}>{file.file.name}</span>
                                <span>{ext}</span>
                              </div>
                              <img
                                onClick={handleDeleteFile}
                                className="close"
                                src={close}
                                alt="close-icon"
                              />
                            </div>
                          </Popover>
                        );
                      })}
                  </div> */}
                  <div className="airi-bot__main__section__bottom__input">
                    <textarea
                      id="messageTextarea"
                      value={isMessage}
                      onChange={handleMessageChange}
                      onKeyPress={
                        !uploading ? (e) => handleKeyPressText(e) : null
                      }
                      rows={1}
                      maxrows={4}
                      draggable={false}
                      className="non-resizable-textarea"
                      placeholder={t("start_typing_here")}
                    />
                    <img
                      className={`${uploading ? "uploading" : ""}`}
                      src={entericon}
                      alt="enter-icon"
                      onClick={handleSendMessage}
                      // onClick={!uploading ? handleSendMessage : null}
                    />
                    <p
                      className="count-remain"
                      style={{ color: remaining === 10000 && "red" }}
                    >
                      {remaining}
                      <span>/ 10000</span>{" "}
                    </p>
                    {/* <label htmlFor="fileInput" className="upload-icon">
                      <Popover
                        overlayClassName="upload-popover-content"
                        content={
                          <div className="upload-popover">
                            <div className="upload-popover__content">
                              <img src={upload} alt="upload-icon" />
                              <h4>{t("upload_heading")}</h4>
                              <p>{t("upload_content")}</p>
                            </div>
                          </div>
                        }
                        arrow={false}
                        trigger="hover"
                        placement="topLeft"
                        open={open2}
                        onOpenChange={handleOpen2}
                      >
                        <img src={uploadicon} alt="Upload" />
                      </Popover>
                    </label>
                    <input
                      id="fileInput"
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      accept={acceptedFileTypes.join(", ")}
                      onChange={handleFileInputChange}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isGptActive === true && (
            <>
              {isGptActivePopup === true && (
                <PLanEnd
                  handleCloseAccessPopup={handleCloseAccessPopup}
                  handleSubscription={handleCloseAccessPopup}
                  content={t("gpt_privillage")}
                  stringB={"Contact_us"}
                />
              )}
            </>
          )}
        </div>
        {deletePopup && (
          <div className="down" onClick={handleCloseDeletePopup}>
            <div className="down__popup">
              <div
                className="down__popup__container"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="down__popup__section">
                  <div className="down__popup__section__header">
                    <img src={info} alt="info-icon" />
                    <h3>
                      {t("delete")} {isDeleteChatRoom?.chatRoomName}
                    </h3>
                    <img
                      className="cross"
                      src={cross}
                      alt="cross-icon"
                      onClick={handleCloseDeletePopup}
                    />
                  </div>
                  <div className="down__popup__section__content">
                    <p>
                      {t("delete_chat_room_text1")}
                      {isDeleteChatRoom?.chatRoomName}{" "}
                      {t("delete_chat_room_text2")}
                    </p>
                  </div>
                  <div className="down__popup__section__buttons">
                    <div className="down__popup__section__buttons__section">
                      <button
                        onClick={handleCloseDeletePopup}
                        className="cancel"
                      >
                        {t("cancel")}
                      </button>
                      <button onClick={handleDeleteChatRoom}>
                        {t("delete")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showConfirmModal && (
          <div className="delete" onClick={handleCloseDeletePopup}>
            <div className="delete__popup">
              <div
                className="delete__popup__container"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="delete__popup__section">
                  <div className="delete__popup__section__content">
                    <p>
                      "{isSelectedChatRoom?.chatRoomName}"{" "}
                      {t("deleted_succesfully")}
                    </p>
                  </div>
                  <div className="delete__popup__section__buttons">
                    <div className="delete__popup__section__buttons__section">
                      <button
                        onClick={() => {
                          setShowConfirmModal(false);
                        }}
                      >
                        {t("ok")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isLoader && <Loader />}
        <Modal
          title={modalFileName}
          centered
          open={isModalOpen}
          onCancel={handleCloseModal}
          footer={null}
          width={1000}
        >
          {renderModalContent()}
        </Modal>
      </div>
    </UserPortalLayout>
  );
};

export default AiriBotComponent;
