import React, { useEffect, useState } from "react";
import "./TooltipLeft.scss";
import Portal from "./Portal";

const TooltipLeft = ({
  triggerElement,
  isVisible,
  TooltipContent,
  style,
  small = false,
}) => {
  const [position, setPosition] = useState(null);

  useEffect(() => {
    if (isVisible && triggerElement) {
      const rect = triggerElement.getBoundingClientRect();
      setPosition({
        top: rect.top + window.scrollY - style,
        left: rect.right + window.scrollX,
      });
    }
  }, [isVisible, triggerElement, style]);

  if (!triggerElement || !position) {
    return null; // Early return if triggerElement is null
  }
  const contentLines = TooltipContent?.split("/n");
  return (
    <Portal>
      <div
        className={`tooltipl ${small ? "small" : ""}`}
        style={{
          top: `${position.top}px`,
          left: `${position.left}px`,
          display: isVisible? "flex" : "none",
        }}
      >
        <div className="tooltipl__arrow"></div>
        <div className="tooltipl__container">
          {small ? <span className="small-content">{contentLines}</span>:
          <ul>
            {contentLines?.map((item, index) => (
              <li key={index}>
                {" "}
                <span>&#x2022;</span> {item?.trim()}
              </li>
            ))}
          </ul>}
        </div>
      </div>
    </Portal>
  );
};

export default TooltipLeft;
