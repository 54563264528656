import React, { useContext, useEffect, useState } from "react";
import AdminLayout from "../../../components/AdminComponents/AdminLayout/AdminLayout";
import { GetAdminDataMongo, GetAdminDataMySQL } from "../../../utils/apiCalls";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import { UserSocketContext } from "../../../store/UserSocketData";
const { RangePicker } = DatePicker;

const Dashboard = () => {
  const { updateSocketData } = useContext(UserSocketContext);
  const [xAxisCategories, setXaxisCategories] = useState({});
  const [pieChart, setPieChart] = useState();
  const [mySQLConnections, setMySQLConnections] = useState();
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(1, "day"),
    dayjs(),
  ]);

  useEffect(() => {
    fetchData();
  }, [dateRange]);

  const fetchData = () => {
    const formattedStartDate = dateRange[0].format("YYYY-MM-DD HH:mm:ss");
    const formattedEndDate = dateRange[1].format("YYYY-MM-DD HH:mm:ss");
    getAdminDataMySql(formattedStartDate, formattedEndDate);
    getAdminDataMongo();
  };

  const getAdminDataMySql = (startDate, endDate) => {
    const payload = {
      startDate: startDate,
      endDate: endDate,
    };
    GetAdminDataMySQL((res) => {
      if (res?.statusCode === 200) {
        setMySQLConnections(res?.data?.clientConnection[0]);
        const xaxis = res?.data?.gpuTime?.map((item) => {
          const date = new Date(item.createdOn);
          return `${date.getUTCHours().toString().padStart(2, "0")}:${date
            .getUTCMinutes()
            .toString()
            .padStart(2, "0")}:${date
            .getUTCSeconds()
            .toString()
            .padStart(2, "0")}`;
        });
        const yaxis = res?.data?.gpuTime?.map((val) =>
          Number(val?.durationSeconds)
        );
        const data = {
          categories: xaxis,
          seconds: yaxis,
        };
        setXaxisCategories(data);
      }
    }, payload);
  };

  const getAdminDataMongo = () => {
    GetAdminDataMongo((res) => {
      if (res?.statusCode === 200) {
        setPieChart(res?.data);
      } else if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
    });
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      setDateRange([startDate, endDate]);
    }
  };

  return (
    <AdminLayout>
      <Space direction="vertical" size={12}>
        <RangePicker
          value={dateRange}
          showTime
          onChange={handleDateRangeChange}
          // disabled={true}
        />
      </Space>
      {xAxisCategories?.categories?.length !== 0 && (
        <BarChart data={xAxisCategories} />
      )}
      {mySQLConnections && pieChart && (
        <PieChart pieChart={pieChart} data={mySQLConnections} />
      )}
      {/* {xAxisCategories?.categories?.length !== 0 && (
        <DumbellMarkers apiData={xAxisCategories} />
      )} */}
    </AdminLayout>
  );
};

export default Dashboard;
