import React from "react";

const PrivateGalleryIcon = ({fill, size = "22"}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7981_102887)">
        <path
          d="M16.8793 0.916016H5.11545C4.00158 0.91735 2.93372 1.36042 2.14609 2.14805C1.35847 2.93567 0.915397 4.00354 0.914063 5.1174L0.914062 16.8813C0.915397 17.9952 1.35847 19.063 2.14609 19.8507C2.93372 20.6383 4.00158 21.0813 5.11545 21.0827H16.8793C17.9932 21.0813 19.0611 20.6383 19.8487 19.8507C20.6363 19.063 21.0794 17.9952 21.0807 16.8813V5.1174C21.0794 4.00354 20.6363 2.93567 19.8487 2.14805C19.0611 1.36042 17.9932 0.91735 16.8793 0.916016ZM5.11545 2.59657H16.8793C17.5479 2.59657 18.1891 2.86216 18.6618 3.33491C19.1346 3.80765 19.4002 4.44884 19.4002 5.1174V16.8813C19.3987 17.2556 19.3125 17.6248 19.1481 17.9611L11.4486 10.2616C11.0585 9.87133 10.5953 9.56176 10.0855 9.35055C9.57565 9.13934 9.02923 9.03064 8.4774 9.03064C7.92558 9.03064 7.37915 9.13934 6.86935 9.35055C6.35954 9.56176 5.89633 9.87133 5.50618 10.2616L2.59462 13.1723V5.1174C2.59462 4.44884 2.86021 3.80765 3.33295 3.33491C3.8057 2.86216 4.44689 2.59657 5.11545 2.59657ZM5.11545 19.4021C4.44689 19.4021 3.8057 19.1365 3.33295 18.6638C2.86021 18.191 2.59462 17.5499 2.59462 16.8813V15.5486L6.69349 11.4497C6.9276 11.2155 7.20556 11.0297 7.51151 10.9029C7.81746 10.7761 8.14539 10.7108 8.47656 10.7108C8.80774 10.7108 9.13567 10.7761 9.44162 10.9029C9.74756 11.0297 10.0255 11.2155 10.2596 11.4497L17.9591 19.15C17.6228 19.3145 17.2537 19.4007 16.8793 19.4021H5.11545Z"
          fill={fill}
        />
        <path
          d="M14.6693 9.62565C15.3038 9.62565 15.9241 9.43749 16.4517 9.08495C16.9793 8.73242 17.3906 8.23134 17.6334 7.6451C17.8762 7.05885 17.9398 6.41376 17.816 5.7914C17.6922 5.16905 17.3866 4.59738 16.9379 4.14869C16.4892 3.69999 15.9175 3.39443 15.2952 3.27063C14.6728 3.14684 14.0277 3.21037 13.4415 3.45321C12.8552 3.69604 12.3542 4.10726 12.0016 4.63486C11.6491 5.16247 11.4609 5.78277 11.4609 6.41732C11.4609 7.26822 11.799 8.08427 12.4006 8.68595C13.0023 9.28763 13.8184 9.62565 14.6693 9.62565ZM14.6693 5.04232C14.9412 5.04232 15.2071 5.12296 15.4332 5.27405C15.6593 5.42514 15.8355 5.63988 15.9396 5.89113C16.0437 6.14238 16.0709 6.41884 16.0179 6.68557C15.9648 6.95229 15.8338 7.19729 15.6415 7.38959C15.4492 7.58189 15.2042 7.71284 14.9375 7.7659C14.6708 7.81895 14.3943 7.79172 14.1431 7.68765C13.8918 7.58358 13.6771 7.40735 13.526 7.18123C13.3749 6.95511 13.2943 6.68927 13.2943 6.41732C13.2943 6.05265 13.4391 5.70291 13.697 5.44505C13.9549 5.18718 14.3046 5.04232 14.6693 5.04232Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_7981_102887">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PrivateGalleryIcon;
