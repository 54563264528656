import React from "react";
import "./Brands.scss";

const Brands = ({ data}) => {
  return (
    <div className="brand">
      <div className="brand__card">
        <div className="brand__card__container">
          <div className="brand__card__container__content">
            <p>{data?.content}</p>
          </div>
          <div className="brand__card__container__data">
            <div className="brand__card__container__data__icon">
              <img src={data?.image} alt={data?.name} />
            </div>
            <div className="brand__card__container__data__content">
              <h4>{data?.name}</h4>
              <h5>{data?.company}</h5>
              <h6>{data?.position}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
