import React from "react";
import "./AiriOffers.scss";

const AiriOffers = ({ data }) => {
  return (
    <div className="offers">
      <div className="offers__container">
        <div className="offers__container__image">
          <img src={data?.image} alt={data?.image} />
        </div>
        <div className="offers__container__content">
          <h3>{data?.heading}</h3>
          <p>{data?.content}</p>
        </div>
      </div>
    </div>
  );
};

export default AiriOffers;
