import React, { createContext, useState } from "react";

const VideoToolsetContext = createContext();

function VideoToolsetProvider({ children }) {
  const [videoToolsetData, setVideoToolsetData] = useState(null);

  const updateVideoToolsetData = (newData) => {
    setVideoToolsetData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearVideoToolsetData = () => {
    setVideoToolsetData(null);
  };

  return (
    <VideoToolsetContext.Provider
      value={{
        videoToolsetData,
        updateVideoToolsetData,
        clearVideoToolsetData,
      }}
    >
      {children}
    </VideoToolsetContext.Provider>
  );
}

export { VideoToolsetContext, VideoToolsetProvider };
