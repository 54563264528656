/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import "./SignIn.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import error from "../../assets/images/error-image-1.png";
import "react-tabs/style/react-tabs.css";
import { useLocation, useNavigate } from "react-router-dom";
import Flag from "react-flagkit";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  CreateOrUpdateProject,
  GetPhoneLengthCountry,
  GetScanQrCodeData,
  UserLogin,
} from "../../utils/apiCalls";
import OtpInput from "react-otp-input";
import Loader from "../../components/loader";
import GifLoader from "../../components/gifLoader";
import { AuthDataContext } from "../../store/UserAuthData";
import { useTranslation } from "react-i18next";
import { ClientDataContext } from "../../store/ClientData";
import { UserSocketContext } from "../../store/UserSocketData";
import { UserContext } from "../../store/UserDetailsStore";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import { TeamDesignContext } from "../../store/TeamDesignData";
import countryCodes from "../../locales/countrycode.json";

const SignIn = ({ handleShowErrorMessage }) => {
  const { t } = useTranslation();
  const apiCalledRef = useRef(false);
  const inputMobileRef = useRef(null);
  const { clientData } = useContext(ClientDataContext);
  const {
    clearLoginUserDetails,
    wechatCode,
    setWechatCode,
    getUserAllPrivilages,
  } = useContext(UserContext);
  const { updateAuthData } = useContext(AuthDataContext);
  const { updateSocketData } = useContext(UserSocketContext);
  const { setTeamsList, handleOpenTeamList, apiCalledRef1 } = useContext(TeamDesignContext);

  // airilab
  const appId = "wx369e5a708f04c8ee";
  const appSecret = "5de1d6e1ed01591cd22887b14b684ce6";
  const redirectURL = "https%3A%2F%2Fairilab.com%2Fsign-in";

  // aigc
  // const appId = "wx45932476a4ceb50e";
  // const appSecret = "91bc13f097cf1db6a3f15c5c5e3af85b";
  // const redirectURL = "https%3A%2F%2Faigc.airilab.net%3A58013%2Fsign-in";

  const navigate = useNavigate();
  const location = useLocation();
  const [phone, setPhone] = useState(null);
  const [countryCode, setCountryCode] = useState("86");
  const [countryName, setCountryName] = useState("China");
  const [verificationCode, setVerificationCode] = useState("");
  const [timer, setTimer] = useState(60);
  const [timerRunning, setTimerRunning] = useState(false);
  const [phoneMinLength, setPhoneMinLength] = useState(null);
  const [phoneMaxLength, setPhoneMaxLength] = useState(11);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [isValidate, setIsValidate] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [isWeChatData, setIsWeChatData] = useState(null);
  const [isOpenId, setIsOpenId] = useState("");
  const [isShowErrorPopup, setIsShowErrorPopup] = useState(false);
  const [isShowVerificationCode, setIsShowVerificationCode] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const baseUrl = window.location.origin;

  const generateRandomState = () => {
    const stateLength = 32;
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let state = "";

    for (let i = 0; i < stateLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      state += characters.charAt(randomIndex);
    }

    return state;
  };
  const state = generateRandomState();
  let j = 0;

  useEffect(() => {
    clearLoginUserDetails();
    setErrorMessage("");
  }, []);

  useEffect(() => {
    if (inputMobileRef.current) {
      inputMobileRef.current.focus();
    }
  }, [selectedTabIndex]);

  const handleTabSelect = (index) => {
    if (index === 0) {
      // Reset loading state when tab with iframe is selected
      setIsShowLoader(true);
    } else {
      setIsShowLoader(false);
      resetMobileInput();
    }
    setSelectedTabIndex(index);
  };

  const resetMobileInput = () => {
    if (isWeChatData === null) {
      // setIsShowLoader(true);
      setCountryCode("86");
      setCountryName("China");
      setPhone(null);
      setIsShowVerificationCode(false);
      setTimerRunning(false);
      setIsValidate(false);
      setIsChecked(false);
      setTimer(60);
      setErrorMessage("");
      setErrorMessage1("");
      setErrorMessage2("");
    }
  };

  const iframeJSX = useMemo(() => {
    if (selectedTabIndex === 0 && wechatCode === null) {
      setIsShowLoader(true);
      if (j === 0) {
        return (
          <iframe
            src={`https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${redirectURL}&response_type=code&scope=snsapi_login&state=${state}`}
            title="Embedded Page"
            width="100%"
            height="400px"
            onLoad={() => setIsShowLoader(false)}
          ></iframe>
        );
      }
    }
  }, [appId, selectedTabIndex]);

  const getFlag = () => {
    return <Flag />;
    return null;
  };

  const handleCountryChange = (country, countryCode) => {
    setCountryCode(country);
    setCountryName(countryCode.name);
    setPhone(null);
    setPhoneMaxLength(11);
    setErrorMessage("");
  };

  useEffect(() => {
    getPhoneLengthWithCountry();
  }, [countryName]);

  const getPhoneLengthWithCountry = () => {
    const payload = {
      countryName: countryName,
    };
    if (countryName !== "") {
      if (payload) {
        GetPhoneLengthCountry((res) => {
          if (res?.status === 200) {
            setPhoneMinLength(res?.data?.minLength);
            setPhoneMaxLength(res?.data?.maxLength);
          } else {
            handleShowErrorMessage(true);
          }
        }, payload);
      }
    }
  };

  useEffect(() => {
    if (!phone) {
      handleMobileChange(phone);
    }
  }, [phoneMaxLength, phone]);

  const handleMobileChange = (event) => {
    const value = event;
    if (value !== null || value !== undefined || value.length !== 0) {
      setPhone(value);
    }
    const minLengthNumber = phoneMinLength;
    if (value !== 0) {
      if (
        value?.match(
          new RegExp(`^([0|\\+[0-9]{1,9})?([0-9]{${minLengthNumber}})$`)
        )
      ) {
        setIsValidate(true);
      } else {
        value && value.length !== 0
          ? setErrorMessage(t("please_enter_a_valid_phone_number"))
          : setErrorMessage("");
        setIsValidate(false);
      }
    }
  };

  const startTimer = () => {
    setTimerRunning(true);
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
      setTimerRunning(false);
      setTimer(60);
    }, 60000);
  };

  useEffect(() => {
    if (
      (isChecked === true || clientData?.privacy === false) &&
      isValidate === true
    ) {
      setIsShowVerificationCode(true);
      setErrorMessage2("");
    } else {
      setVerificationCode("");
      setErrorMessage1("");
    }

    if (
      (isChecked === true || clientData?.privacy === false) &&
      isValidate === false
    ) {
      setIsShowVerificationCode(false);
      setIsChecked(false);
    }

    if (isValidate === false) {
      setErrorMessage2("");
      setIsShowVerificationCode(false);
    }

    if (isValidate === true) {
      if (isChecked === false && clientData?.privacy === true) {
        setIsShowVerificationCode(false);
      }
    }

    if (
      (isChecked === true || clientData?.privacy === false) &&
      isValidate &&
      verificationCode?.length === 6
    ) {
      setIsBtnDisabled(false);
      handleSubmit();
    } else {
      setIsBtnDisabled(true);
    }
  }, [isChecked, isValidate, verificationCode, phoneMaxLength]);

  const hanldeVerification = (value) => {
    const numericValue = value.replace(/\D/g, "");
    setVerificationCode(numericValue);
  };

  const handleFocus = () => {
    //we should focus on mobile on click if there is no otp, else focus on otp
    if (inputMobileRef.current) {
      const nextIndex = verificationCode.length;
      const inputs = document.querySelectorAll(".otpInput");
      if (inputs[nextIndex - 1] || inputs[nextIndex]) {
        if (inputs[nextIndex]) {
          inputs[nextIndex].focus();
        } else {
          inputs[nextIndex - 1].focus();
        }
      } else {
        inputMobileRef.current.focus();
      }
    } else {
    }
  };

  const handleResendClick = () => {
    handleFocus();
    const payload = {
      phoneNumber: phone,
      isAgreedToTerms: clientData?.privacy === false ? true : isChecked,
      role: 2,
      code: "",
      countryCode: `+${countryCode}`,
      countryName: countryName,
    };
    if (phoneMaxLength !== null && phoneMinLength !== null) {
      if (payload) {
        if (phone !== null) {
          if (isChecked === true) {
            setErrorMessage2("");
            if (isValidate === true) {
              setErrorMessage("");
              setIsShowLoader(true);
              UserLogin((res) => {
                if (res?.status === 200) {
                  startTimer();
                  setIsShowLoader(false);
                } else if (res?.status === 403) {
                  setIsShowLoader(false);
                  setIsShowErrorPopup(true);
                } else if (res?.status === 202) {
                  setIsShowLoader(false);
                  setErrorMessage1(t("you_have_just_requested_within_1_mim"));
                  startTimer();
                } else if (res?.status === 412) {
                  setIsShowLoader(false);
                  setIsShowErrorPopup(false);
                  setErrorMessage1(t("your_phone_cannot_receive_otp"));
                } else {
                  setIsShowLoader(false);
                  handleShowErrorMessage(true);
                }
              }, payload);
            } else {
              setIsShowLoader(false);
              setErrorMessage(t("invalid_mobile_phone_number"));
            }
          } else {
            setErrorMessage2(t("login_error_content"));
          }
        } else {
          setErrorMessage(t("please_enter_a_phone_number"));
        }
      }
    } else {
      handleShowErrorMessage(true);
    }
  };

  const urlParams = new URLSearchParams(location.search);
  const code = urlParams.get("code");

  useEffect(() => {
    if (!apiCalledRef.current) {
      apiCalledRef.current = true;
      if (code && isWeChatData === null) {
        setWechatCode(code);
        GetScanQrCodeData((res) => {
          if (res?.status === 200) {
            setIsShowLoader(false);
            setIsOpenId(res?.data?.openId);
            setIsWeChatData(res?.data);
            setTeamsList([]);
            localStorage.setItem(
              "activeTeam",
              JSON.stringify({ teamId: 0.1, teamName: t(t("solo")) })
            );
            if (res?.data?.phoneNumber === false) {
              setIsShowErrorPopup(false);
            } else {
              setWechatCode(null);
              sessionStorage.setItem("token", res?.data?.accessToken);
              localStorage.setItem("token", res?.data?.accessToken);
              localStorage.setItem("expires", res?.data?.expiresIn);
              localStorage.setItem("refreshToken", res?.data?.refreshToken);
              updateSocketData({
                isSocketApiCall: true,
                isShowNotification: false,
                isNewVersionPopup: false,
                isExitPopup: false,
              });
              apiCalledRef1.current = false;
              navigate("/projects");
              const getOldVersion = localStorage.getItem("currentVersion");
              if (getOldVersion !== undefined && getOldVersion !== null) {
                if (res?.data?.version !== "0") {
                  if (res?.data?.version !== "-1") {
                    if (getOldVersion === res?.data?.version) {
                      handleOpenTeamList();
                    }
                  }
                }
              } else {
                handleOpenTeamList();
              }
              setIsShowErrorPopup(false);
            }
          } else if (res?.status === 403) {
            setIsShowErrorPopup(true);
          } else {
            setWechatCode(null);
          }
        }, code);
      }
    }
  }, [appId, appSecret, location.search]);

  const handleSubmit = () => {
    const payload = {
      phoneNumber: phone || null,
      isAgreedToTerms: clientData?.privacy === false ? true : isChecked,
      role: 2,
      code: verificationCode || null,
      countryCode: `+${countryCode}`,
      countryName: countryName,
      openId: isWeChatData?.phoneNumber === false ? isOpenId : "",
    };
    if (payload) {
      if (payload?.phoneNumber !== null) {
        if (isChecked === true) {
          if (payload?.code !== null && payload?.code?.length === 6) {
            setErrorMessage1("");
            setErrorMessage2("");
            // setIsShowLoader(true);
            UserLogin((res) => {
              setWechatCode(null);
              const { status, message, data } = res;
              const loginData = data;
              if (status === 200 && loginData.accessToken) {
                if (data?.firstTime === true) {
                  const payload = {};
                  setTimeout(() => {
                    getUserAllPrivilages();
                    CreateOrUpdateProject(async (res) => {
                      const data = await res?.data;
                      // setIsShowLoader(false);
                      const id = data?.id;
                      navigate(`/workspace/${id}`);
                    }, payload);
                  }, 0);
                } else {
                  setTimeout(() => {
                    navigate("/projects");
                    updateAuthData(newData);
                    setIsShowErrorPopup(false);
                    // setIsShowLoader(false);
                    const getOldVersion =
                      localStorage.getItem("currentVersion");
                    if (getOldVersion !== undefined && getOldVersion !== null) {
                      if (loginData?.version !== "0") {
                        if (loginData?.version !== "-1") {
                          if (getOldVersion === loginData?.version) {
                            handleOpenTeamList();
                          }
                        }
                      }
                    } else {
                      handleOpenTeamList();
                    }
                  }, 0);
                }
                apiCalledRef1.current = false;
                updateSocketData({
                  isSocketApiCall: true,
                  isShowNotification: false,
                  isNewVersionPopup: false,
                  isExitPopup: false,
                });
                setTeamsList([]);
                localStorage.setItem("expires", loginData.expiresIn);
                sessionStorage.setItem("token", loginData.accessToken);
                localStorage.setItem("token", loginData.accessToken);
                localStorage.setItem("refreshToken", loginData.refreshToken);
                const newData = {
                  authToken: loginData?.accessToken,
                };
                updateAuthData(newData);
                setIsShowErrorPopup(false);
              } else if (status === 401) {
                setIsShowLoader(false);
                setIsShowErrorPopup(false);
                if (message === "OTP Expired") {
                  setErrorMessage1(
                    t("your_verification_code_has_expired_please_request_again")
                  );
                } else {
                  setErrorMessage1(
                    t(
                      "wrong_phone_number_or_verification_code_please_try_again"
                    )
                  );
                }
              } else if (res?.status === 412) {
                setIsShowLoader(false);
                setIsShowErrorPopup(false);
                setErrorMessage1(t("your_phone_cannot_receive_otp"));
              } else if (status === 403) {
                setIsShowLoader(false);
                setIsShowErrorPopup(true);
              } else {
                setIsShowLoader(false);
                handleShowErrorMessage(true);
              }
            }, payload);
          } else {
            setErrorMessage1(
              t(
                "please_check_your_input_code_or_request_a_new_verification_code"
              )
            );
          }
        } else {
          setErrorMessage2(t("login_error_content"));
        }
      } else {
        setIsValidate(false);
        setErrorMessage(t("please_enter_a_phone_number"));
      }
    }
  };

  const handleCloseErrorPopup = () => {
    setIsShowErrorPopup(false);
  };

  const renderPhoneNumberInput = () => {
    return (
      <div className="login__section__tabs__details__phone">
        <div className="login__section__tabs__details__phone__input">
          <div
            className="login__section__tabs__details__phone__input__inputs"
            onClick={(e) => e.stopPropagation()}
          >
            <PhoneInput
              country={countryCodes.defaultCountry}
              onlyCountries={countryCodes.Countries}
              placeholder={t("country_code")}
              value={countryCode}
              maxLength={4}
              onChange={(country, countryCode) =>
                handleCountryChange(country, countryCode)
              }
              countryComponent={getFlag()}
              countryCodeEditable={false}
              inputProps={{
                readOnly: true,
              }}
              enableSearch={true}
              disableSearchIcon={true}
            />
            <input
              ref={inputMobileRef}
              type="tel"
              pattern="[0-9]*"
              inputMode="numeric"
              className="fill-num"
              placeholder={`${t("enter_your_phone_number")}`}
              value={phone === null ? "" : phone}
              maxLength={phoneMaxLength}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
              onChange={(e) => handleMobileChange(e.target.value)}
            />
          </div>
          {isValidate === false && errorMessage !== "" && (
            <div className="error-p" style={{ color: "red" }}>
              <p>{errorMessage}</p>
            </div>
          )}
        </div>
        {isShowVerificationCode && (
          <div className="login__section__tabs__details__phone__otp">
            <div className="login__section__tabs__details__phone__otp__content">
              <p>{t("verification_code")}</p>
            </div>
            <div className="login__section__tabs__details__phone__otp__section">
              <div className="login__section__tabs__details__phone__otp__section__inputs">
                <OtpInput
                  inputType="tel"
                  className="otp__form__inputs"
                  value={verificationCode}
                  onChange={hanldeVerification}
                  shouldAutoFocus={true}
                  numInputs={6}
                  renderInput={(props, index) => {
                    const handleKeyPress = (event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                      if (props.onKeyPress) {
                        props.onKeyPress(event);
                      }
                    };

                    return (
                      <input
                        {...props}
                        className="otpInput"
                        onKeyPress={handleKeyPress}
                        onMouseDown={handleFocus}
                        onTouchStart={handleFocus}
                        onClick={handleFocus}
                      />
                    );
                  }}
                  inputStyle={{
                    textAlign: "center",
                    background: "#fafafa",
                    padding: 0,
                  }}
                />
              </div>
              <div className="login__section__tabs__details__phone__otp__section__button">
                {timerRunning ? (
                  <button className="disabled">
                    {t("resend_in")} ({Math.floor(timer)}
                    s)
                  </button>
                ) : (
                  <button onClick={handleResendClick}>
                    {t("recieve_code")}
                  </button>
                )}
              </div>
            </div>
            {errorMessage1 && (
              <div className="error-p" style={{ color: "red" }}>
                <p>{errorMessage1}</p>
              </div>
            )}
          </div>
        )}
        {isValidate === true && clientData?.privacy === true && (
          <div className="login__section__tabs__details__phone__agree">
            <input type="checkbox" onChange={() => setIsChecked(!isChecked)} />
            <p>
              {t("i_have_read")}{" "}
              <a
                href={`${baseUrl}/terms-of-services`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("terms_of_service")}
              </a>{" "}
              {t("and")}{" "}
              <a
                href={`${baseUrl}/privacy-policy`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Privacy_policy")}
              </a>
              .
            </p>
          </div>
        )}
        {errorMessage2 && (
          <div className="error-p" style={{ color: "red" }}>
            <p>{errorMessage2}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <UserPortalLayout>
      <div className="login" onClick={handleFocus}>
        <div className="login__container" data-scrollable="true">
          {isWeChatData === null ? (
            <>
              {wechatCode === null ? (
                <div className="login__sections">
                  <div className="login__heading">
                    <h1>{t("welcome")}</h1>
                  </div>
                  <div className="login__section">
                    <div className="login__section__main">
                      <div className="login__section__tabs">
                        <Tabs onSelect={handleTabSelect}>
                          <TabList>
                            <Tab>{t("wechat_log_in")}</Tab>
                            <Tab
                              disabled={isWeChatData === null ? false : true}
                            >
                              {t("phone_log_in")}
                            </Tab>
                          </TabList>
                          <TabPanel>
                            <div
                              className="login__section__tabs__details"
                              key={isWeChatData}
                            >
                              {isWeChatData === null ? (
                                <div className="login__section__tabs__details__container">
                                  <p>{t("wechat_header")}</p>
                                  <div>{iframeJSX}</div>
                                  <span>
                                    {t("i_have_read")}{" "}
                                    <a
                                      href={`${baseUrl}/terms-of-services`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {t("terms_of_service")}
                                    </a>{" "}
                                    {t("and")}{" "}
                                    <a
                                      href={`${baseUrl}/privacy-policy`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {t("Privacy_policy")}
                                    </a>
                                    .
                                  </span>
                                </div>
                              ) : (
                                <div className="login__section__tabs__details">
                                  <div className="login__section__tabs__details__container">
                                    <div className="login__section__tabs__details__top">
                                      <p>{t("wechat_login_phone_text")}</p>
                                    </div>
                                    {renderPhoneNumberInput()}
                                    <div className="login__section__tabs__details__text">
                                      <span>
                                        {t("wechat_login_phone_text1")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="login__section__tabs__details">
                              <div className="login__section__tabs__details__container">
                                {renderPhoneNumberInput()}
                                <div className="login__section__tabs__details__text">
                                  <span>{t("login_bottom")}</span>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <GifLoader message={t("wechat_authenticating")} />
              )}
            </>
          ) : (
            <>
             <div className="login__sections">
                  <div className="login__heading">
                    <h1>{t("welcome")}</h1>
                  </div>
                  <div className="login__section">
                    <div className="login__section__main">
                      <div className="login__section__tabs">
                        <Tabs onSelect={handleTabSelect}>
                          <TabList>
                            <Tab>{t("wechat_log_in")}</Tab>
                            <Tab
                              disabled={isWeChatData === null ? false : true}
                            >
                              {t("phone_log_in")}
                            </Tab>
                          </TabList>
                          <TabPanel>
                            <div
                              className="login__section__tabs__details"
                              key={isWeChatData}
                            >
                              {isWeChatData === null ? (
                                <div className="login__section__tabs__details__container">
                                  <p>{t("wechat_header")}</p>
                                  <div>{iframeJSX}</div>
                                  <span>
                                    {t("i_have_read")}{" "}
                                    <a
                                      href={`${baseUrl}/terms-of-services`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {t("terms_of_service")}
                                    </a>{" "}
                                    {t("and")}{" "}
                                    <a
                                      href={`${baseUrl}/privacy-policy`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {t("Privacy_policy")}
                                    </a>
                                    .
                                  </span>
                                </div>
                              ) : (
                                <div className="login__section__tabs__details">
                                  <div className="login__section__tabs__details__container">
                                    <div className="login__section__tabs__details__top">
                                      <p>{t("wechat_login_phone_text")}</p>
                                    </div>
                                    {renderPhoneNumberInput()}
                                    <div className="login__section__tabs__details__text">
                                      <span>
                                        {t("wechat_login_phone_text1")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="login__section__tabs__details">
                              <div className="login__section__tabs__details__container">
                                {renderPhoneNumberInput()}
                                <div className="login__section__tabs__details__text">
                                  <span>{t("login_bottom")}</span>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              {isWeChatData?.phoneNumber === false && (
                <div className="login__popup">
                  <div className="login__popup__container">
                    <div className="login__popup__section">
                      <div className="login__popup__section__header">
                        <h4>{t("phone_number_authentication")}</h4>
                      </div>
                      <div className="login__section__tabs__details">
                        <div className="login__section__tabs__details__container">
                          {renderPhoneNumberInput()}
                          <div className="login__section__tabs__details__text">
                            <span>{t("login_bottom")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {isShowErrorPopup && (
          <div className="error-popup" onClick={handleCloseErrorPopup}>
            <div className="error-popup__container">
              <img
                src={error}
                alt="error-popup"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>
        )}
        {isShowLoader && <Loader />}
      </div>
    </UserPortalLayout>
  );
};

export default SignIn;
