/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
  useMemo
} from "react";
import "./Gallery.scss";
import SideNavComponent from "../../components/SideNav/SideNavComponent";
import imgselect from "../../assets/images/icons/image-select.png";
import downarrow from "../../assets/images/chatgpt/down-arrow.png";
import gif from "../../assets/images/workspace/upscale-gif.gif";
import deleted from "../../assets/images/workspace/deleted-image.jpg";
import fav from "../../assets/images/workspace/icons/feed-fav.png";
import fav1 from "../../assets/images/workspace/icons/feed-fav1.png";
import fav2 from "../../assets/images/workspace/icons/feed-fav2.png";
import addicon from "../../assets/images/icons/add-icon1.png";
import addicon1 from "../../assets/images/icons/add-icon1d.png";
import videoGen from "../../assets/images/workspace/icons/video-gen.png";
import {
  GetAllImagesCurrentUserGalleryMongo,
  CallAwsUrl,
  DeleteImagesGallery,
  UpdateFavoriteImage,
} from "../../utils/apiCalls";
import {
  handleConvertDateTime,
  extractNameFromUrl,
} from "../../utils/workspaceUtils";
import { useTranslation } from "react-i18next";
import Confirm from "../../components/confirmModal/confirm";
import Loader from "../../components/loader";
import WorkSpaceFeedPopUp from "../../components/WorkspaceComponents/workspacefeedpopup/WorkSpaceFeedPopUp";
import GalleryHeader from "../../components/GalleryHeader/GalleryHeader";
import { UserSocketContext } from "../../store/UserSocketData";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import FavoriteTooltip from "../../components/Tooltips/FavotiteTooltip";
import { ResponsiveDataContext } from "../../store/ResponsiveData";
import { useNavigate } from "react-router-dom";
import CreateProject from "../../components/WorkshopTeams/CreateProject/CreateProject";
import { Popover } from "antd";

const Gallery = ({
  isActiveTab,
  teamGalleryDisable,
  setTeamGalleryDisable,
  teamGalleryDisable1,
  setTeamGalleryDisable1,
}) => {
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const { updateSocketData, handleShowSubscription } =
    useContext(UserSocketContext);
  const { responsiveData, screenWidth } = useContext(ResponsiveDataContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const galleryApiRef = useRef(false);
  const galleryApiRef1 = useRef(false);
  const [isPageIndex, setIsPageIndex] = useState(0);
  const [isPageSize, setIsPageSize] = useState(40);
  const [isGalleryImages, setIsGalleryImages] = useState([]);
  const [isGalleryNewImages, setIsGalleryNewImages] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmModal1, setShowConfirmModal1] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertContent, setAlertContent] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [galleryPopupData, setGalleryPopupData] = useState([]);
  const [isGalleryCurrentIndex, setIsGalleryCurrentIndex] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [allImagesFetched, setAllImagesFetched] = useState(false);
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [searchOptions, setSearchOptions] = useState({ valueString: "" });
  const [activeTab, setActiveTab] = useState("all");
  const [deletedMediaId, setDeletedMediaId] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [isFavoriteImages, setIsFavoriteImages] = useState({});
  const [loadedImages, setLoadedImages] = useState({});
  const [isDisable, setIsDiable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoader1, setIsLoader1] = useState(false);

  let i = 0;
  const maxImageSelection = 100;

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setShowScrollArrow(true);
    } else {
      setShowScrollArrow(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const handlePopup = (image, index) => {
    handlePopupOpen();
    setIsGalleryCurrentIndex(index);
    const createdDate = new Date(image?.created);
    const day = String(createdDate.getDate()).padStart(2, "0");
    const month = String(createdDate.getMonth() + 1).padStart(2, "0");
    const year = createdDate.getFullYear();
    const hours = String(createdDate.getHours()).padStart(2, "0");
    const minutes = String(createdDate.getMinutes()).padStart(2, "0");
    const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}`;

    setGalleryPopupData({
      ...image,
      created: formattedDate,
    });
    document.body.classList.add("popup-open");
  };

  useEffect(() => {
    if (isGalleryCurrentIndex >= 0 && isGalleryCurrentIndex < isGalleryImages.length) {
      const currentImage = isGalleryImages[isGalleryCurrentIndex];
      const imageElement = new Image();
      imageElement.src = currentImage.baseImage || currentImage.url;
    }
  }, [isGalleryCurrentIndex]);

  const handlePopupOpen = () =>{
    setShowPopup(true);
  }

  const handlePopupclose = () => {
    // if (isSelectMode !== true) return;
    setShowPopup(false);
    document.body.classList.remove("popup-open");
  };

  useEffect(() => {
    setTimeout(() => {
      const delay = isGalleryImages?.length !== 0 ? 10 : 0;
      let timeoutId;

      const debounceSearch = () => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          setAllImagesFetched(false);
          galleryApiRef.current = false;
          handleClick();
          getAllGalleryImages();
        }, delay);
      };

      debounceSearch();

      return () => {
        clearTimeout(timeoutId);
      };
    }, 0);
  }, [searchOptions, activeTab, selectedTeamId, selectedProjectId]);

  const getAllGalleryImages = () => {
    // if (!allImagesFetched) {
    if (!galleryApiRef.current) {
      galleryApiRef.current = true;
      setDataNotFound(true);
      setIsGalleryImages([]);
      setIsGalleryNewImages([]);
      const data = localStorage.getItem("language");
      let searchData = searchOptions;
      if (activeTab !== "all" && activeTab !== "favorite") {
        const currentValues = searchOptions?.valueString?.split(",");
        if (!currentValues?.includes("upscale")) {
          if (currentValues[0] !== "") {
            const newValues = [...currentValues, "upscale"];
            searchData = { valueString: newValues?.join(",") };
          } else {
            const newValues = ["upscale"];
            searchData = { valueString: newValues?.join(",") };
          }
        }
      }
      const payload = {
        pageIndex: 0,
        pageSize: isPageSize,
        searchString: searchData?.valueString,
        chinese: data === "ch" ? true : false,
        teamId: selectedTeamId?.value,
        projectId: selectedProjectId?.value,
        userProject: true,
        isFavorite: activeTab === "favorite" ? true : false,
      };
      if (payload && selectedTeamId && selectedProjectId) {
        setIsPageIndex(0);
        GetAllImagesCurrentUserGalleryMongo((res) => {
          if (res.status === 200) {
            setDataNotFound(false);
            const newImages = res?.projectMedias || [];
            setIsGalleryImages(newImages);
          } else {
            setDataNotFound(false);
          }
          if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          }
        }, payload);
      }
    }
    // }
  };

  const checkAllImagesLoaded = useCallback(() => {
    const images = document.querySelectorAll(".column-img");
    for (const image of images) {
      if (!image?.complete) {
        return false;
      }
    }
    return true;
  }, []);

  const getAllGalleryImages1 = (isLoading) => {
    if (!allImagesFetched && !isLoading && !galleryApiRef1.current) {
      galleryApiRef1.current = true;
      isLoading = true;
      setLoading(true);

      const data = localStorage.getItem("language");
      let searchData = searchOptions;
      if (activeTab !== "all" && activeTab !== "favorite") {
        const currentValues = searchOptions?.valueString?.split(",");
        if (!currentValues?.includes("upscale")) {
          if (currentValues[0] !== "") {
            const newValues = [...currentValues, "upscale"];
            searchData = { valueString: newValues?.join(",") };
          } else {
            const newValues = ["upscale"];
            searchData = { valueString: newValues?.join(",") };
          }
        }
      }
      const index = Math.round(isGalleryImages?.length / 40);
      const payload = {
        pageIndex: index ?? 0,
        pageSize: isPageSize,
        searchString: searchData?.valueString,
        chinese: data === "ch" ? true : false,
        teamId: selectedTeamId?.value,
        projectId: selectedProjectId?.value,
        userProject: true,
        isFavorite: activeTab === "favorite" ? true : false,
      };
      if (payload && selectedTeamId && selectedProjectId) {
        GetAllImagesCurrentUserGalleryMongo((res) => {
          if (res?.status === 200) {
            const newImages = res?.projectMedias || [];
            if (newImages.length === 0) {
              setAllImagesFetched(true);
              setIsDiable(true);
            }
            setTimeout(() => {
              setLoading(false);
            }, 1000);
            setIsGalleryNewImages(res?.projectMedias);
            isLoading = false;
            setIsPageIndex((prevIndex) => prevIndex + 1);
          } else if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          } else {
            setAllImagesFetched(true);
            setIsDiable(true);
            setLoading(false);
          }
        }, payload);
      }
    }
  };

  useEffect(() => {
    let isLoading = false;

    const handleScroll = () => {
      const isNearBottom =
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 100;

      if (isNearBottom && !isLoading && !isDisable) {
        getAllGalleryImages1(isLoading);
        galleryApiRef1.current = false;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isPageIndex, allImagesFetched, checkAllImagesLoaded]);

  const handleLoadMore = () => {
    let isLoading = false;
    getAllGalleryImages1(isLoading);
  };

  useEffect(() => {
    if (isGalleryImages?.length < 40) {
      setIsDiable(true);
    } else if (
      isGalleryImages?.length > 41 &&
      isGalleryNewImages?.length < 40
    ) {
      setIsDiable(true);
    } else {
      setIsDiable(false);
    }
  }, [isGalleryNewImages, isGalleryImages]);

  useEffect(() => {
    const uniqueGalleryImagesIds = new Set(
      isGalleryImages?.map((image) => image?.id)
    );
    const uniqueImages = isGalleryNewImages?.filter((newImage) => {
      return !uniqueGalleryImagesIds.has(newImage?.id);
    });
    if (uniqueImages?.length > 0) {
      setIsGalleryImages((prevImages) => [...prevImages, ...uniqueImages]);
    }
    if (isGalleryImages?.length === 40 && isPageIndex === 0) {
      setIsPageIndex(1);
    }
  }, [isGalleryImages, isGalleryNewImages, isPageIndex]);

  useEffect(() => {
    const areAllImagesLoaded = checkAllImagesLoaded();
    setAllImagesLoaded(areAllImagesLoaded);
  }, [isGalleryImages, checkAllImagesLoaded]);

  const handleImageSelection = (imageId) => {
    if (isSelectMode) {
      if (selectedImages.includes(imageId)) {
        setSelectedImages(selectedImages.filter((id) => id !== imageId));
      } else {
        if (selectedImages.length < maxImageSelection) {
          setSelectedImages([...selectedImages, imageId]);
        } else {
          setShowAlert(true);
        }
      }
    } else {
      setSelectedImages([]);
    }
  };

  useEffect(() => {
    if (isSelectMode === false) {
      setSelectedImages([]);
    }
  }, [isSelectMode]);

  const [downloadIcon, setDownloadIcon] = useState(false);
  const [deleteIcon, setDeleteIcon] = useState(false);

  const handleCloseDownload = () => {
    setDownloadIcon(false);
  };

  const handleDownload = async () => {
    setDownloadIcon(false);
    const zip = new JSZip();
    const imgFolder = zip.folder("images");
    const currentDateTime = new Date().toString();
    const zipName = "AIRILabImages@" + currentDateTime;

    const callAwsUrlAsync = (updatedLink) => {
      return new Promise((resolve, reject) => {
        CallAwsUrl((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(new Error(res.message || "Failed to download image"));
          }
        }, updatedLink);
      });
    };

    const filenames = {};

    const sanitizeFilename = (name) => {
      return name.replace(/[/\\?%|"*<>]/g, "_");
    };

    for (const imageId of selectedImages) {
      const selectedImage = isGalleryImages.find(
        (image) => image.id === imageId
      );
      const imageData = selectedImage;

      const date = await handleConvertDateTime(imageData?.created);
      const updatedLink = selectedImage.url;
      const imageName = extractNameFromUrl(imageData?.url);

      try {
        const data = await callAwsUrlAsync(updatedLink);
        let fileExtension = imageData?.toolset === 'video' ? 'mp4' : 'jpg';
        let downloadName = `${imageData?.username}&${imageData?.projectName}&${imageData?.generationId}&${imageName}&${date}.${fileExtension}`;
        downloadName = sanitizeFilename(downloadName);
        i = i + 1;
        if (filenames[downloadName]) {
          const count = filenames[downloadName];
          const extensionIndex = downloadName.lastIndexOf(`.${fileExtension}`);
          const nameWithoutExtension = downloadName.substring(
            0,
            extensionIndex
          );
          downloadName = `${nameWithoutExtension}_${count}.${fileExtension}`;
          filenames[downloadName] = count + 1;
        } else {
          filenames[downloadName] = 1;
        }

        imgFolder.file(downloadName, data, { base64: true });
      } catch (error) {
        console.error("Error processing image:", error);
      }
    }
    zip
      .generateAsync({ type: "blob" })
      .then((content) => {
        saveAs(content, zipName + ".zip");
      })
      .catch((error) => {
        console.error("Error generating zip:", error);
      });

    setSelectedImages([]);
  };

  const handleCloseDelete = () => {
    setDeleteIcon(false);
  };

  const handleDeleteImages = () => {
    setIsLoader(true);
    const payload = {
      projectId: null,
      mediaIds: selectedImages,
    };
    if (payload) {
      DeleteImagesGallery((res) => {
        const { status, data, message } = res;
        if (status === 200) {
          galleryApiRef.current = false;
          getAllGalleryImages();
          setShowConfirmModal(false);
          setDeleteIcon(false);
          setIsLoader(false);
          setShowConfirmModal1(true);
          setAlertText("Images Deleted Successfully");
          setIsPageIndex(0);
          setSelectedImages([]);
        } else {
          setIsLoader(false);
          setDeleteIcon(false);
          setShowConfirmModal(false);
          setShowConfirmModal1(true);
          setAlertText(message);
          setIsPageIndex(0);
          setSelectedImages([]);
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    }
  };

  const handleDragStart = async (e, image) => {
    e.preventDefault();
    const url = image?.url;
    const date = await handleConvertDateTime(image?.created);
    const fileName = `${image?.username}_${image?.projectName}_${date}_${image?.generationId}.jpg`;

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = fileName;
    // anchor.click();
  };

  useEffect(() => {
    const favoritesMap = {};

    isGalleryImages.forEach((media) => {
      favoritesMap[media.id] = media.isFavorite;
    });
    setIsFavoriteImages(favoritesMap);
  }, [isGalleryImages]);

  const handleFavorite = (id) => {
    const mediaId = id;
    const updatedIsFavoriteImages = { ...isFavoriteImages };
    const currentValue = updatedIsFavoriteImages[mediaId];
    updatedIsFavoriteImages[mediaId] = !currentValue;
    setIsFavoriteImages(updatedIsFavoriteImages);
    const payload = {
      mediaId: mediaId,
    };
    UpdateFavoriteImage((res) => {
      if (res?.status === 200) {
        const updatedFavoriteValue = res?.data?.isFavorite;
      } else {
        const updatedIsFavoriteImages = { ...isFavoriteImages };
        const currentValue = updatedIsFavoriteImages[mediaId];
        updatedIsFavoriteImages[mediaId] = !currentValue;
        setIsFavoriteImages(updatedIsFavoriteImages);
      }
    }, payload);
  };

  const handleImageLoad = (mediaId) => {
    setLoadedImages((prevLoadedImages) => ({
      ...prevLoadedImages,
      [mediaId]: true,
    }));
  };

  const numRows = screenWidth < 1090 ? 3 : screenWidth < 1250 ? 4 : 5;
  const imagesPerPage = numRows * Math.ceil(window.innerHeight / 100);
  const numCols = Math.ceil(isGalleryImages?.length / numRows);

  const rows = useMemo (()=>{
    const tempRows = [];
    for (let row = 0; row < numRows; row++) {
      const cols = [];
      for (let col = 0; col < numCols; col++) {
        const index = col * numRows + row;
        if (index < isGalleryImages?.length) {
          const image = isGalleryImages[index];
          const isDeleted = deletedMediaId?.includes(image?.id);
          const isVideo = image.toolset === "video";
          cols.push(
            <div key={image?.id} className="column">
              <div
                className={`column-cont ${
                  selectedImages?.includes(image?.id) ? "selected" : ""
                }`}
              >
                <div
                  className={`column-cont__sec ${
                    isFavoriteImages[image?.id] ? "favorite" : ""
                  }`}
                >
                  <img className="icon" src={imgselect} alt="select-icon" />
                  {!isDeleted && (
                  <img
                    className={`column-img ${
                      selectedImages?.includes(image?.id) ? "selected" : ""
                    }`}
                    src={image?.thumbnail || image?.url}
                    alt={image?.id}
                    data-image-id={image?.id}
                    onLoad={() => handleImageLoad(image.id)}
                    onDragStart={(e) => handleDragStart(e, image)}
                  />)}
                  <div
                    className="favorites"
                    onClick={
                      image?.url
                        ? isSelectMode === true
                          ? () => handleImageSelection(image?.id)
                          : () => handlePopup(image, index)
                        : undefined
                    }
                  >
                    <div
                      className="fav-icon"
                      style={{
                        display: loadedImages[image?.id] ? "block" : "none",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {/* <img src={fav2} className="fav" alt="favorite" /> */}
                      <img
                        src={fav}
                        className="fav"
                        alt="favorite"
                        onClick={() => handleFavorite(image.id)}
                      />
                      <img
                        src={fav1}
                        className="fav fav1"
                        alt="favorite"
                        onClick={() => handleFavorite(image.id)}
                      />
                      <img
                        src={fav2}
                        className="fav fav2"
                        alt="favorite"
                        onClick={() => handleFavorite(image.id)}
                      />
                      <div className="tooltip-show">
                        {!responsiveData && (
                          <FavoriteTooltip text={t("favorite")} />
                        )}
                      </div>
                    </div>                 
                  </div>
                  <div className="video-gen">
                    <img className="video-gen__icon" src={videoGen} alt="video-icon"
                    style={{
                      display: isVideo ? 'block' : 'none'
                    }} />
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          cols.push(<div key={col} className="column"></div>);
        }
      }
      tempRows.push(
        <div key={row} className="row">
          {cols}
        </div>
      );
    }
    return tempRows;
  },[numRows, numCols, isGalleryImages, deletedMediaId, selectedImages, isFavoriteImages, loadedImages, isSelectMode])


  const [originalImage, setOriginalImage] = useState(new Image());
  const [alternateImage, setAlternateImage] = useState(new Image());

  useEffect(() => {
    originalImage.src = galleryPopupData?.url;

    if (galleryPopupData?.baseImage !== null) {
      alternateImage.src = galleryPopupData?.baseImage;
    }
  }, [galleryPopupData]);

  const dynamicValue1 = galleryPopupData?.toolset?.split(",")[0]?.toUpperCase();

  const dynamicValue2 = galleryPopupData?.toolset?.split(",")[1]?.toUpperCase();

  useEffect(() => {
    setShowSlider(false);
  }, [galleryPopupData]);

  const handleShowSlider = () => {
    setShowSlider(true);
  };

  const downloadTitle = `${t("download_text")} ${selectedImages?.length} ${t(
    "image_text"
  )}`;
  const downloadContent = `${t("download_the_selected")} ${
    selectedImages?.length
  } ${t("image?")}`;
  const deleteTitle = `${t("delete")} ${selectedImages?.length} ${t(
    "image_text"
  )}`;
  const deleteContent = `${t("delete_the_selected")} ${
    selectedImages?.length
  } ${t("image?")}`;

  useEffect(() => {
    // console.log("hiya:", isGalleryImages?.length);
  }, [isGalleryImages]);

  const [dragStart, setDragStart] = useState(null);

  const handleMouseDown = (e) => {
    if (!isSelectMode) return;
    const rect = targetRef.current.getBoundingClientRect();
    setDragStart({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const handleMouseMove = (e) => {
    if (!isSelectMode || !dragStart) return;
    const rect = targetRef.current.getBoundingClientRect();
    const dragCurrent = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
    if (
      Math.abs(dragStart.x - dragCurrent.x) > 5 ||
      Math.abs(dragStart.y - dragCurrent.y) > 5
    ) {
      selectImagesInPath(dragStart, dragCurrent);
    }
  };

  const handleMouseUp = (e) => {
    if (!isSelectMode || !dragStart) return;
    setDragStart(null);
  };

  const touchToMouse = (e) => {
    if (e.touches && e.touches[0]) {
      return {
        clientX: e.touches[0].clientX,
        clientY: e.touches[0].clientY,
        target: e.target,
      };
    }
    return { clientX: 0, clientY: 0, target: e.target }; // Or another value indicating an error or non-existent touch
  };

  const touchStartHandler = (e) => {
    const touchMouseEvent = touchToMouse(e);
    handleMouseDown(touchMouseEvent);
  };

  const touchMoveHandler = (e) => {
    const touchMouseEvent = touchToMouse(e);
    handleMouseMove(touchMouseEvent);
  };

  const touchEndHandler = (e) => {
    const touchMouseEvent = touchToMouse(e);
    handleMouseUp(touchMouseEvent);
  };

  const selectImagesInPath = (start, curr) => {
    const rect = targetRef.current.getBoundingClientRect();
    const newSelected = isGalleryImages.filter((image) => {
      const imgElement = document.querySelector(
        `img[data-image-id="${image.id}"]`
      );
      if (!imgElement) return false; // Skip if no element is found
      const imgRect = imgElement.getBoundingClientRect();
      if (
        imgRect.left + imgRect.width > rect.left + curr.x &&
        imgRect.left < rect.left + curr.x &&
        imgRect.top + imgRect.height > rect.top + curr.y &&
        imgRect.top < rect.top + curr.y
      ) {
        if (selectedImages.length < maxImageSelection) {
          setSelectedImages((prevSelectedImages) => {
            if (!prevSelectedImages.includes(image.id)) {
              return [...new Set([...prevSelectedImages, image.id])];
            }
            return prevSelectedImages;
          });
        } else {
          setShowAlert(true);
          handleMouseUp();
        }
      }
    });
  };

  const handleOpenSubscription = () => {
    handleShowSubscription();
    setTeamGalleryDisable(false);
  };

  const handleOpenSubscription1 = () => {
    setTeamGalleryDisable1(false);
    navigate("/projects");
    localStorage.setItem("isWorkshop", true);
  };

  const handleOpen = (isOpen) => {
    setOpen(isOpen);
  };

  return (
    <div className="gallery">
      <div className="gallery__container">
        <div className="gallery__sections">
          <div className="gallery__main">
            <div className="gallery__main__section">
              <GalleryHeader
                isSelectMode={isSelectMode}
                setIsSelectMode={setIsSelectMode}
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                downloadIcon={downloadIcon}
                setDownloadIcon={setDownloadIcon}
                deleteIcon={deleteIcon}
                setDeleteIcon={setDeleteIcon}
                setAlertText={setAlertText}
                setShowConfirmModal={setShowConfirmModal}
                searchOptions={searchOptions}
                setSearchOptions={setSearchOptions}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isShowIcons={true}
                isLoading={dataNotFound}
                setAlertContent={setAlertContent}
                isFavoriteTab={true}
                isFavoriteImages={isFavoriteImages}
                selectedTeamId={selectedTeamId}
                setSelectedTeamId={setSelectedTeamId}
                selectedProjectId={selectedProjectId}
                setSelectedProjectId={setSelectedProjectId}
                isActiveTab={isActiveTab}
                styles={{
                  width:
                    screenWidth > 1609
                      ? "960px"
                      : screenWidth > 1249
                      ? "670px"
                      : screenWidth > 1089
                      ? "448px"
                      : screenWidth > 767
                      ? "338px"
                      : screenWidth > 600
                      ? "260px"
                      : "960px",
                }}
              />
              <div
                className="gallery__main__images__container"
                ref={targetRef}
                data-scrollable="true"
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onTouchStart={touchStartHandler}
                onTouchEnd={touchEndHandler}
                onTouchMove={touchMoveHandler}
              >
                {selectedProjectId?.value !== -1 ? (
                  <>
                    {isGalleryImages?.length !== 0 ? (
                      rows?.map((row, rowIndex) => (
                        <div key={`row-all-${rowIndex}`} className="row">
                          {row?.props?.children?.map((col, colIndex) => (
                            <div key={`col-all-${rowIndex}-${colIndex}`}>
                              {col}
                            </div>
                          ))}
                        </div>
                      ))
                    ) : (
                      <div className="gif-container">
                        {dataNotFound ? (
                          <img src={gif} alt="gif-loader" />
                        ) : (
                          <p>{t("no_data_found")}</p>
                        )}
                      </div>
                    )}
                    {!loading && !isDisable && (
                      <div className="gif-container1">
                        <img src={gif} alt="gif-loader" />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="gallery__main__images__container__no-project">
                    <p>{t("no_projects")}</p>
                    <Popover
                      overlayClassName="project-popover"
                      content={
                        <CreateProject
                          onClose={handleOpen}
                          setIsLoader1={setIsLoader1}
                          isLoader1={isLoader1}
                          open={open}
                          // filterProjectsList={filterProjectsList}
                        />
                      }
                      arrow={false}
                      trigger="click"
                      placement="bottom"
                      open={open}
                      onOpenChange={handleOpen}
                    >
                      <button>
                        <img
                          className="add-default"
                          src={addicon}
                          alt="add-icon"
                        />
                        <img
                          className="add-active"
                          src={addicon1}
                          alt="add-icon"
                        />
                        <span>{t("create_project")}</span>
                      </button>
                    </Popover>
                  </div>
                )}
              </div>
              <div className="gallery__main__section__button">
                <button disabled={isDisable} onClick={handleLoadMore}>
                  {isDisable ? t("all_loaded") : t("load_more")}
                </button>
              </div>
              {showScrollArrow && (
                <div className="gallery__main__section__scroll">
                  <img src={downarrow} alt="down-arrow" onClick={handleClick} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {downloadIcon && (
        <Confirm
          isCancelRequired={true}
          confirmTitle={downloadTitle}
          content={downloadContent}
          onConfirm={handleDownload}
          onCancel={handleCloseDownload}
          cancelButtonText={t("cancel")}
          buttonText={t("download")}
        />
      )}
      {deleteIcon && (
        <Confirm
          isCancelRequired={true}
          confirmTitle={deleteTitle}
          content={deleteContent}
          onConfirm={handleDeleteImages}
          onCancel={handleCloseDelete}
          cancelButtonText={t("cancel")}
          buttonText={t("delete")}
        />
      )}
      {showPopup && (
        <WorkSpaceFeedPopUp
          galleryPopupData={galleryPopupData}
          isGalleryCurrentIndex={isGalleryCurrentIndex}
          handlePopupclose={handlePopupclose}
          handlePopupShowSlider={handleShowSlider}
          popupShowSlider={showSlider}
          popupDynamicValue1={dynamicValue1}
          popupDynamicValue2={dynamicValue2}
          showStealth={true}
          isGalleryImages={isGalleryImages}
          showAllIcons={true}
          setDeletedMediaId={setDeletedMediaId}
          isFavoriteImages={isFavoriteImages}
          setIsFavoriteImages={setIsFavoriteImages}
          setGalleryPopupData={setGalleryPopupData}
          setIsGalleryCurrentIndex={setIsGalleryCurrentIndex}
        />
      )}
      {showConfirmModal && (
        <Confirm
          buttonText={"OK"}
          isCancelRequired={false}
          confirmTitle={alertText}
          content={alertContent}
          onConfirm={() => {
            setShowConfirmModal(false);
          }}
          onCancel={() => {
            setShowConfirmModal(false);
          }}
        />
      )}
      {showAlert && (
        <Confirm
          buttonText={"OK"}
          isCancelRequired={false}
          confirmTitle={t("not_more")}
          onConfirm={() => {
            setShowAlert(false);
          }}
          onCancel={() => {
            setShowAlert(false);
          }}
        />
      )}
      {showConfirmModal1 && (
        <Confirm
          buttonText={"OK"}
          isCancelRequired={false}
          confirmTitle={alertText}
          onConfirm={() => {
            setShowConfirmModal1(false);
          }}
          onCancel={() => {
            setShowConfirmModal1(false);
          }}
        />
      )}
      {isLoader && <Loader />}
      {teamGalleryDisable && (
        <Confirm
          buttonText={t("view_plans")}
          isCancelRequired={true}
          confirmTitle={t("team_gallery_disable_heading")}
          content={t("team_gallery_disable_content")}
          cancelButtonText={t("ok")}
          onConfirm={handleOpenSubscription}
          onCancel={() => {
            setTeamGalleryDisable(false);
          }}
          isCloseIcon={true}
        />
      )}
      {teamGalleryDisable1 && (
        <Confirm
          buttonText={t("create_team")}
          isCancelRequired={true}
          confirmTitle={t("team_gallery_disable1_heading")}
          content={t("team_gallery_disable1_content")}
          content1={t("team_gallery_disable1_content1")}
          cancelButtonText={t("next_time")}
          onConfirm={handleOpenSubscription1}
          onCancel={() => {
            setTeamGalleryDisable1(false);
          }}
          isCloseIcon={true}
        />
      )}
    </div>
  );
};

export default Gallery;
