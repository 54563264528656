import React from "react";
import "./TooltipBottom.scss";
import { t } from "i18next";

const ModeTooltip = ({ name , tooltipb_content}) => {
  return (
    <div className="tooltipm">
      <div className="tooltipm__container">
        <h5>{name}</h5>
        <p>{tooltipb_content}</p>
      </div>
      <div className="tooltipb__section">
        <div className="tooltipb__arrow"></div>
      </div>
    </div>
  );
};

export default ModeTooltip;
