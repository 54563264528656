import React from "react";

const AiriBotIcon = ({fill, size = "22"}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.14198 9.47848C2.14198 9.90122 2.47817 10.1983 2.81818 10.1983H5.30435C5.76679 10.1983 6.20959 10.3835 6.53207 10.7118L7.20558 11.3975C7.42026 10.7032 8.07246 10.1983 8.84372 10.1983H10.0909C10.4309 10.1983 10.7671 9.90122 10.7671 9.47848V4.85099C10.7671 4.42826 10.4309 4.13116 10.0909 4.13116H2.81818C2.47817 4.13116 2.14198 4.42826 2.14198 4.85099V9.47848ZM19.1818 15.7513C19.5218 15.7513 19.858 15.4542 19.858 15.0315V10.404C19.858 9.98124 19.5218 9.68414 19.1818 9.68414H14.8649C14.7745 11.8403 13.2684 13.6633 11.2329 14.1714V15.0315C11.2329 15.4542 11.5691 15.7513 11.9091 15.7513H13.1563C13.9275 15.7513 14.5797 16.2562 14.7944 16.9505L15.4679 16.2648C15.7904 15.9365 16.2332 15.7513 16.6957 15.7513H19.1818ZM1 9.47848C1 10.4965 1.81818 11.3295 2.81818 11.3295H5.30435C5.4585 11.3295 5.60609 11.3912 5.71359 11.5006L7.2925 13.108C7.64988 13.4719 8.27273 13.2213 8.27273 12.7136V11.8951C8.27273 11.5827 8.52837 11.3295 8.84372 11.3295H10.0909C11.0909 11.3295 11.9091 10.4965 11.9091 9.47848V4.85099C11.9091 3.83295 11.0909 3 10.0909 3H2.81818C1.81818 3 1 3.83295 1 4.85099V9.47848ZM21 15.0315C21 16.0495 20.1818 16.8825 19.1818 16.8825H16.6957C16.5415 16.8825 16.3939 16.9442 16.2864 17.0536L14.7075 18.661C14.3501 19.0249 13.7273 18.7742 13.7273 18.2666V17.448C13.7273 17.1357 13.4716 16.8825 13.1563 16.8825H11.9091C10.9091 16.8825 10.0909 16.0495 10.0909 15.0315V13.746C10.0909 13.4337 10.3483 13.1852 10.6595 13.1349C12.392 12.8549 13.7273 11.3178 13.7273 9.47848V9.11856C13.7273 8.8062 13.9829 8.55298 14.2983 8.55298H19.1818C20.1818 8.55298 21 9.38593 21 10.404V15.0315Z"
        fill={fill}
      />
    </svg>
  );
};

export default AiriBotIcon;
