/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import "./confirm.scss";
import Button from "../button";
import info from "../../assets/images/icons/alert-icon.png";
import closeicon from "../../assets/images/icons/cross.png";

const Confirm = ({
  onCancel,
  onConfirm,
  confirmTitle,
  content,
  content1,
  buttonText,
  className,
  cancelButtonText,
  isCancelRequired,
  isCloseIcon = false
}) => {
  const handleClick = (e) => {
    // if (e?.target.id === "dialog-target") {
    onCancel();
    // }
    return;
  };

  return (
    <div
      className={`confirm ${className}`}
      id="dialog-target"
      onClick={handleClick}
    >
      <div className="confirm__dialog">
        <div
          className="confirm__dialog__center-div"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="confirm__dialog__main">
            <div className={`confirm__dialog__main__heading ${isCloseIcon ? "close" : ""}`}>
              <img src={info} alt="info-icon" />
              <h3 className="confirm__dialog__description">{confirmTitle}</h3>
              {isCloseIcon && <img className="close" src= {closeicon} alt="close-icon" onClick={handleClick} />}
            </div>
            <div className="confirm__dialog__main__content">
               <p
                dangerouslySetInnerHTML={{
                  __html: content?content.replace(/\/n/g, "<br>") : "",
                }}
              ></p>
              {/* <p >{content}</p> */}
              {content1 && <p>{content1}</p>}
            </div>
          </div>
          {isCancelRequired ? (
            <div className="confirm__dialog__buttons">
              <div className="confirm__dialog__buttons__main">
                <Button
                  buttonClick={onCancel}
                  buttonClass="button_link"
                  isBtnDisabled={false}
                  buttonText={cancelButtonText}
                />
                <Button
                  buttonClick={onConfirm}
                  buttonClass="button"
                  isBtnDisabled={false}
                  buttonText={buttonText}
                />
              </div>
            </div>
          ) : (
            <div className="confirm__dialog__button">
              <Button
                buttonClick={onConfirm}
                buttonClass="button"
                isBtnDisabled={false}
                buttonText={buttonText}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Confirm.prototypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmTitle: PropTypes.string,
  buttonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  className: PropTypes.string,
  isCancelRequired: PropTypes.bool,
};

Confirm.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
  confirmTitle: "",
  buttonText: "",
  cancelButtonText: "",
  className: "",
  isCancelRequired: true,
};

export default Confirm;
