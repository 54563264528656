import React, { useEffect, useState } from "react";
import "./TeamNameConfirm.scss";
import info from "../../../assets/images/icons/alert-icon.png";
import { useTranslation } from "react-i18next";
import { Popover } from "antd";

const TeamNameConfirm = ({
  teamName,
  setTeamName,
  onConfirm,
  onCancel,
  exitTeam,
  heading,
  content,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [timer, setTimer] = useState(5);

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer >= 1) {
            return prevTimer - 1;
          } else {
            clearInterval(intervalId);
            return 0;
          }
        });
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [checked]);

  const handleChange = (e) => {
    const sanitizedValue = e.replace(/ {2,}/g, " ");
    setTeamName(sanitizedValue);
  };

  const handleCheck = () => {
    if (teamName !== "") {
      if (exitTeam?.teamName !== teamName) {
        setError(t("team_name_not_match"));
      } else {
        setChecked(true);
        setError(t("team_delete_warn"));
      }
    }
  };

  const handleSubmit = () => {
    if (teamName !== "") {
      if (exitTeam?.teamName !== teamName) {
        setChecked(false);
        setError(t("team_name_not_match"));
      } else {
        onConfirm();
        setChecked(true);
        setError(t("team_delete_warn"));
      }
    }
  };

  const handleOpen = (isOpen) => {
    setOpen(isOpen);
  };

  const isTimer = `${timer} sec`;

  return (
    <div className="team-confirm">
      <div className="team-confirm__container">
        <div className="team-confirm__sections">
          <div className="team-confirm__sections__heading">
            <div className="team-confirm__sections__heading__top">
              <img src={info} alt="info-icon" />
              <h3>{heading}</h3>
            </div>
            <p>{content}</p>
          </div>
          <div className="team-confirm__sections__input">
            <input
              placeholder={t("enter_team_name")}
              value={teamName}
              onChange={(e) => handleChange(e.target.value)}
            />
            {error && <p>{error}</p>}
          </div>
          <div className="team-confirm__sections__buttons">
            <div className="team-confirm__sections__buttons__main">
              <button className="cancel" onClick={() => onCancel()}>
                {t("cancel")}
              </button>
              {teamName === "" || !checked ? (
                <Popover
                  overlayClassName="custom-popover-team"
                  content={t("team_delete_warn")}
                  arrow={true}
                  trigger="hover"
                  placement="top"
                  open={open}
                  onOpenChange={handleOpen}
                >
                  <button
                    onClick={handleCheck}
                    disabled={teamName === "" || timer > 0} 
                  >
                    {timer > 0 ? `${t("check")} (${timer} sec)` : t("check")}
                  </button>
                </Popover>
              ) : (
                <button
                  onClick={timer > 0 ? null : handleSubmit}
                  disabled={teamName === "" || timer > 0 ? true : false}
                >
                  {timer > 0 ? isTimer : t("submit")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamNameConfirm;
