/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import "./AccountStyles.scss";
import userIcon from "../../assets/images/icons/user-icon.png";
import intro from "../../assets/images/icons/intro.png";
import tick from "../../assets/images/icons/tick-sub.png";
import pencil from "../../assets/images/icons/pencil.png";
import gif from "../../assets/images/workspace/upscale-gif.gif";
import info from "../../assets/images/icons/setting.png";
import {
  ChangeProfilePicture,
  EditCurrentUserDetails,
  GetAllSubscriptionAndAddons,
  GetCurrentUserDetails,
  UpdateLanguageAndVersion,
  GetUserStealthData,
  GetUserSubScriptionDetails,
  GetUserSwitchStealth,
} from "../../utils/apiCalls";
import Modal from "react-modal";
import ImageCropperModal from "../../components/cropModal/CropModal";
import Loader from "../../components/loader";
import Confirm from "../../components/confirmModal/confirm";
import { UserContext } from "../../store/UserDetailsStore";
import { useTranslation } from "react-i18next";
import UnCancelPLan from "../../components/SubScriptionPopups/UnCancelPlan/UnCancalPlanPopup";
import Switch from "react-switch";
import AccountTooltip from "../../components/AccountTooltip/AccountTooltip";
import { ResponsiveDataContext } from "../../store/ResponsiveData";
import { ClientDataContext } from "../../store/ClientData";
import { UserSocketContext } from "../../store/UserSocketData";
import { TeamDesignContext } from "../../store/TeamDesignData";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import { sortAddonsByDate } from "../../utils/commonUtils";
const BillingDetails = React.lazy(() =>
  import("../../components/SubScriptionPopups/billing-details/BillingDetails")
);

export const formatUnlimited = (count, threshold = 5000000) => {
  return count >= threshold ? '∞' : count;
};

export function formatMessageUnlimited(point) {
  let formattedMessage = point ? point.trim().replace(/\\n/g, "<br>") : "";
  
  const numberRegex = /\d+/; 
  const numberMatch = formattedMessage.match(numberRegex);
  
  if (numberMatch) {
      const numberValue = parseInt(numberMatch[0], 10);
      const formattedNumber = formatUnlimited(numberValue); 
      //console.log("numberValue:", numberValue);
      //console.log("formattedNumber:", formattedNumber);
      formattedMessage = formattedMessage.replace(numberMatch[0], formattedNumber);
  }
  
  return formattedMessage;
}

const AccountComponent = () => {
  const {
    isLoginUserDetails,
    setIsLoginUserDetails,
    updateLoginUserDetails,
    isPrivilagesDetails,
    isSmallScreen,
  } = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const allSubscriptionsApiRef = useRef(false);
  const generationApiRef = useRef(false);
  const stealthApiRef = useRef(false);
  const { responsiveData } = useContext(ResponsiveDataContext);
  const { clientData } = useContext(ClientDataContext);
  const { updateSocketData, handleShowSubscription } =
    useContext(UserSocketContext);
  const { isUserMembershipDetails, setIsUserMembershipDetails } =
    useContext(UserContext);

  useEffect(() => {
    setUserName(isLoginUserDetails?.name || "");
    setInstitution(isLoginUserDetails?.institution || "");
    setPhoneNumber(isLoginUserDetails?.phoneNumber || "");
    setEmailId(isLoginUserDetails?.email || "");
  }, [isLoginUserDetails]);

  const localStealth = localStorage.getItem("stealth");
  const lang = localStorage.getItem("language");
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const getOldVersion = localStorage.getItem("currentVersion");

  const [userName, setUserName] = useState("");
  const [institution, setInstitution] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("159****1234");
  const [emailId, setEmailId] = useState("");
  const [readOnlyPhone, setReadOnlyPhone] = useState(false);
  const [isLoaderImg, setIsLoaderImg] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isAlertText1, setIsAlertText1] = useState("");
  const [isConfirmModal1, setIsConfirmModal1] = useState(false);
  const [isAlertText, setIsAlertText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedImage, setEditedImage] = useState(null);
  const [languageUpdate, setLanguageUpdate] = useState("");
  const [isBillDetails, setIsBillDetails] = useState(false);
  const [isSubscriptionDetails, setIsSubscriptionDetails] = useState(false);
  const [isCancelPopup, setIsCancelPopup] = useState(false);
  const [isUnCancelPopup, setIsUnCancelPopup] = useState(false);
  const [userSubData, setUserSubData] = useState();
  const [isShowTooltip, setisShowTooltip] = useState({ id: 1, boolean: false });
  const isStealth = localStorage.getItem("stealth");
  const [isCheckedStealth, setIsCheckedStealth] = useState(false);
  const [isChecked, setIsChecked] = useState(
    isStealth === "true" ? true : false
  );
  const [isChecked1, setIsChecked1] = useState(false);

  const baseUrl = window.location.origin;

  useEffect(() => {
    if (!allSubscriptionsApiRef.current) {
      allSubscriptionsApiRef.current = true;
      getAllSubscriptionAndAddons();
    }
  }, []);

  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);

  const handleModifyPhone = () => {
    setReadOnlyPhone(!readOnlyPhone);
  };

  const endDate = isUserMembershipDetails?.membership?.endDate;
  let endSubDate1 = t("account_never");
  let endSubDate = t("account_never");
  if (endDate) {
    const date = new Date(endDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    endSubDate = `${year}/${month}/${day}`;

    const endDate1 = isUserMembershipDetails?.changingTo?.startDate;
    const date1 = new Date(endDate1);
    const year1 = date1.getFullYear();
    const month1 = String(date1.getMonth() + 1).padStart(2, "0");
    const day1 = String(date1.getDate()).padStart(2, "0");
    endSubDate1 = `${year1}/${month1}/${day1}`;
  }

  const handleEditImage = () => {
    setIsModalOpen(true);
  };

  const handleModalSave = (image) => {
    setEditedImage(image);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    UploadNewProfilePicture();
  }, [editedImage]);

  const UploadNewProfilePicture = () => {
    const payload = {
      imageData: editedImage,
    };

    if (editedImage !== null) {
      if (payload) {
        setIsLoaderImg(true);
        ChangeProfilePicture((res) => {
          const { status, data, message } = res;
          if (status === 200) {
            setIsLoaderImg(false);
            setIsConfirmModal(true);
            setEditedImage(null);
            setIsAlertText(t("image_uploaded_successfully"));
            setIsLoginUserDetails((prevState) => ({
              ...prevState,
              avatarUrl: data.imageUrl,
            }));
          } else {
            setIsAlertText(message);
          }
          if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          }
        }, payload);
      }
    }
  };

  useEffect(() => {
    const lang = localStorage.getItem("language");
    setLanguageUpdate(lang);
  }, []);

  const handleEdituserDetails = () => {
    const payload = {
      name: userName,
      phoneNumber: Number(isLoginUserDetails?.phoneNumber),
      email: emailId,
      institution: institution,
    };
    if (payload) {
      EditCurrentUserDetails((res) => {
        const { status, message, data } = res;
        if (status === 200 && message === "Success") {
          GetCurrentUserDetails((res) => {
            const { status, message, data } = res;
            if (status === 200) {
              updateLoginUserDetails(data);
            }
          });
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    }
  };

  const handleBillDetailsPopup = () => {
    setIsBillDetails(!isBillDetails);
  };

  const handleSubscription = () => {
    setIsSubscriptionDetails(!isSubscriptionDetails);
    handleShowSubscription();
  };

  const handleCloseUnCancelPopup = () => {
    setIsUnCancelPopup(!isUnCancelPopup);
  };

  const handleUnCancelPlan = () => {
    handleCloseUnCancelPopup();
  };

  useEffect(() => {
    generationApiRef.current = false;
    if (!generationApiRef.current) {
      generationApiRef.current = true;
      getGenerationDetails();
    }
  }, []);

  const getGenerationDetails = () => {
    const teamId = 0;
    GetUserSubScriptionDetails(async (res) => {
      const data = await res.data;
      setUserSubData(data);
      if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
    }, teamId);
  };

  const handleToggleChange = () => {
    setIsChecked(!isChecked);
  };

  const handleToggleChange1 = () => {
    setIsChecked1(!isChecked1);
  };

  useEffect(() => {
    if (isPrivilagesDetails?.stealthImage === true) {
      if (!stealthApiRef.current) {
        stealthApiRef.current = true;
        getStealthData();
      }
    }
  }, [isPrivilagesDetails?.stealthImage]);

  const getSwitchStealth = () => {
    GetUserSwitchStealth((res) => {
      if (res?.status === 200) {
        getStealthData();
      }
      if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
    });
  };

  const getAllSubscriptionAndAddons = (teamId = 0) => {
    setIsUserMembershipDetails();
    let lang = localStorage.getItem("language") === "ch";
    GetAllSubscriptionAndAddons(
      async (res) => {
        if (res?.status === 200) {
          const data = await res?.data;
          const addons = sortAddonsByDate(data?.addons);
          setIsUserMembershipDetails({...data, addons});
        }
      },
      lang,
      teamId
    );
  };

  const getStealthData = () => {
    const teamId = 0;
    GetUserStealthData((res) => {
      if (res?.status === 200) {
        setIsCheckedStealth(res?.stealth);
        localStorage.setItem("stealth", res.stealth);
      }
      if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
    }, teamId);
  };

  useEffect(() => {
    if (lang === "en") {
      setIsChecked1(false);
    } else {
      setIsChecked1(true);
    }
  }, [lang]);

  useEffect(() => {
    const stealthLocal = localStorage.getItem("stealth");
    if (stealthLocal === null) {
      getStealthData();
    } else if (stealthLocal === undefined) {
      getStealthData();
    }
  }, [isPrivilagesDetails?.stealthImage]);

  const handleSaveSettings = () => {
    if (isChecked1 === false) {
      localStorage.setItem("language", "en");
      i18n.changeLanguage("en");
    } else if (isChecked1 === true) {
      localStorage.setItem("language", "ch");
      i18n.changeLanguage("ch");
    }
    const stealthLocal = localStorage.getItem("stealth");
    if (stealthLocal === "false" && isChecked === true) {
      getSwitchStealth();
    } else if (stealthLocal === "true" && isChecked === false) {
      getSwitchStealth();
    }
    getAllSubscriptionAndAddons();
  };

  useEffect(() => {
    if (localStealth === "true") {
      setIsChecked(true);
    } else if (localStealth === "false") {
      setIsChecked(false);
    } else {
      setIsChecked(false);
    }
  }, [localStealth]);

  const handleShowTooltip = (e) => {
    if (e === isShowTooltip?.id) {
      const newData = {
        id: e,
        boolean: !isShowTooltip?.boolean,
      };
      setisShowTooltip(newData);
    } else if (e !== isShowTooltip?.id) {
      const newData1 = {
        id: e,
        boolean: true,
      };
      setisShowTooltip(newData1);
    }
  };

  const handleCloseTooltip = (e) => {
    if (e === isShowTooltip?.id) {
      const newData = {
        id: e,
        boolean: false,
      };
      setisShowTooltip(newData);
    }
  };

  return (
    <UserPortalLayout>
      <div
        className="account"
        onClick={() => handleCloseTooltip(isShowTooltip?.id)}
      >
        <div className="account__container">
          <div className="account__sections" data-scrollable="true">
            <div className="account__heading">
              <div className="account__heading__section">
                <div className="account__heading__section__image">
                  <img
                    src={isLoginUserDetails?.avatarUrl || userIcon}
                    alt="user-icon"
                  />
                  <img
                    loading="lazy"
                    src={pencil}
                    alt="pencil-icon"
                    onClick={handleEditImage}
                    className="pencil-icon"
                  />
                </div>
                {isModalOpen && (
                  <div className="account__heading__section__edit">
                    <div className="account__heading__section__edit__image">
                      <ImageCropperModal
                        onSave={handleModalSave}
                        onClose={handleModalClose}
                      />
                    </div>
                  </div>
                )}
                <div className="account__heading__section__content">
                  <h4>
                    {isLoginUserDetails?.name
                      ? isLoginUserDetails?.name
                      : "User name"}
                  </h4>
                </div>
              </div>
            </div>
            <div className="account__section-main">
              <div className="account__section">
                <div className="account__section__left">
                  <div className="account__section__left__top">
                    <div className="account__section__left__user">
                      <div className="account__section__left__user__heading">
                        <h3>{t("account")}</h3>
                        <button onClick={handleEdituserDetails}>
                          {t("save")}
                        </button>
                      </div>
                      <div className="account__section__left__user__details">
                        <div className="account__section__left__user__input inputs">
                          <label>{t("user_name")}</label>
                          <input
                            placeholder={t("enter_the_user_name")}
                            value={userName}
                            onChange={(event) => {
                              const sanitizedValue = event.target.value.replace(/ {2,}/g, ' ')
                              setUserName(sanitizedValue);
                            }}
                          ></input>
                        </div>
                        <div className="account__section__left__user__input inputs">
                          <label>{t("user_institution")}</label>
                          <input
                            placeholder={t("enter_the_institution")}
                            value={institution}
                            onChange={(event) =>
                              setInstitution(event.target.value)
                            }
                          ></input>
                        </div>
                        <div className="account__section__left__user__input">
                          <label>{t("user_phone_number")}</label>
                          <div className="account__section__left__user__input__input">
                            {phoneNumber && readOnlyPhone === false ? (
                              <input
                                className="phone"
                                placeholder={t("enter_the_phone_number")}
                                value={
                                  isLoginUserDetails?.phoneNumber
                                    ? isLoginUserDetails?.phoneNumber
                                    : phoneNumber
                                }
                                readOnly
                              ></input>
                            ) : (
                              <input
                                className={`phone ${
                                  readOnlyPhone === true ? "active" : ""
                                }`}
                                placeholder={t("enter_the_phone_number")}
                                value={phoneNumber}
                                maxLength={10}
                                onChange={(event) =>
                                  setPhoneNumber(event.target.value)
                                }
                              ></input>
                            )}
                            <button onClick={handleModifyPhone}>
                              {readOnlyPhone === false
                                ? t("modify")
                                : t("save")}
                            </button>
                          </div>
                        </div>
                        <div className="account__section__left__user__input">
                          <label>{t("user_email")}</label>
                          <input
                            placeholder={t("enter_the_email")}
                            value={emailId}
                            onChange={(event) => setEmailId(event.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="account__section__left__setting">
                      <div className="account__section__left__setting__heading">
                        <h3>{t("settings")}</h3>
                        <button onClick={handleSaveSettings}>
                          {t("save")}
                        </button>
                      </div>
                      <div className="account__section__left__setting__details">
                        <div className="account__section__left__setting__details__sec">
                          <h5>{t("generation_mode")}</h5>
                          {isPrivilagesDetails?.stealthImage === false && (
                            <div className="account__section__left__setting__details__sec__top">
                              <img src={info} alt="info-icon" />
                              <p>{t("generation_mode_content")}</p>
                            </div>
                          )}
                          <div className="account__section__left__setting__details__sec__content">
                            {isPrivilagesDetails && (
                              <div className="account__section__left__setting__details__sec__content__left">
                                <Switch
                                  key={isSmallScreen}
                                  onChange={handleToggleChange}
                                  checked={isPrivilagesDetails?.stealthImage}
                                  disabled={true}
                                  height={isSmallScreen ? 16 : 20}
                                  width={isSmallScreen ? 32 : 38}
                                  handleDiameter={isSmallScreen ? 12 : 16}
                                  offColor="#A8ADAF"
                                  onColor="#000"
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                />
                              </div>
                            )}
                            <div className="account__section__left__setting__details__sec__content__right">
                              {/* {isActiveTeam?.teamId !== 0.1 ? (
                                <>
                                  <h6>{t("Private_mode")}</h6>
                                  <p>{t("Private_mode_content")}</p>
                                </>
                              ) : ( */}
                              <>
                                {isPrivilagesDetails?.stealthImage === true ? (
                                  <>
                                    <h6>{t("Private_mode")}</h6>
                                    <p>{t("Private_mode_content")}</p>
                                  </>
                                ) : (
                                  <>
                                    <h6>{t("Public_mode")}</h6>
                                    <p>{t("Public_mode_content")}</p>
                                  </>
                                )}
                              </>
                            </div>
                          </div>
                        </div>
                        <div className="account__section__left__setting__details__sec">
                          <h5>{t("Language_mode")}</h5>
                          <div className="account__section__left__setting__details__sec__content">
                            <div className="account__section__left__setting__details__sec__content__left">
                              <Switch
                                key={isSmallScreen}
                                onChange={handleToggleChange1}
                                checked={isChecked1}
                                disabled={false}
                                height={isSmallScreen ? 16 : 20}
                                width={isSmallScreen ? 32 : 38}
                                handleDiameter={isSmallScreen ? 12 : 16}
                                offColor="#A8ADAF"
                                onColor="#000"
                                uncheckedIcon={false}
                                checkedIcon={false}
                              />
                            </div>
                            <div className="account__section__left__setting__details__sec__content__right">
                              {localStorage.getItem("language") === "en" ? (
                                <h6>
                                  {isChecked1 === false ? "English" : "中文"}
                                </h6>
                              ) : (
                                <h6>
                                  {isChecked1 === false ? "English" : "中文"}
                                </h6>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="account__section__left__bottom">
                    {clientData?.privacy === true && (
                      <div className="account__section__left__bottom__links">
                        <a href={`${baseUrl}/privacy-policy`} target="_blank">
                          {t("Privacy_policy")}
                        </a>
                        <a
                          href={`${baseUrl}/terms-of-services`}
                          target="_blank"
                        >
                          {t("tos_heading")}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="account__section__right">
                  <div className="account__section__right__subscription">
                    <div className="account__section__right__subscription__heading">
                      <h3>
                        {t("current_active_membership")} / {t("add_on")}{" "}
                      </h3>
                      <button onClick={handleSubscription}>{t("shop")}</button>
                    </div>
                    {isUserMembershipDetails !== undefined ? (
                      <div className="account__section__right__subscription__details">
                        <div className="account__section__right__subscription__details__main">
                          {isUserMembershipDetails?.membership !== null && (
                            <div className="account__section__right__subscription__details__heading">
                              <div className="account__section__right__subscription__details__heading__content">
                                <h5>{t("active_membership & add-ons")}</h5>
                                <span onClick={handleBillDetailsPopup}>
                                  {t("billing_details_and_invoice")}
                                </span>
                              </div>
                            </div>
                          )}
                          {isUserMembershipDetails?.membership !== null ? (
                            <div className="account__section__right__subscription__details__sections">
                              <div className="account__section__right__subscription__details__sections__plan">
                                <div
                                  className="account__section__right__subscription__details__sections__plan__card"
                                  style={{
                                    background:
                                      isUserMembershipDetails?.membership
                                        ?.color,
                                  }}
                                >
                                  <div className="account__section__right__subscription__details__sections__plan__card__heading">
                                    <h3>
                                      {
                                        isUserMembershipDetails?.membership
                                          ?.name
                                      }
                                    </h3>

                                    {isUserMembershipDetails?.changingTo ===
                                    null ? (
                                      <span
                                        className={`${
                                          isUserMembershipDetails?.membership
                                            ?.finalPrice !== 0
                                            ? "border"
                                            : ""
                                        }`}
                                      >
                                        {t("Active")}
                                      </span>
                                    ) : (
                                      <span className="change">
                                        {t("Changing")}
                                      </span>
                                    )}
                                  </div>
                                  <div className="account__section__right__subscription__details__sections__plan__card__content">
                                    <div className="account__section__right__subscription__details__sections__plan__card__content__sec">
                                      <h5>{t("price")}</h5>
                                      <h6>
                                        ￥
                                        {
                                          isUserMembershipDetails?.membership
                                            ?.finalPrice
                                        }
                                        /mo
                                      </h6>
                                    </div>
                                    {isUserMembershipDetails?.membership
                                      ?.name !== `${t("free_plan")}` && (
                                      <div className="account__section__right__subscription__details__sections__plan__card__content__sec">
                                        <h5>{t("billing_period")}</h5>
                                        <h6>
                                          {
                                            isUserMembershipDetails?.membership
                                              ?.category
                                          }
                                        </h6>
                                      </div>
                                    )}
                                    {isUserMembershipDetails?.membership
                                      ?.name !== `${t("free_plan")}` && (
                                      <div className="account__section__right__subscription__details__sections__plan__card__content__sec">
                                        <h5>{t("renewal_date")}</h5>
                                        <h6>
                                          {isUserMembershipDetails?.membership
                                            ?.name !== `${t("free_plan")}`
                                            ? endSubDate
                                            : "account_never"}
                                        </h6>
                                      </div>
                                    )}
                                    {isUserMembershipDetails?.membership
                                      ?.name === `${t("free_plan")}` && (
                                      <div className="account__section__right__subscription__details__sections__plan__card__content__sec">
                                        <h5>{t("expiry_date")}</h5>
                                        <h6>
                                          {isUserMembershipDetails?.membership
                                            ?.name !== `${t("free_plan")}`
                                            ? endSubDate
                                            : "account_never"}
                                        </h6>
                                      </div>
                                    )}
                                    {isUserMembershipDetails?.changingTo !==
                                      null && (
                                      <>
                                        <div className="account__section__right__subscription__details__sections__plan__card__content__sec">
                                          <h5>{t("membership_changing_to")}</h5>
                                          <h6>
                                            {
                                              isUserMembershipDetails
                                                ?.changingTo?.name
                                            }
                                          </h6>
                                        </div>
                                        <div className="account__section__right__subscription__details__sections__plan__card__content__sec">
                                          <h5>{t("Effective_date")}</h5>
                                          <h6>{endSubDate1}</h6>
                                        </div>
                                      </>
                                    )}
                                    {(((isUserMembershipDetails?.membership?.dailyFreeStandardGenerationMaster !== 0)) || 
                                      ((isUserMembershipDetails?.membership?.standardGenerationLifetimeMaster !== 0)) || 
                                       ((isUserMembershipDetails?.membership?.videoQuota !== 0))) && (
                                      <div className="line"></div>
                                    )}
                                    {(isUserMembershipDetails?.membership?.dailyFreeStandardGenerationMaster !== 0) && (
                                      <div className="account__section__right__subscription__details__sections__plan__card__content__sec">
                                        <h5>{t("daily_free_gens")}</h5>
                                        <h6>
                                          {
                                            isUserMembershipDetails?.membership
                                              ?.dailyFreeStandardGeneration
                                          }
                                          /
                                          {
                                            isUserMembershipDetails?.membership
                                              ?.dailyFreeStandardGenerationMaster
                                          }
                                        </h6>
                                      </div>
                                    )}
                                    {(isUserMembershipDetails?.membership?.standardGenerationLifetimeMaster !== 0) && (
                                      <div className="account__section__right__subscription__details__sections__plan__card__content__sec">
                                        <h5>{t("remaining_gens")}</h5>
                                        <h6>
                                          {formatUnlimited(
                                            isUserMembershipDetails?.membership
                                              ?.standardGenerationLifetime
                                          )}
                                          /
                                          {formatUnlimited(
                                            isUserMembershipDetails?.membership
                                              ?.standardGenerationLifetimeMaster
                                          )}
                                        </h6>
                                      </div>
                                    )}
                                    {(isUserMembershipDetails?.membership?.videoQuota !== 0) && (
                                      <div className="account__section__right__subscription__details__sections__plan__card__content__sec">
                                        <h5>{t("remaining_videos")}</h5>
                                        <h6>
                                          {formatUnlimited(
                                            isUserMembershipDetails?.membership
                                              ?.videoQuotaUsed
                                          )}
                                          /
                                          {formatUnlimited(
                                            isUserMembershipDetails?.membership
                                              ?.videoQuota
                                          )}
                                        </h6>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="account__section__right__subscription__details__sections__concurrent">
                                {isUserMembershipDetails?.addons?.length !==
                                  0 &&
                                  isUserMembershipDetails?.addons?.map(
                                    (addon, index) => {
                                      const endDate = addon?.endDate;
                                      let endSubDatAddon;
                                      if (endDate) {
                                        const date = new Date(endDate);
                                        const year = date.getFullYear();
                                        const month = String(
                                          date.getMonth() + 1
                                        ).padStart(2, "0");
                                        const day = String(
                                          date.getDate()
                                        ).padStart(2, "0");
                                        endSubDatAddon = `${year}/${month}/${day}`;
                                      } else {
                                        endSubDatAddon = t("account_never");
                                      }
                                      return (
                                        <div
                                          key={index}
                                          className="account__section__right__subscription__details__sections__concurrent__card"
                                        >
                                          <div className="account__section__right__subscription__details__sections__concurrent__card__heading">
                                            <h3>{addon?.name}</h3>
                                          </div>
                                          <div className="account__section__right__subscription__details__sections__concurrent__card__content">
                                            <div className="account__section__right__subscription__details__sections__concurrent__card__content__sec">
                                              <h5>{t("price")}</h5>
                                              <h6>￥{addon?.finalPrice}/mo</h6>
                                            </div>
                                            <div className="account__section__right__subscription__details__sections__concurrent__card__content__sec">
                                              <h5>{t("expiry_date")}</h5>
                                              <h6>{endSubDatAddon}</h6>
                                            </div>
                                            {/* {((addon?.videoQuota ?? 0) > 0 || (addon?.videoQuotaUsed ?? 0) > 0) && ( // for daily free
                                              <div className="account__section__right__subscription__details__sections__concurrent__card__content__sec">
                                                <h5>{t("555")}</h5>
                                                <h6>
                                                {formatUnlimited( addon?.videoQuotaUsed )}/
                                                {formatUnlimited( addon?.videoQuota )}
                                                </h6>
                                              </div>
                                            )} */}
                                            {((addon?.totalGeneration ?? 0) > 0) && ( //to handle sometimes it is being null or 0
                                              <div className="account__section__right__subscription__details__sections__concurrent__card__content__sec">
                                                <h5>{t("remaining_gens")}</h5>
                                                <h6>
                                                {formatUnlimited( addon?.generationUsed )}/
                                                {formatUnlimited( addon?.totalGeneration )}
                                                </h6>
                                              </div>
                                            )}
                                            {((addon?.videoQuota ?? 0) > 0) && (
                                              <div className="account__section__right__subscription__details__sections__concurrent__card__content__sec">
                                                <h5>{t("remaining_videos")}</h5>
                                                <h6>
                                                {formatUnlimited( addon?.videoQuotaUsed )}/
                                                {formatUnlimited( addon?.videoQuota )}
                                                </h6>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                <div className="account__section__right__subscription__details__sections__privilage">
                                  <div className="account__section__right__subscription__details__sections__privilage__heading">
                                    <h3>{t("my_privileges")}</h3>
                                  </div>
                                  <div className="account__section__right__subscription__details__sections__privilage__card">
                                    <div className="account__section__right__subscription__details__sections__privilage__card__content">
                                      {isUserMembershipDetails?.details
                                        .map((point, index) => {
                                          const formattedMessage = formatMessageUnlimited(point);
                                          return (
                                            <div
                                              key={index}
                                              className="account__section__right__subscription__details__sections__privilage__card__content__sec"
                                            >
                                              <img src={tick} alt="tick-icon" />
                                              <h5
                                                dangerouslySetInnerHTML={{
                                                  __html: formattedMessage,
                                                }}
                                              ></h5>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <p
                              style={{
                                fontSize: "12px",
                                color: "red",
                              }}
                            >
                              {t("no_active_subscription")}
                            </p>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="account__section__right__subscription__gif">
                        <img className="gif" src={gif} alt="gif" />
                      </div>
                    )}
                  </div>
                  <div className="account__section__right__plan">
                    <div className="account__section__right__plan__heading">
                      <h3>{t("usage_details")}</h3>
                    </div>
                    <div className="account__section__right__plan__details">
                      <div className="account__section__right__plan__details__sec">
                        <div className="account__section__right__plan__data">
                          <div className="account__section__right__plan__data__left">
                            <h4>{t("total_number_of_generated_images")}</h4>
                            <div
                              className="account__section__right__plan__data__icon"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <img
                                src={intro}
                                alt="intro-icon"
                                onClick={() => handleShowTooltip(1)}
                              />
                              {!responsiveData && (
                                <AccountTooltip
                                  TooltipContent={t("total_images_tooltip")}
                                  display={{
                                    width: `${
                                      lang === "ch" ? "156px" : "272px"
                                    }`,
                                    left: `${
                                      lang === "ch" ? "-64px" : "-122px"
                                    }`,
                                    display: `${
                                      isShowTooltip.boolean === true &&
                                      isShowTooltip.id === 1
                                        ? "flex"
                                        : "none"
                                    }`,
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <h5>{userSubData?.imageCount}</h5>
                        </div>
                        <div className="account__section__right__plan__data">
                          <div className="account__section__right__plan__data__left">
                            <h4>{t("total_number_of_generated_videos")}</h4>
                            <div
                              className="account__section__right__plan__data__icon"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <img
                                src={intro}
                                alt="intro-icon"
                                onClick={() => handleShowTooltip(6)}
                              />
                              {!responsiveData && (
                                <AccountTooltip
                                  TooltipContent={t("total_videos_tooltip")}
                                  display={{
                                    width: `${
                                      lang === "ch" ? "156px" : "272px"
                                    }`,
                                    left: `${
                                      lang === "ch" ? "-64px" : "-122px"
                                    }`,
                                    display: `${
                                      isShowTooltip.boolean === true &&
                                      isShowTooltip.id === 6
                                        ? "flex"
                                        : "none"
                                    }`,
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <h5>{userSubData?.videoCount}</h5>
                        </div>
                        <div className="account__section__right__plan__data">
                          <div className="account__section__right__plan__data__left">
                            <h4>{t("gpt_number_of_dialogs")}</h4>
                            <div
                              className="account__section__right__plan__data__icon"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <img
                                src={intro}
                                alt="intro-icon"
                                onClick={() => handleShowTooltip(2)}
                              />
                              {!responsiveData && (
                                <AccountTooltip
                                  TooltipContent={t("gpt_dialog_tooltip")}
                                  display={{
                                    width: `${
                                      lang === "ch" ? "156px" : "228px"
                                    }`,
                                    left: `${
                                      lang === "ch" ? "-64px" : "-100px"
                                    }`,
                                    display: `${
                                      isShowTooltip.boolean === true &&
                                      isShowTooltip.id === 2
                                        ? "flex"
                                        : "none"
                                    }`,
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <h5>{userSubData?.gptCount}</h5>
                        </div>
                      </div>
                      <div className="account__section__right__plan__details__sec">
                        <div className="account__section__right__plan__data">
                          <div className="account__section__right__plan__data__left">
                            <h4>{t("current_projects")}</h4>
                            <div
                              className="account__section__right__plan__data__icon"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <img
                                src={intro}
                                alt="intro-icon"
                                onClick={() => handleShowTooltip(3)}
                              />
                              {!responsiveData && (
                                <AccountTooltip
                                  TooltipContent={t("project_total_tooltip")}
                                  display={{
                                    width: `${
                                      lang === "ch" ? "192px" : "312px"
                                    }`,
                                    left: `${
                                      lang === "ch" ? "-82px" : "-142px"
                                    }`,
                                    display: `${
                                      isShowTooltip.boolean === true &&
                                      isShowTooltip.id === 3
                                        ? "flex"
                                        : "none"
                                    }`,
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <h5>{userSubData?.projectCount}</h5>
                        </div>
                        <div className="account__section__right__plan__data">
                          <div className="account__section__right__plan__data__left">
                            <h4>
                              {t("image_total_capacity")} / {t("mb")}
                            </h4>
                            <div
                              className="account__section__right__plan__data__icon"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <img
                                src={intro}
                                alt="intro-icon"
                                onClick={() => handleShowTooltip(4)}
                              />
                              {!responsiveData && (
                                <AccountTooltip
                                  TooltipContent={t("capacity_tooltip")}
                                  display={{
                                    width: `${
                                      lang === "ch" ? "150px" : "222px"
                                    }`,
                                    left: `${
                                      lang === "ch" ? "-60px" : "-96px"
                                    }`,
                                    display: `${
                                      isShowTooltip.boolean === true &&
                                      isShowTooltip.id === 4
                                        ? "flex"
                                        : "none"
                                    }`,
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <h5>{userSubData?.imageSize}</h5>
                        </div>
                      </div>
                      <div className="account__section__right__plan__details__sec">
                        <div className="account__section__right__plan__data">
                          <div className="account__section__right__plan__data__left">
                            <h4>
                              {t("days_since_registration")} / {t("days")}
                            </h4>
                            <div
                              className="account__section__right__plan__data__icon"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <img
                                src={intro}
                                alt="intro-icon"
                                onClick={() => handleShowTooltip(5)}
                              />
                              {!responsiveData && (
                                <AccountTooltip
                                  TooltipContent={t("total_days_tooltip")}
                                  display={{
                                    width: `${
                                      lang === "ch" ? "194px" : "224px"
                                    }`,
                                    left: `${
                                      lang === "ch" ? "-82px" : "-98px"
                                    }`,
                                    display: `${
                                      isShowTooltip.boolean === true &&
                                      isShowTooltip.id === 5
                                        ? "flex"
                                        : "none"
                                    }`,
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <h5>{userSubData?.totalDays}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="account__section__left__bottom1">
                    {clientData?.privacy === true && (
                      <div className="account__section__left__bottom__links">
                        <a href={`${baseUrl}/privacy-policy`} target="_blank">
                          {t("Privacy_policy")}
                        </a>
                        <a
                          href={`${baseUrl}/terms-of-services`}
                          target="_blank"
                        >
                          {t("tos_heading")}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isBillDetails && (
          <React.Suspense fallback={<div>Loading...</div>}>
            <BillingDetails billDetailsPopup={handleBillDetailsPopup} />
          </React.Suspense>
        )}
        {isCancelPopup && (
          <UnCancelPLan
            handleCloseUnCancelPopup={handleCloseUnCancelPopup}
            handleUnCancelPlan={handleUnCancelPlan}
          />
        )}
        {isConfirmModal && (
          <Confirm
            buttonText={"OK"}
            isCancelRequired={false}
            confirmTitle={isAlertText}
            onConfirm={() => {
              setIsConfirmModal(false);
            }}
            onCancel={() => {
              setIsConfirmModal(false);
            }}
          />
        )}
        {isConfirmModal1 && (
          <Confirm
            buttonText={"OK"}
            isCancelRequired={false}
            confirmTitle={isAlertText1}
            onConfirm={() => {
              setIsConfirmModal1(false);
            }}
            onCancel={() => {
              setIsConfirmModal1(false);
            }}
          />
        )}
        {isLoaderImg ? <Loader /> : ""}
      </div>
    </UserPortalLayout>
  );
};

export default AccountComponent;
