import React, { useContext, useEffect, useState } from "react";
import "./EditProject.scss";
import { useTranslation } from "react-i18next";
import Button from "../../button";
import { CreateOrUpdateProject } from "../../../utils/apiCalls";
import { UserSocketContext } from "../../../store/UserSocketData";
import { processText } from "../../../utils/commonUtils";

const EditProject = ({
  project,
  onClose,
  setIsLoader1,
  isLoader1,
  setFilterProjectsList,
  filterProjectsList,
}) => {
  const { t } = useTranslation();
  const { updateSocketData } = useContext(UserSocketContext);
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const [projectName, setProjectName] = useState("");
  const [remaining, setRemaining] = useState(0);
  const [error, setError] = useState("");
  const maxLimit = 24;

  useEffect(() => {
    if (project) {
      handleDefaultState(project?.name);
    }
  }, []);

  const handleDefaultState = (e) => {
    setError("");
    const sanitizedValue = e.replace(/ {2,}/g, ' ');
    const { truncatedText, remainingChars } = processText(sanitizedValue, maxLimit, 1);
    setProjectName(truncatedText);
    setRemaining(Math.max(0, remainingChars));
  };

  const handleChange = (e) => {
    const inputElement = e.target;
    const originalValue = inputElement.value;
    setError("");

    const cursorPosition = inputElement.selectionStart;
    const sanitizedValue = originalValue.replace(/ {2,}/g, ' ');
    const lengthDifference = originalValue.length - sanitizedValue.length;
    const adjustedCursorPosition = cursorPosition - lengthDifference;
  
    const { truncatedText, remainingChars } = processText(sanitizedValue, maxLimit, 1);
    setProjectName(truncatedText);
    setRemaining(Math.max(0, remainingChars));
  
    const finalCursorPosition = Math.min(adjustedCursorPosition, truncatedText.length);
    setTimeout(() => {
      inputElement.setSelectionRange(finalCursorPosition, finalCursorPosition);
    }, 0);
  };

  const handleConfirm = () => {
    setIsLoader1(true);
    const isDuplicate = filterProjectsList?.some(
      (pro) => pro?.name === projectName
    );
    if (isDuplicate) {
      setError(t("project_name_duplicated"));
      setIsLoader1(false);
      return;
    }

    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    const payload = {
      id: project && project?.id,
      name: projectName,
      teamId: teamId,
    };
    const payload1 = {
      name: projectName,
      teamId: teamId,
    };
    const payloadData = project !== null ? payload : payload1;
    setIsLoader1(true);
    if (payloadData) {
      CreateOrUpdateProject((res) => {
        if (res?.status === 200) {
          const repalaceProjectList = filterProjectsList?.filter(
            (pro) => pro?.id !== project?.id
          );
          const projectIndex = filterProjectsList?.findIndex(
            (pro) => pro?.id === project?.id
          );
          if (projectIndex !== -1) {
            const updatedProject = {
              ...filterProjectsList[projectIndex],
              name: res?.data?.name,
            };
            repalaceProjectList.splice(projectIndex, 0, updatedProject);
          }
          setFilterProjectsList(repalaceProjectList);
          setProjectName(res?.data?.name);
          setIsLoader1(false);
          onClose();
        } else if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        } else {
          setIsLoader1(false);
          onClose();
        }
      }, payloadData);
    }
  };

  return (
    <div className="project-edit">
      <div className="project-edit__main" onClick={(e) => e.stopPropagation()}>
        <div className="project-edit__main__container">
          <div className="project-edit__main__container__heading">
            <h2>
              {project !== null ? t("edit_project") : t("create_project")}
            </h2>
          </div>
          <div className="project-edit__main__container__content">
            <h4>{t("project_name")}</h4>
            <div className="project-edit__main__container__content__input">
              <input
                value={projectName}
                onChange={(e) => {handleChange(e)}}
                placeholder={t("enter_project_name")}
              />
              <h6 style={{ color: remaining === maxLimit && "red" }}>
                <span>{remaining}</span> / {maxLimit}
              </h6>
              {error !== "" && <p>{error}</p>}
            </div>
          </div>
          <div className="project-edit__main__container__buttons">
            <div className="project-edit__main__container__buttons__main">
              <Button
                buttonClick={onClose}
                buttonClass={"button_link"}
                isBtnDisabled={false}
                buttonText={t("cancel")}
              />
              <Button
                buttonClick={handleConfirm}
                buttonClass="button"
                isBtnDisabled={isLoader1 ? true : false}
                buttonText={t("confirm")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProject;
