/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect, useRef } from "react";
import { GetCurrentAdminDetails } from "../utils/apiCalls";

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [isAdminDetails, setIsAdminDetails] = useState(null);
  const token = localStorage.getItem("admintoken");
  const apiCalledRef = useRef(false);

  useEffect(() => {
    if (!apiCalledRef.current && token !== null && token !== undefined) {
      apiCalledRef.current = true;
      getAdminDetails();
    }
  }, [token]);

  const getAdminDetails = () => {
    GetCurrentAdminDetails((res) => {
      if (res?.status === 200) {
        setIsAdminDetails(res?.data);
      }
    });
  };

  const updateAdminDetails = (newData) => {
    setIsAdminDetails((prevData) => ({ ...prevData, ...newData }));
  };

  const clearAdminDetails = () => {
    setIsAdminDetails(null);
  };

  return (
    <AdminContext.Provider
      value={{
        isAdminDetails,
        setIsAdminDetails,
        updateAdminDetails,
        clearAdminDetails,
        getAdminDetails,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
