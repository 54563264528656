/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import "./AddonPopup.scss";
import cross from "../../../assets/images/workspace/icons/cross-icon.png";
import method from "../../../assets/images/icons/payment-method.png";
import gif from "../../../assets/images/workspace/upscale-gif.gif";
import { useTranslation } from "react-i18next";
import { GetAddonSitchDetails, MakeNewPayment } from "../../../utils/apiCalls";
import BeforePayment from "../BeforePayment/BeforePayment";
import { UserSocketContext } from "../../../store/UserSocketData";

const AddonPopup = ({
  isAddonId,
  handleBuyAddon,
  closeAddOn,
  handleAddonBuy,
  setIsMessageTitle,
  setIsMessageContent,
  setIsConfirmModal,
}) => {
  const { t } = useTranslation();
  const addonApipiRef = useRef(false);
  const { updateSocketData } = useContext(UserSocketContext);
  let lang = localStorage.getItem("language") === "ch";
  const [isAddonDetails, setIsAddonDetails] = useState(null);
  const [isAddonPayment, setIsAddonPayment] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [paymentData, setPaymentData] = useState();
  const [isPaymentVersion, setIsPaymentVersion] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const baseUrl = window.location.origin;

  useEffect(() => {
    if (!addonApipiRef.current) {
      addonApipiRef.current = true;
      handleAddon();
    }
  }, []);

  const handleAddon = () => {
    setIsLoader(true);
    GetAddonSitchDetails(
      (res) => {
        if (res?.status === 200) {
          setIsAddonDetails(res.data);
          setIsAddonPayment(res.payment);
          setIsLoader(false);
        } else if (res?.status === 400) {
          setIsMessageTitle(t("permission_denied"));
          switch (res?.message) {
            case "Special Addon already exists":
              setIsMessageContent(t("only_one_specialAddon_at_a_time"));
              break;
            case "You cannot purchase this special addon as you already have a high tier membership plan":
              setIsMessageContent(t("not_available_for_corporate_plans"));
              break;
            default:
              setIsMessageContent(res?.message);
          }
          closeAddOn();
          setIsConfirmModal(true);
        } else {
          setIsLoader(false);
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      },
      isAddonId,
      lang
    );
  };

  const handlePayment = () => {
    const price = isAddonDetails?.amountDue;
    const addonId = isAddonId;
    const membershipId = 0;
    const isUpgrade = null;
    const teamId = 0;
    MakeNewPayment(
      (res) => {
        if (res.status === 200) {
          setPaymentData(res.data);
          setIsPaymentVersion(true);
          setIsButtonDisable(true);
          setIsError(false);
        } else if (res?.status === 202) {
          setIsError(true);
        }
      },
      price,
      addonId,
      membershipId,
      isUpgrade,
      teamId
    );
  };

  useEffect(() => {
    if (isPaymentVersion === true) {
      setTimeout(() => {
        setIsButtonDisable(false);
      }, 5000);
    }
  }, [isPaymentVersion]);

  const handleClose = () => {
    setIsPaymentVersion(false);
  };

  const handleRedirect = () => {
    setIsPaymentVersion(false);
    window.location.href = paymentData;
  };

  const fromDate = new Date(isAddonDetails?.fromPeriod);
  const year = fromDate.getFullYear();
  const month = (fromDate.getMonth() + 1).toString().padStart(2, "0");
  const day = fromDate.getDate().toString().padStart(2, "0");

  const formatFromDate = `${year}-${month}-${day}`;

  const toDate = new Date(isAddonDetails?.toPeriod);
  const year1 = toDate.getFullYear();
  const month1 = (toDate.getMonth() + 1).toString().padStart(2, "0");
  const day1 = toDate.getDate().toString().padStart(2, "0");

  const formatToDate = `${year1}-${month1}-${day1}`;

  const months = isAddonDetails?.duration / 30;
  const days = isAddonDetails?.duration % 30;

  const handleBuy = () => {
    handleAddonBuy(isAddonId);
  };

  return (
    <div className="addon-popup" onClick={() => closeAddOn()}>
      <div className="addon-popup__popup" onClick={(e) => e.stopPropagation()}>
        <div className="addon-popup__popup__container">
          <div className="addon-popup__popup__section">
            <div className="addon-popup__popup__section__header">
              <h3>{t("Purchase plan")}</h3>
              <img
                className="cross"
                src={cross}
                alt="cross-icon"
                onClick={() => handleBuyAddon()}
              />
            </div>
            {isAddonDetails !== null ? (
              <>
                <div className="addon-popup__popup__section__plans">
                  <div className="addon-popup__popup__section__plan">
                    <h4>{isAddonDetails?.planName}</h4>
                    <div className="addon-popup__popup__section__plan__details">
                      <h6>{t("Base_price")}</h6>
                      {isAddonDetails?.duration !== -1 ? (
                        <h5>
                          ￥{isAddonDetails?.basePrice} for{" "}
                          {months > 1
                            ? `${months} ${t("months")}`
                            : days > 0
                            ? `${days} ${t("days")}`
                            : `${t("less_than_a_month")}`}
                        </h5>
                      ) : (
                        <h5>
                          ￥{isAddonDetails?.basePrice} {t("Lifetime")}
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
                <div className="addon-popup__popup__section__quantity">
                  <div className="addon-popup__popup__section__quantity__sec">
                    <h5>{t("quantity")}</h5>
                    <h6>{isAddonDetails?.Quantity}</h6>
                  </div>
                  <div className="addon-popup__popup__section__quantity__sec">
                    <h5>{t("active_period")}</h5>
                    {isAddonDetails?.toPeriod !== null ? (
                      <h6>
                        <span>from</span> {formatFromDate} <span>to</span>{" "}
                        {formatToDate}
                      </h6>
                    ) : (
                      <h6>{formatFromDate}</h6>
                    )}
                  </div>
                  <div className="addon-popup__popup__section__quantity__sec">
                    <h5>{t("payment_method")}</h5>
                    <img src={method} alt="payment-method" />
                  </div>
                </div>
                <div className="addon-popup__popup__section__amount">
                  <div className="addon-popup__popup__section__amount__date">
                    <h6>{t("amount_due")}</h6>
                  </div>
                  <h5>￥ {isAddonDetails?.amountDue}</h5>
                </div>
                <div className="addon-popup__popup__section__buttons">
                  <div className="addon-popup__popup__section__buttons__section">
                    <button
                      disabled={isChecked === true ? false : true}
                      onClick={
                        isAddonPayment === true ? handlePayment : handleBuy
                      }
                    >
                      {t("submit_order")}
                    </button>
                    {isError === true && <p>{t("payment_10min")}</p>}
                  </div>
                </div>
                <div
                  className="addon-popup__popup__section__agree"
                  style={{
                    width: `${lang === true ? "100%" : "auto"}`,
                  }}
                >
                  <div
                    className="addon-popup__popup__section__agree__main"
                    style={{
                      width: `${lang === true ? "100%" : "calc(100% - 48px)"}`,
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={() => setIsChecked(!isChecked)}
                    />
                    <p>
                      {t("i_have_read")}{" "}
                      <a
                        href={`${baseUrl}/terms-of-services`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("terms_of_service")}
                      </a>{" "}
                      {t("and")}{" "}
                      <a
                        href={`${baseUrl}/renewal-policy`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("auto_renewal")}
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <div className="gif-icon">
                {isLoader === true && (
                  <img className="gif" src={gif} alt="gif" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isPaymentVersion && (
        <BeforePayment
          handleClose={handleClose}
          handleRedirect={handleRedirect}
          isButtonDisable={isButtonDisable}
        />
      )}
    </div>
  );
};

export default AddonPopup;
