import React from "react";
import "./RenewPopup.scss";
import info from "../../assets/images/icons/alert-icon.png";
import cross from "../../assets/images/workspace/icons/cross-icon.png";
import { useTranslation } from "react-i18next";

const RenewPopup = ({ handleShowRenew, handleSubscription }) => {
  const { t } = useTranslation();

  const handleSetSubscription = () => {
    handleSubscription();
  };

  return (
    <div className="sucess" onClick={handleShowRenew}>
      <div className="sucess__popup">
        <div
          className="sucess__popup__container"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="sucess__popup__section">
            <div className="sucess__popup__section__header">
              <img src={info} alt="info-icon" />
              <h3>{t("benifits_end_heading")}</h3>
              <img
                className="cross"
                src={cross}
                alt="cross-icon"
                onClick={handleShowRenew}
              />
            </div>
            <div className="sucess__popup__section__content">
              <p>{t("benifits_end_content")}</p>
            </div>
            <div className="access__popup__section__buttons">
              <div className="access__popup__section__buttons__section">
                <button onClick={handleShowRenew} className="cancel">
                  {t("ok")}
                </button>
                <button onClick={handleSetSubscription}>
                  {t("renew")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenewPopup;
