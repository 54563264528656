import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

const BarChart = ({ data }) => {
  const options = {
    chart: {
      type: "line",
      zooming: {
        type: "x",
      },
    },
    title: {
      text: "Infography",
    },
    xAxis: {
      categories: data?.categories,
    },
    yAxis: {
      title: {
        text: "GPU Time in Seconds",
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      valueSuffix: "sec",
      xDateFormat: "%A, %b %e",
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: true,
      },
    },
    series: [
      {
        name: "GPU record created date",
        data: data?.seconds,
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BarChart;
