import React from "react";
import "./HeighlightedFeatures.scss";
import backarrow from "../../../assets/images/icons/back-arrow.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const HeighlightedFeatures = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleSignIn = () => {
    const data = {
      heading: "| Register / Login",
      icon: backarrow,
      hide: "",
    };
    navigate("/sign-in", { state: data });
  };

  return (
    <div className="features">
      <div className="features__container main-feat">
        <div className="features__container__left">
          <div className="features__container__left__image">
            <video
              ref={data?.ref}
              // controls
              autoPlay
              loop
              // muted
              playsInline
              className="instruct__element"
            >
              <source src={data?.video} type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="features__container__right">
          <div className="features__container__right__content">
            <div className="features__container__right__content__heading">
              <img src={data?.icon} alt={data?.heading} />
              <h3 className="heading">{data?.heading}</h3>
              <h6>{data?.sub_heading}</h6>
            </div>
            <p>{data?.content}</p>
          </div>
          {!token && (
            <div className="features__container__right__button main-button">
              <button onClick={handleSignIn}>{t("try_it")}</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeighlightedFeatures;
