import React, { useEffect, useState } from "react";
import "./BeforePayment.scss";
import Button from "../../button";
import info from "../../../assets/images/icons/alert-icon.png";
import { useTranslation } from "react-i18next";

const BeforePayment = ({ handleClose, handleRedirect, isButtonDisable }) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(5);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount > 0) {
          return prevCount - 1;
        } else {
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="before" onClick={handleClose}>
      <div className="before__dialog" onClick={(e) => e.stopPropagation()}>
        <div className="before__dialog__center-div">
          <div className="before__dialog__main">
            <div className="before__dialog__main__heading">
              <img src={info} alt="info-icon" />
              <h3 className="before__dialog__description">
                {t("purchase_heading")}
              </h3>
            </div>
            <div className="before__dialog__main__content">
              <p>
                {t("purchase_content1")}
                <b>{t("purchase_content1.1")} </b>
                {t("purchase_content1.2")}
              </p>
              <p>{t("purchase_content2")}</p>
            </div>
          </div>
          <div className="before__dialog__button">
            {isButtonDisable === true ? (
              <button disabled={true}>
                {t("ok")} {count}
              </button>
            ) : (
              <Button
                buttonClick={handleRedirect}
                buttonClass="button"
                isBtnDisabled={false}
                buttonText={t("ok")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeforePayment;
