import React from "react";
import "./ScannerTooltip.scss";

const SacnnerTooltip = ({
  tooltipContent,
  image,
  left = "-60px",
  bottom = "30px",
}) => {
  return (
    <div className="scanner-tooltip" style={{ left: left, bottom: bottom }}>
      <div className="scanner-tooltip__container">
        <p>{tooltipContent}</p>
        <img src={image} alt="scanner" />
      </div>
      <div className="scanner-tooltip__section">
        <div className="scanner-tooltip__arrow"></div>
      </div>
    </div>
  );
};

export default SacnnerTooltip;
