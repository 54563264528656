/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import "./App.scss";
import RouterComopnent from "./App/RouterComponent/RouterComopnent";
import ComingSoon from "./App/components/ComingSoon/ComingSoon";
import { App as AppAnt } from "antd"; //https://ant.design/docs/react/compatible-style  https://blog.csdn.net/BWater_monster/article/details/128722895
import {
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from "@ant-design/cssinjs";
import { BrowserRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();
  const langnuage = localStorage.getItem("language");

  useEffect(() => {
    getIpInfo();
  }, []);

  async function getIpInfo() {
    const url = "https://ipapi.co/json";

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (langnuage === undefined || langnuage === null) {
        if (data?.country_name === "China") {
          localStorage.setItem("language", "ch");
          i18n.changeLanguage("ch");
        } else {
          localStorage.setItem("language", "en");
          i18n.changeLanguage("en");
        }
      }
    } catch (error) {
      if (langnuage === undefined || langnuage === null) {
        localStorage.setItem("language", "en");
        i18n.changeLanguage("en");
      }
      console.error("Error fetching IP information:", error);
    }
  }

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    let startX = null;

    const handleTouchStart = (e) => {
      startX = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
      if (!startX) {
        return;
      }

      let xCurrent = e.touches[0].clientX;
      let xDiff = startX - xCurrent;

      // Check if the touch event is within a draggable image or its container
      const inDraggableImage = e.target.closest('[data-draggable="true"]');
      const isScrollable = e.target.closest('[data-scrollable="true"]');

      if (!inDraggableImage && !isScrollable) {
        //these don't need drag and drop or scrolling
        e.preventDefault();
      } else if (Math.abs(xDiff) > window.innerWidth * 0.08) {
        console.log(Math.abs(xDiff), window.innerWidth);
        e.preventDefault();
      }
    };

    // Add touch event listeners
    window.addEventListener("touchmove", handleTouchMove, { passive: false });
    window.addEventListener("touchstart", handleTouchStart, false);

    // Cleanup the event listeners
    return () => {
      window.removeEventListener("touchmove", handleTouchMove);
      window.addEventListener("touchstart", handleTouchStart, false);
    };
  }, []);

  useEffect(() => {
    const updateVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    updateVH(); // Set the viewport height on initial load

    // Set up event listeners for resizing the window
    window.addEventListener("resize", updateVH);
    window.addEventListener("orientationchange", updateVH);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("resize", updateVH);
      window.removeEventListener("orientationchange", updateVH);
    };
  }, []);

  return (
    <StyleProvider
      hashPriority="high"
      transformers={[legacyLogicalPropertiesTransformer]}
    >
      <AppAnt>
        <div className="app">
          <div className="app__router">
            <BrowserRouter>
              <RouterComopnent />
            </BrowserRouter>
          </div>
          <div className="app__coming">
            <ComingSoon />
          </div>
        </div>
      </AppAnt>
    </StyleProvider>
  );
}

export default App;
