/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useMemo
} from "react";
import "./CommunityGallery.scss";
import SideNavComponent from "../../components/SideNav/SideNavComponent";
import downarrow from "../../assets/images/chatgpt/down-arrow.png";
import gif from "../../assets/images/workspace/upscale-gif.gif";
import fav from "../../assets/images/workspace/icons/feed-fav.png";
import fav1 from "../../assets/images/workspace/icons/feed-fav1.png";
import fav2 from "../../assets/images/workspace/icons/feed-fav2.png";
import { UserContext } from "../../store/UserDetailsStore";
import { useTranslation } from "react-i18next";
import { GetAllImagesCommunityGalleryMongo } from "../../utils/apiCalls";
import PLanEnd from "../../components/plan-end/PLanEnd";
import WorkSpaceFeedPopUp from "../../components/WorkspaceComponents/workspacefeedpopup/WorkSpaceFeedPopUp";
import GalleryHeader from "../../components/GalleryHeader/GalleryHeader";
import { UserSocketContext } from "../../store/UserSocketData";
import { TeamDesignContext } from "../../store/TeamDesignData";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import FavoriteTooltip from "../../components/Tooltips/FavotiteTooltip";
import { ResponsiveDataContext } from "../../store/ResponsiveData";
import Confirm from "../../components/confirmModal/confirm";
import { useNavigate } from "react-router-dom";
import videoGen from "../../assets/images/workspace/icons/video-gen.png";

const CommunityGallery = ({
  isActiveTab,
  teamGalleryDisable,
  setTeamGalleryDisable,
  teamGalleryDisable1,
  setTeamGalleryDisable1,
}) => {
  const { updateSocketData, handleShowSubscription } =
    useContext(UserSocketContext);
  const { isTeamChanges } = useContext(TeamDesignContext);
  const { responsiveData, screenWidth } = useContext(ResponsiveDataContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const galleryApiRef = useRef(false);
  const { isPrivilagesDetails } = useContext(UserContext);
  const [isPageIndex, setIsPageIndex] = useState(0);
  const [isPageSize, setIsPageSize] = useState(40);
  const [isGalleryImages, setIsGalleryImages] = useState([]);
  const [isGalleryNewImages, setIsGalleryNewImages] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [galleryPopupData, setGalleryPopupData] = useState([]);
  const [isGalleryCurrentIndex, setIsGalleryCurrentIndex] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [allImagesFetched, setAllImagesFetched] = useState(false);
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [isGptActive, setIsGptActive] = useState(false);
  const [isGptActivePopup, setIsGptActivePopup] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [searchOptions, setSearchOptions] = useState({ valueString: "" });
  const [activeTab, setActiveTab] = useState("all");
  const [loadedImages, setLoadedImages] = useState({});
  const [isFavoriteImages, setIsFavoriteImages] = useState({});
  const [isDisable, setIsDiable] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isPrivilagesDetails?.communityGallery === false) {
      setIsGptActive(true);
    }
  }, [isPrivilagesDetails]);

  const handleCloseAccessPopup = () => {
    setIsGptActivePopup(!isGptActivePopup);
  };

  const handleSubscription = () => {
    handleShowSubscription();
    setIsGptActivePopup(false);
  };

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setShowScrollArrow(true);
    } else {
      setShowScrollArrow(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const handlePopup = (image, index) => {
    if (isPrivilagesDetails?.communityGallery === false) {
      setIsGptActivePopup(true);
    } else {
      handlePopupOpen();
      setIsGalleryCurrentIndex(index);
      const createdDate = new Date(image?.created);
      const day = String(createdDate.getDate()).padStart(2, "0");
      const month = String(createdDate.getMonth() + 1).padStart(2, "0");
      const year = createdDate.getFullYear();
      const hours = String(createdDate.getHours()).padStart(2, "0");
      const minutes = String(createdDate.getMinutes()).padStart(2, "0");
      const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}`;

      setGalleryPopupData({
        ...image,
        created: formattedDate,
      });
      document.body.classList.add("popup-open");
    }
  };

  useEffect(() => {
    if (isGalleryCurrentIndex >= 0 && isGalleryCurrentIndex < isGalleryImages.length) {
      const currentImage = isGalleryImages[isGalleryCurrentIndex];
      const imageElement = new Image();
      imageElement.src = currentImage.baseImage || currentImage.url;
    }
  }, [isGalleryCurrentIndex]);

  const handlePopupOpen = () =>{
    setShowPopup(true);
  }
  
  const handlePopupclose = () => {
    setShowPopup(false);
    document.body.classList.remove("popup-open");
  };

  useEffect(() => {
    setIsGalleryImages([]);
    setDataNotFound(true);
    setTimeout(() => {
      const delay = isGalleryImages?.length !== 0 ? 10 : 0;
      let timeoutId;

      const debounceSearch = () => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          setAllImagesFetched(false);
          galleryApiRef.current = false;
          getAllCommunityGalleryImages();
        }, delay);
      };

      debounceSearch();

      return () => {
        clearTimeout(timeoutId);
      };
    }, 0);
  }, [searchOptions, activeTab, isTeamChanges]);

  const getAllCommunityGalleryImages = () => {
    // if (!allImagesFetched) {
    if (!galleryApiRef.current) {
      galleryApiRef.current = true;
      setDataNotFound(true);
      let searchData = searchOptions;
      if (activeTab !== "all" && activeTab !== "favorite") {
        const currentValues = searchOptions?.valueString?.split(",");
        if (!currentValues?.includes("upscale")) {
          if (currentValues[0] !== "") {
            const newValues = [...currentValues, "upscale"];
            searchData = { valueString: newValues?.join(",") };
          } else {
            const newValues = ["upscale"];
            searchData = { valueString: newValues?.join(",") };
          }
        }
      }
      const data = localStorage.getItem("language");
      const payload = {
        pageIndex: 0,
        pageSize: isPageSize,
        searchString: searchData?.valueString,
        chinese: data === "ch" ? true : false,
        isFavorite: activeTab === "favorite" ? true : false,
      };
      if (payload) {
        setIsGalleryImages([]);
        GetAllImagesCommunityGalleryMongo((res) => {
          if (res.status === 200) {
            setDataNotFound(false);
            const newImages = res?.projectMedias || [];
            if (newImages.length === 0) {
              setAllImagesFetched(true);
            }
            setIsGalleryImages(newImages);
          } else {
            setDataNotFound(false);
          }
          if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          }
        }, payload);
      }
    }
    // }
  };

  const checkAllImagesLoaded = useCallback(() => {
    const images = document.querySelectorAll(".column-img");
    for (const image of images) {
      if (!image?.complete) {
        return false;
      }
    }
    return true;
  }, []);

  const getAllCommunityGalleryImages1 = (isLoading) => {
    if (!allImagesFetched && !isLoading) {
      isLoading = true;
      setLoading(true);

      const data = localStorage.getItem("language");
      const teamId = 0;
      let searchData = searchOptions;
      if (activeTab !== "all" && activeTab !== "favorite") {
        const currentValues = searchOptions?.valueString?.split(",");
        if (!currentValues?.includes("upscale")) {
          if (currentValues[0] !== "") {
            const newValues = [...currentValues, "upscale"];
            searchData = { valueString: newValues?.join(",") };
          } else {
            const newValues = ["upscale"];
            searchData = { valueString: newValues?.join(",") };
          }
        }
      }
      const index = Math.round(isGalleryImages?.length / 40);
      const payload = {
        pageIndex: index ?? 0,
        pageSize: isPageSize,
        searchString: searchData?.valueString,
        chinese: data === "ch" ? true : false,
        isFavorite: activeTab === "favorite" ? true : false,
      };
      if (payload) {
        GetAllImagesCommunityGalleryMongo((res) => {
          if (res?.status === 200) {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            const newImages = res?.projectMedias || [];
            if (newImages.length === 0) {
              setAllImagesFetched(true);
            }
            setIsGalleryNewImages(res?.projectMedias);
            isLoading = false;
            setIsPageIndex((prevIndex) => prevIndex + 1);
          } else if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          } else if (res?.status === 500) {
            setIsDiable(true);
            setLoading(false);
            setAllImagesFetched(true);
          } else {
            setLoading(false);
            setAllImagesFetched(true);
          }
        }, payload);
      }
    }
  };

  useEffect(() => {
    let isLoading = false;

    const handleScroll = () => {
      const isNearBottom =
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 100;

      if (isNearBottom && !isLoading && !isDisable) {
        getAllCommunityGalleryImages1(isLoading);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isPageIndex, searchInput, allImagesFetched, checkAllImagesLoaded]);

  const handleLoadMore = () => {
    let isLoading = false;
    getAllCommunityGalleryImages1(isLoading);
  };

  useEffect(() => {
    if (isGalleryImages?.length < 40) {
      setIsDiable(true);
    } else if (
      isGalleryImages?.length > 41 &&
      isPageIndex !== 0 &&
      isPageIndex !== 1 &&
      isGalleryNewImages?.length < 40
    ) {
      setIsDiable(true);
    } else {
      setIsDiable(false);
    }
  }, [isGalleryNewImages, isGalleryImages]);

  useEffect(() => {
    const uniqueGalleryImagesIds = new Set(
      isGalleryImages?.map((image) => image?.id)
    );
    const uniqueImages = isGalleryNewImages?.filter((newImage) => {
      return !uniqueGalleryImagesIds.has(newImage?.id);
    });
    if (uniqueImages?.length > 0) {
      setIsGalleryImages((prevImages) => [...prevImages, ...uniqueImages]);
    }
    if (isGalleryImages?.length === 40 && isPageIndex === 0) {
      setIsPageIndex(1);
    }
  }, [isGalleryImages, isGalleryNewImages, isPageIndex]);

  useEffect(() => {
    const areAllImagesLoaded = checkAllImagesLoaded();
    setAllImagesLoaded(areAllImagesLoaded);
  }, [isGalleryImages, checkAllImagesLoaded]);

  useEffect(() => {
    const favoritesMap = {};

    isGalleryImages.forEach((media) => {
      favoritesMap[media.id] = media.isFavorite;
    });

    setIsFavoriteImages(favoritesMap);
  }, [isGalleryImages]);

  const handleImageLoad = (mediaId) => {
    setLoadedImages((prevLoadedImages) => ({
      ...prevLoadedImages,
      [mediaId]: true,
    }));
  };

  const numRows = screenWidth < 1090 ? 3 : screenWidth < 1250 ? 4 : 5;
  const imagesPerPage = numRows * Math.ceil(window.innerHeight / 100);
  const numCols = Math.ceil(isGalleryImages?.length / numRows);

  const rows = useMemo (()=>{
    const tempRows = [];
    for (let row = 0; row < numRows; row++) {
      const cols = [];
      for (let col = 0; col < numCols; col++) {
        const index = col * numRows + row;
        if (index < isGalleryImages?.length) {
          const image = isGalleryImages[index];
          const isVideo = image.toolset === "video";
          cols.push(
            <div key={image?.id} className="column">
              <div className="column-cont">
                <div
                  className={`column-cont__sec ${
                    isFavoriteImages[image?.id] ? "favorite" : ""
                  }`}
                >
                  <img
                    className="column-img"
                    src={image?.thumbnail || image?.url}
                    alt={image?.id}
                    onLoad={() => handleImageLoad(image.id)}
                  />
                  <div
                    className="favorites"
                    onClick={
                      image?.url
                        ? () => handlePopup(image, index)
                        : undefined
                    }
                  >
                    <div
                      className="fav-icon"
                      style={{
                        display: loadedImages[image?.id] ? "block" : "none",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {/* <img src={fav2} className="fav" alt="favorite" /> */}
                      <img
                        src={fav}
                        className="fav"
                        // alt="favorite"
                        // onClick={() => handleFavorite(image.id)}
                      />
                      <img
                        src={fav1}
                        className="fav fav1"
                        alt="favorite"
                        // onClick={() => handleFavorite(image.id)}
                      />
                      <img
                        src={fav2}
                        className="fav fav2"
                        alt="favorite"
                        // onClick={() => handleFavorite(image.id)}
                      />
                      <div className="tooltip-show">
                        {!responsiveData && (
                          <FavoriteTooltip text={t("favorite")} />
                        )}
                      </div>
                    </div>                 
                  </div>
                  <div className="video-gen">
                    <img className="video-gen__icon" src={videoGen} alt="video-icon"
                    style={{
                      display: isVideo ? 'block' : 'none'
                    }} />
                  </div>
                  {/* <div className="favorites">
                    <div
                      className="fav-icon"
                      style={{
                        display: loadedImages[image?.id] ? "block" : "none",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <img src={fav2} className="fav" alt="favorite" />
                      <div className="tooltip-show">
                        {!responsiveData && (
                          <FavoriteTooltip text={t("favorite")} />
                        )}
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          );
        } else {
          cols.push(<div key={col} className="column"></div>);
        }
      }
      tempRows.push(
        <div key={row} className="row">
          {cols}
        </div>
      );
    }
    return tempRows;
  },[numRows, numCols, isGalleryImages, isFavoriteImages, loadedImages])
 

  const [originalImage, setOriginalImage] = useState(new Image());
  const [originalPrevImage, setOriginalPrevImage] = useState(new Image());
  const [originalNextImage, setOriginalNextImage] = useState(new Image());
  const [alternateImage, setAlternateImage] = useState(new Image());

  useEffect(() => {
    originalImage.src = galleryPopupData?.url;
    if (isGalleryCurrentIndex !== 0) {
      originalPrevImage.src = isGalleryImages[isGalleryCurrentIndex - 1]?.url;
    }
    if (isGalleryImages?.length !== isGalleryCurrentIndex + 1) {
      originalNextImage.src = isGalleryImages[isGalleryCurrentIndex + 1]?.url;
    }

    if (galleryPopupData?.baseImage !== null) {
      alternateImage.src = galleryPopupData?.baseImage;
    }
  }, [galleryPopupData, isGalleryCurrentIndex]);

  const dynamicValue1 = galleryPopupData?.toolset?.split(",")[0]?.toUpperCase();

  const dynamicValue2 = galleryPopupData?.toolset?.split(",")[1]?.toUpperCase();

  useEffect(() => {
    setShowSlider(false);
  }, [galleryPopupData]);

  const handleShowSlider = () => {
    setShowSlider(true);
  };

  const handleOpenSubscription = () => {
    handleShowSubscription();
    setTeamGalleryDisable(false);
  };

  const handleOpenSubscription1 = () => {
    setTeamGalleryDisable1(false);
    navigate("/projects");
    localStorage.setItem("isWorkshop", true);
  };

  return (
    <div className="cgallery">
      <div className="cgallery__container">
        <div className="cgallery__sections">
          <div className="cgallery__main">
            <div className="cgallery__main__section">
              <GalleryHeader
                searchOptions={searchOptions}
                setSearchOptions={setSearchOptions}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isFavoriteTab={true}
                isShowIcons={false}
                isLoading={dataNotFound}
                selectTeam={false}
                isActiveTab={isActiveTab}
                styles={{
                  width:
                    screenWidth > 1609
                      ? "1272px"
                      : screenWidth > 1249
                      ? "982px"
                      : screenWidth > 1089
                      ? "760px"
                      : screenWidth > 768
                      ? "650px"
                      : "500px",
                }}
              />
              <div
                className="cgallery__main__images__container"
                data-scrollable="true"
              >
                {isGalleryImages?.length !== 0 ? (
                  rows?.map((row, rowIndex) => (
                    <div key={`row-all-${rowIndex}`} className="row">
                      {row?.props?.children?.map((col, colIndex) => (
                        <div key={`col-all-${rowIndex}-${colIndex}`}>{col}</div>
                      ))}
                    </div>
                  ))
                ) : (
                  <div className="gif-container">
                    {dataNotFound ? (
                      <img src={gif} alt="gif-loader" />
                    ) : (
                      <p>{t("no_data_found")}</p>
                    )}
                  </div>
                )}
                {!loading && !isDisable && (
                  <div className="gif-container1">
                    <img src={gif} alt="gif-loader" />
                  </div>
                )}
              </div>
              <div className="gallery__main__section__button">
                <button disabled={isDisable} onClick={handleLoadMore}>
                  {isDisable ? t("all_loaded") : t("load_more")}
                </button>
              </div>
              {showScrollArrow && (
                <div className="gallery__main__section__scroll">
                  <img src={downarrow} alt="down-arrow" onClick={handleClick} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <WorkSpaceFeedPopUp
          galleryPopupData={galleryPopupData}
          isGalleryCurrentIndex={isGalleryCurrentIndex}
          handlePopupclose={handlePopupclose}
          handlePopupShowSlider={handleShowSlider}
          popupShowSlider={showSlider}
          popupDynamicValue1={dynamicValue1}
          popupDynamicValue2={dynamicValue2}
          showStealth={false}
          isGalleryImages={isGalleryImages}
          showAllIcons={false}
          setGalleryPopupData={setGalleryPopupData}
          setIsGalleryCurrentIndex={setIsGalleryCurrentIndex}
        />
      )}
      {isGptActive === true && (
        <>
          {isGptActivePopup === true && (
            <PLanEnd
              handleCloseAccessPopup={handleCloseAccessPopup}
              handleSubscription={handleSubscription}
            />
          )}
        </>
      )}
      {teamGalleryDisable && (
        <Confirm
          buttonText={t("view_plans")}
          isCancelRequired={true}
          confirmTitle={t("team_gallery_disable_heading")}
          content={t("team_gallery_disable_content")}
          cancelButtonText={t("ok")}
          onConfirm={handleOpenSubscription}
          onCancel={() => {
            setTeamGalleryDisable(false);
          }}
          isCloseIcon={true}
        />
      )}
      {teamGalleryDisable1 && (
        <Confirm
          buttonText={t("create_team")}
          isCancelRequired={true}
          confirmTitle={t("team_gallery_disable1_heading")}
          content={t("team_gallery_disable1_content")}
          content1={t("team_gallery_disable1_content1")}
          cancelButtonText={t("next_time")}
          onConfirm={handleOpenSubscription1}
          onCancel={() => {
            setTeamGalleryDisable1(false);
          }}
          isCloseIcon={true}
        />
      )}
    </div>
  );
};

export default CommunityGallery;
