import React, { useEffect, useRef, useState } from "react";
import "./TeamdataDetails.scss";
import downarrow from "../../../assets/images/icons/down-arrow.png";
import loader from "../../../assets/images/workspace/upscale-gif.gif";
import intro from "../../../assets/images/workspace/icons/intro.png";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  GetAllTeamDetailedData,
  GetAllTeamGenerationData,
} from "../../../utils/apiCalls";
import * as XLSX from "xlsx";
import { DatePicker, Popover, Space } from "antd";
const { RangePicker } = DatePicker;

const TeamdataDetails = ({ team }) => {
  const { t } = useTranslation();
  const abortControllerRef = useRef(null);
  const abortControllerRef1 = useRef(null);
  const [activeDate, setActiveDate] = useState(2);
  const [dateRange, setDateRange] = useState([]);
  const [dateRange1, setDateRange1] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoader1, setIsLoader1] = useState(false);
  const [teamDetailedData, setTeamDetailedData] = useState([]);
  const [teamGenerationData, setTeamGenerationData] = useState(null);
  const [subData, setSubData] = useState([
    { id: 1, boolean: false },
    { id: 2, boolean: false },
    { id: 3, boolean: false },
  ]);
  const [teamMembersDetailData, setTeamMembersDetailData] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const options = [
    { id: 1, name: t("today") },
    { id: 2, name: t("ytd") },
    { id: 3, name: t("7_days") },
    { id: 4, name: t("30_days") },
    { id: 5, name: t("since_created") },
  ];

  const handleMenuClick = (id) => {
    const today = dayjs().startOf("day");
    let startDate = null,
      endDate = null;
    setActiveDate(id);
    switch (id) {
      case 1:
        startDate = today.clone().startOf("day");
        endDate = today.clone().endOf("day");
        break;
      case 2:
        startDate = today.clone().subtract(1, "day").startOf("day");
        endDate = today.clone().subtract(1, "day").endOf("day");
        break;
      case 3:
        startDate = today.clone().subtract(7, "days").startOf("day");
        endDate = today.clone().endOf("day");
        break;
      case 4:
        startDate = today.clone().subtract(30, "days").startOf("day");
        endDate = today.clone().endOf("day");
        break;
      case 5:
        const specificDate = dayjs(team?.createdOn);
        const differenceInDays = today.diff(specificDate, "days");
        startDate = today.clone().subtract(differenceInDays + 1, "days");
        endDate = today.clone().endOf("day");
        break;
      default:
        return;
    }
    setDateRange1([startDate, endDate]);
    setDateRange([startDate, endDate]);
  };

  const handleQuery = () => {
    fetchGetTeamGenerationData(dateRange[0], dateRange[1]);
    fetchGetTeamDetailedData(dateRange[0], dateRange[1]);
    setSubData([
      { id: 1, boolean: false },
      { id: 2, boolean: false },
      { id: 3, boolean: false },
    ]);
  };

  const handleReset = () => {
    handleMenuClick(2);
    // setDateRange([]);
    // setDateRange1([]);
  };

  useEffect(() => {
    const today = dayjs();
    let startDate = null,
      endDate = null;
    startDate = today.clone().startOf("day");
    endDate = today.clone().endOf("day");
    handleMenuClick(1);
    if (team?.teamId !== -1) {
      fetchGetTeamDetailedData(startDate, endDate);
    }
    fetchGetTeamGenerationData(startDate, endDate);
  }, [team]);

  const fetchGetTeamDetailedData = (startDate, endDate) => {
    setIsLoader(true);
    setTeamDetailedData([]);
    setTeamMembersDetailData([]);
    const payload = {
      teamId: team?.teamId !== 0.1 ? team?.teamId : 0,
      startDateTime: startDate,
      endDateTime: endDate,
    };
    // Abort the previous API call if it's still running
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    // Create a new AbortController for the new request
    const controller = new AbortController();
    abortControllerRef.current = controller;
    const signal = controller.signal;
    GetAllTeamDetailedData(
      (res) => {
        // Check if the request was aborted
        if (signal.aborted) {
          return; // Exit if the request was aborted
        }
        
        if (res?.status === 200) {
          setTeamDetailedData(res?.data);
          const filterData = res?.data?.membersData?.filter(
            (member) =>
              member?.memberRoleId === 2 ||
              member?.memberRoleId === 4 ||
              member?.memberRoleId === 1
          );
          setTeamMembersDetailData(filterData);
          setIsLoader(false);
        } else {
          setIsLoader(false);
        }
      },
      payload,
      signal
    );
  };

  const fetchGetTeamGenerationData = (startDate, endDate) => {
    setIsLoader1(true);
    setTeamGenerationData(null);
    const payload = {
      teamId:
        team?.teamId !== 0.1
          ? team?.teamId === -1
            ? "null"
            : team?.teamId
          : 0,
      startDateTime: startDate,
      endDateTime: endDate,
    };
    // Abort the previous API call if it's still running
    if (abortControllerRef1.current) {
      abortControllerRef1.current.abort();
    }
    // Create a new AbortController for the new request
    const controller = new AbortController();
    abortControllerRef1.current = controller;
    const signal = controller.signal;
    GetAllTeamGenerationData(
      (res) => {
        // Check if the request was aborted
        if (signal.aborted) {
          return; // Exit if the request was aborted
        }

        if (res?.status === 200) {
          setTeamGenerationData(res?.data);
          setIsLoader1(false);
        } else {
          setIsLoader1(false);
        }
      },
      payload,
      signal
    );
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      setDateRange1([startDate, endDate]);
    }
  };

  const handleClick = (id) => {
    const updatedData = subData.map((item) => {
      if (item.id === id) {
        return { ...item, boolean: !item?.boolean };
      } else {
        return { ...item, boolean: false };
      }
    });
    setSubData(updatedData);
  };

  const excelitem = [];

  if (teamDetailedData && teamDetailedData.membersData) {
    teamDetailedData.membersData.forEach((val) => {
      excelitem.push({
        [t("user_name")]: val?.username || "--",
        [t("phone_number")]: val?.phoneNumber || "--",
        [t("member_status")]: t(`team_${val?.memberRoleName}`) || "--",
        [t("days_of_use")]: val?.daysOfUse || "0",
        [t("no_of_new_projects")]: val?.numberOfProjects || "0",
        [t("total_number_images")]: val?.totalImages || "0",
        [t("total_number_images_day")]: val?.maxImageDaily || "0",
      });
    });
  }

  const handleDownloadExcel = () => {
    console.log(excelitem, "dsadsd");
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(excelitem);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const startDate = new Date(dateRange[0]);
    const endDate = new Date(dateRange[1]);
    const startDateString = startDate.toISOString().split("T")[0];
    const endDateString = endDate.toISOString().split("T")[0];
    XLSX.writeFile(
      workbook,
      `TeamDetails_data_${startDateString}_${endDateString}.xlsx`
    );
  };

  const handleOpen = (isOpen) => {
    setOpen(isOpen);
  };

  const handleOpen1 = (isOpen) => {
    setOpen1(isOpen);
  };

  const content = t("tau_content");
  const contentLines = content?.split("/n");
  const content1 = t("dau_content");
  const contentLines1 = content1?.split("/n");

  return (
    <div className="team-data-details">
      <div className="team-data-details__container">
        <div className="team-member-details__heading">
          <h2>{team?.teamName}</h2>
          {/* <h2>{t("all_Teams")}</h2> */}
        </div>
        <div className="team-data-details__top__section">
          <div className="team-data-details__top__section__enquiry">
            <div className="team-data-details__top__section__enquiry__date">
              <div className="team-data-details__top__section__enquiry__date__left">
                <h5>{t("enquiry_time")}</h5>
              </div>
              <div className="team-data-details__top__section__enquiry__date__right"></div>
            </div>
            <div className="team-data-details__top__section__enquiry__options">
              <div className="team-data-details__top__section__enquiry__options__date">
                <Space direction="vertical" size={12}>
                  <RangePicker
                    value={dateRange1}
                    showTime
                    //   onChange={handleDateRangeChange}
                    disabled={true}
                  />
                </Space>
              </div>
              {options?.map((opt, index) => {
                return (
                  <div
                    className="team-data-details__top__section__enquiry__options__sec"
                    key={index}
                  >
                    <h6
                      className={`${activeDate === opt.id && "active"}`}
                      onClick={() => handleMenuClick(opt.id)}
                    >
                      {opt.name}
                    </h6>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="team-data-details__top__section__buttons">
            <div className="team-data-details__top__section__buttons__main">
              <button onClick={() => handleReset()} className="cancel">
                {t("reset")}
              </button>
              <button onClick={() => handleQuery()}>{t("query")}</button>
            </div>
          </div>
        </div>
        <div className="team-data-details__center">
          <div className="team-data-details__center__heading">
            <div className="team-data-details__center__heading__left">
              <h3>{t("product_usage")}</h3>
            </div>
          </div>
          {teamGenerationData ? (
            <div className="team-data-details__center__details__main">
              <div className="team-data-details__center__details">
                <div className="team-data-details__center__details__sec">
                  <div className="team-data-details__center__details__sec__heading">
                    <h5>{t("number_of_team_members")}</h5>
                  </div>
                  <div className="team-data-details__center__details__sec__content">
                    <div className="team-data-details__center__details__sec__content__left">
                      <h6>
                        <span>
                          {teamGenerationData?.teamMemberDetails?.finalNumber}
                        </span>{" "}
                        {t("individual")}
                      </h6>
                    </div>
                    <div
                      className="team-data-details__center__details__sec__content__right"
                      onClick={() => handleClick(1)}
                    >
                      <h6>{t("details")}</h6>
                      <img
                        className={`${
                          subData.find(
                            (item) => item.id === 1 && item.boolean
                          ) && "active"
                        }`}
                        src={downarrow}
                        alt="down-arrow-icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="team-data-details__center__details__sec">
                  <div className="team-data-details__center__details__sec__heading">
                    <h5>{t("tau")}</h5>
                    <Popover
                      overlayClassName="question-popover"
                      content={
                        <div className="popover-content">
                          {contentLines?.map((text, index) => {
                            return (
                              <p className="popover-text" key={index}>
                                {text}
                              </p>
                            );
                          })}
                        </div>
                      }
                      arrow={true}
                      trigger="click"
                      placement="top"
                      open={open}
                      onOpenChange={handleOpen}
                    >
                      <img src={intro} alt="question-mark-icon" />
                    </Popover>
                  </div>
                  <div className="team-data-details__center__details__sec__content">
                    <div className="team-data-details__center__details__sec__content__left">
                      <h6>
                        <span>{teamGenerationData?.TAU}</span> {t("individual")}
                      </h6>
                    </div>
                    <div className="team-data-details__center__details__sec__content__right"></div>
                  </div>
                </div>
                <div className="team-data-details__center__details__sec">
                  <div className="team-data-details__center__details__sec__heading">
                    <h5>{t("dau")}</h5>
                    <Popover
                      overlayClassName="question-popover1"
                      content={
                        <div className="popover-content">
                          {contentLines1?.map((text, index) => {
                            return (
                              <p className="popover-text" key={index}>
                                {text}
                              </p>
                            );
                          })}
                        </div>
                      }
                      arrow={true}
                      trigger="click"
                      placement="top"
                      open={open1}
                      onOpenChange={handleOpen1}
                    >
                      <img src={intro} alt="question-mark-icon" />
                    </Popover>
                  </div>
                  <div className="team-data-details__center__details__sec__content">
                    <div className="team-data-details__center__details__sec__content__left">
                      <h6>
                        <span>{teamGenerationData?.DAU}</span> {t("individual")}
                      </h6>
                    </div>
                    <div className="team-data-details__center__details__sec__content__right"></div>
                  </div>
                </div>
                <div className="team-data-details__center__details__sec">
                  <div className="team-data-details__center__details__sec__heading">
                    <h5>{t("number_of_new_projects")}</h5>
                  </div>
                  <div className="team-data-details__center__details__sec__content">
                    <div className="team-data-details__center__details__sec__content__left">
                      <h6>
                        <span>
                          +{teamGenerationData?.newProjectDetails?.newProjects}
                        </span>{" "}
                        {t("items")}
                      </h6>
                    </div>
                    <div
                      className="team-data-details__center__details__sec__content__right"
                      onClick={() => handleClick(2)}
                    >
                      <h6>{t("details")}</h6>
                      <img
                        className={`${
                          subData.find(
                            (item) => item.id === 2 && item.boolean
                          ) && "active"
                        }`}
                        src={downarrow}
                        alt="down-arrow-icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="team-data-details__center__details__sec">
                  <div className="team-data-details__center__details__sec__heading">
                    <h5>{t("number_of_new_images")}</h5>
                  </div>
                  <div className="team-data-details__center__details__sec__content">
                    <div className="team-data-details__center__details__sec__content__left">
                      <h6>
                        <span>+{teamGenerationData?.newImages?.newImages}</span>{" "}
                        {t("items")}
                      </h6>
                    </div>
                    <div
                      className="team-data-details__center__details__sec__content__right"
                      onClick={() => handleClick(3)}
                    >
                      <h6>{t("details")}</h6>
                      <img
                        className={`${
                          subData.find(
                            (item) => item.id === 3 && item.boolean
                          ) && "active"
                        }`}
                        src={downarrow}
                        alt="down-arrow-icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {subData?.find((item) => item.id === 1 && item.boolean) && (
                <div className="team-data-details__center__details__sub">
                  <div className="team-data-details__center__details__sub__header">
                    <ul>
                      <li>
                        <h6>{t("initial_members")}</h6>
                      </li>
                      <li>
                        <h6>{t("increase_members")}</h6>
                      </li>
                      <li>
                        <h6>{t("decrease_members")}</h6>
                      </li>
                      <li>
                        <h6>{t("final_members")}</h6>
                      </li>
                    </ul>
                  </div>
                  <div className="team-data-details__center__details__sub__data">
                    <ul>
                      <li>
                        <h6>
                          {
                            teamGenerationData?.teamMemberDetails
                              ?.initialMembers
                          }
                          <span>{t("individual")}</span>
                        </h6>
                      </li>
                      <li>
                        {teamGenerationData?.teamMemberDetails
                          ?.increasedMembers !== 0 ? (
                          <h6>
                            +
                            {
                              teamGenerationData?.teamMemberDetails
                                ?.increasedMembers
                            }
                            <span>{t("individual")}</span>
                          </h6>
                        ) : (
                          <h6>
                            {
                              teamGenerationData?.teamMemberDetails
                                ?.increasedMembers
                            }
                            <span>{t("individual")}</span>
                          </h6>
                        )}
                      </li>
                      <li>
                        {teamGenerationData?.teamMemberDetails
                          ?.decreasedMembers !== 0 ? (
                          <h6>
                            -
                            {
                              teamGenerationData?.teamMemberDetails
                                ?.decreasedMembers
                            }
                            <span>{t("individual")}</span>
                          </h6>
                        ) : (
                          <h6>
                            {
                              teamGenerationData?.teamMemberDetails
                                ?.decreasedMembers
                            }
                            <span>{t("individual")}</span>
                          </h6>
                        )}
                      </li>
                      <li>
                        <h6>
                          {teamGenerationData?.teamMemberDetails?.finalNumber}
                          <span>{t("individual")}</span>
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {subData.find((item) => item.id === 2 && item.boolean) && (
                <div className="team-data-details__center__details__sub">
                  <div className="team-data-details__center__details__sub__header">
                    <ul>
                      <li>
                        <h6>{t("initial_projects")}</h6>
                      </li>
                      <li>
                        <h6>{t("increase_projects")}</h6>
                      </li>
                      <li>
                        <h6>{t("final_projects")}</h6>
                      </li>
                    </ul>
                  </div>
                  <div className="team-data-details__center__details__sub__data">
                    <ul>
                      <li>
                        <h6>
                          {
                            teamGenerationData?.newProjectDetails
                              ?.initialProjects
                          }
                          <span>{t("count")}</span>
                        </h6>
                      </li>
                      <li>
                        {teamGenerationData?.newProjectDetails
                          ?.increasedProjects !== 0 ? (
                          <h6>
                            +
                            {
                              teamGenerationData?.newProjectDetails
                                ?.increasedProjects
                            }
                            <span>{t("count")}</span>
                          </h6>
                        ) : (
                          <h6>
                            {
                              teamGenerationData?.newProjectDetails
                                ?.increasedProjects
                            }
                            <span>{t("count")}</span>
                          </h6>
                        )}
                      </li>
                      <li>
                        <h6>
                          {teamGenerationData?.newProjectDetails?.totalProjects}
                          <span>{t("count")}</span>
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {subData.find((item) => item.id === 3 && item.boolean) && (
                <div className="team-data-details__center__details__sub">
                  <div className="team-data-details__center__details__sub__header1">
                    <ul>
                      <li>
                        <h6>{t("initial_images")}</h6>
                      </li>
                      <li>
                        <h6>{t("increase_images")}</h6>
                      </li>
                      <li>
                        <h6>{t("final_images")}</h6>
                      </li>
                      <li>
                        <h6>{t("users_more_images")}</h6>
                      </li>
                      <li>
                        <h6>{t("user_more_images")}</h6>
                      </li>
                    </ul>
                  </div>
                  <div className="team-data-details__center__details__sub__data1">
                    <ul>
                      <li>
                        <h6>
                          {teamGenerationData?.newImages?.initialImages}
                          <span>{t("sheets")}</span>
                        </h6>
                      </li>
                      <li>
                        {teamGenerationData?.newImages?.increasedImages !==
                        0 ? (
                          <h6>
                            +{teamGenerationData?.newImages?.increasedImages}
                            <span>{t("sheets")}</span>
                          </h6>
                        ) : (
                          <h6>
                            {teamGenerationData?.newImages?.increasedImages}
                            <span>{t("sheets")}</span>
                          </h6>
                        )}
                      </li>
                      <li>
                        <h6>
                          {teamGenerationData?.newImages?.finalImages}
                          <span>{t("sheets")}</span>
                        </h6>
                      </li>
                      <li>
                        <h6>
                          {teamGenerationData?.newImages?.moreThan100}
                          <span>{t("individual")}</span>
                        </h6>
                      </li>
                      <li>
                        <h6>{teamGenerationData?.newImages?.username}</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="team-data-details__center__details__nodata">
              {isLoader1 ? (
                <img src={loader} alt="loader-gif" />
              ) : (
                <p>{t("No Team Data Found")}</p>
              )}
            </div>
          )}
        </div>
        <div className="team-data-details__bottom">
          {team?.teamId !== -1 ? (
            <>
              <div className="team-data-details__bottom__heading">
                <div className="team-data-details__bottom__heading__left">
                  <h3>{t("detailed_data")}</h3>
                </div>
                <div className="team-data-details__bottom__heading__right">
                  {teamDetailedData?.length !== 0 &&
                    teamDetailedData?.membersData?.length !== 0 && (
                      <button onClick={() => handleDownloadExcel()}>
                        {t("download_file")}
                      </button>
                    )}
                </div>
              </div>
              <div className="team-data-details__bottom__table">
                <div className="team-data-details__bottom__table__container">
                  <div className="team-data-details__bottom__table__header">
                    <ul>
                      <li className="second">
                        <h6>{t("user_name")}</h6>
                      </li>
                      <li className="third">
                        <h6>{t("phone_number")}</h6>
                      </li>
                      <li className="first">
                        <h6>{t("member_status")}</h6>
                      </li>
                      <li className="second">
                        <h6>{t("days_of_use")}</h6>
                      </li>
                      <li className="forth">
                        <h6>{t("no_of_new_projects")}</h6>
                      </li>
                      <li className="fifth">
                        <h6>{t("total_number_images")}</h6>
                      </li>
                      <li className="sixth">
                        <h6>{t("total_number_images_day")}</h6>
                      </li>
                    </ul>
                  </div>
                  <div className="team-data-details__bottom__table__data">
                    {teamMembersDetailData?.length !== 0 ? (
                      teamMembersDetailData?.map((data, index) => {
                        return (
                          <ul key={index}>
                            <li className="second">
                              <h6>{data?.username}</h6>
                            </li>
                            <li className="third">
                              <h6>{data?.phoneNumber}</h6>
                            </li>
                            <li className="first">
                              <h6 style={{ color: data?.color }}>
                                {t(`team_${data?.memberRoleName}`)}
                              </h6>
                            </li>
                            <li className="second">
                              <h6>{data?.daysOfUse}</h6>
                            </li>
                            <li className="forth">
                              <h6>{data?.numberOfProjects}</h6>
                            </li>
                            <li className="fifth">
                              <h6>{data?.totalImages}</h6>
                            </li>
                            <li className="sixth">
                              <h6>{data?.maxImageDaily}</h6>
                            </li>
                          </ul>
                        );
                      })
                    ) : (
                      <div className="team-data-details__bottom__table__data__nodata">
                        {isLoader ? (
                          <img src={loader} alt="loader-gif" />
                        ) : (
                          <p>{t("No Team Data Found")}</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="team-data-details__bottom__heading">
              <div className="team-data-details__bottom__heading__left">
                <h3>{t("detailed_data")}</h3>
              </div>
              <div className="team-data-details__bottom__heading__right">
                <p>
                  {t(
                    "Please select the team on the left to view the corresponding usage data."
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamdataDetails;
