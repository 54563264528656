/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import "./CameraDropdown.scss";
import Select from "react-select";
import { GetCameraViewUser } from "../../../utils/apiCalls";
import { RenderToolsetContext } from "../../../store/RenderToolsetData";
import street from "../../../assets/images/workspace/camera/street.jpg";
import plaza from "../../../assets/images/workspace/camera/plaza.jpg";
import landscape from "../../../assets/images/workspace/camera/landscape.jpg";
import aerial from "../../../assets/images/workspace/camera/aerial.jpg";
import crown from "../../../assets/images/workspace/camera/crown.jpg";
import halfbird from "../../../assets/images/workspace/camera/front.jpg";
import elevation from "../../../assets/images/workspace/camera/elevated.jpg";
import human from "../../../assets/images/workspace/camera/human.jpg";
import landAerial from "../../../assets/images/workspace/camera/land_aerial.jpg";
import landElevation from "../../../assets/images/workspace/camera/land_elevated.jpg";
import { useTranslation } from "react-i18next";
import { ResponsiveDataContext } from "../../../store/ResponsiveData";
import Portal from "../../TipsPopup/Portal1";

let newDataList = [];
const CameraDropDown = ({ data }) => {
  const { t } = useTranslation();
  const url = window.location.pathname;
  const lastPart = url.substring(url.lastIndexOf("/") + 1);
  const lang = localStorage.getItem("language");
  const selectRef = useRef(null);

  const { renderToolsetData } = useContext(RenderToolsetContext);
  const { responsiveData } = useContext(ResponsiveDataContext);

  const tiresData = JSON.parse(
    localStorage.getItem(`designLibraryData_${lastPart}`)
  );
  const cameraData = JSON.parse(localStorage.getItem(`cameraData_${lastPart}`));
  const atmosphereData = JSON.parse(
    localStorage.getItem(`atmosphereData_${lastPart}`)
  );
  const designLibrary = JSON.parse(localStorage.getItem(`dlData_${lastPart}`));

  const [dataNew, setDataNew] = useState([]);
  const [dataNew1, setDataNew1] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    cameraData?.cameraSelectedOption ?? data[0]
  );

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#FFFFFF" : "#F8F8F9",
      color: state.isFocused ? "#677074" : "#A8ADAF",
      borderRadius: "4px",
      padding: "5px",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000000",
    }),
  };

  const handleChange = (e) => {
    setSelectedOption(e);
    let camera = JSON.parse(localStorage.getItem(`cameraData_${lastPart}`));
    if (
      (camera?.cameraSelectedOption !== undefined ||
        camera?.cameraSelectedOption !== null) &&
      camera?.cameraSelectedOption !== e
    ) {
      camera.cameraSelectedOption = e;
      camera.cameraViewId = e?.id;
      camera.cameraViewName = e?.name;
    } else if (
      camera?.cameraSelectedOption === undefined ||
      camera?.cameraSelectedOption === null
    ) {
      camera.cameraSelectedOption = e;
      camera.cameraViewId = e?.id;
      camera.cameraViewName = e?.name;
    }
    localStorage.setItem(`cameraData_${lastPart}`, JSON.stringify(camera));
  };

  useEffect(() => {
    setDataNew1(newDataList);
  }, [newDataList, dataNew]);

  useEffect(() => {
    let camera = JSON.parse(localStorage.getItem(`cameraData_${lastPart}`));
    let tiresData = JSON.parse(
      localStorage.getItem(`designLibraryData_${lastPart}`)
    );
    if (
      camera?.cameraSelectedOption !== null &&
      camera?.cameraSelectedOption !== undefined
    ) {
      if (
        !tiresData?.isSecondTierId ||
        tiresData?.isSecondTierId !== undefined
      ) {
        const payload = {
          secondTierId: tiresData?.isSecondTierId,
        };
        if (payload?.secondTierId) {
          GetCameraViewUser(function (res) {
            if (res?.status === 200 && res?.data?.length > 0) {
              setDataNew(res?.data);
              newDataList = res?.data;
              if (res?.data?.length !== 0) {
                const index = res?.data.findIndex(
                  (item) => item.name === camera?.cameraViewName
                );
                if (index !== -1) {
                  camera.cameraSelectedOption = res?.data[index];
                  camera.cameraViewId = res?.data[index]?.id;
                  camera.cameraViewName = res?.data[index]?.name;
                  setSelectedOption(res?.data[index]);
                } else {
                  camera.cameraSelectedOption = res?.data[0];
                  camera.cameraViewId = res?.data[0]?.id;
                  camera.cameraViewName = res?.data[0]?.name;
                  setSelectedOption(res?.data[0]);
                }
              }
              localStorage.setItem(
                `cameraData_${lastPart}`,
                JSON.stringify(camera)
              );
            }
          }, payload);
        }
      }
    } else {
      if (
        !tiresData?.isSecondTierId ||
        tiresData?.isSecondTierId !== undefined
      ) {
        const payload = {
          secondTierId: tiresData?.isSecondTierId,
        };
        if (payload?.secondTierId) {
          GetCameraViewUser(function (res) {
            if (res?.status === 200 && res?.data?.length > 0) {
              if (res?.data?.length !== 0) {
                setDataNew(res?.data);
                newDataList = res?.data;
                setSelectedOption(res?.data[0]);
                const cameraData = {
                  cameraSelectedOption: res?.data[0],
                  cameraViewId: res?.data[0]?.id,
                  cameraViewName: res?.data[0]?.name,
                };
                localStorage.setItem(
                  `cameraData_${lastPart}`,
                  JSON.stringify(cameraData)
                );
              }
            }
          }, payload);
        }
      }
    }
  }, [tiresData?.isSecondTierId]);

  const OnHoverMenu = [
    {
      id: 1,
      name: t("street"),
      text: t("street_text"),
      image: street,
    },
    {
      id: 2,
      name: t("plaza"),
      text: t("plaza_text"),
      image: plaza,
    },
    {
      id: 3,
      name: t("landscape"),
      text: t("landscape_text"),
      image: landscape,
    },
    {
      id: 4,
      name: t("front"),
      text: t("front_text"),
      image: elevation,
    },
    {
      id: 5,
      name: t("elevated"),
      text: t("elevated_text"),
      image: halfbird,
    },
    {
      id: 6,
      name: t("aerial"),
      text: t("aerial_text"),
      image: aerial,
    },
    {
      id: 7,
      name: t("crown"),
      text: t("crown_text"),
      image: crown,
    },
  ];

  const OnHoverLandscapeMenu = [
    {
      id: 1,
      name: t("eye-level"),
      text: t("land_eye_level_text"),
      image: human,
    },
    {
      id: 2,
      name: t("elevated"),
      text: t("land_elevated_text"),
      image: landElevation,
    },
    {
      id: 3,
      name: t("aerial"),
      text: t("land_aerial_text"),
      image: landAerial,
    },
  ];

  const [hoveredOption, setHoveredOption] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hoveredElement, setHoveredElement] = useState(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const handleMouseOver = (event, option) => {
    if (isDropdownOpen) {
      setHoveredElement(event.currentTarget);
      setHoveredOption(option);
    }
  };

  useEffect(() => {
    if (hoveredElement) {
      const rect = selectRef.current.getBoundingClientRect();
      // if (left) {
      //   setPosition({
      //     left: rect.left + window.scrollX + 214,
      //     top: rect.top + window.scrollY + 46,
      //   });
      // } else {
      setPosition({
        left: rect.left + window.scrollX + 124,
        top: rect.top + window.scrollY + 46,
      });
      // }
    }
  }, [hoveredElement]);

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
    setHoveredOption(null);
  };

  const handleMouseLeaveDropdown = () => {
    setHoveredOption(null);
  };

  return (
    <div
      className="camera"
      ref={selectRef}
      key={JSON.parse(localStorage.getItem(`cameraData_${lastPart}`))}
    >
      {designLibrary?.prevDesignLibraryId === 30 ? (
        <Select
          key={cameraData}
          placeholder={t("select_option")}
          value={
            JSON.parse(localStorage.getItem(`cameraData_${lastPart}`))
              ?.cameraSelectedOption ??
            cameraData?.cameraSelectedOption ??
            selectedOption
          }
          options={dataNew1 ?? data}
          onChange={handleChange}
          isSearchable={false}
          onMenuOpen={handleDropdownOpen}
          onMenuClose={handleDropdownClose}
          isDisabled={true}
          styles={{
            ...customStyles,
            control: (provided, state) => ({
              ...provided,
              backgroundColor: state.isDisabled
                ? "#FFFFFF"
                : provided.backgroundColor,
              border: state.isDisabled ? "1px solid #EDEDED" : provided.border,
            }),
            menu: (provided) => ({
              ...provided,
              width: responsiveData ? 130 : 200,
              left: responsiveData ? 0 : -100,
            }),
            placeholder: (provided) => ({
              ...provided,
              fontSize: "12px",
            }),
          }}
          getOptionLabel={(e) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              className="select-option"
              onMouseOver={(event) => handleMouseOver(event, e)}
            >
              <span
                style={{
                  marginLeft: 5,
                  fontSize: responsiveData ? "12px" : "12px",
                }}
              >
                {t("interior")}
              </span>
            </div>
          )}
          getOptionValue={(option) => option.value}
        />
      ) : (
        <>
          <Select
            menuPlacement="bottom"
            placeholder={t("select_option")}
            value={
              JSON.parse(localStorage.getItem(`cameraData_${lastPart}`))
                ?.cameraSelectedOption ??
              cameraData?.cameraSelectedOption ??
              selectedOption
            }
            options={dataNew1 ?? data}
            onChange={handleChange}
            isSearchable={false}
            onMouseOver={handleMouseLeaveDropdown}
            onMouseLeave={handleMouseLeaveDropdown}
            onMenuOpen={handleDropdownOpen}
            onMenuClose={handleDropdownClose}
            styles={{
              ...customStyles,
              menu: (provided) => ({
                ...provided,
                width: responsiveData ? 130 : 200,
                left: responsiveData ? 0 : -100,
              }),
              placeholder: (provided) => ({
                ...provided,
                fontSize: "12px",
              }),
            }}
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="select-option"
                onMouseOver={(event) => handleMouseOver(event, e)}
                onMouseLeave={handleMouseLeaveDropdown}
              >
                <span
                  style={{
                    marginLeft: 5,
                    fontSize: responsiveData ? "12px" : "12px",
                  }}
                >
                  {t(e.name)}
                </span>
              </div>
            )}
            getOptionValue={(option) => option.value}
          />
          {!responsiveData && (
            <Portal style={{ display: "block" }}>
              {isDropdownOpen && hoveredOption && (
                <div
                  className="hover__options1"
                  style={{
                    top: `${position.top}px`,
                    left: `${position.left}px`,
                  }}
                >
                  {designLibrary?.prevDesignLibraryId === 31 ? (
                    <>
                      {OnHoverLandscapeMenu?.map((menuOption) => {
                        const name =
                          lang === "ch"
                            ? hoveredOption.alias
                            : hoveredOption.name;
                        const match = menuOption.name === name;
                        return (
                          match && (
                            <div
                              className="hover__section"
                              key={menuOption?.id}
                            >
                              <img
                                className="hover__image"
                                key={menuOption?.id}
                                src={menuOption?.image}
                                alt={menuOption?.name}
                              />
                              <p>{menuOption?.text}</p>
                            </div>
                          )
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {!responsiveData && (
                        <>
                          {OnHoverMenu?.map((menuOption) => {
                            const name =
                              lang === "ch"
                                ? hoveredOption.alias
                                : hoveredOption.name;
                            const match = menuOption.name === name;
                            return (
                              match && (
                                <div
                                  className="hover__section"
                                  key={menuOption?.id}
                                >
                                  <img
                                    className="hover__image"
                                    key={menuOption?.id}
                                    src={menuOption?.image}
                                    alt={menuOption?.name}
                                  />
                                  <p>{menuOption?.text}</p>
                                </div>
                              )
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </Portal>
          )}
        </>
      )}
    </div>
  );
};

export default CameraDropDown;
