import React from "react";
import "./ProfessionalsAndRsponsibe.scss";

const ProfessionalsAndRsponsibe = ({ data}) => {
  return (
    <div className="professional">
      <div className="professional__card">
        <div className="professional__card__container">
          <div className="professional__card__image">
            <img src={data?.image} alt={data?.heading} />
          </div>
          <div className="professional__card__content">
            <h3>{data?.heading}</h3>
            <p>{data?.content}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalsAndRsponsibe;
