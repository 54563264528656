import React, { useContext, useState } from "react";
import "./AdminHeader.scss";
import logo from "../../../assets/images/icons/airi-lab-beta_tag.jpg";
import userIcon from "../../../assets/images/icons/user-icon.png";
import { NavLink, useNavigate } from "react-router-dom";
import { AdminContext } from "../../../store/AdminDetailsStore";
import { ResponsiveDataContext } from "../../../store/ResponsiveData";
import Confirm from "../../confirmModal/confirm";
import Logout from "../../../assets/IconComponents/Logout";
import { useTranslation } from "react-i18next";
import { UserSocketContext } from "../../../store/UserSocketData";

const AdminHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAdminDetails } = useContext(AdminContext);
  const { responsiveData } = useContext(ResponsiveDataContext);
  const { updateSocketData } = useContext(UserSocketContext);
  const [confirmLogOut, setconfirmLogOut] = useState(false);

  const handleLogout = () => {
    setconfirmLogOut(!confirmLogOut);
  };

  const handleHome = () => {
    navigate("/admin/dashboard");
  };

  const menuList = [
    {
      id: 1,
      name: "Dashboard",
      link: "/admin/dashboard",
    },
    {
      id: 2,
      name: "Users",
      link: "/admin/users",
    },
  ];

  const activeStyle = {
    color: "#263238",
    fontWeight: 600,
  };

  const onConfirm = () => {
    setconfirmLogOut(false);
    updateSocketData({
      isSocketClose: true,
    });
    const getOldVersion = localStorage.getItem("currentVersion");
    const lang = localStorage.getItem("language");
    localStorage.clear();
    localStorage.setItem("currentVersion", getOldVersion);
    localStorage.setItem("language", lang);
    navigate("/admin/sign-in", { replace: true });
  };

  return (
    <div className="admin-header">
      <div className="admin-header__container">
        <div className="admin-header__nav">
          <div className="admin-header__nav__logo">
            <img onClick={handleHome} src={logo} alt="AIRI-logo" />
          </div>
          <div className="admin-header__nav__links">
            <ul>
              {menuList?.map((menu) => {
                return (
                  <li key={menu?.id}>
                    <NavLink
                      to={menu.link}
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      {menu?.name}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="admin-header__nav__user">
            <button onClick={handleLogout}>
              <Logout size={responsiveData ? "18" : "22"} />
              <span>{t("logout")}</span>
            </button>
            {/* <div className="admin-header__nav__user__name">
              <p>{isAdminDetails ? isAdminDetails?.name : "user Name"}</p>
            </div>
            <div className="admin-header__nav__user__icon">
              <img
                src={isAdminDetails ? isAdminDetails.avatarUrl : userIcon}
                alt="user-icon"
                className="usericon"
              />
            </div> */}
          </div>
        </div>
      </div>
      {confirmLogOut && (
        <Confirm
          isCancelRequired={true}
          confirmTitle={t("log_heading")}
          content={t("log_content1")}
          content1={t("log_content2")}
          onConfirm={onConfirm}
          onCancel={handleLogout}
          cancelButtonText={t("cancel")}
          buttonText={t("log_out_me")}
        />
      )}
    </div>
  );
};

export default AdminHeader;
