/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import "./TeamNotifications.scss";
import cross from "../../../assets/images/workspace/icons/cross-icon.png";
import { useTranslation } from "react-i18next";
import { UserSocketContext } from "../../../store/UserSocketData";
import Button from "../../button";
import { AcceprOrRejectTeamInvite } from "../../../utils/apiCalls";
import { TeamDesignContext } from "../../../store/TeamDesignData";
import { useNavigate } from "react-router-dom";
import { Popover } from "antd";

const TeamNotifications = ({ allTeamsInvites, getAllInvites }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const url = window.location.pathname;
  const substring1 = "workspace";
  let getval1 = url.includes(substring1);
  const { handleTeamNofications, updateSocketData } =
    useContext(UserSocketContext);
  const {
    getAllUserTeams,
    getTeamsOrder,
    apiCalledRef1,
    handleTeamChanges,
    isGenCount,
  } = useContext(TeamDesignContext);
  const [open, setOpen] = useState(false);

  const handleConfirm = (id, bool) => {
    const paylaod = {
      requestId: id,
      isAccepted: bool,
    };
    AcceprOrRejectTeamInvite((res) => {
      if (res?.status === 200) {
        getAllInvites();
        if (!bool) return;
        getTeamsOrder();
        getAllUserTeams();
        handleTeamChanges();
        apiCalledRef1.current = false;
        navigate("/projects");
      } else if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      } else {
        getAllUserTeams();
        getTeamsOrder();
        getAllInvites();
      }
    }, paylaod);
  };

  const handleOpen = (isOpen) => {
    setOpen(isOpen);
  };

  return (
    <div className="team-notifications" onClick={handleTeamNofications}>
      <div className="team-notifications__container">
        <div
          className="team-notifications__sections"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="team-notifications__header">
            <div className="team-notifications__header__left">
              <h3>{t("team_notifications")}</h3>
            </div>
            <div className="team-notifications__header__right">
              <img
                className="cross"
                src={cross}
                alt="cross-icon"
                onClick={handleTeamNofications}
              />
            </div>
          </div>
          <div className="team-notifications__list">
            {allTeamsInvites?.length !== 0 ? (
              allTeamsInvites?.map((invite, index) => {
                return (
                  <div className="team-notifications__list__sec" key={index}>
                    <div className="team-notifications__list__sec__heading">
                      <h4>{t("team_collab_invite")}</h4>
                    </div>
                    <div className="team-notifications__list__sec__content">
                      <div className="team-notifications__list__sec__content__main">
                        <img src={invite?.teamIcon} alt="team-icon" />
                        <h5>{invite?.teamName}</h5>
                      </div>
                      <p>{invite?.subheading}</p>
                    </div>
                    <div className="team-notifications__list__sec__buttons">
                      <div className="team-notifications__list__sec__buttons__main">
                        <Button
                          buttonClick={() =>
                            handleConfirm(invite?.inviteId, false)
                          }
                          buttonClass="button_link"
                          isBtnDisabled={false}
                          buttonText={t("reject")}
                        />
                        {getval1 && isGenCount !== 0 ? (
                          <Popover
                            overlayClassName="accept-popover"
                            content={t("accept_popup_content")}
                            arrow={true}
                            trigger="hover"
                            placement="top"
                            open={open}
                            onOpenChange={handleOpen}
                          >
                            <button className="button" onClick={handleTeamNofications}>
                              {t("accept_later")}
                            </button>
                          </Popover>
                        ) : (
                          <Button
                            buttonClick={() =>
                              handleConfirm(invite?.inviteId, true)
                            }
                            buttonClass="button"
                            isBtnDisabled={false}
                            buttonText={t("accept")}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="team-notifications__list__nodata">
                <p>{t("no_team_notifications")}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamNotifications;
