import React from "react";
import "./TooltipBottom.scss";

const InfoTooltipBottom = ({ TooltipContent }) => {

  const contentLines = TooltipContent?.split("/n");

  return (
    <div className="tooltipbi">
      <div className="tooltipb__container">
        {contentLines?.map((item, index) => (
          <p key={index}>{item?.trim()}</p>
        ))}
      </div>
      <div className="tooltipb__section">
        <div className="tooltipb__arrow"></div>
      </div>
    </div>
  );
};

export default InfoTooltipBottom;
